import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ActivityViewDetailsPopup } from './ActivityViewDetailsPopup';
import { CourseViewDetailsPopup } from './CourseViewDetailsPopup';
import { HonorViewDetailsPopup } from './HonorViewDetailsPopup';
import useNicheStore from '../../../../stores/NicheStore';
import { ReactComponent as NicheZeroDetailsState } from '../../../../assets/svgs/nicheZeroDetailsState.svg';

export const ViewDetailsPopup = ({
  utilityData = {},
  nicheName = '',
  isParent = false,
}) => {
  const nicheStore = useNicheStore();
  return (
    <div
      className={`absolute bg-white flex flex-col h-3/6 left-0 bottom-0 w-[fill] border border-[#F4F4F4] rounded-2xl m-4 drop-shadow-lg z-[100]`}
    >
      {Object.keys(utilityData).length > 0 &&
        utilityData?.type === 'ACTIVITY' && (
          <ActivityViewDetailsPopup
            utilityData={utilityData}
            onClose={() => nicheStore.setIsViewDetailPopup(false)}
            isParent={isParent}
          />
        )}
      {Object.keys(utilityData).length > 0 &&
        utilityData?.type === 'COURSE' && (
          <CourseViewDetailsPopup
            utilityData={utilityData}
            nicheName={nicheName}
            onClose={() => nicheStore.setIsViewDetailPopup(false)}
            isParent={isParent}
          />
        )}
      {Object.keys(utilityData).length > 0 && utilityData?.type === 'HONOR' && (
        <HonorViewDetailsPopup
          utilityData={utilityData}
          onClose={() => nicheStore.setIsViewDetailPopup(false)}
          isParent={isParent}
        />
      )}
      {Object.keys(utilityData).length === 0 && (
        <>
          <div className="flex justify-end ">
            <IconButton
              aria-label="Edit"
              onClick={() => nicheStore.setIsViewDetailPopup(false)}
            >
              <CloseIcon className="cursor-pointer text-base text-[#757575]" />
            </IconButton>
          </div>

          <div className="text-[#22252D] flex-1 flex flex-col items-center justify-center">
            <NicheZeroDetailsState />
            <div>Click an Activity, Honor or Course to view its details</div>
          </div>
        </>
      )}
    </div>
  );
};
