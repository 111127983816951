import { Tab as TAB } from '@mui/material';
import { styled } from '@mui/system';
import Tooltip from './Tooltip';

const StyledTab = styled(TAB)(() => ({
  textTransform: 'none',
  height: '40px',
  minHeight: '40px',
  color: '#838489',
  ':hover': {
    color: '#494E5B',
    '.MuiTab-iconWrapper': {
      backgroundColor: '#494E5B',
    },
  },
  '&.Mui-disabled': {
    color: '#838489',
  },
  '&.Mui-selected': {
    color: '#22252D',
  },
  '.MuiTab-iconWrapper': {
    backgroundColor: '#838489',
    marginRight: '4px',
    ':hover': {
      backgroundColor: '#494E5B',
    },
  },
  '&.Mui-disabled .MuiTab-iconWrapper': {
    backgroundColor: '#838489',
  },
  '&.Mui-selected .MuiTab-iconWrapper': {
    backgroundColor: '#22252D',
  },
  '.MuiSvgIcon-root': {
    color: 'white',
    width: '8px',
    height: '8px',
  },
}));

const Tab = ({ icon, iconPosition = 'start', ...props }) => {
  const Icon = icon && (
    <div className="w-5 h-5 rounded flex justify-center items-center">
      {icon}
    </div>
  );

  return (
    <Tooltip
      title={
        props.label === '150 Characters'
          ? 'Add your 150 characters version'
          : props.label === '350 characters'
          ? 'Add your 350 characters version'
          : props.label === '100 Characters'
          ? 'Add your 100 characters version'
          : props.label === '250 Characters'
          ? 'Add your 250 characters version'
          : ''
      }
    >
      <StyledTab icon={Icon} iconPosition={iconPosition} {...props} />
    </Tooltip>
  );
};

export default Tab;
