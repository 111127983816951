import CloseIcon from '@mui/icons-material/Close';
import {
  Divider,
  FormControlLabel,
  InputAdornment,
  FormControl,
  OutlinedInput,
  InputLabel,
} from '@mui/material';
import Button from '../../shared/Button';
import { useState, useEffect } from 'react';
import { Search } from '@mui/icons-material';
import { Checkbox } from '../../shared/Checkbox';

const AddUtilityToNiche = ({
  courseData = [],
  activityData = [],
  honorData = [],
  onClose = () => {},
  options = [],
  nicheId = '',
  label = '',
  setAddUtiltytoNiche = () => {},
  canCreateNewUtilities = () => {},
}) => {
  const [activityTitle, setActivityTitle] = useState('');
  const [courseTitle, setCourseTitle] = useState('');
  const [honorTitle, setHonorTitle] = useState('');
  const [isUtilityChecked, setIsUtilityChecked] = useState([]);
  const [filteredUtilities, setFilteredUtilities] = useState({
    activity: activityData,
    honor: honorData,
    course: courseData,
  });

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    activityData.map((activity) => {
      if (activity.nicheId === '' || activity.nicheId === 'None') {
        setActivityTitle('Activities');
      }
    });
    // eslint-disable-next-line array-callback-return
    courseData.map((course) => {
      if (course.nicheId === '' || course.nicheId === 'None') {
        setCourseTitle('Course');
      }
    });
    // eslint-disable-next-line array-callback-return
    honorData.map((honor) => {
      if (honor.nicheId === '' || honor.nicheId === 'None') {
        setHonorTitle('Honors');
      }
    });
  });

  const getCheckedValue = (id, type, checked, value) => {
    if (checked) {
      setIsUtilityChecked([
        ...isUtilityChecked,
        {
          type: type,
          data: value,
        },
      ]);
    } else {
      setIsUtilityChecked(
        isUtilityChecked.filter((item) => item.data.id !== id)
      );
    }
  };

  const clearAll = () => {
    const emptyArr = [];
    setIsUtilityChecked(emptyArr);
  };

  const filterUtilities = (searchKeyword) => {
    if (searchKeyword !== '') {
      const filteredActivities = activityData.filter((activityObj) =>
        activityObj?.activityName.toLowerCase().includes(searchKeyword)
      );
      const filteredHonors = honorData.filter((honorObj) =>
        honorObj?.honorName.toLowerCase().includes(searchKeyword)
      );
      const filteredCourses = courseData.filter((courseObj) =>
        courseObj.courseName.toLowerCase().includes(searchKeyword)
      );
      setFilteredUtilities({
        activity: filteredActivities,
        honor: filteredHonors,
        course: filteredCourses,
      });
    } else {
      setFilteredUtilities({
        activity: activityData,
        honor: honorData,
        course: courseData,
      });
    }
  };

  return (
    <div
      className="flex items-center w-96 border border-[#F4F4F4] rounded-2xl bg-white py-6 z-50"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex flex-1 flex-col gap-8">
        <div className="px-6 flex justify-between">
          <div className="font-bold text-xl text-[#2C303C]">
            Add Unassigned Items
          </div>
          <div
            className="text-[#757575] cursor-pointer"
            onClick={(e) => onClose(e)}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="px-6 flex flex-col flex-1 gap-5">
          <FormControl variant="outlined" className="w-full">
            <InputLabel htmlFor="outlined-adornment-search">Search</InputLabel>
            <OutlinedInput
              className="w-full"
              id="outlined-basic"
              label="Search"
              variant="outlined"
              endAdornment={
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              }
              onChange={(e) => filterUtilities(e.target.value.toLowerCase())}
            />
          </FormControl>
          <div className="flex flex-col h-[150px] px-4 gap-5 overflow-y-auto">
            <div className="flex flex-col gap-2">
              {filteredUtilities?.activity.length > 0 && (
                <div className="text-xs opacity-50 text-grey-900">
                  {activityTitle && activityTitle}
                </div>
              )}
              {filteredUtilities.activity &&
                filteredUtilities.activity.map((activity, index) => {
                  return (
                    (activity.nicheId === '' ||
                      activity.nicheId === 'None') && (
                      <div className="flex">
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              key={activity.id}
                              checked={Boolean(
                                isUtilityChecked.find(
                                  (utility) => utility.data.id === activity.id
                                )
                              )}
                              value={activity.id}
                              onChange={(e) => {
                                e.stopPropagation();
                                getCheckedValue(
                                  activity.id,
                                  'ACTIVITY',
                                  e.target.checked,
                                  activity
                                );
                              }}
                              inputProps={{ 'aria-label': 'controlled' }}
                              className="cursor-pointer"
                            />
                          }
                          label={
                            <div className="text-sm opacity-70 text-[#22252D]">
                              {activity.activityName}
                            </div>
                          }
                        />
                      </div>
                    )
                  );
                })}
            </div>
            <div className="flex flex-col gap-2">
              {filteredUtilities?.course.length > 0 && (
                <div className="text-xs opacity-50 text-[#22252D]">
                  {courseTitle && courseTitle}
                </div>
              )}
              {filteredUtilities.course &&
                filteredUtilities.course.map((course, index) => {
                  return (
                    (course.nicheId === '' || course.nicheId === 'None') && (
                      <div className="flex">
                        <FormControlLabel
                          control={
                            <Checkbox
                              key={course.id}
                              checked={Boolean(
                                isUtilityChecked.find(
                                  (utility) => utility.data.id === course.id
                                )
                              )}
                              onChange={(e) => {
                                getCheckedValue(
                                  course.id,
                                  'COURSE',
                                  e.target.checked,
                                  course
                                );
                              }}
                              inputProps={{ 'aria-label': 'controlled' }}
                              className="cursor-pointer"
                            />
                          }
                          label={
                            <div className="text-sm opacity-70 text-[#22252D]">
                              {course.courseName}
                            </div>
                          }
                        />
                      </div>
                    )
                  );
                })}
            </div>
            <div className="flex flex-col gap-2">
              {filteredUtilities?.honor.length > 0 && (
                <div className="text-xs opacity-50 text-grey-900">
                  {honorTitle && honorTitle}
                </div>
              )}
              {filteredUtilities.honor &&
                filteredUtilities.honor.map((honor, index) => {
                  return (
                    (honor.nicheId === '' || honor.nicheId === 'None') && (
                      <div className="flex">
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={honor.id}
                              key={honor.id}
                              checked={Boolean(
                                isUtilityChecked.find(
                                  (utility) => utility.data.id === honor.id
                                )
                              )}
                              onChange={(e) => {
                                getCheckedValue(
                                  honor.id,
                                  'HONOR',
                                  e.target.checked,
                                  honor
                                );
                              }}
                              inputProps={{ 'aria-label': 'controlled' }}
                              className="cursor-pointer text-[#22252D]"
                            />
                          }
                          label={
                            <div className="text-sm opacity-70 text-[#22252D]">
                              {honor.honorName !== '' && honor.honorName}
                            </div>
                          }
                        />
                      </div>
                    )
                  );
                })}
            </div>
            {activityTitle === '' &&
              honorTitle === '' &&
              courseTitle === '' && (
                <div className="text-sm opacity-70 text-[#22252D]">
                  You are yet to add new Activities, Honors or Courses.
                </div>
              )}
          </div>
        </div>
        <div>
          <Divider
            flexItem
            sx={{
              borderColor: '#D6D6D8',
            }}
          />
        </div>
        <div className="flex items-center justify-end gap-4 px-6">
          <div>
            <Button
              variant="text"
              disabled={isUtilityChecked.length === 0}
              className={`${
                isUtilityChecked.length === 0 ? 'opacity-0' : 'opacity-30'
              }font-medium text-sm text-[#22252D] cursor-pointer`}
              onClick={clearAll}
            >
              Clear All
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              disabled={isUtilityChecked.length === 0}
              onClick={async () => {
                isUtilityChecked.map(async (utility) => {
                  await setAddUtiltytoNiche(
                    utility.data,
                    utility.type,
                    nicheId
                  );
                  onClose();
                });
              }}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUtilityToNiche;
