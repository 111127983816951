import { styled } from '@mui/system';
import { Alert } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useRef, useState } from 'react';
import { ReactComponent as DropDown } from '../../assets/svgs/addDetail.svg';

const CustomInfoIcon = styled(InfoIcon)(() => ({
  color: '#22252D',
  opacity: '0.7',
}));

const CustomAlert = styled(Alert)(() => ({
  backgroundColor: '#F0F2F8',
  boxShadow:
    '0.5px 0px 2px rgba(0, 0, 0, 0.05), 0px 0.5px 2px rgba(0, 0, 0, 0.05), 0px 0.5px 2px rgba(0, 0, 0, 0.05)',
  borderRadius: '8px',
  padding: '8px',
  gap: '10px',
  '& .MuiAlert-icon': {
    margin: '0px',
    padding: '0px',
    display: 'inline-block',
  },
  '& .MuiAlert-message': {
    padding: '0px',
    display: 'inline-flex',
    alignItems: 'center',
  },
}));

const AddMoreDetails = ({
  expanded = false,
  title = 'Add details',
  alert = `You can add these details later if you'd like.`,
  children,
}) => {
  const [parent] = useAutoAnimate(/* optional config */);
  const [isExpanded, setIsExpanded] = useState(expanded);
  const ref = useRef(null);
  // useEffect(() => {
  //   if (isExpanded) {
  //     ref.current?.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [ref, isExpanded]);

  return (
    <div className="flex flex-col gap-8" ref={ref}>
      <div className="flex flex-col gap-2">
        <div
          className="text-sm text-lead-500 flex items-center gap-2 font-medium underline underline-offset-1 cursor-pointer"
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          <div>{title}</div>
          <DropDown
            className={`${
              isExpanded && 'rotate-180 transition duration-500 ease-in'
            }`}
          />
        </div>
        {!isExpanded && (
          <div>
            <CustomAlert icon={<CustomInfoIcon />}>
              <div className="text-[13px] text-[#22252D] opacity-70 p-0">
                {alert}
              </div>
            </CustomAlert>
          </div>
        )}
        <div
          ref={parent}
          className="overflow-hidden transition duration-1000 ease-in"
        >
          {isExpanded && children}
        </div>
      </div>
    </div>
  );
};

export default AddMoreDetails;
