import { Snackbar as SNACKBAR } from '@mui/material';
import { ReactComponent as FunFactIcon } from '../../assets/svgs/funfacticon.svg';
import { styled } from '@mui/system';
import useActivityStore from '../../stores/ActivityStore';

const StyledSnackbar = styled(SNACKBAR)(() => ({
  boxShadow:
    '1px 4px 6px rgba(0, 0, 0, 0.05), 1px 4px 6px rgba(0, 0, 0, 0.05), 1px 4px 6px rgba(0, 0, 0, 0.05), 0px 2px 6px rgba(0, 0, 0, 0.05)',
  backgroundColor: '#FDC24F',
  borderRadius: 8,
  flex: 1,
}));

const FunFactSnackbar = ({
  action,
  autoHideDuration = '4000',
  message = '',
  ...props
}) => {
  const activityStore = useActivityStore();
  return (
    <StyledSnackbar {...props} autoHideDuration={autoHideDuration}>
      <div className="flex gap-3 items-center h-12 px-4 rounded-lg">
        <FunFactIcon />
        <div className="flex flex-1 items-center gap-1 text-sm text-[#22252D]">
          <div className=" whitespace-nowrap ">
            {activityStore.isFunFactSnackbarOpen ? (
              <>
                <span className="font-semibold">Fun Fact: </span>
                {/* The Common App
                colleges allow up to 10 Activities, and the University <br />
                of California colleges allow up to 20 Activities and Honors
                combined. */}
                {message}
              </>
            ) : (
              <>
                <span className="font-semibold">Fun Fact: </span>
                {message}
              </>
            )}
          </div>
        </div>
        {action && <div className="text-center cursor-pointer">{action}</div>}
      </div>
    </StyledSnackbar>
  );
};

export default FunFactSnackbar;
