import MyStoryDocs from '../MyStoryDocs';
import HonorDocsGuidelines from './HonorDocsGuidelines';
import MyStoryHonorDocsTabs from './MyStoryHonorDocsTabs';
import MyStoryHonorDocsFooter from '../MyStoryHonorDocsFooter';
import { useState } from 'react';
import { Dialog } from '@mui/material';
import MyStoryHonorDocsHeader from './MyStoryHonorDocsHeader';
import { honorDocCharVersion } from '../../../constants/staticData';
import useMyTeamStore from '../../../stores/MyTeamStore';
import useStudentStore from '../../../stores/StudentStore';

const MyStoryHonorDocs = ({
  id = '',
  title = '',
  nicheName = '',
  grades = [],
  honorTypes = [],
  docs = [],
  reviewers = [],
  onBack = () => {},
  onAddTabBtnClick = () => {},
  onEditHeader = () => {},
  onEdit = () => {},
  isParent = false,
  canCreateNewUtilities = () => {},
  docInformation = [],
  docsKeys = [],
  loadingState = '',
  isMobile = false,
  ...props
}) => {
  const [selectedTab, setSelectedTab] = useState(null);
  const myTeamStore = useMyTeamStore();
  const studentStore = useStudentStore();
  const [isSelectedInfo, setIsSelectedInfo] = useState(isMobile ? false : true);
  const handleInfo = () => {
    setIsSelectedInfo(!isSelectedInfo);
  };
  const tabLabels =
    docs.length > 1
      ? [honorDocCharVersion.CHAR_100, honorDocCharVersion.CHAR_250]
      : docs.length > 0
      ? [
          honorDocCharVersion.CHAR_100.includes(docsKeys[0])
            ? honorDocCharVersion.CHAR_100
            : honorDocCharVersion.CHAR_250,
        ]
      : [];

  return (
    <Dialog
      fullScreen={true}
      disableRestoreFocus={true}
      disableEnforceFocus={true}
      style={{
        zIndex: 80,
      }}
      {...props}
    >
      <MyStoryDocs
        onAddTabBtnClick={onAddTabBtnClick}
        isParent={isParent}
        placeholderText="Start writing your activity here..."
        docsVersions={Object.values(honorDocCharVersion)}
        id={id}
        header={
          <MyStoryHonorDocsHeader
            id={id}
            title={title}
            isParent={isParent}
            nicheName={nicheName}
            onBack={onBack}
            onEdit={onEdit}
            onInfo={handleInfo}
            isSelectedInfo={isSelectedInfo}
            onEditHeader={onEditHeader}
            canCreateNewUtilities={canCreateNewUtilities}
          />
        }
        tabs={
          <MyStoryHonorDocsTabs
            tabLabels={tabLabels}
            onSelectedTabChange={setSelectedTab}
            onAddTabBtnClick={onAddTabBtnClick}
            isParent={isParent}
            loadingState={loadingState}
            docsVersions={Object.values(honorDocCharVersion)}
          />
        }
        docLink={docs[selectedTab]}
        docInformation={docInformation}
        docGuidelines={<HonorDocsGuidelines />}
        footer={
          <MyStoryHonorDocsFooter
            id={id}
            grades={grades}
            honorTypes={honorTypes}
            reviewers={reviewers}
            editIcon={true}
            onEdit={onEdit}
            isParent={isParent}
          />
        }
        isSelectedInfo={isSelectedInfo}
        chatPromptUtilName="Honors"
        {...props}
      />
    </Dialog>
  );
};

export default MyStoryHonorDocs;
