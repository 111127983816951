import { useState } from 'react';
import { localKeys, secretKeys } from '../constants/localStorage';
import { ApiRoutes } from '../firebase/apis';
import { axiosPost } from '../firebase/axios';
import { editProfile } from '../firebase/services/updateServices';
import useProfileStore from '../stores/ProfileStore';
import {
  getDecryptedLocalData,
  setEncryptedLocalData,
} from '../utils/localStorage';
import useAuth from './use-auth';
import { openSnackbar } from '../components/shared/GlobalSnackbar/GlobalSnackbar';

const useGoogleAuthorization = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { tokenExpireHandler, user } = useAuth();
  const { profile, setProfile } = useProfileStore();

  const tokens = getDecryptedLocalData(
    localKeys.GOOGLE_OAUTH,
    secretKeys.GOOGLE_OAUTH
  );

  const onSuccess = async (response) => {
    const { code } = response;
    try {
      const res = await axiosPost(ApiRoutes.GOOGLE_AUTH, { code });
      setIsLoading(false);
      if (res.status && res.data.access_token) {
        const { access_token, expiry_date, refresh_token } = res.data;
        setEncryptedLocalData(
          access_token,
          localKeys.GOOGLE_OAUTH,
          secretKeys.GOOGLE_OAUTH
        );
        setEncryptedLocalData(
          { ...res.data },
          localKeys.GOOGLE_OAUTH_DATA,
          secretKeys.GOOGLE_OAUTH_DATA
        );
        await editProfile({
          id: profile?.id,
          rt: refresh_token,
          t_expire: expiry_date,
        });
        setProfile({
          ...profile,
          rt: refresh_token,
          t_expire: expiry_date,
        });
        await tokenExpireHandler(expiry_date, refresh_token);
      } else {
        await onInitAuthorization();
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onInitAuthorization = () => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      try {
        const client = window.google.accounts.oauth2.initCodeClient({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          scope:
            'https://www.googleapis.com/auth/documents https://www.googleapis.com/auth/drive.file',
          callback: (e) => {
            onSuccess(e)
            resolve(true)
          },
          hint: user?.email,
          error_callback: (err) => {
            if (err.type === "popup_closed") {
              console.warn("The popup was closed by the user.");
              openSnackbar("Grant access to create and edit essay documents", "alert");
            } else {
              console.error("An unexpected error occurred:", err.message);
              openSnackbar("An unexpected error occurred", "error");
            }
            setIsLoading(false);
            resolve(false); // Resolve with false on error
          },
          select_account: null,
        });
        client.requestCode();
      } catch (error) {
        console.error('Error during OAuth:', error);
        setIsLoading(false);
        reject(false); // Reject with false on unexpected error
      }
    });
  };


  return { onInitAuthorization, tokens, isLoading };
};

export default useGoogleAuthorization;
