import { useEffect, useState } from 'react';
import { AddNewACTScore } from './AddNewACTScore';
import { checkWrongScore, checkWrongTarget } from '../../../utils/helper';
const { default: Dialog } = require('../../shared/Dialog');

const AddNewACTScoreDialog = ({
  actScores = [],
  actSubjects = [],
  actTarget = [],
  onContinue = () => {},
  isAddingACTTarget = false,
  isEditingACTTarget = false,
  scoreData = [],
  targetData = [],
  isAddDialog = false,
  isTargetDialog = false,
  onCancel = () => {},
  ...props
}) => {
  const [actScore, setACTScore] = useState([]);
  const [actTargetScore, setACTTargetScore] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isContinue, setIsContinue] = useState(false);
  const onChangeScore = (actScoreData) => {
    if (!actScoreData?.testDate) {
      setIsError(true);
    } else {
      setIsError(false);
    }
    setIsError(checkWrongScore(actSubjects, actScoreData));
    setACTScore(actScoreData);
  };
  const onChangeTarget = (actTargetData) => {
    setIsError(checkWrongTarget(actSubjects, actTargetData));
    setACTTargetScore(actTargetData);
  };

  useEffect(() => {
    if (isAddDialog) {
      setACTScore([]);
      setIsError(false);
      setIsContinue(false);
    }
  }, [isAddDialog]);

  return (
    <Dialog
      title={`${
        isAddDialog ||
        scoreData[0]?.scores?.length === 0 ||
        scoreData[0]?.testDate === ''
          ? 'Add ACT'
          : 'Edit ACT'
      } ${isTargetDialog ? 'Target Score' : 'Score'}  `}
      isHeight={true}
      maxWidth="sm"
      onContinue={() => {
        setIsContinue(true);
        if (!isError) {
          if (
            actScores.length !== 0 &&
            (actScore.length !== 0 ||
              ((isAddingACTTarget || isEditingACTTarget) &&
                (actTargetScore.length !== 0 || actTargetScore.length === 0)))
          ) {
            onContinue(actScore, actTargetScore);
            setACTScore([]);
            setACTTargetScore([]);
            setIsError(false);
            setIsContinue(false);
          } else if (actScores.length === 0) {
            if (actScore.length === 0) {
              onContinue(
                {
                  scores: [],
                  testDate: '',
                },
                actTargetScore
              );
            } else {
              onContinue(actScore, actTargetScore);
            }
            setACTScore([]);
            setACTTargetScore([]);
            setIsError(false);
            setIsContinue(false);
          }
        }
      }}
      onCancel={() => {
        onCancel();
        setACTScore([]);
        setACTTargetScore([]);
        setIsContinue(false);
        setIsError(false);
      }}
      continueText={
        isAddDialog ||
        scoreData[0]?.scores?.length === 0 ||
        scoreData[0]?.testDate === ''
          ? 'Add'
          : 'Update'
      }
      {...props}
    >
      <AddNewACTScore
        actScores={actScores}
        actSubjects={actSubjects}
        actTarget={actTarget}
        isContinue={isContinue}
        isAddDialog={isAddDialog}
        onChangeScore={onChangeScore}
        onChangeTarget={onChangeTarget}
        isAddingACTTarget={isAddingACTTarget}
        isEditingACTTarget={isEditingACTTarget}
        scoreData={scoreData[0]}
        targetData={targetData[0]}
      />
    </Dialog>
  );
};

export default AddNewACTScoreDialog;
