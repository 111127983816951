import { useState } from 'react';
import MyStoryDocs from '../MyStoryDocs';
import MyStoryActivityDocsHeader from './MyStoryActivityDocsHeader';
import MyStoryActivityDocsFooter from './MyStoryActivityDocsFooter';
import ActivityDocsGuidelines from './ActivityDocsGuidelines';
import MyStoryActivityDocsTabs from './MyStoryActivityDocsTabs';
import { Dialog } from '@mui/material';
import { activityDocCharVersion } from '../../../constants/staticData';

const MyStoryActivityDocs = ({
  id = '',
  title = '',
  nicheName = '',
  grades = [],
  durationHoursPerWeek = '',
  durationWeeksPerYear = '',
  activityTypes = [],
  reviewers = [],
  docs = [],
  docsKeys = [],
  onBack = () => {},
  onAddTabBtnClick = () => {},
  onEdit = () => {},
  onEditHeader = () => {},
  isParent = false,
  canCreateNewUtilities = () => {},
  docInformation = [],
  loadingState = '',
  isMobile = false,
  ...props
}) => {
  const [selectedTab, setSelectedTab] = useState(null);
  const [isSelectedInfo, setIsSelectedInfo] = useState(isMobile ? false : true);
  const handleInfo = () => {
    setIsSelectedInfo(!isSelectedInfo);
  };

  const tabLabels =
    docs.length > 1
      ? [activityDocCharVersion.CHAR_150, activityDocCharVersion.CHAR_350]
      : docs.length > 0
      ? [
          activityDocCharVersion.CHAR_150.includes(docsKeys[0])
            ? activityDocCharVersion.CHAR_150
            : activityDocCharVersion.CHAR_350,
        ]
      : [];

  return (
    <Dialog
      fullScreen={true}
      disableRestoreFocus={true}
      disableEnforceFocus={true}
      style={{
        zIndex: 80,
      }}
      {...props}
    >
      <MyStoryDocs
        onAddTabBtnClick={onAddTabBtnClick}
        docsVersions={Object.values(activityDocCharVersion)}
        docInformation={docInformation}
        id={id}
        placeholderText="Start writing your activity here..."
        header={
          <MyStoryActivityDocsHeader
            id={id}
            title={title}
            nicheName={nicheName}
            onBack={onBack}
            onEdit={onEdit}
            onInfo={handleInfo}
            isSelectedInfo={isSelectedInfo}
            onEditHeader={onEditHeader}
            isParent={isParent}
            canCreateNewUtilities={canCreateNewUtilities}
          />
        }
        tabs={
          <MyStoryActivityDocsTabs
            tabLabels={tabLabels}
            loadingState={loadingState}
            onSelectedTabChange={setSelectedTab}
            onAddTabBtnClick={onAddTabBtnClick}
            isParent={isParent}
            docsVersions={Object.values(activityDocCharVersion)}
          />
        }
        docLink={docs[selectedTab]}
        docGuidelines={
          <ActivityDocsGuidelines isSelectedInfo={isSelectedInfo} />
        }
        footer={
          <MyStoryActivityDocsFooter
            id={id}
            grades={grades}
            durationHoursPerWeek={durationHoursPerWeek}
            durationWeeksPerYear={durationWeeksPerYear}
            activityTypes={activityTypes}
            reviewers={reviewers}
            editIcon={true}
            onEdit={onEdit}
            isParent={isParent}
          />
        }
        isSelectedInfo={isSelectedInfo}
        isParent={isParent}
        chatPromptUtilName="Activities"
        {...props}
      />
    </Dialog>
  );
};

export default MyStoryActivityDocs;
