import { ToggleButtonGroup as TOGGLEBUTTONGROUP } from '@mui/material';
import { styled } from '@mui/system';

const ToggleButtonGroup = styled(TOGGLEBUTTONGROUP)(() => ({
  backgroundColor: '#E9E9EA',
  padding: 2,
  borderRadius: 8,
  gap: 4,
}));

export default ToggleButtonGroup;
