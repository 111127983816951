import { useEffect, useState } from 'react';
import InputWithTooltip from '../../shared/InputWithTooltip';
import ChooseNiche from '../../shared/ChooseNiche';
import AddMoreCourseDetails from './AddMoreCourseDetails';
import SelectTeamMembers from '../../shared/SelectTeamMembers';
import ErrorIcon from '@mui/icons-material/Error';
import { useLocation } from 'react-router-dom';
import Alert from '../../shared/Alert';

const AddNewCourse = ({
  niches = [],
  editMode = false,
  onChange = () => {},
  courseData = {},
  collaborators = [],
  selectedCollaborators = [],
  isCourseName = false,
  isAdding,
}) => {
  const [formData, setFormData] = useState({
    courseName: courseData.courseName || '',
    nicheId: courseData.nicheId || '',
    description: courseData.description || '',
    grades: courseData.grades || [],
    reviewers: courseData.reviewers || [],
  });
  const location = useLocation();
  function defaultTeamMembers() {
    if (isAdding) {
      return collaborators;
    }
    return selectedCollaborators;
  }
  const [selectedTeamMembers, setSelectedTeamMembers] = useState(
    defaultTeamMembers()
  );
  useEffect(() => {
    setFormData({
      ...formData,
      sharedWith: selectedTeamMembers.map((teamMember) => {
        return {
          id: teamMember?.id,
          email: teamMember?.email,
          photoURL: teamMember?.photoURL,
          uid: teamMember?.id,
        };
      }),
    });
    // eslint-disable-next-line
  }, [selectedTeamMembers]);

  useEffect(() => {
    onChange(formData);
    // eslint-disable-next-line
  }, [formData]);

  const tooltipTitle = (
    <div className="flex flex-col gap-3 text-sm">
      <div>
        Here’s the place to add any coursework that was or is especially
        compelling to you.{' '}
      </div>

      <div>
        Eventually you’ll put all your coursework into your college
        applications, but for now let’s just capture the classes that make your
        mind buzz.{' '}
      </div>
    </div>
  );

  return (
    <div className="flex flex-col gap-8 py-2">
      {isAdding && location.pathname === '/myStory/niche' && (
        <Alert className="w-fit mt-3" severity="info">
          Note: Adding Courses from Niche will also create that item in your
          Courses section. You’ll also be able to add further details and
          collaborate in the Courses section.
        </Alert>
      )}
      {isAdding && location.pathname === '/myHub' && (
        <Alert className="w-fit mt-3" severity="info">
          Note: Adding Courses from the Hub will also create that item in your
          Courses section. You’ll also be able to add further details and
          collaborate in the Courses section.
        </Alert>
      )}
      <InputWithTooltip
        label="Course"
        error={isCourseName}
        required
        placeholder="E.g. AP Biology"
        onChange={(e) => {
          setFormData({ ...formData, courseName: e.target.value });
        }}
        value={formData.courseName}
        title={courseData?.courseName ? '' : tooltipTitle}
        helperText={
          isCourseName && (
            <div className="flex items-center gap-1 text-xs">
              <ErrorIcon color="error" className="text-base" />{' '}
              {`Enter Course Title`}
            </div>
          )
        }
      />
      <ChooseNiche
        niches={niches}
        utilityName="Course"
        data={formData}
        selectedNiche={[formData.nicheId]}
        onChange={(e, niche) => {
          if (niche !== null) {
            setFormData({ ...formData, nicheId: niche });
          } else {
            setFormData({ ...formData, nicheId: '' });
          }
        }}
        onNicheAdd={() => {}}
      />

      <AddMoreCourseDetails
        expanded={editMode}
        description={formData.description}
        grades={formData.grades}
        placeholder="E.g. I had the highest grade in the class and won an award for my term project."
        onChange={({ description, grades }) => {
          setFormData({ ...formData, description, grades });
        }}
      />
      <SelectTeamMembers
        label="Select Team Members (optional)"
        options={collaborators}
        value={selectedTeamMembers}
        menuListTitle="Select your Existing Team Members"
        onChange={(e, teamMembers) => {
          setSelectedTeamMembers(teamMembers);
        }}
        tooltipTitle={`You can select or add Team Members with whom you wish to collaborate`}
      />
    </div>
  );
};

export default AddNewCourse;
