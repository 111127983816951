import { useEffect, useState } from 'react';
import AddMoreDetails from '../../shared/AddMoreDetails';
import GradeSelector from '../../shared/GradeSelector';
import HonorLevelTagInput from './HonorLevelTagInput';
import useProfileStore from '../../../stores/ProfileStore';

const AddMoreHonorDetails = ({
  expanded,
  grades = [],
  allHonorTypes = [],
  honorTypes = [],
  onChange = () => {},
}) => {
  const [inputBoxValue, setInputBoxValue] = useState('');
  const onInputChange = (event, newInputValue) => {
    setInputBoxValue(newInputValue);
  };
  const onHonorTypeChange = (event, honorTypes) => {
    setHonorDetails({ ...honorDetails, honorTypes });
  };

  const [honorDetails, setHonorDetails] = useState({
    grades,
    honorTypes,
  });

  useEffect(() => {
    onChange(honorDetails);
    // eslint-disable-next-line
  }, [honorDetails]);

  const profileStore = useProfileStore();
  return (
    <>
      <div className="flex flex-col gap-8">
        <GradeSelector
          grades={honorDetails.grades}
          gradeSelecterTitle="When was the honor received?"
          onChange={(grades) => {
            setHonorDetails({ ...honorDetails, grades });
          }}
          currentYear={`${profileStore?.profile?.grade}th`}
        />
        <HonorLevelTagInput
          tooltipTitle="Use the drop down list to enter the level at which this competition or honor took place."
          label="Level of Honor"
          placeholder={
            honorDetails.honorTypes.length === 0 ? 'E.g. State/Regional' : ''
          }
          options={allHonorTypes}
          inputValue={inputBoxValue}
          onInputChange={onInputChange}
          value={honorDetails.honorTypes}
          onChange={onHonorTypeChange}
        />
      </div>
    </>
  );
};

export default AddMoreHonorDetails;
