import { useShallow } from "zustand/react/shallow"
import useProfileStore from "../../stores/ProfileStore"
import ProfileCompletionWidget from "./ProfileCompletionWidget"

const Heading = () => {
    const profile = useProfileStore(useShallow((state) => state.profile))
    return (
        <div className='flex items-center justify-between col-span-1 w-full'>
            <div className='text-base font-medium flex items-center gap-2 text-[#3A3A3A] py-2 rounded-full bg-amber-50 px-4'><span>👋</span> Welcome {profile?.firstName}!</div>
            <ProfileCompletionWidget/>
        </div>
    )
}


export default Heading