import { useState, useEffect } from 'react';
import AddMoreDetails from '../../shared/AddMoreDetails';
import TextAreaWithTooltip from '../../shared/TextAreaWithTooltip';
import GradeSelector from '../../shared/GradeSelector';
import useProfileStore from '../../../stores/ProfileStore';
const defaultTitle = (
  <div className="text-xs font-normal">
    Briefly describe this class and give us an idea of what made it compelling
    to you. Was it the teacher, a particular idea that it sparked, or just a
    feeling of overall satisfaction that made you love it? Colleges want to
    know!
  </div>
);
const AddMoreCourseDetails = ({
  expanded = false,
  description = '',
  grades = [],
  onChange = () => {},
  placeholder = '',
}) => {
  const [courseDetails, setCourseDetails] = useState({
    description,
    grades,
  });
  useEffect(() => {
    onChange(courseDetails);
    //eslint-disable-next-line
  }, [courseDetails]);

  const profileStore = useProfileStore();
  return (
    <>
      <div className="py-4">
        <TextAreaWithTooltip
          title={defaultTitle}
          maxLength="100"
          redIndicator={true}
          value={courseDetails.description}
          placeholder={placeholder}
          onChange={(e) => {
            setCourseDetails({ ...courseDetails, description: e.target.value });
          }}
        />
        <GradeSelector
          grades={courseDetails.grades}
          onChange={(grades) => {
            setCourseDetails({ ...courseDetails, grades });
          }}
          gradeSelecterTitle="Which years did you do or are planning to do this course?"
          currentYear={`${profileStore?.profile?.grade}th`}
        />
      </div>
    </>
  );
};

export default AddMoreCourseDetails;
