import DeleteDialog from '../../shared/DeleteDialog';

const DeleteScoreDialog = (args) => {
  return (
    <DeleteDialog title="Delete Actual Score?" {...args}>
      <div>Actual Score will be permanently deleted.</div>
    </DeleteDialog>
  );
};

export default DeleteScoreDialog;
