import React from 'react';
import Chip from '../../../shared/Chip';
import Tooltip from '../../../shared/Tooltip';
import { GenericMenu } from '../../../shared/GenericMenu/GenericMenu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { GenericTabs } from '../../../shared/GenericTabs/GenericTabs';
import { ChevronRightOutlined } from '@mui/icons-material';
import { useMyStoryStore } from '../../../../stores/myStoryStore';
import { useShallow } from 'zustand/react/shallow';
import { MyNicheSlideOutTips } from './myNicheSlideOutTips';
import { MyNicheSlidOutSettings } from './myNicheSlideOutSettings';
import { MyNicheSlideOutNotes } from './myNicheSlideOutNotes';
import MenuItem from '../../../shared/MenuItem';
import TeamMemberAvatarGroup from '../../../shared/TeamMemberAvatarGroup';
import IosShareIcon from '@mui/icons-material/IosShare';
import { Avatar, IconButton } from '@mui/material';
import { avatarTitle } from '../../../shared/TeamMembersGroup';
import useMyTeamStore from '../../../../stores/MyTeamStore';
import {
  UTILITY_TYPES,
  filterUniAndTeamUniversily,
} from '../../../../utils/utlityTypes';
import useMyHubStore from '../../../../stores/MyHubStore';
import useNicheStore from '../../../../stores/NicheStore';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
export const MyNicheSlideOut = () => {
  const tabs = [
    { label: 'Notes', content: <MyNicheSlideOutNotes /> },
    { label: 'Details', content: <MyNicheSlidOutSettings /> },
    { label: 'Tips', content: <MyNicheSlideOutTips /> },
  ];
  const { setStorySlideOut, setActiveSlideOutTabIndex } = useMyStoryStore(
    useShallow((state) => ({
      setStorySlideOut: state.setStorySlideOut,
      setActiveSlideOutTabIndex: state.setActiveSlideOutTabIndex,
    }))
  );
  const getNicheData = (nicheId) => {
    if (nicheId) {
      const niche = (nicheStore.niches || []).find(
        (niche) => niche.id === nicheId
      );
      return niche;
    }
  };

  const myTeamStore = useMyTeamStore(
    useShallow((state) => ({
      myTeam: state.myTeam,
    }))
  );
  const myHubStore = useMyHubStore(
    useShallow((state) => ({
      isShareUtility: state.isShareUtility,
      utilityId: state.utilityId,
      setIsShareUtility: state.setIsShareUtility,
      setUtilityId: state.setUtilityId,
      setUtilityType: state.setUtilityType,
      setUtilityName: state.setUtilityName,
    }))
  );

  const nicheStore = useNicheStore(
    useShallow((state) => ({
      editNichedId: state.editNicheId,
      niches: state.niches,
      setIsAddUtilityToNiche: state.setIsAddUtilityToNiche,
      setDeleteNicheId: state.setDeleteNicheId,
      setIsDeletingNiche: state.setIsDeletingNiche,
    }))
  );

  const menuItems = [
    {
      label: 'Add Unassigned Items',
      icon: <MenuItem.ShareIcon />,
      action: () => {
        nicheStore.setIsAddUtilityToNiche(nicheStore.editNichedId);
      },
    },
    {
      label: 'Delete',
      icon: <MenuItem.DeleteIcon />,
      action: () => {
        nicheStore.setDeleteNicheId(nicheStore.editNichedId);
        nicheStore.setIsDeletingNiche(true);
        setStorySlideOut(false);
      },
    },
  ];
  const niche = getNicheData(nicheStore.editNichedId);
  // const { activityName, reviewers } = activitiesById[addEditActivityId];
  const teamMembers = []; // myTeamStore.myTeam.filter((item) =>  reviewers.includes(item.id));
  return (
    <div className=" h-full flex flex-col">
      <div className="bg-[#F6F6F6] px-4 py-4 flex justify-between font-roboto items-center">
        <span className="font-semibold text-base font-roboto flex gap-3">
          <ChevronRightOutlined
            onClick={() => {
              setStorySlideOut(false);
              setActiveSlideOutTabIndex(0);
            }}
            className="cursor-pointer"
          />
          {niche?.nicheName}
        </span>

        <div className="flex items-center gap-2">
          <TeamMemberAvatarGroup
            handleClick={() => {
              setActiveSlideOutTabIndex(1);
            }}
          >
            {filterUniAndTeamUniversily(teamMembers)?.map((reviewer) => (
              <Tooltip title={avatarTitle(reviewer)}>
                <Avatar src={reviewer?.photoURL} />
              </Tooltip>
            ))}
          </TeamMemberAvatarGroup>
          <Chip
            label="Niche"
            color="yellow"
            icon={<StarOutlineRoundedIcon height={10} />}
            className="font-medium"
          />
          <IconButton
              aria-label="Share"
              onClick={() => {
                myHubStore.setIsShareUtility(true);
                myHubStore.setUtilityId(nicheStore.editNichedId);
                myHubStore.setUtilityType(UTILITY_TYPES.Niche);
                myHubStore.setUtilityName(
                  nicheStore.niches.find((item) => item.id === nicheStore.editNichedId)
                    ?.nicheName
                );
              }}
            >
            <IosShareIcon style={{fill: '#838489'}} />
          </IconButton>
          <Tooltip title="More Actions">
            <GenericMenu buttonLabel={<MoreVertIcon />} menuItems={menuItems} />
          </Tooltip>
        </div>
      </div>
      {/* Body  */}
      <GenericTabs tabs={tabs} />
    </div>
  );
};
