import { useState } from 'react';
import Button from '../shared/Button';
import Menu from '../shared/Menu';
import MenuItem from '../shared/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Divider } from '@mui/material';
import useEssayStore from '../../stores/EssayStore';
import useGoogleAuthorization from '../../hooks/use-google-authorization';
import { useGoogleDocs } from '../../hooks';
import YouTubeIcon from '@mui/icons-material/YouTube';
import useProfileStore from '../../stores/ProfileStore';
import { UTILITY_TYPES } from '../../utils/utlityTypes';
import useAccessCheck from '../../utils/accessCheck';
import { openSnackbar } from '../shared/GlobalSnackbar/GlobalSnackbar';

const AddEssayButton = ({
  variant = 'contained',
  showDropDown = '',
  canCreateNewEssays = () => {},
  disabled = false,
  isVideoShow = false,
  text = 'Add New',
}) => {
  const [anchor, setAnchor] = useState(null);
  const { onInitAuthorization, tokens } = useGoogleAuthorization();
  const { checkIfAccessTokenIsInvalid } = useGoogleDocs();
  const essayStore = useEssayStore();
  const isParent = useProfileStore((state) => state.isParent);

  const [checkAccess] = useAccessCheck([UTILITY_TYPES.Essay]);

  return (
    <div className="flex flex-col gap-3">
      <Button
        variant={variant}
        className="w-fit whitespace-nowrap"
        startIcon={<AddIcon />}
        onClick={async (event) => {
          setAnchor(event.currentTarget);
        }}
        endIcon={
          <div className="flex gap-2 pl-1">
            <Divider orientation="vertical" flexItem />
            {variant !== 'text' && showDropDown && <KeyboardArrowDownIcon />}
          </div>
        }
        disabled={disabled}
      >
        {text}
      </Button>
      {isVideoShow && (
        <a
          className="text-brand-blue-500 text-xs font-medium flex gap-1.5 cursor-pointer hover:underline underline-offset-2"
          href="https://youtu.be/wQxSNx_OrJ8"
          target="_blank"
          rel="noreferrer"
        >
          <div className="flex items-center">Learn more about Essays</div>
          <YouTubeIcon />
        </a>
      )}
      <Menu
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={() => {
          setAnchor(null);
        }}
      >
        <MenuItem
          onClick={async () => {
            setAnchor(null);
            // essayStore.setIsAddingEssay(true);
            if (checkAccess) {
              if (
                (!tokens || (await checkIfAccessTokenIsInvalid())) &&
                !isParent
              ) {
                await onInitAuthorization();
              } else essayStore.setIsAddingCoreEssay(true);
            } else {
              // shoe alert for limit reached
              openSnackbar(
                'You have reached the maximum limit, please upgrade to add more',
                'error'
              );
            }
          }}
        >
          <div className="flex flex-col group">
            <div className="text-sm text-lead-500 group-hover:text-brand-blue-500">
              Core Essay
            </div>
            <div className="text-[10px] text-grey-400 group-hover:text-brand-blue-500">
              Reusable essays that can be copied to multiple college
              applications.
            </div>
          </div>
        </MenuItem>
        <MenuItem
          onClick={async () => {
            setAnchor(null);
            if (checkAccess) {
              if (
                (!tokens || (await checkIfAccessTokenIsInvalid())) &&
                !isParent
              ) {
                await onInitAuthorization();
              } else essayStore.setIsAddingEssay(true);
            } else {
              // shoe alert for limit reached
              openSnackbar(
                'You have reached the maximum limit, please upgrade to add more',
                'error'
              );
            }
          }}
        >
          <div className="flex flex-col group">
            <div className="text-sm text-lead-500 group-hover:text-brand-blue-500">
              Application Essay
            </div>
            <div className="text-[10px] text-grey-400 group-hover:text-brand-blue-500">
              Essays required for specific college applications
            </div>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default AddEssayButton;
