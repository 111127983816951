import { useState } from 'react';
import AddNewACTScoreDialog from '../components/myStory/tests/AddNewACTScoreDialog';
import AddNewSATScoreDialog from '../components/myStory/tests/AddNewSATScoreDialog';
import DeleteScoreDialog from '../components/myStory/tests/DeleteScoreDialog';
import DeleteTargetDialog from '../components/myStory/tests/DeleteTargetDialog';
import SuccessSnackbar from '../components/shared/SuccessSnackbar';
import { addStoryItemTypes, NOTIFICATION_ACTION_TYPES, TEST_SCORE_TYPES } from '../constants/other';
import { TEST_TYPE_ACT, TEST_TYPE_SAT } from '../firebase/constants';
import { addNotificationItem, addStoryItem } from '../firebase/services/addServices';
import { deleteStoryItem } from '../firebase/services/deleteServices';
import { updateStoryItem } from '../firebase/services/updateServices';
import useTestStore from '../stores/TestStore';
import { useLocation, useNavigate } from 'react-router-dom';
import { currentDateChecker } from '../utils/helper';
import DeleteDateDialog from '../components/myStory/tests/DeleteDateDialog';
import useProfileStore from '../stores/ProfileStore';
import useStudentStore from '../stores/StudentStore';
import useTeamStore from '../stores/TeamStore';
import { useShallow } from 'zustand/react/shallow';
import { addTeamUniversilyToChatParticipants, UTILITY_TYPES } from '../utils/utlityTypes';

const TestContainer = () => {
  const testStore = useTestStore();
  const profile = useProfileStore((state) => state.profile);
  const teamStore = useTeamStore(
    useShallow((state) => ({
      teams: state.teams,
      selectedTeam: state.selectedTeam,
    }))
  );
  const { successSnackbarMessage, setSuccessSnackbarMessage } = useTestStore(
    (state) => ({
      successSnackbarMessage: state.successSnackbarMessage,
      setSuccessSnackbarMessage: state.setSuccessSnackbarMessage,
    })
  );
  const navigate = useNavigate();
  const location = useLocation();
  const isParent = useProfileStore((state) => state.isParent);
  const selectedStudent = useStudentStore((state) => state.selectedStudent);
  const selectedTeam = useTeamStore((state) => state.selectedTeam);
  const onAddScore = async (
    scoreData,
    targetData,
    testName,
    isAdding,
    isEditing
  ) => {
    const targetDataScore = getTargetData(testName);
    let achieved = '';
    const testTypeId = testStore.testTypes.find(
      (test) => test.type === testName
    )?.id;

    const setSnackbarMessage = (message) => {
      testStore.setIsDeletingSnackbar(true);
      setSuccessSnackbarMessage(message);
    };

    const handleScoreAchievedMessage = () => {
      switch (achieved) {
        case 'EQUAL':
          setSnackbarMessage(
            `Awesome! You have achieved your ${testName} Target score!`
          );
          break;
        case 'GREATER':
          setSnackbarMessage(
            `Great! You have Exceeded your ${testName} score!`
          );
          break;
        default:
          setSnackbarMessage(`You have added your ${testName} Actual Score.`);
          break;
      }
    };

    const handleScoreData = async () => {
      const testData = {
        ...scoreData,
        createdBy: profile?.uid,
        owner: teamStore.selectedTeam?.studentEmail,
      };
      const testId = await addStoryItem(
        { testType: testTypeId, ...testData },
        addStoryItemTypes.TEST_SCORE
      );

      const testType = testStore.testTypes.find(
        (test) => test.id === testTypeId
      )

      if(testId) {
        // Create notification for the test score
        const notificationData = {
          utilityType: UTILITY_TYPES.Test,
          utilityName: testType?.type,
          utilityId: testId,
          teamId: selectedTeam?.id,
          actionType: NOTIFICATION_ACTION_TYPES.CREATED,
          scoreType: TEST_SCORE_TYPES.TEST_SCORES,
        }
        await addNotificationItem(notificationData);
      }

      console.log({ testId });
      if (location.pathname !== '/myHub') {
        navigate(`/${selectedTeam?.id}/myStory/tests`);
      }
      handleScoreAchievedMessage();

      if (testId) {
        // Create team channel for test score
        const channelMembers = addTeamUniversilyToChatParticipants(
          teamStore?.selectedTeam?.teamMembers
            ?.filter((member) => member?.uid)
            ?.map((member) => member?.uid)
        );

        await addStoryItem(
          {
            isTopic: true,
            archivedBy: [],
            chatParticipants: channelMembers,
            topicName: 'Topic for ' + testName,
            utilityId: testId,
            topicType: 'Tests',
          },
          addStoryItemTypes.CHAT_ROOM
        );
      }
      if (scoreData?.testDate === '') {
        setSnackbarMessage(
          `You are yet to add your ${testName} Target and Actual Score.`
        );
      } else if (
        !currentDateChecker(scoreData?.testDate !== '' && scoreData?.testDate)
      ) {
        setSnackbarMessage(
          `You have added a future date, actual scores will be added after you have taken the test.`
        );
      }
    };

    const handleEditingScore = async () => {
      const scoreItem = testStore.testScores.find(
        (score) => score.id === testStore.editScoreId
      );

      await updateStoryItem(
        {
          ...scoreItem,
          scores: scoreData.scores || [],
          testDate: scoreData.testDate,
        },
        addStoryItemTypes.TEST_SCORE
      );
      handleScoreAchievedMessage();
    };

    const handleAddingTargets = async () => {
      const testTargetData = {
        targets: targetData,
        createdBy: profile?.uid,
        owner: teamStore?.selectedTeam?.studentEmail,
      };

      const targetId = await addStoryItem(
        { testType: testTypeId, ...testTargetData },
        addStoryItemTypes.TEST_TARGETS
      );

      const testType = testStore.testTypes.find(
        (test) => test.id === testTypeId
      )

      if (targetId) {
        // Create notification for the test target
        const notificationData = {
          utilityType: UTILITY_TYPES.Test,
          utilityName: testType?.type,
          utilityId: targetId,
          teamId: selectedTeam?.id,
          actionType: NOTIFICATION_ACTION_TYPES.CREATED,
          scoreType: TEST_SCORE_TYPES.TEST_TARGETS,
        }
        await addNotificationItem(notificationData);

        // Create team channel for test score
        const channelMembers = addTeamUniversilyToChatParticipants(
          teamStore?.selectedTeam?.teamMembers
            ?.filter((member) => member?.uid)
            ?.map((member) => member?.uid)
        );

        await addStoryItem(
          {
            isTopic: true,
            archivedBy: [],
            chatParticipants: channelMembers,
            topicName: 'Topic for ' + testName,
            utilityId: targetId,
            topicType: 'Tests',
          },
          addStoryItemTypes.CHAT_ROOM
        );
      }

      if (location.pathname !== '/myHub') {
        navigate(`/${selectedTeam?.id}/myStory/tests`);
      }

      if (!scoreData?.scores?.length) {
        setSnackbarMessage(`You have added your ${testName} Target score!`);
      } else {
        setSnackbarMessage(
          `Awesome! You have added your both ${testName} Target & Actual score!`
        );
      }
    };

    const handleEditingTargets = async () => {
      const targetItem = testStore.testTargets.find(
        (target) => target.id === testStore.editTargetId
      );

      await updateStoryItem(
        { ...targetItem, targets: targetData || [] },
        addStoryItemTypes.TEST_TARGETS
      );
      setSnackbarMessage(`Your ${testName} Target is Updated Successfully`);
      testStore.setEditTargetId(null);
    };

    if (
      (scoreData?.testDate && isAdding) ||
      !testStore.testScores.some(
        ({ testType }) => testStore.testTypesById[testType]?.type === testName
      )
    ) {
      targetDataScore[0]?.targets?.forEach((item) => {
        const score = scoreData.scores.find(
          (value) => value.subjectId === item.subjectId
        );
        if (score) {
          if (score.scoreValue > item.targetValue) {
            achieved = 'GREATER';
          } else if (score.scoreValue === item.targetValue) {
            achieved = 'EQUAL';
          }
        }
      });
      await handleScoreData();
    }

    if (scoreData?.testDate && isEditing) {
      targetDataScore[0]?.targets?.forEach((item) => {
        const score = scoreData.scores.find(
          (value) => value.subjectId === item.subjectId
        );
        if (score && score.scoreValue >= item.targetValue) {
          achieved = true;
        }
      });
      await handleEditingScore();
    }

    if (targetData?.length > 0) {
      if (
        !testStore.testTargets.some(
          ({ testType }) => testStore.testTypesById[testType]?.type === testName
        )
      ) {
        await handleAddingTargets();
      } else {
        await handleEditingTargets();
      }
    }
  };

  const onDelete = async () => {
    testStore.setIsDeletingSnackbar(true);
    const satData = getScoreData(TEST_TYPE_SAT);
    const actData = getScoreData(TEST_TYPE_ACT);
    const scoreData = actData?.concat(satData);
    const deletedItem = scoreData?.find(
      (item) => item?.id === testStore.deleteScoreId
    );
    if (deletedItem?.scores.length === 0) {
      const testType = testStore.testTypes.find(
        (item) => item?.id === deletedItem?.testType
      );
      setSuccessSnackbarMessage(`${testType?.type} future date is deleted.`);
    } else {
      setSuccessSnackbarMessage(
        `Your ${testStore.deleteTargetId ? 'Target Score' : 'Score'} is deleted`
      );
    }
  };

  const deleteTestUtility = async (id, itemType) => {
    const testScores = testStore.testScores.find((item) => item.id === id);

    const testTargets = testStore.testTargets.find((item) => item.id === id);
    const res = await deleteStoryItem(id, itemType);
    if(res) { 
      if(testScores) {
        const testType = testStore.testTypes.find(
          (item) => item?.id === testScores?.testType
        );
        // create notification for the test score deletion
        const notificationData = {
          utilityType: UTILITY_TYPES.Test,
          utilityName: testType?.type,
          utilityId: id,
          teamId: selectedTeam?.id,
          actionType: NOTIFICATION_ACTION_TYPES.DELETED,
          scoreType: TEST_SCORE_TYPES.TEST_SCORES
        }
        await addNotificationItem(notificationData);
      } else {
        const testType = testStore.testTypes.find(
          (item) => item?.id === testTargets?.testType
        );
        // create notification for the test target deletion
        const notificationData = {
          utilityType: UTILITY_TYPES.Test,
          utilityName: testType?.type,
          utilityId: id,
          teamId: selectedTeam?.id,
          actionType: NOTIFICATION_ACTION_TYPES.DELETED,
          scoreType: TEST_SCORE_TYPES.TEST_TARGETS
        }
        await addNotificationItem(notificationData);
      }
    }

    testStore.setDeleteScoreId(null);
    testStore.setDeleteTargetId(null);
  };

  const getScoreData = (value) => {
    if (testStore.editScoreId) {
      return testStore.testScores
        ?.filter(
          ({ testType }) => testStore.testTypesById[testType]?.type === value
        )
        .filter((item) => item.id === testStore.editScoreId);
    }
    return testStore.testScores?.filter(
      ({ testType }) => testStore.testTypesById[testType]?.type === value
    );
  };

  const getTargetData = (value) => {
    if (testStore.editTargetId) {
      return testStore.testTargets
        ?.filter(
          ({ testType }) => testStore.testTypesById[testType]?.type === value
        )
        .filter((item) => item.id === testStore.editTargetId);
    }
    return testStore.testTargets?.filter(
      ({ testType }) => testStore.testTypesById[testType]?.type === value
    );
  };

  return (
    <>
      <SuccessSnackbar
        message={successSnackbarMessage}
        open={testStore.isDeletingSnackbar}
        onClose={() => {
          if (testStore.deleteTargetId) {
            deleteTestUtility(
              testStore.deleteTargetId,
              addStoryItemTypes.TEST_TARGETS
            );
          } else {
            deleteTestUtility(
              testStore.deleteScoreId,
              addStoryItemTypes.TEST_SCORE
            );
          }
          setSuccessSnackbarMessage('');
          testStore.setIsDeletingSnackbar(false);
        }}
        actionName={
          (testStore.deleteTargetId || testStore.deleteScoreId) && 'Undo'
        }
        onAction={() => {
          testStore.setDeleteScoreId(null);
          testStore.setDeleteTargetId(null);
          testStore.setIsDeletingSnackbar(false);
          setSuccessSnackbarMessage('');
        }}
      />
      <AddNewSATScoreDialog
        open={testStore.isAddingSATScore || testStore.isAddingSATTarget}
        isAddingSATTarget={testStore.isAddingSATTarget}
        isEditingSATTarget={testStore.isEditingSATTarget}
        onCancel={() => {
          testStore.setEditScoreId(null);
          testStore.setEditTargetId(null);
          testStore.setIsEditingSATScore(false);
          testStore.setIsAddingSATScore(false);
          testStore.setIsAddingSATTarget(false);
          testStore.setIsEditingSATTarget(false);
        }}
        onContinue={(satScore, satTarget) => {
          onAddScore(
            satScore,
            satTarget,
            TEST_TYPE_SAT,
            testStore.isAddingSATScore,
            testStore.isEditingSATScore
          );
          testStore.setIsEditingSATScore(false);
          testStore.setIsAddingSATScore(false);
          testStore.setIsAddingSATTarget(false);
          testStore.setIsEditingSATTarget(false);
        }}
        satScores={getScoreData(TEST_TYPE_SAT)}
        satSubjects={testStore.testSubjectsSAT}
        satTarget={getTargetData(TEST_TYPE_SAT)}
        targetData={
          testStore.isEditingSATTarget ? getTargetData(TEST_TYPE_SAT) : {}
        }
        scoreData={
          testStore.isEditingSATScore ? getScoreData(TEST_TYPE_SAT) : {}
        }
        isTargetDialog={
          testStore.isAddingSATTarget || testStore.isEditingSATTarget
        }
        isAddDialog={testStore.isAddingSATScore || testStore.isAddingSATTarget}
      />
      <AddNewACTScoreDialog
        open={testStore.isAddingACTScore || testStore.isAddingACTTarget}
        isAddingACTTarget={testStore.isAddingACTTarget}
        isEditingACTTarget={testStore.isEditingACTTarget}
        onCancel={() => {
          testStore.setIsEditingACTScore(false);
          testStore.setEditScoreId(null);
          testStore.setEditTargetId(null);
          testStore.setIsAddingACTScore(false);
          testStore.setIsAddingACTTarget(false);
          testStore.setIsEditingACTTarget(false);
        }}
        onContinue={(actScore, actTarget) => {
          onAddScore(
            actScore,
            actTarget,
            TEST_TYPE_ACT,
            testStore.isAddingACTScore,
            testStore.isEditingACTScore
          );
          testStore.setIsEditingACTScore(false);
          testStore.setIsAddingACTScore(false);
          testStore.setIsAddingACTTarget(false);
          testStore.setIsEditingACTTarget(false);
        }}
        actScores={getScoreData(TEST_TYPE_ACT)}
        actSubjects={testStore.testSubjectsACT}
        actTarget={getTargetData(TEST_TYPE_ACT)}
        targetData={
          testStore.isEditingACTTarget ? getTargetData(TEST_TYPE_ACT) : {}
        }
        scoreData={
          testStore.isEditingACTScore ? getScoreData(TEST_TYPE_ACT) : {}
        }
        isTargetDialog={
          testStore.isAddingACTTarget || testStore.isEditingACTTarget
        }
        isAddDialog={testStore.isAddingACTScore || testStore.isAddingACTTarget}
      />
      <DeleteScoreDialog
        maxWidth="xs"
        open={testStore.isDeletingScore}
        onContinue={() => {
          onDelete();
          testStore.setIsDeletingScore(false);
        }}
        onCancel={() => {
          testStore.setIsDeletingScore(false);
          testStore.setDeleteScoreId(null);
        }}
      />
      <DeleteDateDialog
        maxWidth="xs"
        open={testStore.isDeletingDate}
        onContinue={() => {
          onDelete();
          testStore.setIsDeletingDate(false);
        }}
        onCancel={() => {
          testStore.setIsDeletingDate(false);
          testStore.setDeleteScoreId(null);
        }}
      />
      <DeleteTargetDialog
        maxWidth="xs"
        open={testStore.isDeletingTarget || testStore.isDeletingTarget}
        onContinue={() => {
          onDelete();
          testStore.setIsDeletingTarget(false);
          testStore.setIsDeletingTarget(false);
        }}
        onCancel={() => {
          testStore.setIsDeletingTarget(false);
          testStore.setIsDeletingTarget(false);
          testStore.setDeleteTargetId(null);
        }}
      />
    </>
  );
};

export default TestContainer;
