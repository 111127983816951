import { useState } from 'react';
import { AddNewGPAScoreDialog } from '../components/myStory/gpaScores/AddNewGPAScoreDialog';
import SuccessSnackbar from '../components/shared/SuccessSnackbar';
import { addStoryItemTypes } from '../constants/other';
import { TEST_TYPE_SAT } from '../firebase/constants';
import { addStoryItem } from '../firebase/services/addServices';
import { deleteStoryItem } from '../firebase/services/deleteServices';
import { updateStoryItem } from '../firebase/services/updateServices';
import { useGPAStore } from '../stores/GPAStore';
import { useLocation, useNavigate } from 'react-router-dom';
import { currentDateChecker } from '../utils/helper';
import useProfileStore from '../stores/ProfileStore';
import useStudentStore from '../stores/StudentStore';
import useTeamStore from '../stores/TeamStore';

const GPAContainer = () => {
  const GPAStore = useGPAStore();
  const [successSnackbarMessage, setSuccessSnackbarMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const isParent = useProfileStore((state) => state.isParent);
  const selectedStudent = useStudentStore((state) => state.selectedStudent);
  const selectedTeam = useTeamStore((state) => state.selectedTeam);
  const onAddScore = async (
    scoreData,
    targetData,
    testName,
    isAdding,
    isEditing
  ) => {
    const targetDataScore = getTargetData(testName);
    let achieved = '';
    const testTypeId = GPAStore.testTypes.find(
      (test) => test.type === testName
    )?.id;
    if (
      (scoreData?.testDate && isAdding) ||
      GPAStore.testScores?.filter(
        ({ testType }) => GPAStore.testTypesById[testType]?.type === testName
      ).length === 0
    ) {
      // eslint-disable-next-line array-callback-return
      targetDataScore[0]?.targets?.map((item) => {
        let score = scoreData.scores.find(
          (value) => value.subjectId === item.subjectId
        );
        if (
          score?.scoreValue > item.targetValue &&
          score?.subjectId === item.subjectId
        ) {
          achieved = 'GREATER';
        } else if (
          score?.scoreValue === item.targetValue &&
          score?.subjectId === item.subjectId
        ) {
          achieved = 'EQUAL';
        }
      });
      if (isParent) {
        const parentViewScoreData = {
          ...scoreData,
          createdBy: selectedStudent.uid,
        };
        await addStoryItem(
          {
            testType: testTypeId,
            ...parentViewScoreData,
          },
          addStoryItemTypes.TEST_SCORE
        );
      } else {
        await addStoryItem(
          {
            testType: testTypeId,
            ...scoreData,
          },
          addStoryItemTypes.TEST_SCORE
        );
      }
      if (location.pathname !== '/myHub') {
        navigate(`/${selectedTeam?.id}/myStory/tests`);
      }
      switch (achieved) {
        case 'EQUAL':
          GPAStore.setIsDeletingSnackbar(true);
          setSuccessSnackbarMessage(
            `Awesome! You have achieved your ${testName} Target score!`
          );
          break;
        case 'GREATER':
          GPAStore.setIsDeletingSnackbar(true);
          setSuccessSnackbarMessage(
            `Great! You have Exceeded your ${testName} score!`
          );
          break;
        default:
          GPAStore.setIsDeletingSnackbar(true);
          setSuccessSnackbarMessage(
            `You have added your ${testName} Actual Score.`
          );
          break;
      }

      if (scoreData?.testDate === '') {
        GPAStore.setIsDeletingSnackbar(true);
        setSuccessSnackbarMessage(
          `You are yet to add your ${testName} Target and Actual Score.`
        );
      } else if (
        !currentDateChecker(scoreData?.testDate !== '' && scoreData?.testDate)
      ) {
        GPAStore.setIsDeletingSnackbar(true);
        setSuccessSnackbarMessage(
          `You have added a future date, actual scores will be added after you have taken the test.`
        );
      }
    }

    if (scoreData?.testDate && isEditing) {
      const scoreItem = GPAStore.testScores.find(
        (score) => score.id === GPAStore.editScoreId
      );
      // eslint-disable-next-line array-callback-return
      targetDataScore[0]?.targets?.map((item) => {
        let score = scoreData.scores.find(
          (value) => value.subjectId === item.subjectId
        );
        if (
          score?.scoreValue >= item.targetValue &&
          score?.subjectId === item.subjectId
        ) {
          achieved = true;
        }
      });
      await updateStoryItem(
        {
          ...scoreItem,
          scores: scoreData.scores || [],
          testDate: scoreData.testDate,
        },
        addStoryItemTypes.TEST_SCORE
      );
      switch (achieved) {
        case 'EQUAL':
          GPAStore.setIsDeletingSnackbar(true);
          setSuccessSnackbarMessage(
            `Awesome! You have achieved your ${testName} Target score!`
          );
          break;
        case 'GREATER':
          GPAStore.setIsDeletingSnackbar(true);
          setSuccessSnackbarMessage(
            `Great! You have Exceeded your ${testName} score!`
          );
          break;
        default:
          GPAStore.setIsDeletingSnackbar(true);
          setSuccessSnackbarMessage(
            `Your ${testName} Score is updated successfully`
          );
          break;
      }
    }
    if (targetData?.length > 0) {
      if (
        GPAStore.testTargets?.filter(
          ({ testType }) => GPAStore.testTypesById[testType]?.type === testName
        ).length === 0
      ) {
        if (isParent) {
          const parentViewTargetData = {
            ...targetData,
            createdBy: selectedStudent.uid,
          };
          await addStoryItem(
            { testType: testTypeId, ...parentViewTargetData },
            addStoryItemTypes.TEST_TARGETS
          );
        } else {
          await addStoryItem(
            { testType: testTypeId, targets: targetData },
            addStoryItemTypes.TEST_TARGETS
          );
        }

        if (location.pathname !== '/myHub') {
          navigate('/myStory/tests');
        }
        GPAStore.setIsDeletingSnackbar(true);
        if (scoreData?.scores?.length === 0 || scoreData?.length === 0) {
          setSuccessSnackbarMessage(
            `You have added your ${testName} Target score!`
          );
        } else {
          setSuccessSnackbarMessage(
            `Awesome! You have added your both ${testName} Target & Actual score!`
          );
        }
      } else {
        const targetItem = GPAStore.testTargets.find(
          (target) => target.id === GPAStore.editTargetId
        );
        await updateStoryItem(
          { ...targetItem, targets: targetData || [] },
          addStoryItemTypes.TEST_TARGETS
        );
        GPAStore.setIsDeletingSnackbar(true);
        setSuccessSnackbarMessage(
          `Your ${testName} Target is Updated Successfully`
        );
        GPAStore.setEditTargetId(null);
      }
    }
  };

  const deleteTestUtility = async (id, itemType) => {
    await deleteStoryItem(id, itemType);
    GPAStore.setDeleteScoreId(null);
    GPAStore.setDeleteTargetId(null);
  };

  const getScoreData = (value) => {
    if (GPAStore.editScoreId) {
      return GPAStore.testScores
        ?.filter(
          ({ testType }) => GPAStore.testTypesById[testType]?.type === value
        )
        .filter((item) => item.id === GPAStore.editScoreId);
    }
    return GPAStore.testScores?.filter(
      ({ testType }) => GPAStore.testTypesById[testType]?.type === value
    );
  };

  const getTargetData = (value) => {
    if (GPAStore.editTargetId) {
      return GPAStore.testTargets
        ?.filter(
          ({ testType }) => GPAStore.testTypesById[testType]?.type === value
        )
        .filter((item) => item.id === GPAStore.editTargetId);
    }
    return GPAStore.testTargets?.filter(
      ({ testType }) => GPAStore.testTypesById[testType]?.type === value
    );
  };

  return (
    <>
      <SuccessSnackbar
        message={successSnackbarMessage}
        open={GPAStore.isDeletingSnackbar}
        onClose={() => {
          if (GPAStore.deleteTargetId) {
            deleteTestUtility(
              GPAStore.deleteTargetId,
              addStoryItemTypes.TEST_TARGETS
            );
          } else {
            deleteTestUtility(
              GPAStore.deleteScoreId,
              addStoryItemTypes.TEST_SCORE
            );
          }
          setSuccessSnackbarMessage('');
          GPAStore.setIsDeletingSnackbar(false);
        }}
        actionName={
          (GPAStore.deleteTargetId || GPAStore.deleteScoreId) && 'Undo'
        }
        onAction={() => {
          GPAStore.setDeleteScoreId(null);
          GPAStore.setDeleteTargetId(null);
          GPAStore.setIsDeletingSnackbar(false);
          setSuccessSnackbarMessage('');
        }}
      />
      <AddNewGPAScoreDialog
        open={GPAStore.IsAddingGPAScore}
        isAddingSATTarget={GPAStore.isAddingSATTarget}
        isEditingGpaScore={GPAStore.isEditingGpaScore}
        onCancel={() => {
          GPAStore.setIsEditingGpaScore(false);
          GPAStore.setIsAddingGPAScore(false);
        }}
        onContinue={(satScore, satTarget) => {
          onAddScore(
            satScore,
            satTarget,
            TEST_TYPE_SAT,
            GPAStore.IsAddingGPAScore,
            GPAStore.isEditingSATScore
          );
          GPAStore.setIsEditingSATScore(false);
          GPAStore.setIsAddingGPAScore(false);
          GPAStore.setIsAddingSATTarget(false);
          GPAStore.setIsEditingSATTarget(false);
        }}
        satScores={getScoreData(TEST_TYPE_SAT)}
        satSubjects={GPAStore.testSubjectsSAT}
        satTarget={getTargetData(TEST_TYPE_SAT)}
        targetData={
          GPAStore.isEditingSATTarget ? getTargetData(TEST_TYPE_SAT) : {}
        }
        scoreData={GPAStore.isEditingGpaScore ? GPAStore?.gpaScores : {}}
        isAddDialog={GPAStore.IsAddingGPAScore}
      />
    </>
  );
};

export default GPAContainer;
