import { auth, db } from '../config';
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { APPLICATION_TEMPLATES, COURSE, PROFILE } from '../constants';
import { fireLogEvent } from './analytics';
import {
  ANALYTICS_EVENTS,
  COURSE_EVENTS,
  TEMPLATE_EVENTS,
} from '../../constants/firebaseAnalytics';
import { getLocalUser } from '../../utils/localStorage';
import { advanceDateByYear } from '../../utils/helper';
import { documentExists } from './auth';
import { TEAM_UNIVERSILY_DETAILS } from '../../utils/utlityTypes';
import { openSnackbar } from '../../components/shared/GlobalSnackbar/GlobalSnackbar';

const user = getLocalUser();

// Edit Course
export const updateCourse = (course) =>
  new Promise((resolve, reject) => {
    updateDoc(doc(db, COURSE, course?.id), {
      ...course,
      updatedBy: auth?.currentUser?.uid,
      updateCount: increment(1),
      updatedAt: serverTimestamp(),
    })
      .then(() => {
        resolve(true);
        fireLogEvent(COURSE_EVENTS?.COURSE, {
          type: COURSE_EVENTS?.COURSE_UPDATED,
        });
      })
      .catch((error) => reject(false));
  });

// Edit Story Item
export const updateStoryItem = (storyItemDetails, collectionName) =>
  new Promise((resolve, reject) => {
    updateDoc(doc(db, collectionName, storyItemDetails?.id), {
      ...storyItemDetails,
      updatedBy: storyItemDetails?.createdBy
        ? storyItemDetails?.createdBy
        : auth?.currentUser?.uid,
      updateCount: increment(1),
      updatedAt: serverTimestamp(),
    })
      .then(() => {
        resolve(storyItemDetails?.id);
        fireLogEvent(ANALYTICS_EVENTS[collectionName].LABEL, {
          type: ANALYTICS_EVENTS[collectionName].UPDATED,
        });
      })
      .catch((error) => {
        reject(false);
      });
  });

export const editProfile = (profile) =>
  new Promise((resolve, reject) => {
    updateDoc(doc(db, PROFILE, profile?.id), {
      ...profile,
      updates: increment(1),
    })
      .then(() => resolve(true))
      .catch((error) => reject(false));
  });

export const editRequirementTemplate = (data, id) =>
  new Promise((resolve, reject) => {
    updateDoc(doc(db, APPLICATION_TEMPLATES, id), {
      ...data,
      updatedAt: serverTimestamp(),
      updatedBy: auth?.currentUser?.uid ?? user?.uid,
      updateCount: increment(1),
    })
      .then(() => {
        resolve(id);
        fireLogEvent(TEMPLATE_EVENTS?.TEMPLATE, {
          type: TEMPLATE_EVENTS?.TEMPLATE_UPDATED,
        });
      })
      .catch((error) => {
        reject(false);
      });
  });

//Utility to update a document in a collection based on a search key and value
export const updateDocument = async (
  collectionName,
  searchKey,
  searchValue,
  key,
  payload,
  shouldReplace = false
) => {
  try {
    const q = query(
      collection(db, collectionName),
      where(searchKey, '==', searchValue)
    );

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log('No matching documents found.');
      return;
    }

    // Assuming only one document matches the query
    const docId = querySnapshot.docs[0].id;

    const docRef = doc(db, collectionName, docId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      console.log('No such document!');
      return;
    }

    const currentData = docSnap.data();
    const currentFieldValue = currentData[key];

    let newValue;
    if (shouldReplace || currentFieldValue === undefined) {
      // Replace the value completely
      newValue = payload;
    } else if (Array.isArray(currentFieldValue) && Array.isArray(payload)) {
      // Spread and add to an array
      newValue = [...currentFieldValue, ...payload];
    } else if (
      typeof currentFieldValue === 'object' &&
      typeof payload === 'object'
    ) {
      // Spread and add to an object
      newValue = { ...currentFieldValue, ...payload };
    } else {
      // Replace if types don't match or spreading is not applicable
      newValue = payload;
    }

    await updateDoc(docRef, {
      [key]: newValue,
    });
    console.log('Document successfully updated!');
  } catch (error) {
    console.error('Error updating document: ', error);
  }
};

const getDocumentByKeyValue = async (collectionName, key, value) => {
  console.log(`Searching for document with ${key} = ${value}`);
  const collectionRef = collection(db, collectionName);
  const q = query(collectionRef, where(key, '==', value));
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return null; // No matching document found
  }

  // Assuming there's only one document that matches the query
  const docData = querySnapshot.docs[0].data();
  const docId = querySnapshot.docs[0].id;
  console.log({ docId, docData });
  return { docId, docData };
};

const updateDocumentById = async (collectionName, docId, updateData) => {
  const docRef = doc(db, collectionName, docId);
  await updateDoc(docRef, updateData);
  console.log(`Document with ID ${docId} updated successfully.`);
};

// Utility to get document data from a collection based on a search key and value and update it
export const updateTeamMemberUIDByEmail = async (email, newUid) => {
  console.log(`Updating UID for team member with email ${email}`);
  const collectionName = 'teams';
  const key = 'studentEmail'; // Assuming this is a valid path for where clause

  const result = await getDocumentByKeyValue(collectionName, key, email);
  console.log(result);
  if (!result) {
    console.log(`No team member found with email ${email}`);
    return;
  }

  const { docId, docData } = result;

  // Update the team member's UID
  const updatedTeamMembers = docData.teamMembers.map((member) => {
    if (member.email === email) {
      return {
        ...member,
        uid: newUid,
      };
    }
    return member;
  });

  await updateDocumentById(collectionName, docId, {
    teamMembers: updatedTeamMembers,
  });
  console.log(`UID updated for team member with email ${email}`);
};

//  Utility to update all documents in a collection with a new value
export const updateAllDocuments = async (collectionName, updatedValue) => {
  const success = [];
  const failed = [];

  try {
    const querySnapshot = await getDocs(collection(db, collectionName));

    for (const documentSnapshot of querySnapshot.docs) {
      const docRef = doc(db, collectionName, documentSnapshot.id);

      try {
        await updateDoc(docRef, updatedValue);
        success.push({ id: documentSnapshot.id });
      } catch (error) {
        failed.push({ id: documentSnapshot.id });
        console.error('Error updating document: ', documentSnapshot.id, error);
      }
    }
  } catch (error) {
    console.error('Error getting documents: ', error);
  }

  return { success, failed };
};

export const advanceDateToYearInDocuments = async (collectionName) => {
  const success = [];
  const failed = [];

  try {
    const querySnapshot = await getDocs(collection(db, collectionName));

    for (const documentSnapshot of querySnapshot.docs) {
      const docRef = doc(db, collectionName, documentSnapshot.id);
      const data = documentSnapshot.data();

      // Assuming the field containing the array of objects is named 'rounds'
      if (Array.isArray(data.rounds)) {
        const updatedArray = data.rounds.map((item) => {
          if (item.applicationDueDate) {
            item.applicationDueDate = advanceDateByYear(
              item.applicationDueDate.toDate()
            );
          }
          if (item.applicationOpenDate) {
            item.applicationOpenDate = advanceDateByYear(
              item.applicationOpenDate.toDate()
            );
          }
          if (item.decisionDueDate) {
            item.decisionDueDate = advanceDateByYear(
              item.decisionDueDate.toDate()
            );
          }
          if (item.financialAidDueDate) {
            item.financialAidDueDate = advanceDateByYear(
              item.financialAidDueDate.toDate()
            );
          }
          return item;
        });

        try {
          await updateDoc(docRef, { rounds: updatedArray });
          console.log('Updated Document', documentSnapshot.id);
          success.push({ id: documentSnapshot.id });
        } catch (error) {
          failed.push({ id: documentSnapshot.id });
          console.error(
            'Error updating document: ',
            documentSnapshot.id,
            error
          );
        }
      } else {
        failed.push({
          id: documentSnapshot.id,
          error: 'rounds field is not an array',
        });
      }
    }
  } catch (error) {
    console.error('Error getting documents: ', error);
  }

  return { success, failed };
};

export const deleteMember = async (teamMembers, teamId, requestedMember) => {
  const updatedMembersList = teamMembers?.filter(
    (member) => member?.uid !== requestedMember
  );

  await updateDocument(
    'teams',
    'id',
    teamId,
    'teamMembers',
    updatedMembersList,
    true
  );
  await updateDocument('profile', 'uid', requestedMember, 'teams', [], true);
};
export const leaveTeam = async (teamId, requestedMember) => {
  const team = await documentExists('teams', 'id', teamId);

  if (!team) {
    return;
  }
  const teamData = team.data[0];
  const updatedMembersList = teamData.teamMembers?.filter(
    (member) => member?.uid !== requestedMember
  );

  await updateDocument(
    'teams',
    'id',
    teamId,
    'teamMembers',
    updatedMembersList,
    true
  );

  const profile = await documentExists('profile', 'uid', requestedMember);

  if (!profile) {
    return;
  }

  const profileData = profile.data[0];
  const updatedTeamsList = profileData.teams?.filter(
    (team) => team?.teamId !== teamId
  );

  await updateDocument(
    'profile',
    'uid',
    requestedMember,
    'teams',
    updatedTeamsList,
    true
  );
  return true;
};

export const assignAllTeamsForProfile = async (uid) => {
  // 1. first get profile data from uid
  // 2. Get all teams data
  // 3. create and array of objects with teamId and status: status: 'JOINED'
  // 4. update profile with teams array with field teams

  // 1. first get profile data from uid
  const profileCollectionRef = collection(db, 'profile');
  const profileQuery = query(profileCollectionRef, where('uid', '==', uid));
  const profileSnapshot = await getDocs(profileQuery);

  if (profileSnapshot.empty) {
    console.log('No such profile document!');
    return null;
  }

  // 2. Get all teams data
  const teamsCollectionRef = collection(db, 'teams');
  const teamsData = await getDocs(teamsCollectionRef);

  // 3. create and array of objects with teamId and status: status: 'JOINED'
  const teamsArray = [];
  teamsData.forEach((doc) => {
    teamsArray.push({
      teamId: doc.id,
      status: 'JOINED',
    });
  });

  const profileDocRef = profileSnapshot.docs[0].ref;

  // 4. update profile with teams array with field teams
  for (const team of teamsArray) {
    await updateDoc(profileDocRef, {
      teams: arrayUnion(team),
    });
  }
};

// Parameters may be declared in a variety of syntactic forms
/**
 * A function that adds a parent to team utilities like niche, honor, activities, courses, essay
 * @param {string}  teamId - A string param Uid.
 * @param {string}  parentUid - A string param.
 */
export const addParentToTeamUtilities = async (teamId, parentUid) => {
  try {
    // 1. Get team data from where id = teamId without fetching all data
    const teamDocRef = doc(db, 'teams', teamId);
    const teamDoc = await getDoc(teamDocRef);
    const teamData = teamDoc.data();

    // 2. declare a variable to store studentEmail from team data
    const studentEmail = teamData.studentEmail;

    // 3. Get all utilities separately like (niche, honor, activities, courses, essay) where owner is studentEmail
    // 3.1 Get niche data
    const nicheCollectionRef = collection(db, 'niche');
    const allNiches = query(
      nicheCollectionRef,
      where('owner', '==', studentEmail),
      where('deleteDocument', '==', false)
    );
    const nicheData = await getDocs(allNiches);
    nicheData.forEach(async (doc) => {
      const nicheDocRef = doc.ref;
      await updateDoc(nicheDocRef, {
        sharedWith: arrayUnion(parentUid),
      });
    });

    // 3.2 Get honor data
    const honorCollectionRef = collection(db, 'honor');
    const allHonors = query(
      honorCollectionRef,
      where('owner', '==', studentEmail),
      where('deleteDocument', '==', false)
    );
    const honorData = await getDocs(allHonors);
    honorData.forEach(async (doc) => {
      const honorDocRef = doc.ref;
      await updateDoc(honorDocRef, {
        sharedWith: arrayUnion(parentUid),
      });
    });

    // 3.3 Get activities data
    const activitiesCollectionRef = collection(db, 'activity');
    const allActivities = query(
      activitiesCollectionRef,
      where('owner', '==', studentEmail),
      where('deleteDocument', '==', false)
    );
    const activitiesData = await getDocs(allActivities);
    activitiesData.forEach(async (doc) => {
      const activitiesDocRef = doc.ref;
      await updateDoc(activitiesDocRef, {
        sharedWith: arrayUnion(parentUid),
      });
    });

    // 3.4 Get courses data
    const coursesCollectionRef = collection(db, 'course');
    const allCourses = query(
      coursesCollectionRef,
      where('owner', '==', studentEmail),
      where('deleteDocument', '==', false)
    );
    const coursesData = await getDocs(allCourses);
    coursesData.forEach(async (doc) => {
      const coursesDocRef = doc.ref;
      await updateDoc(coursesDocRef, {
        sharedWith: arrayUnion(parentUid),
      });
    });

    // 3.5 Get essay data
    const essayCollectionRef = collection(db, 'essay');
    const allEssays = query(
      essayCollectionRef,
      where('owner', '==', studentEmail),
      where('deleteDocument', '==', false)
    );
    const essayData = await getDocs(allEssays);
    essayData.forEach(async (doc) => {
      const essayDocRef = doc.ref;
      await updateDoc(essayDocRef, {
        sharedWith: arrayUnion(parentUid),
      });

      // NOTE: We are not adding parent to google docs for now since parent has different Auth token and we can't add add parent to student's google docs
    });

    // 3.6 Get all applications data
    const applicationsCollectionRef = collection(db, 'applications');
    const allApplications = query(
      applicationsCollectionRef,
      where('owner', '==', studentEmail),
      where('deleteDocument', '==', false)
    );
    const applicationsData = await getDocs(allApplications);
    applicationsData.forEach(async (doc) => {
      const applicationsDocRef = doc.ref;
      await updateDoc(applicationsDocRef, {
        sharedWith: arrayUnion(parentUid),
      });
    });

    return true;
  } catch (error) {
    console.error('Error updating document: ', error);
    return false;
  }
};

export const getAllCollegesForOnBoarding = async () => {
  const collegesCollectionRef = collection(db, 'collegesList');
  const allColleges = query(collegesCollectionRef);
  const collegesData = await getDocs(allColleges);
  return collegesData.docs.map((doc) => doc.data());
};

export const createChatRoomForEssays = async () => {
  try {
    // get all essay data with deleteDocument is false
    // get all chatRoom data with isTopic is true
    // check if chatRoom exists with essayId (utilityId)
    // if not exists create chatRoom
    // add essayId to chatRoom

    // get all essay data with deleteDocument is false
    const essayCollectionRef = collection(db, 'essay');

    const allEssays = await getDocs(
      query(essayCollectionRef, where('deleteDocument', '==', false))
    );

    // get all chatRoom data with isTopic is true
    const chatRoomCollectionRef = collection(db, 'chatRooms');
    const allChatRooms = await getDocs(
      query(chatRoomCollectionRef, where('isTopic', '==', true))
    );

    // check if chatRoom exists with essayId (utilityId)
    allEssays.forEach(async (essayDoc) => {
      const essayData = essayDoc.data();
      const essayId = essayDoc.id;

      const chatRoomExists = allChatRooms.docs.some(
        (chatRoom) => chatRoom.data().utilityId === essayId
      );

      if (!chatRoomExists) {
        let allChatParticipants = [
          TEAM_UNIVERSILY_DETAILS.uid,
          essayData.createdBy,
          ...essayData.sharedWith,
        ];

        // also get essay owner details to add to chatParticipants
        const essayOwnerProfile = await documentExists(
          'profile',
          'email',
          essayData.owner
        );
        if (essayOwnerProfile) {
          allChatParticipants.push(essayOwnerProfile.data[0].uid);
        }

        // before pushing to chatParticipants remove duplicates
        const chatParticipants = [...new Set(allChatParticipants)];


        const docRef = doc(collection(db, 'chatRooms'));

        // if not exists create chatRoom
        await setDoc(docRef, {
          id: docRef.id,
          isTopic: true,
          archivedBy: [],
          chatParticipants: chatParticipants,
          utilityId: essayId,
          topicType: 'Essays',
          topicName: `Topic for ${essayData.essayName}`,
          deleteDocument: false,
          createdBy: essayData.createdBy,
          createdAt: essayData.createdAt,
          updatedAt: new Date(),
        });

        console.log(`Chat Room created for essay ${essayId}`);
      }
    });
  } catch (error) {
    console.error('Error updating document: ', error);
    return false;
  }
};

export const createGroupChatForExistingTeams = async () => {
  // 1. Get all teams data where deleteDocument is false.
  // 2. Traverse through each team and create a chat room for each team.
  // 3. Add team members to chat room participants. since team owner is also present in team.
  // 4. Add teamId to chat room utilityId.
  // 5. Add team name to chat room topicName.
  // 6. Add team createdBy to chat room createdBy.
  // 7. Make isTopic true for chat room.
  // 8. isDeleteDocument false for chat room.
  try {

  // 1. Get all teams data where deleteDocument is false.
  const teamsCollectionRef = collection(db, 'teams');
  const allTeams = await getDocs(teamsCollectionRef);

  // 2. Traverse through each team and create a chat room for each team.
  allTeams.forEach(async (teamDoc) => {
    const teamData = teamDoc.data();
    const teamId = teamData.id;

    // 3. Add team members to chat room participants. since team owner is also present in team.
    let allChatParticipants = [
      ...teamData.teamMembers.map((member) => member.uid),
    ];

    const createdBy = teamData?.teamMembers?.find(mem => mem.email === teamData.studentEmail)?.uid;

    // before pushing to chatParticipants remove duplicates
    const chatParticipants = [...new Set(allChatParticipants)];

    // before creating chat room check if chat room already exists for team
    const chatRoomExists = await documentExists('chatRooms', 'utilityId', teamId);

    // 4. Add teamId to chat room utilityId.
    // 5. Add team name to chat room topicName.
    // 6. Add team createdBy to chat room createdBy.
    // 7. Make isTopic true for chat room.
    // 8. deleteDocument false for chat room.
    if(chatRoomExists.exists) {
      console.log(`Chat Room already exists for team ${teamId}`);
      return;
    } else {
      const docRef = doc(collection(db, 'chatRooms'));
      await setDoc(docRef, {
        id: docRef.id,
        isTopic: true,
        archivedBy: [],
        chatParticipants: chatParticipants,
        utilityId: teamId,
        topicType: 'Group Chat',
        topicName: teamData.teamName,
        deleteDocument: false,
        createdBy: teamData?.createdBy || createdBy,
        createdAt: new Date(),
        updatedAt: new Date(),
      });
  
      console.log(`Chat Room created for team ${teamId}`);
    }
  })

  } catch (error) {
    console.error('Error updating document: ', error);
    return false;
  }
}

export async function removeTeamMemberFromChatRoom({teamId, memberUiD}) {
  try {
    // before removing the member from chat room, check if the member is part of the chat room
    // if yes, then remove the member from chat room
    // if not, then show a toast message that the member is not part of the chat room

    // get the chat room data by chat room id
    const chatRoomCollection = collection(db, 'chatRooms');

    const q = query(chatRoomCollection, where('utilityId', '==', teamId));

    const chatRoomSnapshot = await getDocs(q);

    const chatRoomData = chatRoomSnapshot?.docs[0]?.data();

    // check if the member is part of the chat room
    const isMemberExist = chatRoomData?.chatParticipants?.find(
      (member) => member === memberUiD
    );

    if (isMemberExist) {
      // if the member is part of the chat room, then remove the member from the chat room
      await updateDoc(chatRoomSnapshot?.docs[0]?.ref, {
        chatParticipants: chatRoomData?.chatParticipants?.filter(
          (member) => member !== memberUiD
        ),
      });

      // show a toast message that the member is removed from the chat room
      console.log('Member removed from the chat room');
    } else {
      // if the member is not part of the chat room, then show a toast message
      console.log('Member is not part of the chat room');
    }
    
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function checkAndCreateChatRoom({collectionName, studentEmail = '', isTopic = false, topicType = ''}) {
  try {
    // get all data from collection where owner is userId
    // loop through all data and check if chat room exists with utilityId
    // if not exists create chat room
    // add utilityId to chat room
    // else do nothing
    const collectionRef = collection(db, collectionName);
    const allData = query(collectionRef, where('owner', '==', studentEmail), where('deleteDocument', '==' , false));
    const data = await getDocs(allData);
    const chatRoomCollectionRef = collection(db, 'chatRooms');

    data.forEach(async (story) => {
      const docData = story.data();
      if(docData?.owner) {
        const utilityId = story.id;

        const chatRoomData = query(chatRoomCollectionRef, where('utilityId','==' ,utilityId), where('isTopic', '==', isTopic))
        const data = await getDocs(chatRoomData)

        if(data.empty) {
          if(topicType) {
            const sharedWith = [TEAM_UNIVERSILY_DETAILS.uid, docData?.createdBy];

            if(docData?.sharedWith?.length) {
              sharedWith.push(...docData?.sharedWith)
            }

            // filter out duplicates
            const uniqueSharedWith = [...new Set(sharedWith)];

            const chatRoomDataPayload = {
              isTopic: true,
              archivedBy: [],
              chatParticipants: uniqueSharedWith,
              topicName: 'Topic for '+ docData?.essayName,
              utilityId: utilityId,
              topicType: topicType,
            }
            const docRef = doc(collection(db, 'chatRooms'));
            // create chatRoom for utility
            await setDoc(docRef, {
              id: docRef.id,
              ...chatRoomDataPayload,
              deleteDocument: false,
              createdBy: docData?.createdBy,
              createdAt: docData?.createdAt,
              updatedAt: new Date(),
            });

            console.log(`Chat Room created for ${docData?.essayName}`);
          } else {
            throw new Error("Please provide topicType")
          }
        } else {
          console.log("Chat Room Exist For", docData?.essayName)
        }
      }
    })

  } catch (error) {
    console.log(error);
    return error;
  }
}

export const checkChatRoomFields = async (collectionName, fieldName = 'id', fieldValue) => {
  try {
    // get all charRooms data where isTopic is true
    // loop through all chatRooms data and check if field exists
    // if field exists then do nothing
    // if field does not exists then add field to chat room
    const chatRoomCollectionRef = collection(db, 'chatRooms');

    const allChatRooms = query(chatRoomCollectionRef, where('isTopic', '==', true));

    const chatRoomsData = await getDocs(allChatRooms);

    chatRoomsData.forEach(async (doc) => {
      const docData = doc.data();
      const chatRoomId = doc.id;

      if(docData[fieldName] === undefined) {
        // console.log("docData", docData)
        // console.log(`Field ${fieldName} does not exist in chat room ${chatRoomId}`);
        await updateDoc(doc.ref, {
          id: chatRoomId,
          updatedAt: new Date(),
        });

        console.log(`Field ${fieldName} added to chat room ${chatRoomId}`);
      } else {
        console.log(`Field ${fieldName} already exists in chat room ${chatRoomId}`);
      }
    });
  } catch (error) {
    console.log(error);
    return error;
  }
}

// for notification read
export async function markNotificationAsRead({userId, notificationId}) {
  // get the notification data by notification id
  // check if userId is present in seenBy array
  // if not present then add userId to seenBy array
  // else do nothing

  const notificationCollectionRef = collection(db, 'notifications');

  const notificationData = query(notificationCollectionRef, where('id', '==', notificationId));

  const data = await getDocs(notificationData);

  if(data.empty) {
    console.log("Notification not found")
    return false;
  }

  const notificationDoc = data.docs[0];

  const notification = notificationDoc.data();

  const seenBy = notification.seenBy || [];

  if(!seenBy.includes(userId)) {
    seenBy.push(userId);
    await updateDoc(notificationDoc.ref, {
      seenBy: seenBy,
    });

    console.log(`Notification marked as read for user ${userId}`);
  } else {
    console.log(`Notification already marked as read for user ${userId}`);
  }
}