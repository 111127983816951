import { useEffect, useState } from 'react';

import Dialog from '../../shared/Dialog';
import { AddNewGPAScore } from './AddNewGPAScore';
import { addNotificationItem, addStoryItem } from '../../../firebase/services/addServices';
import { addStoryItemTypes, NOTIFICATION_ACTION_TYPES } from '../../../constants/other';
import useProfileStore from '../../../stores/ProfileStore';
import useTeamStore from '../../../stores/TeamStore';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateStoryItem } from '../../../firebase/services/updateServices';
import useStudentStore from '../../../stores/StudentStore';
import { UTILITY_TYPES } from '../../../utils/utlityTypes';
const convertToFloat = (value) => {
  return value ? parseFloat(value).toFixed(1) : null;
};
export const DEFAULT_GPA_RANK_DATA = {
  GPAData: {
    unweighted: convertToFloat(null),
    unweightedOutOf: convertToFloat(4),
    weighted: convertToFloat(null),
    weightedOutOf: convertToFloat(5),
  },
  RankData: {
    unweighted: convertToFloat(null),
    unweightedOutOf: convertToFloat(null),
    weighted: convertToFloat(null),
    weightedOutOf: convertToFloat(null),
  },
};
export const AddNewGPAScoreDialog = ({
  satScores = [],
  satSubjects = [],
  satTarget = [],
  isAddingSATTarget = false,
  onContinue = () => {},
  isEditingGpaScore = false,
  scoreData = [],
  targetData = [],
  open = false,
  isAddDialog = false,
  isTargetDialog = false,
  onCancel = () => {},
  ...props
}) => {
  const userProfile = useProfileStore((state) => state.profile);
  const selectedTeam = useTeamStore((state) => state.selectedTeam);
  const isParent = useProfileStore((state) => state.isParent);
  const selectedStudent = useStudentStore((state) => state.selectedStudent);

  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (isEditingGpaScore && scoreData.length > 0) {
      setGPARankData(scoreData[0]);
    }
  }, [scoreData]);

  const [GPARankData, setGPARankData] = useState(DEFAULT_GPA_RANK_DATA);
  const onDialogContinue = async (data) => {
    if (isEditingGpaScore) {
      const res = await updateStoryItem(
        {
          id: scoreData[0]?.id,
          userId: isParent ? selectedStudent?.uid : userProfile?.uid,
          ...GPARankData,
        },
        addStoryItemTypes.GPA_SCORES
      );

      if (res) {
        // create notification and chat room for the essay
        const notificationData = {
          utilityType: UTILITY_TYPES.GPA,
          utilityName: "GPA Score",
          utilityId: scoreData[0]?.id,
          teamId: selectedTeam?.id,
          actionType: NOTIFICATION_ACTION_TYPES.UPDATED,
        }
        await addNotificationItem(notificationData);
      }

      onCancel();
      setGPARankData(DEFAULT_GPA_RANK_DATA);
      return;
    } else {
      const gpaId = await addStoryItem(
        {
          ...GPARankData,
          createdBy: userProfile?.uid,
          owner: isParent ? selectedTeam?.studentEmail : userProfile?.email,
        },
        addStoryItemTypes.GPA_SCORES
      );

      if (gpaId) {
        // create notification and chat room for the GPA Scores
        const notificationData = {
          utilityType: UTILITY_TYPES.GPA,
          utilityName: "GPA Score",
          utilityId: gpaId,
          teamId: selectedTeam?.id,
          actionType: NOTIFICATION_ACTION_TYPES.CREATED,
        }
        await addNotificationItem(notificationData);
        // Create chat channel for gpa
        // get all teams member from the selected team
        const channelMembers = selectedTeam?.teamMembers
          ?.filter((member) => member.invite_status === 'JOINED')
          ?.map((member) => member.uid);

        // create chat room
        await addStoryItem(
          {
            isTopic: true,
            archivedBy: [],
            chatParticipants: channelMembers,
            topicName: 'Topic for GPA',
            utilityId: gpaId,
            topicType: 'GPA',
          },
          addStoryItemTypes.CHAT_ROOM
        );
      }

      onCancel();
      setGPARankData(DEFAULT_GPA_RANK_DATA);
      if (location.pathname !== '/myHub') {
        navigate(`/${selectedTeam?.id}/myStory/gpaScores`);
      }
    }
  };

  const onDialogCancel = () => {
    onCancel();

    setGPARankData(DEFAULT_GPA_RANK_DATA);
  };

  return (
    <Dialog
      isHeight={true}
      title={`${
        isAddDialog ||
        scoreData[0]?.scores?.length === 0 ||
        scoreData[0]?.testDate === ''
          ? 'Add GPA and Class Rank'
          : 'Edit GPA and Class Rank'
      }`}
      open={open}
      maxWidth="sm"
      onContinue={onDialogContinue}
      onCancel={onDialogCancel}
      continueText={
        isAddDialog ||
        scoreData[0]?.scores?.length === 0 ||
        scoreData[0]?.testDate === ''
          ? 'Add'
          : 'Update'
      }
      {...props}
    >
      <AddNewGPAScore
        setGPARankData={setGPARankData}
        GPARankData={GPARankData}
      />
    </Dialog>
  );
};
