import { useState } from 'react';
import { Drawer as DRAWER } from '@mui/material';
import NicheToolWindowHeader from './NicheToolWindowHeader';
import { styled } from '@mui/system';

const Drawer = styled(DRAWER, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'fullScreen',
})(({ theme, open, fullScreen, isMobile }) => ({
  zIndex: 80,
  '& .MuiDrawer-paper': {
    backgroundColor: 'transparent',
    height: open ? (fullScreen ? '100%' : 292) : 48,
    borderTop: 0,
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow:
      '0px -3px 12px -4px rgb(16 24 40 / 24%), 0px 4px 6px -2px rgb(16 24 40 / 3%)',
    top: open && fullScreen && 64,
    position: isMobile && 'absolute',
  },
}));

const NicheToolWindow = ({
  isOpen = true,
  setIsOpen = () => {},
  isHeaderExpanded = false,
  children,
  lanes,
  unAssignedLane,
  isParent = false,
  canCreateNewUtilities = () => {},
  isMobile = false,
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  return (
    <Drawer
      variant="permanent"
      PaperProps={{
        style: {
          position: 'absolute',
        },
      }}
      ModalProps={{
        container: document.getElementById('niche-page'),
        style: {
          position: 'absolute',
        },
      }}
      slotProps={{
        backdrop: {
          style: {
            position: 'absolute',
          },
        },
      }}
      anchor="bottom"
      open={isOpen}
      fullScreen={isFullscreen}
      isMobile={isMobile}
    >
      <NicheToolWindowHeader
        canCreateNewUtilities={canCreateNewUtilities}
        isExpanded={isHeaderExpanded}
        isParent={isParent}
        isCollapsed={isOpen}
        onExpand={() => {
          setIsOpen(isFullscreen ? true : !isOpen);
          setIsFullscreen(false);
        }}
        onUnfold={() => {
          setIsFullscreen(!isFullscreen);
          setIsOpen(true);
        }}
        isFullscreen={isFullscreen}
        lanes={lanes}
        unAssignedLane={unAssignedLane}
        isMobile={isMobile}
      />
      <div className="bg-[#EFEFEA] h-full">{children}</div>
    </Drawer>
  );
};

export default NicheToolWindow;
