import { useRef, useState } from 'react';
import Button from '../shared/Button';
import Menu from '../shared/Menu';
import MenuItem from '../shared/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useActivityStore from '../../stores/ActivityStore';
import useHonorStore from '../../stores/HonorStore';
import useNicheStore from '../../stores/NicheStore';
import { useLocation, useNavigate } from 'react-router-dom';
import useTestStore from '../../stores/TestStore';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import useCourseStore from '../../stores/CourseStore';
import {
  freemiumLimitCounts,
  myStoryUtilities,
} from '../../constants/staticData';
import { Divider } from '@mui/material';
import useGoogleAuthorization from '../../hooks/use-google-authorization';
import { useGoogleDocs } from '../../hooks';
import useEssayStore from '../../stores/EssayStore';
import useApplicationStore from '../../stores/ApplicationStore';
import useProfileStore from '../../stores/ProfileStore';
import useSubscriptionStore from '../../stores/SubscriptionStore';
import useThemeStore from '../../stores/ThemeStore';
import { useGPAStore } from '../../stores/GPAStore';
import { useShallow } from 'zustand/react/shallow';
import { useMyStoryStore } from '../../stores/myStoryStore';
import { UTILITY_TYPES } from '../../utils/utlityTypes';
import useTeamStore from '../../stores/TeamStore';
import useAccessCheck from '../../utils/accessCheck';
import { openSnackbar } from '../shared/GlobalSnackbar/GlobalSnackbar';

const MyStoryAddNewButton = ({
  variant = 'contained',
  onSelect,
  showDropDown = true,
  isUtility = false,
  showNiche = true,
  showTests = true,
  showEssays = true,
  showCollege = true,
  showGPA = true,
  canCreateNewUtilities = () => {},
  ...props
}) => {
  const button = useRef();
  const [
    nicheAccess,
    activityAccess,
    honorAccess,
    courseAccess,
    testAccess,
    essayAccess,
    collegeAccess,
  ] = useAccessCheck([
    UTILITY_TYPES.Niche,
    UTILITY_TYPES.Activities,
    UTILITY_TYPES.Honors,
    UTILITY_TYPES.Courses,
    UTILITY_TYPES.Test,
    UTILITY_TYPES.Essay,
    UTILITY_TYPES.Applications,
  ]);
  const { onInitAuthorization, tokens } = useGoogleAuthorization();
  const [isOpen, setIsOpen] = useState(false);
  const [isSubMenu, setIsSubMenu] = useState(false);
  const [isTestMenu, setIsTestMenu] = useState(false);
  const [isEssaySubMenu, setIsEssaySubMenu] = useState(false);
  const { setAddingActivity } = useActivityStore();
  const { setAddingHonors } = useHonorStore();
  const { setAddingCourse } = useCourseStore();
  const { setIsAddingGPAScore, setIsEditingGpaScore, gpaScores } = useGPAStore(
    useShallow((state) => ({
      setIsAddingGPAScore: state.setIsAddingGPAScore,
      setIsEditingGpaScore: state.setIsEditingGpaScore,
      gpaScores: state.gpaScores,
    }))
  );
  const myStoryStore = useMyStoryStore(
    useShallow((state) => ({
      setActiveStoryType: state.setActiveStoryType,
      setStorySlideOut: state.setStorySlideOut,
      setActiveTabIndex: state.setActiveSlideOutTabIndex
    }))
  );

  const navigate = useNavigate()

  const { applications, setIsAddCollege } = useApplicationStore();
  const { setIsAddingSATScore, setIsAddingACTScore } = useTestStore();
  const { setIsAddingNiche, setIsSelectTeamMember } = useNicheStore();
  const { essays, setIsAddingEssay, setIsAddingCoreEssay } = useEssayStore();
  const { setUpgradeBannerUtil, setShowUpgradeBanner } = useSubscriptionStore();
  const location = useLocation();
  const { isMobile } = useThemeStore();
  const { checkIfAccessTokenIsInvalid } = useGoogleDocs();
  const { profile, isParent } = useProfileStore();
  const selectedTeam = useTeamStore((state) => state.selectedTeam);
  const canCreateNewEssayApplications = (type) => {
    if (!profile?.isSubscriptionActive) {
      switch (type) {
        case 'essays': {
          if (essays.length >= freemiumLimitCounts.ESSAY) {
            setUpgradeBannerUtil('essay');
            setShowUpgradeBanner(true);
            return false;
          } else {
            return true;
          }
        }
        case 'applications': {
          if (applications?.length >= freemiumLimitCounts.APPLICATION) {
            setUpgradeBannerUtil('application');
            setShowUpgradeBanner(true);
            return false;
          } else {
            return true;
          }
        }
        default:
          return false;
      }
    } else {
      return true;
    }
  };
  async function handleAddNew(e) {
    if (location.pathname === `/${selectedTeam?.id}/myStory/courses`) {
      if (courseAccess) {
        setAddingCourse(true);
      } else {
        openSnackbar(
          'You have reached the maximum limit, please upgrade to add more',
          'error'
        );
      }
    }
    if (location.pathname === `/${selectedTeam?.id}/myStory/honors`) {
      if (honorAccess) {
        await setAddingHonors(true);
      } else {
        openSnackbar(
          'You have reached the maximum limit, please upgrade to add more',
          'error'
        );
      }
    }
    if (location.pathname === `/${selectedTeam?.id}/myStory/activities`) {
      if (activityAccess) {
        await setAddingActivity(true);
      } else {
        openSnackbar(
          'You have reached the maximum limit, please upgrade to add more',
          'error'
        );
      }
    }
    if (
      location.pathname === `/${selectedTeam?.id}/myStory/niche` &&
      !isUtility
    ) {
      if (nicheAccess) {
        setIsAddingNiche(true);
        setIsSelectTeamMember(true);
      } else {
        openSnackbar(
          'You have reached the maximum limit, please upgrade to add more',
          'error'
        );
      }
    }
    if (location.pathname === `/${selectedTeam?.id}/myStory/tests`) {
      if (testAccess) {
        setIsTestMenu(true);
        setIsOpen(false);
      } else {
        openSnackbar(
          'You have reached the maximum limit, please upgrade to add more',
          'error'
        );
      }
    }
    if (location.pathname === `/${selectedTeam?.id}/myStory/gpaScores`) {
      if (gpaScores.length === 0) {
        setIsAddingGPAScore(true);
      } else {
        setIsEditingGpaScore(true);
      }
      setIsOpen(false);
    }
    if (location.pathname === `/${selectedTeam?.id}/myStory`) {
      setIsOpen(true);
    }
    if (isUtility) {
      setIsOpen(true);
    }
    e.stopPropagation();
  }

  return (
    <div className="flex h-fit">
      <div ref={button}>
        <Button
          variant={variant}
          startIcon={variant === 'text' ? <AddCircleIcon /> : <AddIcon />}
          endIcon={
            <div className="flex gap-2 pl-1">
              <Divider orientation="vertical" flexItem />
              {variant !== 'text' && showDropDown && (
                <KeyboardArrowDownIcon
                  onClick={(e) => {
                    setIsOpen(true);
                    e.stopPropagation();
                  }}
                />
              )}
            </div>
          }
          onClick={(e) => handleAddNew(e)}
          {...props}
        >
          Add New
        </Button>
      </div>
      {isTestMenu && (
        <Menu
          open={isTestMenu}
          anchorEl={button.current}
          PaperProps={{ sx: { width: 140 } }}
          onClose={() => {
            setIsTestMenu(false);
          }}
        >
          <MenuItem
            onClick={() => {
              setIsTestMenu(false);
              setIsAddingSATScore(true);
            }}
          >
            SAT
          </MenuItem>
          <MenuItem
            onClick={() => {
              setIsTestMenu(false);
              setIsAddingACTScore(true);
            }}
          >
            ACT
          </MenuItem>
        </Menu>
      )}
      {!isTestMenu && (
        <Menu
          open={isOpen}
          anchorEl={button.current}
          PaperProps={{ sx: { width: 140 } }}
          onClose={() => {
            setIsOpen(false);
            setIsSubMenu(false);
          }}
        >
          {showNiche && (
            <MenuItem
              onClick={() => {
                if (nicheAccess) {
                  setIsOpen(false);
                  setIsSelectTeamMember(true);
                  setIsAddingNiche(true);
                } else {
                  openSnackbar(
                    'You have reached the maximum limit, please upgrade to add more',
                    'error'
                  );
                }
              }}
            >
              Niche
            </MenuItem>
          )}
          <MenuItem
            onClick={async () => {
              if (activityAccess) {
                setIsOpen(false);
                setAddingActivity(true);
              } else {
                openSnackbar(
                  'You have reached the maximum limit, please upgrade to add more',
                  'error'
                );
              }
              setIsOpen(false);
            }}
          >
            Activities
          </MenuItem>
          <MenuItem
            onClick={async () => {
              if (honorAccess) {
                setIsOpen(false);
                setAddingHonors(true);
              } else {
                openSnackbar(
                  'You have reached the maximum limit, please upgrade to add more',
                  'error'
                );
              }
              setIsOpen(false);
            }}
          >
            Honors
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (courseAccess) {
                setIsOpen(false);
                if (onSelect) onSelect('courses');
                else setAddingCourse(true);
              } else {
                openSnackbar(
                  'You have reached the maximum limit, please upgrade to add more',
                  'error'
                );
              }
              setIsOpen(false);
            }}
          >
            Courses
          </MenuItem>
          {showGPA && (
            <MenuItem
              onClick={() => {
                setIsOpen(false);
                if (gpaScores.length === 0) {
                  setIsAddingGPAScore(true);
                } else {
                  navigate(`/${selectedTeam?.id}/myStory/gpaScores`)
                  setIsEditingGpaScore(true);
                  myStoryStore.setActiveStoryType(UTILITY_TYPES.GPA);
                  myStoryStore.setStorySlideOut(true);
                  myStoryStore.setActiveTabIndex(1);
                }
              }}
            >
              GPA / Rank
            </MenuItem>
          )}
          {showTests && (
            <MenuItem
              onClick={() => {
                if (testAccess) {
                  setIsSubMenu(!isSubMenu);
                } else {
                  openSnackbar(
                    'You have reached the maximum limit, please upgrade to add more',
                    'error'
                  );
                }
              }}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              Tests
              <ArrowDropDownIcon
                className={`${
                  isSubMenu && 'rotate-180 transition duration-500 ease-in'
                }`}
              />
            </MenuItem>
          )}
          {isSubMenu && (
            <>
              <MenuItem
                onClick={() => {
                  setIsSubMenu(false);
                  setIsOpen(false);
                  setIsAddingSATScore(true);
                }}
                sx={{
                  marginLeft: '10px',
                }}
              >
                SAT
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setIsSubMenu(false);
                  setIsOpen(false);
                  setIsAddingACTScore(true);
                }}
                sx={{
                  marginLeft: '10px',
                }}
              >
                ACT
              </MenuItem>
            </>
          )}
          {!isMobile && showEssays && (
            <MenuItem
              onClick={() => {
                if (essayAccess) {
                  setIsEssaySubMenu(!isEssaySubMenu);
                } else {
                  openSnackbar(
                    'You have reached the maximum limit, please upgrade to add more',
                    'error'
                  );
                }
              }}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              Essays
              <ArrowDropDownIcon
                className={`${
                  isEssaySubMenu && 'rotate-180 transition duration-500 ease-in'
                }`}
              />
            </MenuItem>
          )}
          {isEssaySubMenu && (
            <>
              <MenuItem
                onClick={async () => {
                  setIsEssaySubMenu(false);
                  setIsOpen(false);
                  if (!tokens || (await checkIfAccessTokenIsInvalid())) {
                    await onInitAuthorization();
                  } else {
                    setIsAddingCoreEssay(true);
                  }
                }}
                sx={{
                  marginLeft: '10px',
                }}
              >
                Core
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  setIsEssaySubMenu(false);
                  setIsOpen(false);
                  if (!tokens || (await checkIfAccessTokenIsInvalid())) {
                    await onInitAuthorization();
                  } else {
                    setIsAddingEssay(true);
                    setIsAddingCoreEssay(false);
                  }
                }}
                sx={{
                  marginLeft: '10px',
                }}
              >
                Application
              </MenuItem>
            </>
          )}
          {showCollege && (
            <MenuItem
              onClick={async () => {
                if (collegeAccess) {
                  setIsAddCollege(true);
                } else {
                  openSnackbar(
                    'You have reached the maximum limit, please upgrade to add more',
                    'error'
                  );
                }
                setIsOpen(false);
              }}
            >
              Colleges
            </MenuItem>
          )}
        </Menu>
      )}
    </div>
  );
};

export default MyStoryAddNewButton;
