import useActivityStore from '../../../../stores/ActivityStore';
import useNicheStore from '../../../../stores/NicheStore';
import MyStoryActivityDocsFooter from '../../activities/MyStoryActivityDocsFooter';
import AddDetailsPopupDocs from '../AddDetailsPopupDocs';
import AddDetailsPopupEmptyBody from '../AddDetailsPopupEmptyBody';
import { ViewDetailsHeader } from './ViewDetailsHeader';

export const ActivityViewDetailsPopup = ({
  utilityData = {},
  openStoryDoc = () => {},
  onClose = () => {},
  isParent = false,
}) => {
  const activityStore = useActivityStore();
  const nicheStore = useNicheStore();
  const onEdit = () => {
    activityStore.setAddEditActivityId(utilityData.id);
    activityStore.setAddingOrEditingDocs(true);
  };
  return (
    <>
      <ViewDetailsHeader
        type="Activity"
        title={utilityData.name}
        isParent={isParent}
        onClose={onClose}
        onEdit={onEdit}
        onReassignNiche={() => {
          nicheStore.setIsReassignNiche(true);
          nicheStore.setReassignUtilityType('ACTIVITY');
          nicheStore.setReassignUtilityId(utilityData.id);
        }}
      />
      {utilityData?.docInformation &&
        ((utilityData?.docInformation?.length === 1 &&
          utilityData?.docInformation[0]?.charCount > 0) ||
          utilityData?.docInformation[0]?.charCount > 0 ||
          utilityData?.docInformation[1]?.charCount > 0) && (
          <AddDetailsPopupDocs tabData={utilityData?.docInformation} />
        )}
      {utilityData?.docInformation &&
        ((utilityData?.docInformation?.length === 1 &&
          utilityData?.docInformation[0]?.charCount === 0) ||
          (utilityData?.docInformation[0]?.charCount === 0 &&
            utilityData?.docInformation[1]?.charCount === 0)) && (
          <AddDetailsPopupEmptyBody openStoryDoc={onEdit} isParent={isParent} />
        )}
      <div className="px-6 py-4 bottom-0">
        <MyStoryActivityDocsFooter
          grades={utilityData.grades}
          durationHoursPerWeek={utilityData.hoursPerWeek}
          durationWeeksPerYear={utilityData.weeksPerYear}
          activityTypes={utilityData.activityTypes?.map((type) => type.label)}
          reviewers={utilityData.reviewers}
          editIcon={false}
          onEdit={() => {
            activityStore.setAddEditActivityId(utilityData.id);
            activityStore.setEditingActivity(true);
          }}
        />
      </div>
    </>
  );
};
