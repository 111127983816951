import React, { useCallback, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import ChatBubbleGroup from '../../../chat/ChatBubbleGroup';
import ChatInputField from '../../../chat/ChatInputField';
import { addStoryItem } from '../../../../firebase/services/addServices';
import useProfileStore from '../../../../stores/ProfileStore';
import { ReactComponent as MessageIcon } from '../../../../assets/svgs/messageIcon.svg';
import GroupIcon from '@mui/icons-material/Group';
import {
  addStoryItemTypes,
  firebaseDataType,
} from '../../../../constants/other';
import { getDocumentByUtilityId } from '../../../../firebase/services/getServices';
import { useShallow } from 'zustand/react/shallow';
import { useStoryItemListener } from '../../../../hooks';
import { firebaseQueryOperators } from '../../../../firebase/queryBuilder';
import { CHAT_MESSAGES } from '../../../../firebase/constants';
import moment from 'moment';
import { getProfileData } from '../../../../firebase/services/user';
import { CircularProgress } from '@mui/material';
import useNicheStore from '../../../../stores/NicheStore';
import useTeamStore from '../../../../stores/TeamStore';

export const MyNicheSlideOutNotes = () => {
  const profile = useProfileStore((state) => state.profile);

  const nicheStore = useNicheStore(
    useShallow((state) => ({
      editNicheId: state.editNicheId,
    }))
  );
  const [notesData, setNotesData] = useState();
  const [chatMessages, setChatMessages] = useState([]);
  const teamStore = useTeamStore();
  const [recipientDetails, setRecipientDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const onSendMessage = async (messageText, callback = () => {}) => {
    callback();
    await addStoryItem(
      {
        messageContent: messageText,
        messageType: 'text',
        timeStamp: Date.now(),
        senderId: profile?.uid,
        isRead: false,
      },
      `${addStoryItemTypes.CHAT_ROOM}/${notesData?.id}/${addStoryItemTypes.CHAT_MESSAGES}`
    );
  };
  const getData = useCallback(async () => {
    setLoading(true);
    const activityChatRoomData = await getDocumentByUtilityId(
      addStoryItemTypes.CHAT_ROOM,
      nicheStore.editNicheId
    );
    setNotesData(activityChatRoomData[0]);

    const participants = activityChatRoomData[0]?.chatParticipants;
    let recipientProfileData = [];
    for (const participant of participants) {
      // const profileData = await getProfileData(participants[i]);
      const profileData = teamStore.selectedTeam?.teamMembers?.find(x => x?.uid === participant);
      recipientProfileData.push(profileData);
    }
    setRecipientDetails(recipientProfileData);
    setLoading(false);
  }, [nicheStore.editNicheId]);

  useEffect(() => {
    getData();
  }, []);

  const {
    data: chatMessageData,
    // eslint-disable-next-line no-unused-vars
    count: chatMessageCount,
    isFetching: isFetchingMessageData,
  } = useStoryItemListener(
    `${addStoryItemTypes.CHAT_ROOM}/${notesData?.id}/${CHAT_MESSAGES}`,
    firebaseDataType.METADATA,
    [
      {
        property: 'deleteDocument',
        operator: firebaseQueryOperators.EQUAL_TO,
        value: false,
      },
    ]
  );

  const createdDate = dayjs
    .utc(notesData?.createdAt?.toDate())
    .local()
    .format('DD MMMM YYYY');
  const LAST_ONE_HOUR_MESSAGES = useMemo(() => {
    if (chatMessageData && chatMessageData?.length > 0) {
      return chatMessageData?.filter(({ createdAt }) =>
        moment(createdAt?.toDate()).isSameOrAfter(moment().subtract(1, 'hour'))
      );
    } else return [];
  }, [chatMessageData]);
  useEffect(() => {
    setChatMessages(
      chatMessageData?.sort((message1, message2) => {
        return new Date(message1.timeStamp) - new Date(message2.timeStamp);
      })
    );
    //eslint-disable-next-line
  }, [chatMessageData, isFetchingMessageData]);

  return (
    <>
      {loading ? (
        <div className=" w-full flex items-center justify-center">
          <CircularProgress />
        </div>
      ) : (
        <div className="h-full flex flex-col">
          <div className="flex flex-1 pt-6 pb-1 ">
            <div
              className={`flex flex-col gap-3 items-center w-full ${
                chatMessages?.length > 0 ? 'mt-auto' : 'justify-center'
              }`}
            >
              {chatMessages?.length > 0 ? (
                <>
                  <MessageIcon
                    sx={{
                      height: '24px',
                      width: '24px',
                    }}
                  />
                  <div className="flex items-center text-sm justify-center text-grey-200">
                    You created the chat{' '}
                    {dayjs(createdDate).isYesterday() && 'yesterday'}
                    {dayjs(createdDate).isToday() && 'today'}
                    {!dayjs(createdDate).isYesterday() &&
                      'on' &&
                      !dayjs(createdDate).isToday() &&
                      dayjs(createdDate).year() === dayjs().year() &&
                      dayjs(createdDate).format('DD MMMM').toUpperCase()}
                    {!dayjs(createdDate).isYesterday() &&
                      dayjs(createdDate).year() !== dayjs().year() &&
                      dayjs(createdDate).format('DD MMMM YYYY').toUpperCase()}
                  </div>
                </>
              ) : (
                <div className="items-center flex flex-col">
                  <GroupIcon />
                  <p className="flex items-center text-base justify-center text-grey-400 mx-6 text-center">
                    Use this space to share thoughts, record ideas, or ask
                    questions with your team.
                  </p>
                </div>
              )}
            </div>
          </div>

          <>
            <div className="px-6 overflow-auto">
              <ChatBubbleGroup
                currentUserId={profile?.uid}
                messageList={chatMessages}
                receipientDetails={recipientDetails}
              />
            </div>
            <div className="shadow-top">
              <ChatInputField
                onSendMessage={(
                  messageText,
                  callback,
                  storyContext = '',
                  id = ''
                ) =>
                  onSendMessage(
                    messageText,
                    callback,
                    LAST_ONE_HOUR_MESSAGES,
                    storyContext,
                    id
                  )
                }
                receipientDetails={{}}
              />
            </div>
          </>
        </div>
      )}
    </>
  );
};
