import { useState, useEffect } from 'react';
import AddNewCourseDialog from '../components/myStory/courses/AddNewCourseDialog';
import { addNotificationItem, addStoryItem } from '../firebase/services/addServices';
import SuccessSnackbar from '../components/shared/SuccessSnackbar';
import { useLocation, useNavigate } from 'react-router-dom';
import useProfileStore from '../stores/ProfileStore';
import useStudentStore from '../stores/StudentStore';
import {
  addTeamUniversilyToChatParticipants,
  addTeamUniversilyToReviewers,
  UTILITY_TYPES,
} from '../utils/utlityTypes';
import { addStoryItemTypes, NOTIFICATION_ACTION_TYPES } from '../constants/other';
import { useShallow } from 'zustand/react/shallow';
import useTeamStore from '../stores/TeamStore';
import { removeNullUndefined } from '../utils/helper';

const AddNewCourseDialogContainer = ({
  onFinish = () => {},
  teamMemberData,
  disabled = false,
  setDisabled = () => {},
  isAdding,
  ...props
}) => {
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isParent = useProfileStore((state) => state.isParent);
  const selectedStudent = useStudentStore((state) => state.selectedStudent);
  const selectedTeam = useTeamStore((state) => state.selectedTeam);
  const profileStore = useProfileStore(
    useShallow((state) => ({
      profile: state.profile,
    }))
  );
  const teamStore = useTeamStore(
    useShallow((state) => ({
      teams: state.teams,
      selectedTeam: state.selectedTeam,
    }))
  );

  useEffect(() => {
    if (isSuccessDialogOpen) {
      const timeId = setTimeout(() => {
        setIsSuccessDialogOpen(false);
      }, 3000);

      return () => {
        clearTimeout(timeId);
      };
    }
  }, [isSuccessDialogOpen]);

  //To handle the creation of a new course
  const onCourseContinue = async (courseData) => {
    let generatedUtilityId;
    if (isParent) {
      // Create a new course from the parent
      const parentCourseData = {
        ...courseData,
        sharedWith: addTeamUniversilyToReviewers(
          courseData?.sharedWith.map((teamMember) => teamMember?.id)
        ),
        owner: teamStore?.selectedTeam?.studentEmail,
        createdBy: profileStore.profile.uid,
      };
      generatedUtilityId = await addStoryItem(
        parentCourseData,
        addStoryItemTypes.COURSE
      );
    } else {
      // Create a new course from the student
      const studentCourseData = {
        ...courseData,
        owner: profileStore.profile.email,
        createdBy: profileStore.profile.uid,
        sharedWith: addTeamUniversilyToReviewers(
          courseData?.sharedWith.map((teamMember) => teamMember?.id)
        ),
      };
      generatedUtilityId = await addStoryItem(
        studentCourseData,
        addStoryItemTypes.COURSE
      );
    }
    if (generatedUtilityId) {
      // create notification for the course creation
      const notificationData = {
        utilityType: UTILITY_TYPES.Courses,
        utilityName: courseData.courseName,
        utilityId: generatedUtilityId,
        teamId: selectedTeam?.id,
        actionType: NOTIFICATION_ACTION_TYPES.CREATED,
      }
      await addNotificationItem(notificationData);

      const channelMembers = addTeamUniversilyToChatParticipants([
        ...courseData?.sharedWith?.map((member) => member?.id),
        isParent
          ? teamStore?.selectedTeam?.studentUID
          : profileStore.profile.uid,
      ]).filter((member) => member !== null && member !== undefined);

      await addStoryItem(
        {
          isTopic: true,
          archivedBy: [],
          chatParticipants: removeNullUndefined(channelMembers),
          topicName: 'Topic for ' + courseData?.courseName,
          utilityId: generatedUtilityId,
          topicType: 'Courses',
        },
        addStoryItemTypes.CHAT_ROOM
      );
    }
    // Strings to search for
    const searchStrings = ["/myHub", "/myNiche"];

    // Check if the content contains any of the search strings
    const foundStrings = searchStrings.filter(searchString => location.pathname.includes(searchString));
    if (
      foundStrings.length === 0
    ) {
      navigate(`/${selectedTeam?.id}/myStory/courses`, { replace: true });
    }
    setIsSuccessDialogOpen(true);
    onFinish();
  };
  return (
    <>
      <SuccessSnackbar
        message="A New Course has been created."
        open={isSuccessDialogOpen}
        autoHideDuration={6000}
        onCancel={() => setIsSuccessDialogOpen(false)}
      />
      <AddNewCourseDialog
        disabled={disabled}
        setDisabled={setDisabled}
        onContinue={onCourseContinue}
        onCancel={onFinish}
        teamMemberData={teamMemberData}
        isAdding={isAdding}
        {...props}
      />
    </>
  );
};

export default AddNewCourseDialogContainer;
