import DeleteDialog from '../../shared/DeleteDialog';

const DeleteNicheDialog = (args) => {
  return (
    <DeleteDialog
      title="Delete Niche"
      header="All items in this Niche will be moved to the Unassigned section."
      {...args}
    >
      <div className="text-[#2C303C] font-medium">
        All items in this Niche will be moved to the Unassigned section.
      </div>
    </DeleteDialog>
  );
};

export default DeleteNicheDialog;
