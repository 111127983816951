import { useEffect, useState } from 'react';
import { activityDocCharVersion } from '../../../constants/staticData';
import { useGoogleDocs } from '../../../hooks';
import useGoogleAuthorization from '../../../hooks/use-google-authorization';
import MyStoryDocsTabs from '../MyStoryDocsTabs';

const MyStoryActivityDocsTabs = ({
  addTabBtnText = 'Add 350 Character Version',
  tabLabels = [],
  onSelectedTabChange = () => {},
  onAddTabBtnClick = () => {},
  isParent = false,
  docsVersions = [],
  loadingState = '',
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { onInitAuthorization, tokens } = useGoogleAuthorization();
  const { checkIfAccessTokenIsInvalid } = useGoogleDocs();
  useEffect(() => {
    onSelectedTabChange(selectedTab);
    //eslint-disable-next-line
  }, [selectedTab]);

  async function handleAddTabButtonClick(version) {
    if (!tokens || (await checkIfAccessTokenIsInvalid()))
      await onInitAuthorization();
    else {
      await onAddTabBtnClick(version);
      setSelectedTab(version === activityDocCharVersion.CHAR_150 ? 0 : 1);
    }
  }

  return (
    <MyStoryDocsTabs
      value={selectedTab}
      onChange={(e, newValue) => {
        setSelectedTab(newValue);
      }}
      addTabBtnText={addTabBtnText}
      docsVersions={docsVersions}
      onAddTabBtnClick={(version) => handleAddTabButtonClick(version)}
      tabLabels={tabLabels}
      showAddTabBtn={!isParent && tabLabels.length !== 2}
      loadingState={loadingState}
      isParent={isParent}
    />
  );
};

export default MyStoryActivityDocsTabs;
