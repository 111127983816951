import React, { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/system';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const CustomChip = styled(Chip)(({ theme }) => ({
  borderRadius: '8px',
}));

const gradeArray = [
  {
    key: 0,
    label: '9th',
    icon: <CheckCircleIcon style={{ width: '15px', height: '15px' }} />,
  },
  {
    key: 1,
    label: '10th',
    icon: <CheckCircleIcon style={{ width: '15px', height: '15px' }} />,
  },
  {
    key: 2,
    label: '11th',
    icon: <CheckCircleIcon style={{ width: '15px', height: '15px' }} />,
  },
  {
    key: 3,
    label: '12th',
    icon: <CheckCircleIcon style={{ width: '15px', height: '15px' }} />,
  },
  {
    key: 4,
    label: '12th+',
    icon: <CheckCircleIcon style={{ width: '15px', height: '15px' }} />,
  },
];

const GradeSelector = ({
  grades = [],
  currentYear = '10th',
  onChange = () => {},
  gradeSelecterTitle = '',
}) => {
  const [selectedGrade, setSelectedGrade] = useState(grades);

  useEffect(() => {
    onChange(selectedGrade);
    //eslint-disable-next-line
  }, [selectedGrade]);

  return (
    <div className="flex flex-col gap-3">
      <div className="text-xs text-[#7D7D7D]">{gradeSelecterTitle}</div>
      <div className="flex gap-2">
        {gradeArray.map((data, index) => {
          return (
            <div className="flex flex-col items-center">
              <div>
                <CustomChip
                  label={
                    selectedGrade.includes(data.label) ? (
                      <div>
                        {data.label} {data.icon}
                      </div>
                    ) : (
                      data.label
                    )
                  }
                  onClick={() => {
                    if (!selectedGrade.includes(data.label)) {
                      setSelectedGrade([...selectedGrade, data.label]);
                    }
                    if (selectedGrade.includes(data.label)) {
                      let index = selectedGrade.indexOf(data.label);
                      selectedGrade.splice(index, 1);
                      setSelectedGrade([...selectedGrade]);
                    }
                  }}
                  sx={{
                    backgroundColor: selectedGrade.includes(data.label)
                      ? '#E9F0FF'
                      : '#EDEDEE',
                    color: selectedGrade.includes(data.label)
                      ? '#1958CD'
                      : '#22252D',
                  }}
                />
              </div>
              {data.label === currentYear && (
                <div className="flex flex-col items-center">
                  <ArrowDropUpIcon sx={{ color: '#D9D9D9' }} />
                  <span className="text-xs -mt-2 text-[#22252D] opacity-50">
                    {' '}
                    Current Year
                  </span>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GradeSelector;
