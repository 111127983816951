import Tooltip from '../../shared/Tooltip';
import TagsInput from '../../shared/TagsInput';

export default function ActivityTagsInput({
  label = 'Activity Type',
  placeholder = 'Art, Dance, etc.',
  tooltipTitle = '',
  menuListCount = 5,
  options = [],
  menuListTitle = '',
  ...props
}) {
  const title = `College applications will ask you to list what is the "type" of your Activity. 
  This field has tags for the "types" used by both the Common App and the University of California application platforms. 
  This is an optional field that will speed up your college applications if you apply to Common App or UC colleges.`;

  const uniList = [
    {
      id: 'uc01',
      label: 'Educational Preparation Programs',
      type: 'UC',
    },
    {
      id: 'uc02',
      label: 'Extracurricular Activities',
      type: 'UC',
    },
    {
      id: 'uc03',
      label: 'Other Coursework',
      type: 'UC',
    },
    {
      id: 'uc04',
      label: 'Volunteering / Community Service',
      type: 'UC',
    },
    {
      id: 'uc05',
      label: 'Work Experience',
      type: 'UC',
    },
  ];

  return (
    <Tooltip title={title} placement="right">
      <TagsInput
        multiple
        label={label}
        placeholder={placeholder}
        menuListCount={menuListCount}
        options={options}
        uniList={uniList}
        isViewAll={true}
        menuListTitle={menuListTitle}
        sx={{
          '.MuiFormControl-root': {
            width: '100%',
            '.MuiOutlinedInput-notchedOutline': {
              span: {
                margin: '0px 4px',
              },
            },
            '.MuiAutocomplete-endAdornment': {
              '.MuiAutocomplete-popupIndicator': {
                display: 'none',
              },
            },
          },
        }}
        {...props}
      />
    </Tooltip>
  );
}
