import NicheLanes from './NicheLanes';
import NichePageHeaders from './NicheHeaders';
import NichePageOverlay from './NicheOverlays';
import { useState } from 'react';
import useNicheStore from '../../../stores/NicheStore';
import useThemeStore from '../../../stores/ThemeStore';

const NichePage = ({
    currentGrade = '10th',
    lanes = [],
    unAssignedLane = [],
    showOverlay = false,
    onAddNiche = () => {},
    isViewDetails = false,
    setAddUtiltytoNiche = () => {},
    isAddNew = false,
    isParent = false,
    canCreateNewUtilities = () => {},
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const { isMobile } = useThemeStore();

    return (
        <div
            id="niche-page"
            className={`${
                !showOverlay && 'w-max sm:w-full overflow-y-auto'
            } max-h-full sm:overflow-hidden sm:w-full flex flex-col z-20 relative w-max`}
        >
        <NichePageHeaders
            currentGrade={currentGrade}
            onAddNiche={onAddNiche}
            isAddNew={isAddNew}
            isViewDetails={isViewDetails}
            isParent={isParent}
        />
            <div
                className='overflow-auto max-h-72'
            >   
                <NicheLanes
                    isOpen={isOpen}
                    currentGrade={currentGrade}
                    lanes={lanes}
                    unAssignedLane={unAssignedLane}
                    isViewDetails={isViewDetails}
                    setAddUtiltytoNiche={setAddUtiltytoNiche}
                    isParent={isParent}
                    canCreateNewUtilities={canCreateNewUtilities}
                    isMobile={isMobile}
                />
            </div>
        </div>
    );
};

export default NichePage;
