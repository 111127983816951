import React, { useEffect, useState } from 'react';
import NichePageContainer from './nicheSection/NicheContainer';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import useNicheStore from '../../stores/NicheStore';
import { useShallow } from 'zustand/react/shallow';
import AddNewNiche from '../../components/myStory/niches/AddNewNiche';
import {
  addNotificationItem,
  addStoryItem,
} from '../../firebase/services/addServices';
import useProfileStore from '../../stores/ProfileStore';
import useTeamStore from '../../stores/TeamStore';
import {
  addStoryItemTypes,
  NOTIFICATION_ACTION_TYPES,
} from '../../constants/other';
import {
  addTeamUniversilyToChatParticipants,
  filterUniAndTeamUniversily,
  INVITE_STATUS,
  UTILITY_TYPES,
} from '../../utils/utlityTypes';
import { openSnackbar } from '../../components/shared/GlobalSnackbar/GlobalSnackbar';
import nicheZeroStateLeft from '../../assets/svgs/ZeroStateLeftNiche.png';
import nicheZeroStateRight from '../../assets/svgs/zeroStateNicheRight.png';
import DeleteNicheDialog from '../../components/myStory/niches/DeleteNicheDialog';
import { updateStoryItem } from '../../firebase/services/updateServices';
import useActivityStore from '../../stores/ActivityStore';
import { deleteStoryItem } from '../../firebase/services/deleteServices';
import SuccessSnackbar from '../../components/shared/SuccessSnackbar';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';
import NicheSectionAddButton from './nicheSection/NicheSectionAddButton';


const NicheSection = () => {
  const nicheStore = useNicheStore();

  const [isNichePresent, setIsNichePresent] = useState(false);

  const selectedTeam = useTeamStore((state) => state.selectedTeam);

  const [deleteSnackbar, setDeleteSnackbar] = useState(false);

  const activityStore = useActivityStore(useShallow((state) => ({
    activities: state.activities,
  })));

  const navigate = useNavigate();

  const courseStore = useActivityStore(useShallow((state) => ({
    courses: state.courses,
  })));

  const honorStore = useActivityStore(useShallow((state) => ({
    honors: state.honors,
  })));

  useEffect(() => {
    const allNiches = (nicheStore.niches || []).map(({ id, nicheName }) => ({
      value: id,
      label: nicheName,
    }));

    setIsNichePresent(nicheStore.niches.length > 0);
  }, [nicheStore.niches, activityStore.activities, courseStore.courses, honorStore.honors]);

  const onDelete = async () => {
    setDeleteSnackbar(true);
    nicheStore.setIsDeletingSnackbar(true);
  };
  const hardDeleteNiche = async () => {
    const id = nicheStore.deleteNicheId;
    if (id) {
      // get niche data to get the niche name
      const nicheData = getNicheData(id); 
      removeNicheFromUtilities(id);
      const res = await deleteStoryItem(id, addStoryItemTypes.NICHE);
      if(res && nicheData) { 
        // create notification for the niche deletion
        const notificationData = {
          utilityType: UTILITY_TYPES.Niche,
          utilityName: nicheData?.nicheName,
          utilityId: id,
          teamId: selectedTeam?.id,
          actionType: NOTIFICATION_ACTION_TYPES.DELETED,
        }
        await addNotificationItem(notificationData);
      }
      nicheStore.setIsDeletingSnackbar(false);
    }
    nicheStore.setDeleteNicheId(null);
  };


  useEffect(() => {
    if (!deleteSnackbar) {
      hardDeleteNiche();
    }
    //eslint-disable-next-line
  }, [deleteSnackbar]);

  const getNicheData = (nicheId) => {
    if (nicheId) {
      const niche = (nicheStore.niches || []).find(
        (niche) => niche.id === nicheId
      );
      return niche;
    }
  };

  const removeNicheFromUtilities = (nicheId) => {
      activityStore?.activities?.map(async (activity) => {
        if (activity.nicheId === nicheId) {
          await updateStoryItem(
            {
              ...activity,
              nicheId: '',
            },
            addStoryItemTypes.ACTIVITY
          );
        }
      });
      honorStore?.honors?.map(async (honor) => {
        if (honor.nicheId === nicheId) {
          await updateStoryItem(
            {
              ...honor,
              nicheId: '',
            },
            addStoryItemTypes.HONOR
          );
        }
      });
      courseStore?.courses?.map(async (course) => {
        if (course.nicheId === nicheId) {
          await updateStoryItem(
            {
              ...course,
              nicheId: '',
            },
            addStoryItemTypes.COURSE
          );
        }
      });
    };

  return (
    <div className="bg-[#FCFCFD] dashboard_box_shadow font-archivo rounded-2xl flex flex-col gap-3 p-5 h-max">
      <div className="text-xl font-normal flex items-center justify-between">
        {isNichePresent ? (
          <div className="font-archivo flex items-center text-gray-600 underline cursor-pointer" role="button" onClick={() => navigate(`/${selectedTeam?.id}/myStory/niche`)}>
              <p className="font-archivo text-xl font-normal">My Niche (Passion)</p>
              <ChevronRightIcon />
            </div>
        ) : null}
        {isNichePresent ? (
          <NicheSectionAddButton
            showDropDown={true}
            variant="text"
            text="Add New"
          />
        ) : null}
      </div>
      {isNichePresent ? (
        <NichePageContainer />
      ) : (
        <div className="flex justify-between">
          <div>
            <img
              className="w-[347px] h-60"
              src={nicheZeroStateLeft}
              alt=""
              srcset=""
            />
          </div>
          <div className="flex flex-col gap-3 items-center justify-center">
            <h2 className="font-archivo text-xl font-normal leading-5">
              What are you passionate about?
            </h2>
            <p className="font-roboto text-base font-normal leading-6 text-neutral-600">
              Sharing your passion can <span className="font-bold">triple</span>{' '}
              your chances of being admitted!
            </p>
            <div
              className="text-sm flex items-center gap-2 text-white bg-primary-blue rounded-lg px-4 py-2 font-medium mr-4"
              role="button"
              onClick={() => {
                nicheStore.setIsSelectTeamMember(true);
                nicheStore.setIsAddingNiche(true);
              }}
            >
              <AddRoundedIcon fontSize="small" />
              <div>Add Passion</div>
            </div>
          </div>
          <div>
            <img src={nicheZeroStateRight} alt="" srcset="" />
          </div>
        </div>
      )}
      <SuccessSnackbar
        open={deleteSnackbar}
        message="The niche was deleted."
        actionName="Undo"
        autoHideDuration={4000}
        onAction={() => {
          nicheStore.setDeleteNicheId(null);
          setDeleteSnackbar(false);
          nicheStore.setIsDeletingSnackbar(false);
        }}
        onClose={() => {
          setDeleteSnackbar(false);
        }}
      />
      <DeleteNicheDialog
        open={nicheStore.isDeletingNiche}
        maxWidth="xs"
        onContinue={async () => {
          await onDelete();
          nicheStore.setIsDeletingNiche(false);
        }}
        onCancel={() => {
          nicheStore.setIsDeletingNiche(false);
          nicheStore.setDeleteNicheId(null);
        }}
      />
    </div>
  );
};

export default NicheSection;
