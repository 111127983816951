import { memo, useEffect, useState } from 'react';
import { checkWrongScore, checkWrongTarget } from '../../../utils/helper';
import Dialog from '../../shared/Dialog';
import AddNewSATScore from './AddNewSATScore';

const AddNewSATScoreDialog = ({
  satScores = [],
  satSubjects = [],
  satTarget = [],
  isAddingSATTarget = false,
  onContinue = () => {},
  isEditingSATTarget = false,
  scoreData = [],
  targetData = [],
  open = false,
  isAddDialog = false,
  isTargetDialog = false,
  onCancel = () => {},
  ...props
}) => {
  const [satScore, setSATScore] = useState([
    {
      scores: [],
      testDate: '',
    },
  ]);

  const [satTargetScore, setSATTargetScore] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isContinue, setIsContinue] = useState(false);

  const onChangeScore = (satScoreData) => {
    setIsError(checkWrongScore(satSubjects, satScoreData));
    setSATScore(satScoreData);
  };

  const onChangeTarget = (satTargetData) => {
    setIsError(checkWrongTarget(satSubjects, satTargetData));
    setSATTargetScore(satTargetData);
  };

  const handleSATAdd = () => {
    setIsContinue(true);

    const hasSATScores = satScores?.length > 0;
    const hasSATScoreDetails = satScore?.scores?.length > 0;
    const isModifyingSATTarget = isAddingSATTarget || isEditingSATTarget;
    const hasTestDate = satScore?.testDate !== '';

    const hasValidSATScore =
      hasSATScores &&
      (hasSATScoreDetails || isModifyingSATTarget || hasTestDate);

    if (!isError) {
      if (hasValidSATScore) {
        onContinue(satScore, satTargetScore);
        resetState();
      } else if (!hasSATScores) {
        const scoreToContinue =
          satScore.length === 0 ? { scores: [], testDate: '' } : satScore;
        onContinue(scoreToContinue, satTargetScore);
        resetState();
      }
    }
  };

  function resetState() {
    setSATScore([]);
    setSATTargetScore([]);
    setIsError(false);
    setIsContinue(false);
  }

  useEffect(() => {
    if (isAddDialog) {
      setSATScore([]);
      setIsError(false);
      setIsContinue(false);
    }
  }, [isAddDialog]);

  return (
    <Dialog
      isHeight={true}
      title={`${
        isAddDialog ||
        scoreData[0]?.scores?.length === 0 ||
        scoreData[0]?.testDate === ''
          ? 'Add SAT'
          : 'Edit SAT'
      } ${isTargetDialog ? 'Target Score' : 'Score'}  `}
      open={open}
      maxWidth="sm"
      onContinue={handleSATAdd}
      onCancel={() => {
        onCancel();
        setIsContinue(false);
        setIsError(false);
        setSATScore([]);
        setSATTargetScore([]);
      }}
      continueText={
        isAddDialog ||
        scoreData[0]?.scores?.length === 0 ||
        scoreData[0]?.testDate === ''
          ? 'Add'
          : 'Update'
      }
      {...props}
    >
      <AddNewSATScore
        satScores={satScores}
        satSubjects={satSubjects}
        satTarget={satTarget}
        isContinue={isContinue}
        isAddDialog={isAddDialog}
        isAddingSATTarget={isAddingSATTarget}
        onChangeScore={onChangeScore}
        onChangeTarget={onChangeTarget}
        scoreData={scoreData[0]}
        targetData={targetData[0]}
        isEditingSATTarget={isEditingSATTarget}
      />
    </Dialog>
  );
};

export default memo(AddNewSATScoreDialog);
