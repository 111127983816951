import { useState } from 'react';
import Tab from '../../../shared/Tab';
import Tabs from '../../../shared/Tabs';

export const NicheUtilityFilterTabs = ({
  args,
  totalCount = 0,
  activityCount = 0,
  courseCount = 0,
  honorCount = 0,
  onChange = () => {},
}) => {
  const [value, setValue] = useState(0);
  return (
    <Tabs
      value={value}
      {...args}
      sx={{
        height: '64px',
        minHeight: '64px',
        '.MuiTabs-scroller': {
          height: '64px',
          '.MuiButtonBase-root': {
            paddingTop: 0,
          },
          '.Mui-selected': {
            background:
              'linear-gradient(360deg, rgba(34, 37, 45, 0.06) 0%, rgba(34, 37, 45, 0) 50%);',
          },
        },
      }}
      onChange={onChange}
    >
      <Tab
        label={
          <div className="flex flex-col gap-2 shrink-0">
            <div className="text-xl font-semibold">
              {totalCount < 10 ? `0${totalCount}` : totalCount}
            </div>
            <div className="text-xs font-medium">Total</div>
          </div>
        }
        onClick={() => {
          setValue(0);
        }}
        sx={{
          height: '100%',
        }}
      />
      <Tab
        label={
          <div className="flex flex-col gap-2">
            <div className="text-xl font-semibold">
              {activityCount < 10 ? `0${activityCount}` : activityCount}
            </div>
            <div className="text-xs font-medium">Activities</div>
          </div>
        }
        onClick={() => {
          setValue(1);
        }}
        sx={{
          height: '100%',
        }}
      />
      <Tab
        label={
          <div className="flex flex-col gap-2">
            <div className="text-xl font-semibold">
              {honorCount < 10 ? `0${honorCount}` : honorCount}
            </div>
            <div className="text-xs font-medium">Honors</div>
          </div>
        }
        onClick={() => {
          setValue(2);
        }}
        sx={{
          height: '100%',
        }}
      />
      <Tab
        label={
          <div className="flex flex-col gap-2">
            <div className="text-xl font-semibold">
              {courseCount < 10 ? `0${courseCount}` : courseCount}
            </div>
            <div className="text-xs font-medium">Courses</div>
          </div>
        }
        onClick={() => {
          setValue(3);
        }}
        sx={{
          height: '100%',
        }}
      />
    </Tabs>
  );
};
