import { ToggleButton as TOGGLEBUTTON } from '@mui/material';
import { styled } from '@mui/system';

const ToggleButton = styled(TOGGLEBUTTON)(() => ({
  border: 0,
  width: 'fit-content',
  textTransform: 'none',
  fontWeight: '400',
  color: '#22252D',
  opacity: '0.7',
  padding: '8px 16px',
  borderRadius: '8px !important',
  '&.Mui-selected': {
    backgroundColor: 'white',
    color: '#22252D',
    borderRadius: '8px !important',
    opacity: '1',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255)',
    },
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  '&.Mui-disabled': {
    border: 0,
  },
  '&:not(:first-of-type)': {
    borderRadius: 8,
  },
  '&:first-of-type': {
    borderRadius: 8,
  },
}));

export default ToggleButton;
