import { useEffect, useState } from 'react';
import AddNewNiche from '../components/myStory/niches/AddNewNiche';
import DeleteNicheDialog from '../components/myStory/niches/DeleteNicheDialog';
import ReassignNicheDialog from '../components/myStory/niches/ReassignNicheDialog';
import SuccessSnackbar from '../components/shared/SuccessSnackbar';
import { addStoryItemTypes, CIO_SHARE_ITEM_TYPES, NOTIFICATION_ACTION_TYPES } from '../constants/other';
import { addNotificationItem, addStoryItem } from '../firebase/services/addServices';
import { deleteStoryItem } from '../firebase/services/deleteServices';
import { updateStoryItem } from '../firebase/services/updateServices';
import useActivityStore from '../stores/ActivityStore';
import useCourseStore from '../stores/CourseStore';
import useHonorStore from '../stores/HonorStore';
import useMyTeamStore from '../stores/MyTeamStore';
import useNicheStore from '../stores/NicheStore';
import { useLocation, useNavigate } from 'react-router-dom';
import useStudentStore from '../stores/StudentStore';
import useProfileStore from '../stores/ProfileStore';
import {
  INVITE_STATUS,
  UTILITY_TYPES,
  addTeamUniversilyToChatParticipants,
  filterUniAndTeamUniversily,
} from '../utils/utlityTypes';
import { useShallow } from 'zustand/react/shallow';
import { ShareUtility } from '../components/myHub/ShareUtility';
import useMyHubStore from '../stores/MyHubStore';
import { axiosPost } from '../firebase/axios';
import { ApiRoutes } from '../firebase/apis';
import useTeamStore from '../stores/TeamStore';
import { removeNullUndefined } from '../utils/helper';

const NicheContainer = () => {
  const nicheStore = useNicheStore();
  const activityStore = useActivityStore();
  const honorStore = useHonorStore();
  const courseStore = useCourseStore();
  const myTeamStore = useMyTeamStore();

  const profileStore = useProfileStore(
    useShallow((state) => ({
      profile: state.profile,
    }))
  );

  const location = useLocation();
  const activeStudentReviewers = useStudentStore(
    (state) => state.activeStudentReviewers
  );
  const isParent = useProfileStore((state) => state.isParent);
  const [isSuccessSnackbar, setIsSuccessSnackbar] = useState({
    open: false,
    message: '',
  });
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState({
    open: false,
    message: '',
  });
  const [deleteSnackbar, setDeleteSnackbar] = useState(false);
  const navigate = useNavigate();
  const niches = (nicheStore.niches || []).map(({ id, nicheName }) => ({
    value: id,
    label: nicheName,
  }));
  const teamStore = useTeamStore(
    useShallow((state) => ({
      teams: state.teams,
      selectedTeam: state.selectedTeam,
    }))
  );
  let teamDataReviewers = [];

  // write a function to assign teamDataReviewers with conditions
  // If the user is parent then he can assign all teamMember as reviewers excluding himself and filterUniAndTeamUniversily
  // If the user is not parent then he can assign all teamMember as reviewers excluding himself and filterUniAndTeamUniversily
  const filteredTeamMembers = teamStore?.selectedTeam?.teamMembers?.filter(
    (item) =>
      item?.invite_status === INVITE_STATUS.JOINED &&
      item?.uid !== profileStore.profile?.uid
  );
  teamDataReviewers = filteredTeamMembers?.map((item) => ({
    id: item.uid,
    email: item.email,
    label: `${item.firstName} ${item.lastName}`,
    photoURL: item.photoURL,
    uid: item,
  }));

  const teamDataReviewersParentView = activeStudentReviewers.map(
    (reviewer) => ({
      email: reviewer.email,
      label: `${reviewer.firstName} ${reviewer.lastName}`,
      photoURL: reviewer.photoURL,
      id: reviewer.reviewerDocId,
    })
  );
  const teamMemberData = isParent
    ? teamDataReviewersParentView
    : teamDataReviewers;
  const onDelete = async () => {
    setDeleteSnackbar(true);
    nicheStore.setIsDeletingSnackbar(true);
  };
  const hardDeleteNiche = async () => {
    const id = nicheStore.deleteNicheId;
    if (id) {
      // get niche data to get the niche name
      const nicheData = getNicheData(id); 
      await removeNicheFromUtilities(id);
      const res = await deleteStoryItem(id, addStoryItemTypes.NICHE);
      if(res && nicheData) { 
        // create notification for the niche deletion
        const notificationData = {
          utilityType: UTILITY_TYPES.Niche,
          utilityName: nicheData?.nicheName,
          utilityId: id,
          teamId: selectedTeam?.id,
          actionType: NOTIFICATION_ACTION_TYPES.DELETED,
        }
        await addNotificationItem(notificationData);
      }
      nicheStore.setIsDeletingSnackbar(false);
    }
    nicheStore.setDeleteNicheId(null);
  };
  useEffect(() => {
    if (!deleteSnackbar) {
      hardDeleteNiche();
    }
    //eslint-disable-next-line
  }, [deleteSnackbar]);
  const getNicheData = (nicheId) => {
    if (nicheId) {
      const niche = (nicheStore.niches || []).find(
        (niche) => niche.id === nicheId
      );
      return niche;
    }
  };
  const removeNicheFromUtilities = (nicheId) => {
    activityStore?.activities.map(async (activity) => {
      if (activity.nicheId === nicheId) {
        await updateStoryItem(
          {
            ...activity,
            nicheId: '',
          },
          addStoryItemTypes.ACTIVITY
        );
      }
    });
    honorStore?.honors.map(async (honor) => {
      if (honor.nicheId === nicheId) {
        await updateStoryItem(
          {
            ...honor,
            nicheId: '',
          },
          addStoryItemTypes.HONOR
        );
      }
    });
    courseStore?.courses.map(async (course) => {
      if (course.nicheId === nicheId) {
        await updateStoryItem(
          {
            ...course,
            nicheId: '',
          },
          addStoryItemTypes.COURSE
        );
      }
    });
  };
  const getSelectedNicheForUtility = () => {
    const utilityType = nicheStore.reassignUtilityType;
    switch (utilityType) {
      case 'ACTIVITY': {
        return activityStore.activitiesById[nicheStore.reassignUtilityId]
          ?.nicheId;
      }
      case 'HONOR': {
        return honorStore.honorsById[nicheStore.reassignUtilityId]?.nicheId;
      }
      case 'COURSE': {
        return courseStore.courses.find(
          (course) => course.id === nicheStore.reassignUtilityId
        )?.nicheId;
      }
      default:
        return '';
    }
  };

  const updateNicheForUtility = async (newNiche) => {
    const utilityType = nicheStore.reassignUtilityType;
    switch (utilityType) {
      case 'ACTIVITY': {
        const activity =
          activityStore.activitiesById[nicheStore.reassignUtilityId];
        await updateStoryItem(
          {
            ...activity,
            nicheId: newNiche,
          },
          addStoryItemTypes.ACTIVITY
        );
        break;
      }
      case 'HONOR': {
        const honor = honorStore.honorsById[nicheStore.reassignUtilityId];
        await updateStoryItem(
          {
            ...honor,
            nicheId: newNiche,
          },
          addStoryItemTypes.HONOR
        );
        break;
      }
      case 'COURSE': {
        const course = courseStore.courses.find(
          (course) => course.id === nicheStore.reassignUtilityId
        );
        await updateStoryItem(
          {
            ...course,
            nicheId: newNiche,
          },
          addStoryItemTypes.COURSE
        );
        break;
      }
      default:
        return '';
    }
  };

  const myHubStore = useMyHubStore(
    useShallow((state) => ({
      isShareUtility: state.isShareUtility,
      utilityId: state.utilityId,
      setIsShareUtility: state.setIsShareUtility,
      utilityType: state.utilityType,
    }))
  );
  const selectedTeam = useTeamStore((state) => state.selectedTeam);

  const niche = getNicheData(nicheStore.editNicheId ?? myHubStore?.utilityId);
  const [isContinue, setIsContinue] = useState(false);

  const handleOnNicheAdd = async (nicheData) => {
    setIsContinue(true);

    if (nicheStore.isAddingNiche) {
      let utilityId;
      if (isParent) {
        // Creating a niche from parent
        const parentNicheData = {
          ...nicheData,
          owner: teamStore?.selectedTeam?.studentEmail,
          sharedWith: nicheData.sharedWith.map(
            (collaborator) => collaborator?.id
          ),
          createdBy: profileStore.profile.uid,
        };
        utilityId = await addStoryItem(parentNicheData, addStoryItemTypes.NICHE);
      } else {
        // Creating a niche from student
        const studentNicheData = {
          ...nicheData,
          sharedWith: nicheData.sharedWith.map(
            (collaborator) => collaborator?.id
          ),
          owner: profileStore.profile.email,
        };
        utilityId = await addStoryItem(
          studentNicheData,
          addStoryItemTypes.NICHE
        );
      }

      if (utilityId) {
        // create notification for the niche creation
        const notificationData = {
          utilityType: UTILITY_TYPES.Niche,
          utilityName: nicheData.nicheName,
          utilityId: utilityId,
          teamId: selectedTeam?.id,
          actionType: NOTIFICATION_ACTION_TYPES.CREATED,
        }
        await addNotificationItem(notificationData);

        const channelMembers = addTeamUniversilyToChatParticipants([
          ...teamStore.selectedTeam?.teamMembers
            .filter((item) =>
              nicheData?.sharedWith
                ?.map((niche) => niche?.id)
                ?.includes(item?.uid)
            )
            .map((item) => item.uid),
          isParent
            ? teamStore?.selectedTeam?.studentUID
            : profileStore.profile.uid,
        ]).filter((member) => member !== null && member !== undefined);;

        await addStoryItem(
          {
            isTopic: true,
            archivedBy: [],
            chatParticipants: removeNullUndefined(channelMembers),
            topicName: 'Topic for ' + niche?.nicheName,
            utilityId: utilityId,
            topicType: 'Niche',
          },
          addStoryItemTypes.CHAT_ROOM
        );
      }

      if (!location.pathname.includes('/myHub')) {
        navigate(`/${selectedTeam?.id}/myStory/niche`);
      }
      setIsSuccessSnackbar({
        open: true,
        message: 'A New Niche has been created.',
      });
      nicheStore.setIsAddingNiche(false);
      nicheStore.setUtilityName('');
    }
  };
  const selectedTeamMember = isParent
    ? filterUniAndTeamUniversily(
        teamDataReviewersParentView?.filter((teamMember) =>
          niche?.reviewers?.includes(teamMember.id)
        )
      )
    : filterUniAndTeamUniversily(
        teamMemberData?.filter((teamMember) =>
          niche?.reviewers?.includes(teamMember.id)
        )
      );

  const collaborators = teamStore?.selectedTeam?.id
    ? teamStore?.selectedTeam?.teamMembers
        ?.filter((profile) => profile?.invite_status === INVITE_STATUS.JOINED)
        ?.filter((member) => member.email !== selectedTeam?.studentEmail)
        ?.map((profile) => ({
          email: profile.email,
          id: profile.uid,
          photoURL: profile?.photoURL,
          label: profile?.firstName + ' ' + profile?.lastName,
        }))
    : [];
  return (
    <>
      <SuccessSnackbar
        message={isSuccessSnackbar.message}
        open={isSuccessSnackbar.open}
        autoHideDuration={6000}
        onClose={() => {
          setIsSuccessSnackbar({
            open: false,
            message: '',
          });
        }}
      />
      <SuccessSnackbar
        open={deleteSnackbar}
        message="The niche was deleted."
        actionName="Undo"
        autoHideDuration={4000}
        onAction={() => {
          nicheStore.setDeleteNicheId(null);
          setDeleteSnackbar(false);
          nicheStore.setIsDeletingSnackbar(false);
        }}
        onClose={() => {
          setDeleteSnackbar(false);
        }}
      />
      {(nicheStore.isAddingNiche || nicheStore.isEditingNiche) && (
        <AddNewNiche
          title={`${nicheStore.isEditingNiche ? 'Edit Niche' : 'Add Niche'}`}
          open={nicheStore.isAddingNiche}
          niches={niches}
          onContinue={handleOnNicheAdd}
          onCancel={() => {
            nicheStore.setIsAddingNiche(false);
            nicheStore.setUtilityName('');
            nicheStore.setIsEditingNiche(false);
            nicheStore.setEditNicheId(null);
          }}
          selectedTeamMember={selectedTeamMember}
          niche={niche}
          collaborators={filterUniAndTeamUniversily(collaborators)}
          isContinue={isContinue}
        />
      )}
      <DeleteNicheDialog
        open={nicheStore.isDeletingNiche}
        maxWidth="xs"
        onContinue={async () => {
          await onDelete();
          nicheStore.setIsDeletingNiche(false);
        }}
        onCancel={() => {
          nicheStore.setIsDeletingNiche(false);
          nicheStore.setDeleteNicheId(null);
        }}
      />
      <ReassignNicheDialog
        niches={(nicheStore.niches || []).map(({ id, nicheName }) => ({
          value: id,
          label: nicheName,
        }))}
        onChange={() => {}}
        selectedNiche={[getSelectedNicheForUtility()]}
        open={nicheStore.isReassignNiche}
        onContinue={async (newNiche) => {
          nicheStore.setIsReassignNiche(false);
          nicheStore.setIsViewDetailPopup(false);
          nicheStore.setViewDetailPopupUtilityId(null);
          await updateNicheForUtility(newNiche);
          nicheStore.setReassignUtilityType(null);
          nicheStore.setReassignUtilityId(null);
          nicheStore.setIsViewDetails(false);
          nicheStore.setViewNicheId(null);
        }}
        onCancel={() => {
          nicheStore.setIsReassignNiche(false);
          nicheStore.setReassignUtilityType(null);
          nicheStore.setReassignUtilityId(null);
        }}
      />

      <SuccessSnackbar
        message={successSnackbarOpen.message}
        open={successSnackbarOpen.open}
        autoHideDuration={3000}
        onClose={() => {
          setSuccessSnackbarOpen({
            open: false,
            message: '',
          });
        }}
      />
    </>
  );
};

export default NicheContainer;
