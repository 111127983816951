import { Avatar, Divider } from '@mui/material';
import Chip from '../../shared/Chip';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getFormattedStringFromArray } from '../../../utils/helper';
import Button from '../../shared/Button';
import TeamMemberAvatarGroup from '../../shared/TeamMemberAvatarGroup';

const AddDetailsPopupFooter = ({
  niche = '',
  grades = [],
  reviewers = [],
  type = '',
  isParent = false,
  openStoryDoc = () => {},
}) => {
  return (
    <div className="flex items-center justify-between py-5 px-6 gap-6">
      <div className="flex gap-4">
        {niche && (
          <div className="flex gap-2 items-center">
            <div className="text-[#22252D] font-medium"> Niche :</div>
            <Chip
              label={niche}
              sx={{
                padding: '8px 0px',
                borderRadius: '16px',
                color: '#22252D',
                fontWeight: 500,
                fontSize: '12px',
              }}
            />
          </div>
        )}
        {grades.length > 0 && (
          <>
            <Divider
              orientation="vertical"
              flexItem
              variant="middle"
              sx={{
                borderColor: '#D6D6D8',
              }}
            />
            <div className="flex gap-2 items-center">
              <div className="text-[#22252D] font-medium">Grade :</div>
              <div className="text-grey-400 font-medium">{`${getFormattedStringFromArray(
                grades
              )} Grade`}</div>
            </div>
          </>
        )}
        {reviewers.length > 0 && (
          <>
            <Divider
              orientation="vertical"
              flexItem
              variant="middle"
              sx={{
                borderColor: '#D6D6D8',
              }}
            />
            <div className="flex items-center">
              <TeamMemberAvatarGroup>
                {reviewers.map((reviewer) => (
                  <Avatar src={reviewer?.photoURL} />
                ))}
              </TeamMemberAvatarGroup>
            </div>
          </>
        )}
      </div>
      {(type === 'HONOR' || type === 'ACTIVITY') && !isParent && (
        <div className="flex gap-2 text-[#1976D2] font-medium text-sm cursor-pointer items-center">
          <Button
            variant="text"
            endIcon={<ArrowForwardIosIcon sx={{ height: '15px' }} />}
            onClick={openStoryDoc}
          >
            View Details
          </Button>
        </div>
      )}
    </div>
  );
};

export default AddDetailsPopupFooter;
