import { Divider } from '@mui/material';
import MyStoryDocsCharacterGuide from '../MyStoryDocsCharacterGuide';
import ActivityDocsTipsPanel from './ActivityDocsTipsPanel';

const characterGuideData = [
  {
    characterCount: '150 Characters',
    description: `Most colleges, including those that use the 
      Common App, allow 150 characters for your 
      Activity description.`,
  },
  {
    characterCount: '350 Characters',
    description: `Other colleges, such as the University of 
    California system, allow 350 characters to 
    describe your Activity.`,
  },
];

export const ActivityDocsGuidelines = ({ isSelectedInfo = true }) => {
  return (
    <>
      {isSelectedInfo && (
        <div className="flex flex-col gap-6 p-6">
          <div className="text-sm text-[#22252D]">
            Tips for Activity Details
          </div>
          <div className="text-grey-300 text-xs">
            Start writing your 150 character description. You can always add the
            350 character description later.
          </div>
          <div className="flex flex-col gap-4">
            {characterGuideData?.map((item, index) => (
              <>
                <MyStoryDocsCharacterGuide
                  characterCount={item.characterCount}
                  description={item.description}
                  key={item?.id}
                />
                {characterGuideData.length - 1 !== index && (
                  <Divider
                    className="opacity-20 -mx-3 border-t"
                    sx={{
                      borderColor: '#BABAAE',
                    }}
                  />
                )}
              </>
            ))}
          </div>
          <div className="flex flex-col mt-2 gap-8">
            <Divider
              className="border-t"
              sx={{
                borderColor: '#D5D5D5',
              }}
            />
            <ActivityDocsTipsPanel />
          </div>
        </div>
      )}
    </>
  );
};

export default ActivityDocsGuidelines;
