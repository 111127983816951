import { ThemeProvider } from '@mui/material/styles';
import { customTheme } from './styles/customTheme';
import { CustomGlobalStyles } from './styles/globalStyles';
import { NotiStackProvider } from './components';
import RouterWrapper from './RouterWrapper';
import { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { UserProfileContextProvider } from './contexts/userContext';
import useThemeStore from './stores/ThemeStore';
import userflow from 'userflow.js';
import useProfileStore from './stores/ProfileStore';
import { sha256 } from 'js-sha256';
import * as Sentry from '@sentry/react';
import { clarity } from 'react-microsoft-clarity';

clarity.init(process.env.REACT_APP_CLARITY_TRACKING_CODE);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  allowUrls: ['https://v2dev.universily.com', 'https://app.universily.com/'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const App = () => {
  const theme = useTheme();
  const { setIsMobile } = useThemeStore();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const profileStore = useProfileStore();
  useEffect(() => {
    setIsMobile(isMobile);
    //eslint-disable-next-line
  }, [isMobile]);

  Sentry.setUser({
    id: profileStore?.profile?.uid,
    email: profileStore?.profile?.email,
    ip_address: '{{auto}}',
  });

  useEffect(() => {
    const profile = profileStore?.profile;
    // Trigger pipeline
    if (profile?.uid) {
      const UF_SECRET = process.env.REACT_APP_USERFLOW_TOKEN;
      const UF_IDENTITIY_VERIFICATION_KEY =
        process.env.REACT_APP_USERFLOW_IDENTITY_VERIFICATION_KEY;
      let signature = sha256.hmac(UF_IDENTITIY_VERIFICATION_KEY, profile?.uid);

      const timestamp = profile?.createdAt?.seconds * 1000;
      const date = new Date(timestamp);
      const signed_up = date.toISOString();
      userflow.init(UF_SECRET);
      if (clarity.hasStarted()) {
        console.log('clarity has started', { profile });

        clarity.identify(profile?.uid, {
          email: profile?.email,
          name: profile?.firstName,
          profileType: profile?.profileType,
        });
      }
      // NOTE: If any of the following keys are undefined then it will not be sent to userflow
      userflow.identify(
        profile?.uid,
        {
          name: profile?.firstName,
          email: profile?.email,
          signed_up_at: signed_up,
          profileType: profile?.profileType,
          grade: profile?.grade,
          subscriptionStatus: profile?.licenseData?.subscriptionStatus,
          isOnboardingCompleted: profile?.isOnboardingCompleted,
        },
        {
          signature: signature,
        }
      );
    }
    //eslint-disable-next-line
  }, [profileStore?.profile]);

  return (
    <UserProfileContextProvider>
      <ThemeProvider theme={customTheme}>
        <NotiStackProvider>
          <CustomGlobalStyles />
          <RouterWrapper />
        </NotiStackProvider>
      </ThemeProvider>
    </UserProfileContextProvider>
  );
};

export default App;
