import { Snackbar as SNACKBAR } from '@mui/material';
import { ReactComponent as YellowInfoIcon } from '../../assets/svgs/YellowInfo.svg';
import { styled } from '@mui/system';

const StyledSnackbar = styled(SNACKBAR)(() => ({
  boxShadow:
    '1px 4px 6px rgba(0, 0, 0, 0.05), 1px 4px 6px rgba(0, 0, 0, 0.05), 1px 4px 6px rgba(0, 0, 0, 0.05), 0px 2px 6px rgba(0, 0, 0, 0.05)',
  backgroundColor: '#2B2F38',
  borderRadius: 8,
}));

const NoGoogleAccountSnackbar = ({
  message,
  action,
  autoHideDuration = '4000',
  ...props
}) => {
  return (
    <StyledSnackbar {...props} autoHideDuration={autoHideDuration}>
      <div className="flex gap-3 items-center h-12 px-4 rounded-lg w-full flex justify-center items-center">
        <YellowInfoIcon />
        <div className="flex gap-1 text-sm text-[#22252D]">
          <div className="text-white whitespace-nowrap">{message}</div>
        </div>
        {action && <div className="cursor-pointer">{action}</div>}
      </div>
    </StyledSnackbar>
  );
};

export default NoGoogleAccountSnackbar;
