import Tab from '../shared/Tab';
import Tabs from '../shared/Tabs';
import { ReactComponent as TextIcon } from '../../assets/svgs/a-text.svg';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { CircularProgress } from '@mui/material';

const MyStoryDocsTabs = ({
  value = '0',
  onChange = () => {},
  showAddTabBtn = false,
  addTabBtnText = '',
  onAddTabBtnClick = '',
  tabLabels = [],
  docsVersions = [],
  loadingState = '',
  isParent = false,
}) => {
  return (
    <div className="flex gap-4">
      {tabLabels.length > 1 && (
        <Tabs value={value} onChange={onChange}>
          {tabLabels.map((tabLabel, index) => (
            <Tab key={index} label={tabLabel} icon={<TextIcon />} />
          ))}
        </Tabs>
      )}
      {tabLabels.length < 2 &&
        docsVersions.map((docsVersion) => {
          return tabLabels.includes(docsVersion) ? (
            <Tabs value={value} onChange={onChange}>
              <Tab key={docsVersion} label={docsVersion} icon={<TextIcon />} />
            </Tabs>
          ) : (
            !isParent && (
              <div
                className={`flex gap-1 text-grey-400 border-dashed border rounded-md border-brand-blue-500 group text-sm font-medium items-center p-2 ${
                  loadingState === docsVersion
                    ? 'disabled'
                    : 'cursor-pointer hover:text-grey-600 truncate'
                }`}
                onClick={() => {
                  if (loadingState !== docsVersion)
                    onAddTabBtnClick(docsVersion);
                }}
              >
                <div className="w-5 h-5 group rounded flex items-center justify-center">
                  <AddCircleIcon />
                </div>
                <div>Add {docsVersion} Version</div>
                {loadingState === docsVersion && (
                  <CircularProgress
                    color="inherit"
                    sx={{
                      height: '16px !important',
                      width: '16px !important',
                      marginLeft: '10px !important',
                    }}
                  />
                )}
              </div>
            )
          );
        })}
    </div>
  );
};

export default MyStoryDocsTabs;
