import { ReactComponent as HighlightImage } from '../../../assets/svgs/Highlight.svg';
import { ReactComponent as SpiralImage } from '../../../assets/svgs/Spiral.svg';
import { ReactComponent as NicheHomeBG } from '../../../assets/svgs/NicheHomeBG.svg';
import { ReactComponent as NicheHomeMobile } from '../../../assets/svgs/NicheMobile.svg';
import MyStoryAddNewButton from '../MyStoryAddNewButton';
import nicheParentZeroState from '../../../assets/svgs/nicheParentZeroState.png';
import Button from '../../shared/Button';
import AddIcon from '@mui/icons-material/Add';
import useInviteAFriendStore from '../../../stores/InviteAFriendStore';
import useMyStudentStore from '../../../stores/MyStudentStore';

const NichePageOverlay = ({
  isParent = false,
  canCreateNewUtilities = () => {},
  isMobile = false,
}) => {
  const inviteStore = useInviteAFriendStore();
  const { setIsAddStudent } = useMyStudentStore();

  return (
    <>
      {!isParent ? (
        <div
          className="absolute inset-0 bg-cream-75 flex items-center flex-col sm:flex-row py-4 sm:py-0 px-4 sm:px-0 h-full"
          style={{ zIndex: 100 }}
        >
          <div className="flex flex-col sm:w-1/2 sm:pl-24 items-start">
            <div className="flex flex-col items-center sm:items-start text-5xl font-bold leading-[56px]">
              <div className="flex">
                <div className="relative">
                  Start adding
                  <HighlightImage className="absolute top-0 right-0 translate-x-8" />
                </div>
              </div>
              <div>your Niche</div>
              <div className="flex">
                <div className="relative">
                  details here.
                  <SpiralImage className="absolute bottom-0 right-0 translate-y-4" />
                </div>
              </div>
            </div>
            <div className="mt-8 mb-6 text-lg text-body-text">
              <>
                Take the first step towards creating your personal
                <br /> profile for colleges.
              </>
            </div>
            <MyStoryAddNewButton
              showDropDown={false}
              canCreateNewUtilities={canCreateNewUtilities}
              disabled={isMobile}
            />
          </div>
          <div className="flex items-center flex-col sm:flex-row relative pt-16 sm:pt-5 pb-4 sm:pb-0 sm:ml-auto gap-4 sm:gap-0">
            {/* <div className="w-full">{image}</div> */}
            {/* <img src={NicheHome} alt="" /> */}
            <NicheHomeMobile className="!block sm:!hidden" />
            <NicheHomeBG className="!hidden sm:!block" />
            <div className="sm:absolute sm:left-16 sm:bottom-36 rounded-full">
              <iframe
                style={{ borderRadius: '14px' }}
                // width="333 sm:438"
                // height="185 sm:244"
                width={isMobile ? '333' : '438'}
                height={isMobile ? '185' : '244'}
                title="Niche"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                src="https://www.youtube.com//embed/ffAVF1T0a2E"
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          className="absolute inset-0 bg-cream-75 flex justify-between p-5 flex-col sm:flex-row h-full overflow-auto"
          // className="absolute inset-0 bg-cream-75 flex items-center flex-col sm:flex-row py-4 sm:py-0 px-4 sm:px-0 h-full"
          style={{ zIndex: 100 }}
        >
          <div className="flex flex-col sm:w-1/2 sm:pl-16 justify-center flex-1">
            <div className="flex flex-col text-4xl sm:text-5xl font-bold">
              <div className="flex">
                <div className="relative">
                  Niches make the
                  <HighlightImage className="absolute top-0 right-0 translate-x-8" />
                </div>
              </div>
              <div>student’s profile stand out. Student’s Niche details </div>
              <div className="flex">
                <div className="relative">
                  will appear here.
                  <SpiralImage className="absolute bottom-0 right-[20px] translate-y-4" />
                </div>
              </div>
            </div>
            <div className="mt-8 mb-6 text-lg">
              Invite students to be a part of Universily!
            </div>
            <Button
              startIcon={<AddIcon />}
              onClick={() => {
                setIsAddStudent(true);
              }}
              className="w-fit"
            >
              Invite Student
            </Button>
          </div>
          <div className="sm:w-1/2 flex items-center flex-1">
            {/* <div className="w-full">{image}</div> */}
            <img src={nicheParentZeroState} className="w-full h-fit" alt="" />
          </div>
        </div>
      )}
    </>
  );
};

export default NichePageOverlay;
