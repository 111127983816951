import { Avatar, Divider, IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import Chip from '../../shared/Chip';
import Button from '../../shared/Button';
import { getFormattedStringFromArray } from '../../../utils/helper';
import TeamMemberAvatarGroup from '../../shared/TeamMemberAvatarGroup';
// import MyStoryDocsFooter from '../MyStoryDocsFooter';
import Tooltip from '../../shared/Tooltip';
import PersonIcon from '@mui/icons-material/Person';

const MyStoryActivityDocsFooter = ({
  id = '',
  grades = [],
  durationHoursPerWeek = '',
  durationWeeksPerYear = '',
  activityTypes = [],
  reviewers = [],
  editIcon = false,
  onEdit = () => {},
  isParent = false,
}) => {
  const lastAvatarTitle = (
    <div className="flex flex-col gap-2">
      {reviewers?.slice(-(reviewers.length - 2))?.map((item) => (
        <li className="flex gap-2 text-xs items-center" key={item?.id}>
          <Avatar
            src={item?.photoURL}
            aria-label={item?.firstName + ' ' + item?.lastName}
            sx={{
              height: '16px',
              width: '16px',
            }}
          />
          <div>
            {item.firstName} {item.lastName}
          </div>
        </li>
      ))}
    </div>
  );

  const avatarTitle = (item) => {
    return (
      <div className="flex gap-2 text-xs items-center">
        <Avatar
          src={item?.photoURL}
          aria-label={item?.firstName + ' ' + item?.lastName}
          sx={{
            height: '16px',
            width: '16px',
          }}
        />
        <div>
          {item.firstName} {item.lastName}
        </div>
      </div>
    );
  };

  return (
    <div>
      {(grades?.length > 0 ||
        durationHoursPerWeek ||
        durationWeeksPerYear ||
        activityTypes?.length > 0) && (
        <div className="flex gap-6 items-center">
          {grades && (
            <>
              <div className="flex font-medium gap-1 flex-col sm:flex-row">
                <div className="text-[#22252D] text-sm">Year Details :</div>
                <div className="text-grey-400 text-sm whitespace-nowrap sm:whitespace-normal">{`${getFormattedStringFromArray(
                  grades
                )} Grade`}</div>
              </div>
              <Divider
                orientation="vertical"
                flexItem
                variant="middle"
                sx={{
                  borderColor: '#D6D6D8',
                }}
                className="!hidden sm:!flex"
              />
            </>
          )}
          {durationHoursPerWeek && (
            <>
              <div className="flex font-medium gap-1 flex-col sm:flex-row">
                <div className="text-[#22252D] text-sm whitespace-nowrap sm:whitespace-normal">
                  Hours/week :
                </div>
                <div className="text-grey-400 text-sm">
                  {durationHoursPerWeek}
                </div>
              </div>
              <Divider
                orientation="vertical"
                flexItem
                variant="middle"
                sx={{
                  borderColor: '#D6D6D8',
                }}
                className="!hidden sm:!flex"
              />
            </>
          )}
          {durationWeeksPerYear && (
            <>
              <div className="flex font-medium gap-1 flex-col sm:flex-row">
                <div className="text-[#22252D] text-sm whitespace-nowrap sm:whitespace-normal">
                  Weeks/year :
                </div>
                <div className="text-grey-400 text-sm">
                  {durationWeeksPerYear}
                </div>
              </div>
              <Divider
                orientation="vertical"
                flexItem
                variant="middle"
                sx={{
                  borderColor: '#D6D6D8',
                }}
                className="!hidden sm:!flex"
              />
            </>
          )}
          {activityTypes?.length > 0 && (
            <div className="flex gap-4 items-center">
              {activityTypes?.map((item) => (
                <Tooltip title="Activity Type">
                  <div className="flex rounded-full" key={item}>
                    <Chip
                      label={item}
                      sx={{
                        borderRadius: '50px',
                        paddingLeft: '2px',
                        paddingRight: '2px',
                        backgroundColor: '#EDEDEE',
                        fontWeight: '500',
                        color: '#22252D',
                      }}
                    />
                  </div>
                </Tooltip>
              ))}
            </div>
          )}
          <div className="flex-1 flex justify-end relative">
            <TeamMemberAvatarGroup>
              {reviewers?.map((reviewer) => (
                <Tooltip title={avatarTitle(reviewer)}>
                  <Avatar src={reviewer?.photoURL} />
                </Tooltip>
              ))}
            </TeamMemberAvatarGroup>
            {reviewers?.length > 2 && (
              <Tooltip title={lastAvatarTitle}>
                <div className="h-7 w-7 text-grey-600 bg-grey-50 border-2 border-white text-xs font-medium absolute flex items-center justify-center top-px rounded-full">
                  +{reviewers?.length - 2}
                </div>
              </Tooltip>
            )}
            {reviewers?.length === 0 && (
              <Tooltip
                title={`${
                  !isParent
                    ? 'Once you add your Team Members they will appear here'
                    : 'Student is yet to add team member'
                }`}
              >
                <div className="h-7 w-7 text-grey-600 bg-grey-50 border-2 border-white flex items-center justify-center top-px rounded-full">
                  <PersonIcon
                    sx={{
                      height: '18px',
                    }}
                  />
                </div>
              </Tooltip>
            )}
          </div>

          {editIcon && (
            <>
              {
                <Tooltip title="Edit Activity Details">
                  <IconButton
                    aria-label="Edit"
                    onClick={onEdit}
                    className="third-step !hidden sm:!flex"
                  >
                    <EditIcon className="cursor-pointer" />
                  </IconButton>
                </Tooltip>
              }
            </>
          )}
        </div>
      )}
      {grades?.length === 0 &&
        !durationHoursPerWeek &&
        !durationWeeksPerYear &&
        activityTypes?.length === 0 && (
          <>
            {
              <div className="flex gap-6 text-sm ">
                <Tooltip title="Add Details like when you did this activity and the time duration of it">
                  <div>
                    <Button
                      className="flex gap-2 items-center second-step "
                      variant="text"
                      onClick={onEdit}
                    >
                      <AddCircleIcon color="primary" />
                      <div className="text-[#1958CD] font-medium">
                        Add More Details
                      </div>
                    </Button>
                  </div>
                </Tooltip>

                <div className="flex gap-2 items-center text-grey-400">
                  <InfoIcon />
                  <div>
                    Details: like Activity Type, Which Year & Timeframes
                  </div>
                </div>
              </div>
            }
          </>
        )}
    </div>
  );
};

export default MyStoryActivityDocsFooter;
