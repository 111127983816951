import React from 'react';
import Chip from '../../../shared/Chip';
import Tooltip from '../../../shared/Tooltip';
import { GenericMenu } from '../../../shared/GenericMenu/GenericMenu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { GenericTabs } from '../../../shared/GenericTabs/GenericTabs';
import { ChevronRightOutlined } from '@mui/icons-material';
import { useMyStoryStore } from '../../../../stores/myStoryStore';
import { useShallow } from 'zustand/react/shallow';
import { ReactComponent as BusinessCenterIcon } from '../../../../assets/svgs/briefcase.svg';
import { MyCourseSlideOutTips } from './myCourseSlideOutTips';
import IosShareIcon from '@mui/icons-material/IosShare';
import { MyCourseSlidOutSettings } from './myCourseSlideOutSettings';
import { MyCourseSlideOutNotes } from './myCourseSlideOutNotes';
import TeamMemberAvatarGroup from '../../../shared/TeamMemberAvatarGroup';
import { Avatar, IconButton } from '@mui/material';
import { avatarTitle } from '../../../shared/TeamMembersGroup';
import useMyTeamStore from '../../../../stores/MyTeamStore';
import {
  UTILITY_TYPES,
  filterUniAndTeamUniversily,
} from '../../../../utils/utlityTypes';
import useMyHubStore from '../../../../stores/MyHubStore';
import useCourseStore from '../../../../stores/CourseStore';
export const MyCourseSlideOut = () => {
  const tabs = [
    { label: 'Notes', content: <MyCourseSlideOutNotes /> },
    { label: 'Details', content: <MyCourseSlidOutSettings /> },
    { label: 'Tips', content: <MyCourseSlideOutTips /> },
  ];
  const { setStorySlideOut, setActiveSlideOutTabIndex } = useMyStoryStore(
    useShallow((state) => ({
      setStorySlideOut: state.setStorySlideOut,
      setActiveSlideOutTabIndex: state.setActiveSlideOutTabIndex,
    }))
  );

  const myTeamStore = useMyTeamStore(
    useShallow((state) => ({
      myTeam: state.myTeam,
    }))
  );
  const myHubStore = useMyHubStore(
    useShallow((state) => ({
      isShareUtility: state.isShareUtility,
      utilityId: state.utilityId,
      setIsShareUtility: state.setIsShareUtility,
      setUtilityId: state.setUtilityId,
      setUtilityType: state.setUtilityType,
      utilityType: state.utilityType,
      setUtilityName: state.setUtilityName,
    }))
  );
  const courseStore = useCourseStore(
    useShallow((state) => ({
      addEditCourseId: state.addEditCourseId,
      coursesById: state.coursesById,
      setCopyCourseId: state.setCopyCourseId,
      setDeleteCourseId: state.setDeleteCourseId,
      setIsCopyingCourse: state.setIsCopyingCourse,
      setIsDeletingCourse: state.setIsDeletingCourse,
      setDeletingCourseId: state.setDeletingCourseId,
    }))
  );

  const menuItems = [
    {
      label: 'Copy',
      action: () => {
        courseStore.setIsCopyingCourse(true);
        courseStore.setCopyCourseId(courseStore?.addEditCourseId);
        setStorySlideOut(false);
      },
    },
    {
      label: 'Delete',
      action: () => {
        courseStore.setIsDeletingCourse(true);
        courseStore.setDeletingCourseId(courseStore?.addEditCourseId);
        setStorySlideOut(false);
      },
    },
  ];
  const courseDetails = courseStore?.coursesById[courseStore?.addEditCourseId];

  const teamMembers = myTeamStore.myTeam?.filter((item) =>
    courseDetails?.sharedWith?.includes(item.id)
  );
  return (
    <div className=" h-full flex flex-col">
      <div className="bg-[#F6F6F6] px-4 py-4 flex justify-between font-roboto items-center">
        <span className="font-semibold text-base font-roboto flex gap-3">
          <ChevronRightOutlined
            onClick={() => {
              setStorySlideOut(false);
              setActiveSlideOutTabIndex(0);
            }}
            className="cursor-pointer"
          />
          {courseDetails?.courseName}
        </span>

        <div className="flex items-center gap-2">
          <TeamMemberAvatarGroup
            handleClick={() => {
              setActiveSlideOutTabIndex(1);
            }}
          >
            {filterUniAndTeamUniversily(teamMembers)?.map((reviewer) => (
              <Tooltip title={avatarTitle(reviewer)}>
                <Avatar src={reviewer?.photoURL} />
              </Tooltip>
            ))}
          </TeamMemberAvatarGroup>
          <Chip
            label="Courses"
            color="green"
            icon={<BusinessCenterIcon />}
            className="font-medium"
          />
          <IconButton
              aria-label="Share"
              onClick={()=> {
                myHubStore.setIsShareUtility(true);
                myHubStore.setUtilityId(courseStore?.addEditCourseId);
                myHubStore.setUtilityType(UTILITY_TYPES.Courses);
                myHubStore.setUtilityName(
                  courseStore?.coursesById[courseStore?.addEditCourseId]?.courseName
                );
              }}
            >
            <IosShareIcon style={{fill: '#838489'}} />
          </IconButton>
          <Tooltip title="More Actions">
            <GenericMenu buttonLabel={<MoreVertIcon />} menuItems={menuItems} />
          </Tooltip>
        </div>
      </div>
      {/* Body  */}
      <GenericTabs tabs={tabs} />
    </div>
  );
};
