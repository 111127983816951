import { FormControl, OutlinedInput as OUTLINEDINPUT } from '@mui/material';
import { styled } from '@mui/system';

const OutlinedInput = styled(OUTLINEDINPUT)(() => ({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  borderRadius: '8px',
  paddingTop: '16px',
  paddingBottom: '16px',
  '&.MuiOutlinedInput-root': {
    borderColor: 'rgba(43, 47, 56, 0.23) !important',
    display: 'flex',
    '.MuiOutlinedInput-input': {
      textAlign: 'center',
    },
    '.MuiInputBase-input': {
      padding: '2px 0px',
      fontSize: '14px',
    },
    '.MuiTypography-root': {
      color: '#22252D',
      opacity: '50%',
      //paddingRight: '16px',
    },
    '&.Mui-disabled': {
      borderColor: '#E4E4E5 !important',
      opacity: '30%',
    },
  },
}));

const ScoreInputField = ({
  score = false,
  minScore = 0,
  maxScore = 0,
  isHelperText = false,
  onChange = () => {},
  value,
  defaultValue,
  ...props
}) => {
  return (
    <FormControl
      variant="outlined"
      InputProps={{ inputProps: { min: 0, max: 40 } }}
      // error={isError}

      {...props}
    >
      <OutlinedInput
        className="rounded-lg"
        id="outlined-adornment-score"
        value={value}
        defaultValue={defaultValue}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        aria-describedby="outlined-score-helper-text"
        inputProps={{
          'aria-label': 'score',
          step: '0.1',
        }}
        type="number"
        {...props}
      />
    </FormControl>
  );
};

export default ScoreInputField;
