import NicheLanes from './NicheLanes';

const UnassignedNicheLane = ({
  lane = [],
  currentGrade = '',
  isViewDetails = false,
  isParent = false,
  canCreateNewUtilities = () => {},
  isMobile = false,
}) => {
  return (
    <div className="bg-grey-50 h-full">
      <NicheLanes
        lanes={lane}
        currentGrade={currentGrade}
        isViewDetails={isViewDetails}
        isHeader={true}
        isParent={isParent}
        canCreateNewUtilities={canCreateNewUtilities}
        isMobile={isMobile}
      />
    </div>
  );
};

export default UnassignedNicheLane;
