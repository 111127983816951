import NicheLanes from './NicheLanes';
import NicheToolWindow from './NicheToolWindow';
import UnassignedNicheLane from './UnassignedNicheLane';
import NichePageHeaders from './NichePageHeaders';
import NichePageOverlay from './NichePageOverlay';
import { useState } from 'react';
import useNicheStore from '../../../stores/NicheStore';
import useThemeStore from '../../../stores/ThemeStore';

const NichePage = ({
  currentGrade = '10th',
  lanes = [],
  unAssignedLane = [],
  showOverlay = false,
  onAddNiche = () => {},
  isViewDetails = false,
  setAddUtiltytoNiche = () => {},
  isAddNew = false,
  isParent = false,
  canCreateNewUtilities = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const nicheStore = useNicheStore();
  const { isMobile } = useThemeStore();

  return (
    <div
      id="niche-page"
      className={`${
        !showOverlay && 'w-max sm:w-full overflow-y-auto'
      } h-full sm:overflow-hidden sm:w-full flex flex-col z-20 relative`}
    >
      {showOverlay && (
        <NichePageOverlay
          isParent={isParent}
          canCreateNewUtilities={canCreateNewUtilities}
          isMobile={isMobile}
        />
      )}
      <NichePageHeaders
        currentGrade={currentGrade}
        onAddNiche={onAddNiche}
        isAddNew={isAddNew}
        isViewDetails={isViewDetails}
        isParent={isParent}
      />
      <div
        className={`${
          isOpen ? 'pb-0' : !nicheStore.isViewDetailPopup ? 'pb-0' : 'pb-12'
        } flex-1`}
      >
        <NicheLanes
          isOpen={isOpen}
          currentGrade={currentGrade}
          lanes={lanes}
          unAssignedLane={unAssignedLane}
          isViewDetails={isViewDetails}
          setAddUtiltytoNiche={setAddUtiltytoNiche}
          isParent={isParent}
          canCreateNewUtilities={canCreateNewUtilities}
          isMobile={isMobile}
        />
      </div>
      {!isViewDetails && (
        <NicheToolWindow
          isHeaderExpanded={true}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          lanes={lanes}
          isParent={isParent}
          unAssignedLane={unAssignedLane}
          canCreateNewUtilities={canCreateNewUtilities}
          isMobile={isMobile}
        >
          <UnassignedNicheLane
            currentGrade={currentGrade}
            lane={unAssignedLane}
            isViewDetails={isViewDetails}
            isParent={isParent}
            canCreateNewUtilities={canCreateNewUtilities}
            isMobile={isMobile}
          />
        </NicheToolWindow>
      )}
    </div>
  );
};

export default NichePage;
