import { ReactComponent as CharacterIcon } from '../../assets/svgs/CharacterIcon.svg';
const initialCharacterCount = '150';
const initialDescription = `Most colleges, including those that use the Common App, allow 100 characters for your Honors description.`;
const MyStoryDocsCharacterGuide = ({
  characterCount = { initialCharacterCount },
  description = { initialDescription },
}) => {
  return (
    <div className="flex gap-4">
      <div className="flex items-center justify-center h-8 w-8 rounded-full bg-[#EEEEEE] shrink-0">
        <CharacterIcon />
      </div>
      <div className="flex flex-col gap-3">
        {characterCount ? (
          <div className="text-base font-medium text-[#22252D] tracking-xs">
            {characterCount}
          </div>
        ) : null}
        <div className="text-xs text-lead-500 tracking-xs">{description}</div>
      </div>
    </div>
  );
};

export default MyStoryDocsCharacterGuide;
