import { useState, useEffect } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import AddMoreDetails from '../../shared/AddMoreDetails';
import GradeSelector from '../../shared/GradeSelector';
import ActivityTagsInput from './ActivityTagsInput';
import useProfileStore from '../../../stores/ProfileStore';
import Tooltip from '../../shared/Tooltip';

const AddMoreActivityDetails = ({
  expanded = false,
  grades = [],
  hoursPerWeek = '',
  weeksPerYear = '',
  activityTypes = [],
  allActivityTypes = [],
  onChange = () => {},
}) => {
  const [inputBoxValue, setInputBoxValue] = useState('');

  const onInputChange = (event, newInputValue) => {
    setInputBoxValue(newInputValue);
  };
  const onActivityTypeChange = (event, activityTypes) => {
    setActivityDetails({ ...activityDetails, activityTypes });
  };

  const [activityDetails, setActivityDetails] = useState({
    grades,
    hoursPerWeek,
    weeksPerYear,
    activityTypes,
  });

  useEffect(() => {
    onChange(activityDetails);
    //eslint-disable-next-line
  }, [activityDetails]);

  const profileStore = useProfileStore();
  return (
    <>
      <div className="flex flex-col gap-8">
        <div>
          <GradeSelector
            grades={activityDetails.grades}
            onChange={(grades) => {
              setActivityDetails({ ...activityDetails, grades });
            }}
            currentYear={`${profileStore?.profile?.grade}th`}
          />
        </div>
        <div className="flex flex-row gap-4">
          <Tooltip
            placement="right"
            title={
              'On average, how many hours per week did you spend on this Activity? College applications will ask for this information.'
            }
          >
            <TextField
              type="number"
              value={activityDetails.hoursPerWeek}
              onChange={(e) => {
                if (e.target.value >= 0) {
                  setActivityDetails({
                    ...activityDetails,
                    hoursPerWeek: e.target.value,
                  });
                }
              }}
              placeholder="E.g. 4"
              label="Hours/Week"
              className="flex-1"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
              sx={{
                '& .MuiOutlinedInput-input': {
                  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button':
                    {
                      '-webkit-appearance': 'none',
                    },
                },
              }}
            />
          </Tooltip>
          <Tooltip
            placement="right"
            title="On average, how many weeks per year did you spend on this Activity? College applications will ask for this information."
          >
            <TextField
              type="number"
              value={activityDetails.weeksPerYear}
              onChange={(e) => {
                if (e.target.value >= 0) {
                  setActivityDetails({
                    ...activityDetails,
                    weeksPerYear: e.target.value,
                  });
                }
              }}
              placeholder="E.g. 20"
              sx={{
                '& .MuiOutlinedInput-input': {
                  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button':
                    {
                      '-webkit-appearance': 'none',
                    },
                },
              }}
              label="Weeks/Year"
              className="flex-1"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />
          </Tooltip>
        </div>
        <div>
          <ActivityTagsInput
            label="Activity Type"
            placeholder={
              activityDetails.activityTypes.length ? '' : 'E.g. Academic'
            }
            options={allActivityTypes}
            inputValue={inputBoxValue}
            onInputChange={onInputChange}
            value={activityDetails.activityTypes}
            onChange={onActivityTypeChange}
            menuListTitle="CommonApp App Types"
          />
        </div>
      </div>
    </>
  );
};

export default AddMoreActivityDetails;
