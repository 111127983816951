import React from 'react';
import useNotificationStore from '../../stores/NotificationStore';
import RecentActivityLineItem from './recentActivites/RecentActivityLineItem';

const RecentActivitiesSection = () => {

    const {notifications} = useNotificationStore()


    return (
        <div className='col-span-2 bg-[#FCFCFD] py-5 dashboard_box_shadow font-roboto rounded-2xl flex flex-col gap-6 h-full overflow-y-auto'>
            <p className='font-archivo text-xl px-5'>Recent activities</p>
            <div className='h-full overflow-auto flex flex-col gap-2'>
                {notifications?.map((notification, index) => 
                    <RecentActivityLineItem activity={index} key={index} utilityType={notification?.utilityType} actionType={notification?.actionType} utilityData={notification}/>
                )}
            </div>
        </div>
    );
};

export default RecentActivitiesSection;