import DeleteDialog from '../../shared/DeleteDialog';

const DeleteTargetDialog = (args) => {
  return (
    <DeleteDialog title="Delete Target Score?" {...args}>
      Target Score will be permanently deleted.
    </DeleteDialog>
  );
};

export default DeleteTargetDialog;
