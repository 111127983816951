import { IconButton } from '@mui/material';
import { ReactComponent as ChatGreyIcon } from '../../assets/svgs/chat.svg';
import { ReactComponent as BlueChatIcon } from '../../assets/svgs/blueChat.svg';
import { ReactComponent as AddNewChatIcon } from '../../assets/svgs/addChatIcon.svg';
import useChatStore from '../../stores/ChatStore';
import Tooltip from './Tooltip';
import useProfileStore from '../../stores/ProfileStore';
import { userTypeKeywords } from '../../constants/keywords';

const ChatIcon = ({
  id = '',
  isHeader = false,
  setChatUtil = () => {},
  onClick,
}) => {
  const chatStore = useChatStore();
  const profileStore = useProfileStore();
  const isParent =
    profileStore?.profile?.profileType === userTypeKeywords.FAMILY ||
    profileStore?.profile?.profileType === userTypeKeywords.PRIVATE_COUNSELOR ||
    profileStore?.profile?.profileType === userTypeKeywords.SCHOOL_COUNSELOR ||
    profileStore?.profile?.profileType === userTypeKeywords.TEACHER ||
    profileStore?.profile?.profileType === userTypeKeywords.UNIVERSILY;

  const unreadCount = chatStore.chatRoomsTopics
    ?.filter((chatRoom) => chatRoom?.utilityId === id)
    .reduce((total, chatRoom) => total + chatRoom?.unreadMessages, 0);

  const containsUtilTopic = chatStore.chatRoomsTopics?.filter(
    (chatTopic) => chatTopic?.utilityId === id && chatTopic?.teamMemberExists
  );

  return (
    <div
      className="relative"
      onClick={(event) => {
        console.log({ onClick });
        event.stopPropagation();
        if (onClick) {
          onClick();
          return;
        }
        setChatUtil();
        if (isHeader) {
          chatStore.setIsChatWindowOpen(!chatStore.isChatWindowOpen);
        } else if (containsUtilTopic?.length > 0) {
          if (!chatStore.isChatWindowOpen) {
            chatStore.setIsChatWindowOpen(!chatStore.isChatWindowOpen);
          }
          if (!chatStore.isGenAIChatOpen) {
            chatStore.setIsGenAIChatOpen(!chatStore.isGenAIChatOpen);
          }
          chatStore.setIsFilterTopic(true);
          chatStore.setUtilityId(id);
        } else if (!isHeader) {
          chatStore.setIsAddingTopic(true);
          chatStore.setIsFilterTopic(false);
          chatStore.setUtilityId(id);
        }
      }}
    >
      {(unreadCount > 0 || (containsUtilTopic.length === 0 && !isHeader)) && (
        <div className="absolute top-px right-0.5 z-10">
          {unreadCount !== 0 && (
            <span className="text-[10px] flex h-[14px] w-[14px] items-center justify-center font-medium rounded-full border border-white bg-success-90 text-white mt-px">
              {unreadCount}
            </span>
          )}
          {!isHeader && containsUtilTopic.length === 0 && <AddNewChatIcon />}
        </div>
      )}

      <Tooltip
        title={!isParent ? 'Chat with Team Member' : 'Chat with the Student'}
      >
        <IconButton aria-label="Chat Icon">
          {chatStore.isChatWindowOpen && isHeader ? (
            <BlueChatIcon />
          ) : (
            <ChatGreyIcon
              style={{
                fill: '#838489',
              }}
            />
          )}
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ChatIcon;
