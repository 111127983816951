import { Divider, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../../../shared/Button';

export const ViewDetailsHeader = ({
  title = '',
  type = '',
  onClose = () => {},
  onEdit = () => {},
  onReassignNiche = () => {},
  isParent = false,
}) => {
  return (
    <div className="flex items-center justify-between pt-6 pb-4 px-6">
      <div className="text-xl font-bold text-[#2C303C] font-archivo">
        {title}
      </div>
      <div className="flex gap-6">
        {
          <>
            <Button variant="text" onClick={onEdit}>{`Edit ${type}`}</Button>
            <Divider
              orientation="vertical"
              flexItem
              variant="middle"
              sx={{
                borderColor: '#D6D6D8',
              }}
            />
            <Button variant="text" onClick={onReassignNiche}>
              Reassign Niche
            </Button>
          </>
        }
        <IconButton aria-label="Close" onClick={() => onClose(false)}>
          <CloseIcon className="cursor-pointer text-base text-[#757575]" />
        </IconButton>
      </div>
    </div>
  );
};
