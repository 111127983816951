import { Divider } from '@mui/material';
import MyStoryDocsCharacterGuide from '../MyStoryDocsCharacterGuide';

const characterGuideData = [
  {
    characterCount: '',
    description: `When the time comes to submit college applications, you’ll enter your courses directly into the application platform like Common App or University of California.  Most colleges will also ask that the high school submit the transcripts which will list all of the courses and grades. `,
  },
  {
    characterCount: '',
    description: `So while you can enter all of your courses and grades into Universily, it is most important to enter your courses that are AP, IB, or Honors.  You should also enter any other courses that support your niches or where you challenged yourself academically.  With this information Uni can help you find good college matches and help tell your unique story.`,
  },
];

export const CourseDocsGuidelines = ({ isSelectedInfo = true }) => {
  return (
    <>
      {isSelectedInfo && (
        <div className="flex flex-col gap-6 p-6">
          <div className="text-sm text-[#22252D]">Tips for Courses</div>
          <div className="text-grey-300 text-xs"></div>
          <div className="flex flex-col gap-4">
            {characterGuideData?.map((item, index) => (
              <>
                <MyStoryDocsCharacterGuide
                  characterCount={item.characterCount}
                  description={item.description}
                  key={item?.id}
                />
                {characterGuideData.length - 1 !== index && (
                  <Divider
                    className="opacity-20 -mx-3 border-t"
                    sx={{
                      borderColor: '#BABAAE',
                    }}
                  />
                )}
              </>
            ))}
          </div>
          <div className="flex flex-col mt-2 gap-8">
            <Divider
              className="border-t"
              sx={{
                borderColor: '#D5D5D5',
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
