import React from 'react';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';

const DotPagination = ({
    totalPages,
    currentPage,
    onPageChange,
    nextStep,
    prevStep
}) => {
    return (
    <div
        className='flex items-center justify-center gap-3'
        role="navigation"
        aria-label="Pagination Navigation"
    >
        <ChevronLeftRoundedIcon fontSize='medium' role='button' sx={{marginRight: '2rem', color: '#C2C2C2'}} onClick={prevStep}/>
        {Array.from({ length: totalPages }).map((_, index) => (
            <button
            key={index}
            onClick={() => onPageChange(index)}
            className={`w-2 h-2 rounded-full transition-all duration-100
                hover:bg-gray-400
                ${currentPage === index
                ? 'bg-[#5A5A5A] scale-125'
                : 'bg-[#C2C2C2] scale-100'}`}
            aria-current={currentPage === index ? 'page' : undefined}
            aria-label={`Go to page ${index + 1}`}
            />
        ))}
        <ChevronRightRoundedIcon fontSize='medium' role='button' sx={{marginLeft: '2rem', color: '#C2C2C2'}} onClick={nextStep}/>
    </div>
    );
}

export default DotPagination;