import { useRef, useState } from 'react';
import Menu from '../shared/Menu';
import MenuItem from '../shared/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { ReactComponent as CopyIcon } from './../../assets/svgs/copy.svg';
import { ReactComponent as InfoIcon } from './../../assets/svgs/storyInfo.svg';
import { ReactComponent as SelectedInfoIcon } from './../../assets/svgs/selectedInfoIcon.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Divider, IconButton } from '@mui/material';
import Button from '../shared/Button';
import Tooltip from '../shared/Tooltip';
import ChatIcon from '../shared/ChatIcon';
import useGoogleAuthorization from '../../hooks/use-google-authorization';
import { useGoogleDocs } from '../../hooks';
import AIChatIcon from '../shared/AIChatIcon';
import { UTILITY_TYPES } from '../../utils/utlityTypes';
import useAccessCheck from '../../utils/accessCheck';
import { openSnackbar } from '../shared/GlobalSnackbar/GlobalSnackbar';
import IosShareIcon from '@mui/icons-material/IosShare';

const MyStoryDocsHeader = ({
  id = '',
  onBack = () => {},
  title = '',
  nicheName = '',
  saveIcon = false,
  onEdit = () => {},
  menuItems = [],
  onCopy = () => {},
  onCopyCoreEssay = () => {},
  onInfo = () => {},
  isSelectedInfo,
  onEditHeader = () => {},
  isActivity = false,
  isParent = false,
  isChatWindowOpen = false,
  onClickChat = () => {},
  isNiche = false,
  canCreateNewUtilities = () => {},
  isNicheName = false,
  isEssay = false,
  onCopyApplicationEssay = () => {},
  canCreateNewEssays = () => {},
  onClickShare = () => {},
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuIconRef = useRef();
  const [anchor, setAnchor] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const open = Boolean(anchor);
  const { onInitAuthorization, tokens } = useGoogleAuthorization();
  const { checkIfAccessTokenIsInvalid } = useGoogleDocs();

  const [checkAccess] = useAccessCheck([UTILITY_TYPES.Essay]);
  return (
    <div className="flex flex-col w-full justify-between">
      <div className="flex flex-1 items-center justify-between">
        <div className="flex gap-3 items-center w-1/2 sm:w-full">
          <Tooltip title="Back">
            <IconButton
              aria-label="Back Icon"
              size="small"
              onClick={() => {
                onBack();
              }}
            >
              <ArrowBackIcon fontSize="small" />
            </IconButton>
          </Tooltip>

          <Tooltip
            title={
              isActivity
                ? 'Activity Name'
                : isEssay
                ? 'Essay Name'
                : isNiche
                ? 'Niche Name'
                : 'Honor Name'
            }
          >
            <div className="text-primary-black font-medium truncate">
              {title}
            </div>
          </Tooltip>

          {isNicheName && (
            <div className="hidden sm:flex">
              {nicheName && (
                <Tooltip title="Niche name">
                  <div className="text-xs text-primary-black">
                    (Niche: {nicheName})
                  </div>
                </Tooltip>
              )}
              {!nicheName && (
                <Tooltip title="Niche name">
                  <div className="text-xs text-primary-black">
                    (Niche: Unassigned)
                  </div>
                </Tooltip>
              )}
            </div>
          )}
          {
            <div>
              {isNiche ? (
                <Tooltip title="Edit Niche Name">
                  <IconButton
                    aria-label="Edit Icon"
                    size="small"
                    onClick={onEditHeader}
                    className="fifth-step !hidden sm:!block"
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              ) : (
                <></>
              )}
            </div>
          }
        </div>
        <div className="flex items-center gap-5 shrink-0">
          {!isNiche && (
            <>
              {
                <>
                  {
                    <Tooltip
                      title={
                        isEssay &&
                        'You can make a copy of this Essay to use as a Core Essay or to use for another Application Essay.'
                      }
                    >
                      <div>
                        <Button
                          variant="text"
                          startIcon={<CopyIcon />}
                          onClick={(event) => {
                            if (checkAccess) {
                              if (isEssay) {
                                setAnchor(event.currentTarget);
                              } else {
                                onCopy();
                              }
                            } else {
                              // shoe alert for limit reached
                              openSnackbar(
                                'You have reached the maximum limit, please upgrade to add more',
                                'error'
                              );
                            }
                          }}
                          className="sixth-step  !hidden sm:!flex"
                        >
                          Make a Copy
                        </Button>
                      </div>
                    </Tooltip>
                  }
                  <Menu
                    anchorEl={anchor}
                    open={Boolean(anchor)}
                    onClose={() => setAnchor(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <MenuItem
                      onClick={async () => {
                        if (canCreateNewEssays()) {
                          if (
                            (!tokens ||
                              (await checkIfAccessTokenIsInvalid())) &&
                            !isParent
                          )
                            await onInitAuthorization();
                          else {
                            onCopyCoreEssay();
                          }
                        }
                        setAnchor(null);
                      }}
                    >
                      as Core Essay
                    </MenuItem>
                    <MenuItem
                      onClick={async () => {
                        if (canCreateNewEssays()) {
                          if (
                            (!tokens ||
                              (await checkIfAccessTokenIsInvalid())) &&
                            !isParent
                          )
                            await onInitAuthorization();
                          else {
                            onCopyApplicationEssay();
                          }
                        }
                        setAnchor(null);
                      }}
                    >
                      as Application Essay
                    </MenuItem>
                  </Menu>
                </>
              }
            </>
          )}
          <div className="seventh-step">
            <AIChatIcon id={id} />
          </div>
          <div className="seventh-step">
            <ChatIcon id={id} isHeader={true} />
          </div>
          <Tooltip title="Tips">
            <IconButton aria-label="Info Icon" onClick={onInfo}>
              <MenuOpenIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Share">
            <IconButton
                aria-label="Share"
                onClick={onClickShare}
              >
              <IosShareIcon />
            </IconButton>
          </Tooltip>
          <div className="cursor-pointer">
            {
              <div ref={menuIconRef}>
                <Tooltip title="More Actions">
                  <IconButton
                    aria-label="Notification Icon"
                    onClick={() => {
                      setIsMenuOpen(true);
                    }}
                  >
                    <MoreVertIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div>
            }
            <Menu
              open={isMenuOpen}
              anchorEl={menuIconRef.current}
              onClose={() => {
                setIsMenuOpen(false);
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              {menuItems.map((item, index) => {
                return (
                  <MenuItem onClick={item.onClick} key={index}>
                    {item.title}
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        {isNicheName && (
          <div className="flex sm:hidden pl-10">
            {nicheName && (
              <Tooltip title="Niche name">
                <div className="text-xs text-primary-black">
                  (Niche: {nicheName})
                </div>
              </Tooltip>
            )}
            {!nicheName && (
              <Tooltip title="Niche name">
                <div className="text-xs text-primary-black">
                  (Niche: Unassigned)
                </div>
              </Tooltip>
            )}
          </div>
        )}
        {!isParent && (
          <div>
            {isNiche ? (
              <Tooltip title="Edit Niche Name">
                <IconButton
                  aria-label="Edit Icon"
                  size="small"
                  onClick={onEditHeader}
                  className="fifth-step !flex sm:!hidden"
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : (
              !isEssay && (
                <Tooltip title="Edit Title and Niche Name">
                  <IconButton
                    aria-label="Edit Icon"
                    size="small"
                    onClick={onEditHeader}
                    className="fifth-step !flex sm:!hidden"
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MyStoryDocsHeader;
