import DeleteDialog from '../../shared/DeleteDialog';

const DeleteDateDialog = (args) => {
  return (
    <DeleteDialog title="Delete Future Date?" {...args}>
      <div>Future Date will be permanently deleted.</div>
    </DeleteDialog>
  );
};

export default DeleteDateDialog;
