import { useEffect, useState } from 'react';
import InputWithToolTip from '../../shared/InputWithTooltip';
import ChooseNiche from '../../shared/ChooseNiche';
import AddMoreActivityDetails from './AddMoreActivityDetails';
import SelectTeamMembers from '../../shared/SelectTeamMembers';
import ErrorIcon from '@mui/icons-material/Error';
import Alert from '../../shared/Alert';
import { useLocation } from 'react-router-dom';
import useStudentStore from '../../../stores/StudentStore';
import useProfileStore from '../../../stores/ProfileStore';
import TextAreaWithTooltip from '../../shared/TextAreaWithTooltip';
import useTeamStore from '../../../stores/TeamStore';

const AddNewActivity = ({
  activityData = {},
  niches,
  editMode = false,
  allActivityTypes = [],
  onChange = () => {},
  collaborators = [],
  selectedCollaborators = [],
  isActivityName = false,
  isAdding = false,
}) => {
  const [formData, setFormData] = useState({
    activityName: activityData.activityName || '',
    nicheId: activityData.nicheId || '',
    grades: activityData.grades || [],
    hoursPerWeek: activityData.hoursPerWeek || '',
    weeksPerYear: activityData.weeksPerYear || '',
    activityTypes: activityData.activityTypes || [],
    sharedWith: activityData.sharedWith || [],
    docInformation: activityData.docInformation || [],
  });

  const selectedTeam = useTeamStore((state) => state.selectedTeam);
  const isParent = useProfileStore((state) => state.isParent);

  // To set the default team members
  function defaultTeamMembers() {
    if (isAdding) {
      return collaborators;
    }
    return selectedCollaborators;
  }

  const [selectedTeamMembers, setSelectedTeamMembers] = useState(() =>
    defaultTeamMembers()
  );

  useEffect(() => {
    setFormData({
      ...formData,
      sharedWith: selectedTeamMembers?.map((teamMember) => {
        return {
          id: teamMember?.id,
          email: teamMember?.email,
          photoURL: teamMember?.photoURL,
          uid: teamMember?.uid,
        };
      }),
    });
    // eslint-disable-next-line
  }, [selectedTeamMembers]);

  console.log({ selectedTeamMembers, selectedCollaborators });
  useEffect(() => {
    onChange(formData);
    // eslint-disable-next-line
  }, [formData]);

  const location = useLocation();
  return (
    <div className="flex flex-col gap-4 py-4 px-2">
      {isAdding && location.pathname === '/myStory/niche' && (
        <Alert className="w-fit mt-3" severity="info">
          Note: Adding Activities from Niche will also create that item in your
          Activities section. You’ll also be able to add further details and
          collaborate in the Activities section.
        </Alert>
      )}
      {isAdding && location.pathname === '/myHub' && (
        <Alert className="w-fit mt-3" severity="info">
          Note: Adding Activities from the Hub will also create that item in
          your Activities section. You’ll also be able to add further details
          and collaborate in the Activities section.
        </Alert>
      )}
      <div>
        <InputWithToolTip
          error={isActivityName}
          title={
            <div>
              Admissions officers want a quick glimpse of your overall profile
              and they get this by looking at your Activity section. Your
              information in this section may even lead them to categorize you
              into a type like the genius math student, or the international
              affairs expert.
            </div>
          }
          required
          label="Activity"
          placeholder="E.g. Independent Study"
          value={formData.activityName}
          onChange={(e) => {
            setFormData({ ...formData, activityName: e.target.value });
          }}
          helperText={
            isActivityName && (
              <div className="flex items-center gap-1 text-xs">
                <ErrorIcon color="error" className="text-base" />
                {`Enter Activity Title`}
              </div>
            )
          }
        />
      </div>
      <div>
        <ChooseNiche
          niches={niches}
          utilityName="Activity"
          selectedNiche={[formData.nicheId]}
          onChange={(e, nicheId) => {
            if (nicheId !== null) {
              setFormData({ ...formData, nicheId });
            }
          }}
        />
      </div>
      <>
        <TextAreaWithTooltip
          title={
            'Most colleges, including those that use the Common App, allow 150 characters for your Activity description.'
          }
          redIndicator={true}
          maxLength="150"
          label="Short Description"
          placeholder="E.g. Online Learning (Coursera, MIT OpenCourseWare, Art of Problem Solving), four courses in discrete math and two in calculus."
          value={
            formData?.docInformation?.find(
              (doc) => doc.label === '150 Characters'
            )?.content || ''
          }
          onChange={(e) => {
            setFormData({
              ...formData,
              docInformation: [
                {
                  label: '150 Characters',
                  content: e.target.value,
                  charCount: e.target.value.length,
                },
                ...formData.docInformation.filter(
                  (doc) => doc.label !== '150 Characters'
                ),
              ],
            });
          }}
        />
        <TextAreaWithTooltip
          title={
            'Other colleges, such as the University of California system, allow 350 characters to describe your Activity.'
          }
          redIndicator={true}
          maxLength="350"
          label="Long Description"
          value={
            formData?.docInformation?.find(
              (doc) => doc.label === '350 Characters'
            )?.content || ''
          }
          placeholder="E.g. Online Learning (Coursera, MIT OpenCourseWare, Art of Problem Solving): Introduction to Discrete Mathematics for Computer Science, Discrete Mathematics, 
          Discrete Mathematics Generality, Data Science Math Skills, Calculus One and Calculus Two."
          onChange={(e) => {
            setFormData({
              ...formData,
              docInformation: [
                {
                  label: '350 Characters',
                  content: e.target.value,
                  charCount: e.target.value.length,
                },
                ...formData.docInformation.filter(
                  (doc) => doc.label !== '350 Characters'
                ),
              ],
            });
          }}
        />
      </>

      <div>
        <AddMoreActivityDetails
          grades={formData.grades}
          hoursPerWeek={formData.hoursPerWeek}
          weeksPerYear={formData.weeksPerYear}
          activityTypes={formData.activityTypes}
          allActivityTypes={allActivityTypes}
          expanded={true}
          onChange={({ ...activityDetails }) => {
            setFormData({ ...formData, ...activityDetails });
          }}
        />
      </div>
      <div>
        <SelectTeamMembers
          label="Select Team Members (optional)"
          options={collaborators}
          value={selectedTeamMembers}
          placeholder={selectedTeamMembers.length ? '' : 'E.g. Team Member'}
          menuListTitle="Select your Existing Team Members"
          onChange={(e, teamMembers) => {
            setSelectedTeamMembers(teamMembers);
          }}
          tooltipTitle={`You can select or add Team Members with whom you wish to collaborate`}
        />
      </div>
    </div>
  );
};

export default AddNewActivity;
