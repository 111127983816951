import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Button from '../../../components/shared/Button';

const grades = ['9th', '10th', '11th', '12th', '12th+'];

const NichePageHeaders = ({
    currentGrade = '',
    onAddNiche = () => {},
    isAddNew = false,
    isViewDetails = false,
    isParent = false,
}) => {
    return (
        <div className="bg-[#FCFCFB]">
            <div className="h-12 border-y flex border-cream-100">
                {!isViewDetails && (
                <div className="w-48 shrink-0 px-4 flex items-center text-xs text-primary-black font-medium">
                    Passion List
                </div>
                )}
                <div className="flex-1 border-l grid grid-cols-6">
                <div
                    className={`${
                    isParent ? 'px-6 sm:px-3' : 'px-3'
                    } flex items-center text-xs text-grey-900 font-medium`}
                >
                    No Grade
                </div>
                {grades.map((grade, index) => {
                    if (grade.toLowerCase() === currentGrade.toLowerCase()) {
                    return (
                        <div
                        key={index}
                        className="flex items-center text-xs text-white font-medium -ml-6"
                        >
                        <div className="bg-primary-black rounded-full px-3 py-1.5 relative">
                            {grade}
                            <ArrowDropDownRoundedIcon
                            fontSize="large"
                            className="absolute text-grey-900 left-1/2 -translate-x-1/2 -bottom-6"
                            />
                        </div>
                        </div>
                    );
                    } else {
                    return (
                        <div
                        className="flex items-center text-xs text-grey-900 font-medium -ml-3"
                        key={index}
                        >
                        {grade}
                        </div>
                    );
                    }
                })}
                </div>
            </div>
        </div>
    );
};

export default NichePageHeaders;
