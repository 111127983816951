import { ReactComponent as UpgradeIcon } from '../../../assets/svgs/upgrade.svg';
import { ReactComponent as RightIcon } from '../../../assets/svgs/RightArrow.svg';
import { ReactComponent as InfoIcon } from '../../../assets/svgs/infoHeader.svg';

import { NavLink, useNavigate } from 'react-router-dom';
import useSubscriptionStore from '../../../stores/SubscriptionStore';
import Dialog from '../Dialog';
import UpgradePlans from './UpgradePlans';
import useProfileStore from '../../../stores/ProfileStore';
import { stripeApiHandler } from '../../../utils/stripe';
import { updateStoryItem } from '../../../firebase/services/updateServices';
import { ApiRoutes } from '../../../firebase/apis';
import { addStoryItemTypes } from '../../../constants/other';
import config from '../../../utils/urlConfig';
import useActivityStore from '../../../stores/ActivityStore';
import useHonorStore from '../../../stores/HonorStore';
import useCourseStore from '../../../stores/CourseStore';
import useEssayStore from '../../../stores/EssayStore';
import useStudentStore from '../../../stores/StudentStore';
import useApplicationStore from '../../../stores/ApplicationStore';
const initialMessage =
  'You’ve reached the course limit for your free Universily subscription.';

const UpgradeBanner = ({ upgradeMessage = initialMessage }) => {
  const subscriptionStore = useSubscriptionStore();
  const profileStore = useProfileStore();
  const { activities } = useActivityStore();
  const { honors } = useHonorStore();
  const { courses } = useCourseStore();
  const { essays } = useEssayStore();
  const { applications } = useApplicationStore();
  const studentStore = useStudentStore();
  const navigate = useNavigate();

  const onUpgradeNow = () => {
    subscriptionStore.setShowSubscriptionDialog(true);
  };

  const buySubscription = async (duration) => {
    const profileData = profileStore.profile;
    if (profileData) {
      let cusId = '';
      if (!profileData?.stripe_customer_id) {
        const isStripeUserCreated = await stripeApiHandler(
          ApiRoutes?.CREATE_STRIPE_USER,
          {
            displayName: profileData?.profileName,
            email: profileData?.email,
            profileType: profileData?.profileType,
            uid: profileData?.uid,
          }
        );
        if (isStripeUserCreated) {
          cusId = isStripeUserCreated?.data;
          cusId &&
            (await updateStoryItem(
              { id: profileData?.id, stripe_customer_id: cusId },
              addStoryItemTypes.PROFILE
            ));
        }
      } else {
        cusId = profileData?.stripe_customer_id;
      }
      if (cusId) {
        const subscriptionData = {
          priceData: {
            price: config.getStripeSubscriptionPriceId[duration],
            quantity: 1,
          },
          customer_id: cusId,
          redirect_url: config.getStripeRedirectUrl,
          isPromoCodeApplied: true,
          paymentMethodsTypes: ['card'],
        };
        const resp = await stripeApiHandler(
          ApiRoutes?.BUY_SUBSCRIPTION,
          subscriptionData
        );
        if (resp?.data?.statusCode !== 400 && resp?.status === 200) {
          window.open(resp?.data, '_self');
        }
      }
    }
  };

  const onRefer = () => {
    navigate('/referAFriend');
  };

  return (
    <div>
      <div className="flex bg-[#2B2F38] items-center py-4 text-white text-sm gap-6 justify-center">
        <div className="flex gap-2.5">
          <UpgradeIcon />
          <div>{upgradeMessage}</div>
        </div>
        <div className="flex gap-2.5 items-center opacity-80 font-medium">
          <NavLink
            className="underline underline-offset-1 tracking-[0.15px]"
            type="button"
            onClick={onUpgradeNow}
          >
            Upgrade Now
          </NavLink>
          <RightIcon />
        </div>
        <Dialog
          open={subscriptionStore.showSubscriptionDialog}
          onCancel={() => {
            subscriptionStore.setShowSubscriptionDialog(false);
          }}
        >
          <UpgradePlans
            buySubscription={(duration) => {
              subscriptionStore.setShowSubscriptionDialog(false);
              buySubscription(duration);
            }}
            activityCount={activities?.length || studentStore.activityCount}
            honorCount={honors?.length || studentStore.honorCount}
            courseCount={courses?.length || studentStore.courseCount}
            essaysCount={essays?.length}
            applicationsCount={applications?.length}
            continueWithFreemium={() => {
              subscriptionStore.setShowSubscriptionDialog(false);
              subscriptionStore.setShowUpgradeBanner(false);
              subscriptionStore.setUpgradeBannerUtil(null);
            }}
          />
        </Dialog>
      </div>
      <div className="flex bg-[#2B2F38] items-center py-4 text-white text-sm gap-6 justify-center">
        <div className="flex gap-2.5">
          <InfoIcon />
          <div className=" text-[20px] font-bold">
            <NavLink
              className="underline underline-offset-1 tracking-[0.15px]  text-[20px] font-bold"
              to={'/myTeam'}
            >
              Add Team Member
            </NavLink>{' '}
            or{' '}
            <NavLink
              className="underline underline-offset-1 tracking-[0.15px]  text-[20px] font-bold"
              to="/referAFriend"
            >
              Refer a Friend
            </NavLink>{' '}
            for free months of Universily Premium Membership!
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeBanner;
