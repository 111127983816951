import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { IconButton } from '@mui/material';
import MyStoryAddNewButton from '../MyStoryAddNewButton';
import Tooltip from '../../shared/Tooltip';
import { ReactComponent as CollapseFullScreen } from '../../../assets/svgs/collapse.svg';
import { ReactComponent as ExpandFullScreen } from '../../../assets/svgs/expand.svg';

const NicheToolWindowHeader = ({
  isCollapsed = false,
  isExpanded = false,
  onExpand = () => {},
  onUnfold = () => {},
  lanes,
  unAssignedLane,
  isParent = false,
  isFullscreen,
  canCreateNewUtilities = () => {},
  isMobile = false,
}) => {
  return (
    <div
      style={{
        boxShadow:
          ' 0px -3px 12px -4px rgba(16, 24, 40, 0.06), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
      }}
      className={`flex  ${
        isExpanded
          ? 'border-b border-t border-b-grey-100 border-t-cream-200'
          : ''
      } w-full`}
    >
      <div
        className={`rounded-t-lg bg-grey-50 h-12 px-6 flex items-center ${
          isExpanded ? 'w-full' : ''
        } ${
          unAssignedLane[0]?.items.length !== 0
            ? 'justify-center sm:justify-between'
            : 'justify-center'
        }`}
      >
        {isExpanded && <div></div>}
        <div className="flex items-center gap-1  text-[#363A46]">
          {!isMobile ? (
            <div className="text-sm font-medium">
              Click "+ ADD NEW" to add new Activities, Honors, or Courses
            </div>
          ) : (
            <div className="text-sm font-medium">
              Unassigned Activities, Honors, or Courses
            </div>
          )}
          {!isCollapsed && (
            <Tooltip title="Expand Pane">
              <IconButton size="small" onClick={onExpand}>
                <ExpandLessIcon className="cursor-pointer" fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          {isCollapsed && (
            <Tooltip title="Collapse Pane">
              <IconButton size="small" onClick={onExpand}>
                <ExpandMoreIcon className="cursor-pointer" fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          {!isFullscreen ? (
            <Tooltip title="Full Screen">
              <IconButton size="small" onClick={onUnfold}>
                <ExpandFullScreen className="cursor-pointer" fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Exit Full Screen">
              <IconButton size="small" onClick={onUnfold}>
                <CollapseFullScreen
                  className="cursor-pointer"
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>
          )}
        </div>
        {isExpanded && unAssignedLane[0]?.items.length !== 0 && (
          <MyStoryAddNewButton
            variant="text"
            isUtility={true}
            showNiche={false}
            showTests={false}
            showEssays={false}
            showCollege={false}
            showDropDown={false}
            showGPA={false}
            className="!hidden sm:!flex"
            canCreateNewUtilities={canCreateNewUtilities}
            sx={{ backgroundColor: 'none' }}
          />
        )}
      </div>
    </div>
  );
};

export default NicheToolWindowHeader;
