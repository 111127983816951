import React, { useEffect, useRef, useState } from 'react';
import RecentActivityLineItem from '../../pages/dashboardV2/recentActivites/RecentActivityLineItem';
import useNotificationStore from '../../stores/NotificationStore';
import useProfileStore from '../../stores/ProfileStore';
import { markNotificationAsRead } from '../../firebase/services/updateServices';

export function NotificationPanel({ 
    isVisible = false, 
    onClose = () => {},
    containerRef = null
}) {
    const { notifications } = useNotificationStore();
    const panelRef = useRef(null);
    const [markAsSeen, setMarkAsSeen] = useState([]);
    const [seenNotifications, setSeenNotifications] = useState([]);
    const { profile } = useProfileStore();

    const processVisibleNotifications = (container) => {
        const elements = container.getElementsByClassName('notification-item');
        
        Array.from(elements).forEach((element) => {
            const rect = element.getBoundingClientRect();
            const containerRect = container.getBoundingClientRect();
            
            // Check if element is visible in the container
            if (rect.top >= containerRect.top && rect.bottom <= containerRect.bottom) {
                const id = element.getAttribute('data-id');
                if (id) {
                    const utilityDataSeenBy = notifications.find((notification) => notification.id === id)?.seenBy;
                    if(!utilityDataSeenBy) return;
                    if(utilityDataSeenBy.includes(profile?.uid)) return;
                    if(seenNotifications.includes(id)) return;
                    setSeenNotifications([...seenNotifications, id]);
                }
            }
        });
    };

    const handleScroll = (e) => {
        const container = e.currentTarget;
        processVisibleNotifications(container);
    };

    useEffect(() => {
        async function checkAndSetMarkAsSeen() {
            if(seenNotifications.length > 0) {
                // check it seenNotifications items are present in markAsSeen
                // store in variable which are not present in markAsSeen
                // then call markAsSeen API with those items and update markAsSeen
                // then add those items to markAsSeen
                const unseenNotifications = seenNotifications.filter((item) => !markAsSeen.includes(item));
                if(unseenNotifications.length > 0) {
                    // call markAsSeen API
                    await Promise.all(unseenNotifications.map(async (notificationId) => { 
                        await markNotificationAsRead({userId: profile?.uid, notificationId});
                        // update markAsSeen
                        setMarkAsSeen([...markAsSeen, ...notificationId]);
                    }))

                }
            }
        }
        checkAndSetMarkAsSeen();
    }, [seenNotifications]);

    useEffect(() => {
        if (isVisible && panelRef.current) {
            processVisibleNotifications(panelRef.current);
        }
    }, [isVisible]); // Run this effect when `isVisible` changes

    if (!isVisible) return null;

    return (
        <div ref={containerRef} className="relative z-50 mt-5 rounded-xl font-archivo" style={{
            boxShadow: '0px 0px 16.9px -3px rgba(0, 0, 0, 0.12)'
        }}>
            {/* Pointing edge with border fix */}
            <div className="absolute -top-2 right-8 w-4 h-4">
                <div className="absolute inset-0 bg-white transform rotate-45"></div>
                <div className="absolute inset-0 bg-white transform rotate-45 rounded-sm" style={{ marginTop: '1px', marginLeft: '1px' }}></div>
            </div>
            
            <div ref={panelRef} className="relative mt-2 w-[28rem] py-5 bg-white rounded-xl shadow-xl flex flex-col gap-4 max-h-[28rem] overflow-y-auto">
                <div className="sticky top-0 bg-white px-5">
                    <h2 className="text-xl font-normal">All activities ({notifications?.length})</h2>
                </div>
                <div className="flex flex-col gap-2 overflow-y-auto" onScroll={(e) => handleScroll(e)}>
                    {notifications?.map((notification, index) => (
                        <div className="notification-item w-full" data-id={notification?.id} key={notification?.id}>
                            <RecentActivityLineItem activity={index} utilityType={notification?.utilityType} actionType={notification?.actionType} utilityData={notification} notification={true}/>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
