import MyStoryDocsTabs from '../MyStoryDocsTabs';
import { useState, useEffect } from 'react';
import { honorDocCharVersion } from '../../../constants/staticData';
import useGoogleAuthorization from '../../../hooks/use-google-authorization';
import { useGoogleDocs } from '../../../hooks';

const MyStoryHonorDocsTabs = ({
  addTabBtnText = 'Add 250 Character Version',
  tabLabels = [],
  onSelectedTabChange = () => {},
  onAddTabBtnClick = () => {},
  isParent = false,
  docsVersions = [],
  loadingState = '',
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { onInitAuthorization, tokens } = useGoogleAuthorization();
  const { checkIfAccessTokenIsInvalid } = useGoogleDocs();

  useEffect(() => {
    onSelectedTabChange(selectedTab);
    //eslint-disable-next-line
  }, [selectedTab]);

  return (
    <MyStoryDocsTabs
      value={selectedTab}
      onChange={(e, newValue) => {
        setSelectedTab(newValue);
      }}
      addTabBtnText={addTabBtnText}
      onAddTabBtnClick={async (version) => {
        if (!tokens || (await checkIfAccessTokenIsInvalid()))
          await onInitAuthorization();
        else {
          await onAddTabBtnClick(version);
          setSelectedTab(version === honorDocCharVersion.CHAR_100 ? 0 : 1);
        }
      }}
      docsVersions={docsVersions}
      tabLabels={tabLabels}
      loadingState={loadingState}
      showAddTabBtn={!isParent && tabLabels.length !== 2}
      isParent={isParent}
    />
  );
};

export default MyStoryHonorDocsTabs;
