import { Button } from '@mui/material';
import { AddCircle } from '@mui/icons-material';

const AddDetailsPopupCourseDesc = ({
  description = '',
  openAddDialog = () => {},
  isParent = false,
}) => {
  return (
    <>
      {description === '' && (
        <div className="p-6 flex-1">
          {!isParent ? (
            <div className="flex flex-col h-full bg-[#FCFCF9] border-2 border-dashed border-[#E7E7E1] gap-2 justify-center items-center rounded-xl">
              <div className="text-xl text-[#22252D] opacity-70 font-medium">
                No Details Added Yet
              </div>
              <div>
                <Button
                  variant="text"
                  startIcon={<AddCircle />}
                  onClick={openAddDialog}
                >
                  Start Adding Details Now
                </Button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col h-full bg-[#FCFCF9] border-2 border-dashed border-[#E7E7E1] gap-2 justify-center items-center rounded-xl">
              <div className="text-xl text-[#22252D] opacity-70 font-medium">
                Details are yet to be added
              </div>
            </div>
          )}
        </div>
      )}
      {description && (
        <div className="flex-1 p-0">
          <div className="text-[#22252D] flex flex-col gap-4 px-6 pb-6">
            <div className="text-sm font-medium">Course description</div>
            <div>{description}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddDetailsPopupCourseDesc;
