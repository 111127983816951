import React from 'react'

const CollegeDueChip = ({
    daysLeft = 3,
}) => {
    const text = `Hurry! ${daysLeft} day${daysLeft > 1 ? 's' : ''} left!`
    return (
        <div className='w-max py-1 px-1.5 bg-orange-apricotGlow text-orange-deepAmber text-xs font-medium rounded text-end'>{text}</div>
    )
}

export default CollegeDueChip