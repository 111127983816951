import {
  FormControl,
  FormHelperText as FORMHELPERTEXT,
  InputAdornment,
  OutlinedInput as OUTLINEDINPUT,
} from '@mui/material';
import { styled } from '@mui/system';
import { useState } from 'react';

const FormHelperText = styled(FORMHELPERTEXT)(() => ({
  '&.MuiFormHelperText-root': {
    color: '#22252D',
    opacity: '50%',
    marginRight: 0,
    marginLeft: 0,
    display: 'flex',
    justifyContent: 'center',
    '&.Mui-disabled': {
      opacity: '30%',
    },
    '&.textRed': {
      color: '#D32F2F',
      opacity: 1,
    },
  },
}));

const OutlinedInput = styled(OUTLINEDINPUT)(() => ({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  borderRadius: '8px',
  paddingTop: '16px',
  paddingBottom: '16px',
  '&.MuiOutlinedInput-root': {
    borderColor: 'rgba(43, 47, 56, 0.23) !important',
    display: 'flex',
    '.MuiOutlinedInput-input': {
      textAlign: 'right',
    },
    '.MuiInputBase-input': {
      padding: '2px 0px',
      fontSize: '14px',
    },
    '.MuiTypography-root': {
      color: '#22252D',
      opacity: '50%',
      //paddingRight: '16px',
    },
    '&.Mui-disabled': {
      borderColor: '#E4E4E5 !important',
      opacity: '30%',
    },
  },
}));

const ScoreInputField = ({
  score = false,
  minScore = 0,
  maxScore = 0,
  isHelperText = false,
  onChange = () => {},
  value,
  defaultValue,
  ...props
}) => {
  const [isError, setIsError] = useState(false);
  return (
    <FormControl
      variant="outlined"
      InputProps={{ inputProps: { min: 0, max: 10 } }}
      error={isError}
      {...props}
    >
      <OutlinedInput
        className="rounded-lg"
        id="outlined-adornment-score"
        value={value}
        defaultValue={defaultValue}
        onChange={(e) => {
          if (
            parseInt(e.target.value) >= minScore &&
            parseInt(e.target.value) <= maxScore
          ) {
            setIsError(false);
          } else {
            setIsError(true);
          }
          onChange(parseInt(e.target.value));
        }}
        endAdornment={
          <InputAdornment position="end">{` /${maxScore}`}</InputAdornment>
        }
        aria-describedby="outlined-score-helper-text"
        inputProps={{
          'aria-label': 'score',
        }}
        type="Number"
        {...props}
      />
      {isHelperText && !isError && (
        <FormHelperText id="outlined-score-helper-text">
          {`Min. score ${minScore}`}
        </FormHelperText>
      )}
      {isError && value < minScore && value !== '' && (
        <FormHelperText className="textRed">
          Min Score is {minScore}
        </FormHelperText>
      )}
      {isError && value > maxScore && value !== '' && (
        <FormHelperText className="textRed">
          Max Score is {maxScore}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default ScoreInputField;
