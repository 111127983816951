import Button from './Button';
import { ReactComponent as CalendarIcon } from '../../assets/svgs/calendar.svg';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from 'react';
import { useEffect } from 'react';

export const SelectDateButton = ({ value, ...props }) => {
  const [formattedDate, setFormattedDate] = useState('');

  const getFormattedDate = () => {
    if (value) {
      const date = new Date(value);
      const monthName = date.toLocaleString('en-US', { month: 'short' });
      const selectedDate = date.getDate();
      const selectedYear = date.getFullYear();
      setFormattedDate(monthName + ' ' + selectedDate + ', ' + selectedYear);
    }
  };

  useEffect(() => {
    getFormattedDate();
    //eslint-disable-next-line
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={value}
        renderInput={(params) => (
          <div ref={params.inputRef} className="inline-block">
            <Button
              variant="text"
              startIcon={
                <CalendarIcon
                  style={{
                    fill: '#1958CD',
                  }}
                />
              }
              onClick={() =>
                params.InputProps.endAdornment.props.children.props.onClick()
              }
              {...params}
            >
              {formattedDate === '' ? 'Select Date' : formattedDate}
            </Button>
          </div>
        )}
        style={{
          '.MuiOutlinedInput-notchedOutline': {
            span: {
              padding: '0px 10px !important',
            },
          },
        }}
        {...props}
      />
    </LocalizationProvider>
  );
};
