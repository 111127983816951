import { Avatar, Divider, IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import Button from '../shared/Button';
import Chip from '../shared/Chip';
import useHonorStore from '../../stores/HonorStore';
import { getFormattedStringFromArray } from '../../utils/helper';
import TeamMemberAvatarGroup from '../shared/TeamMemberAvatarGroup';
import Tooltip from '../shared/Tooltip';
import PersonIcon from '@mui/icons-material/Person';

const MyStoryHonorDocsFooter = ({
  id = '',
  grades = [],
  honorTypes = [],
  editIcon = false,
  reviewers = [],
  onEdit = () => {},
  isParent = false,
}) => {
  const honorStore = useHonorStore();
  const lastAvatarTitle = (
    <div className="flex flex-col gap-2">
      {reviewers.slice(-(reviewers.length - 2)).map((item) => (
        <li className="flex gap-2 text-xs items-center" key={item?.id}>
          <Avatar
            src={item?.photoURL}
            aria-label={item?.firstName + ' ' + item?.lastName}
            sx={{
              height: '16px',
              width: '16px',
            }}
          />
          <div>
            {item.firstName} {item.lastName}
          </div>
        </li>
      ))}
    </div>
  );

  const avatarTitle = (item) => {
    return (
      <div className="flex gap-2 text-xs items-center">
        <Avatar
          src={item?.photoURL}
          aria-label={item?.firstName + ' ' + item?.lastName}
          sx={{
            height: '16px',
            width: '16px',
          }}
        />
        <div>
          {item.firstName} {item.lastName}
        </div>
      </div>
    );
  };
  return (
    <div>
      {(grades.length > 0 || honorTypes.length > 0) && (
        <div className="flex gap-6 items-center">
          {grades && (
            <>
              <div className="flex font-medium gap-1 flex-col sm:flex-row">
                <div className="text-grey-900 text-sm">Year Details :</div>
                <div className="text-grey-400 text-sm whitespace-nowrap sm:whitespace-normal">{`${getFormattedStringFromArray(
                  grades
                )} Grade`}</div>
              </div>
              <Divider
                orientation="vertical"
                flexItem
                variant="middle"
                sx={{
                  borderColor: '#D6D6D8',
                }}
              />
            </>
          )}
          {honorTypes.length > 0 && (
            <div className="flex gap-4 items-center">
              {honorTypes.map((item) => (
                <Tooltip title="Honor Level" key={item.id}>
                  <div className="flex rounded-full">
                    <Chip
                      label={item}
                      sx={{
                        borderRadius: '50px',
                        paddingLeft: '2px',
                        paddingRight: '2px',
                        backgroundColor: '#EDEDEE',
                        fontWeight: '500',
                        color: '#22252D',
                        fontSize: '12px',
                      }}
                    />
                  </div>
                </Tooltip>
              ))}
            </div>
          )}
          <div className="flex-1 flex justify-end relative">
            <TeamMemberAvatarGroup>
              {reviewers.map((reviewer) => (
                <Tooltip title={avatarTitle(reviewer)} key={reviewer.id}>
                  <Avatar src={reviewer?.photoURL} />
                </Tooltip>
              ))}
            </TeamMemberAvatarGroup>
            {reviewers.length > 2 && (
              <Tooltip title={lastAvatarTitle}>
                <div className="h-7 w-7 text-grey-600 bg-grey-50 border-2 border-white text-xs font-medium absolute flex items-center justify-center top-px rounded-full">
                  +{reviewers.length - 2}
                </div>
              </Tooltip>
            )}
            {reviewers.length === 0 && (
              <Tooltip
                title={`${
                  !isParent ? (
                    <div>
                      Once you add your Team Members
                      <br /> they will appear here
                    </div>
                  ) : (
                    'Student is yet to add team member'
                  )
                }`}
              >
                <div className="h-7 w-7 text-grey-600 bg-grey-50 border-2 border-white flex items-center justify-center top-px rounded-full">
                  <PersonIcon
                    sx={{
                      height: '18px',
                    }}
                  />
                </div>
              </Tooltip>
            )}
          </div>
          {editIcon && (
            <Tooltip title="Edit Honor Details">
              <IconButton
                aria-label="Edit"
                onClick={onEdit}
                className="third-step !hidden sm:!flex"
              >
                <EditIcon className="cursor-pointer " />
              </IconButton>
            </Tooltip>
          )}
        </div>
      )}
      {grades.length === 0 && honorTypes.length === 0 && (
        <>
          {
            <div className="flex gap-5 text-sm">
              <Tooltip title="Add Details like what is your honor level">
                <div>
                  <Button
                    className="flex gap-2 items-center second-step"
                    variant="text"
                  >
                    <AddCircleIcon color="primary" />
                    <div
                      className="text-[#1958CD] font-medium cursor-pointer"
                      onClick={() => {
                        honorStore.setAddEditHonorId(id);
                        honorStore.setEditingHonors(true);
                      }}
                    >
                      Add More Details
                    </div>
                  </Button>
                </div>
              </Tooltip>
              <div className="flex gap-2 items-center text-grey-400">
                <InfoIcon />
                <div className="text-[#000000] opacity-80">
                  Details: like Level of Honor, Which Year
                </div>
              </div>
            </div>
          }
        </>
      )}
    </div>
  );
};

export default MyStoryHonorDocsFooter;
