import { Divider } from '@mui/material';
import MyStoryDocsCharacterGuide from '../MyStoryDocsCharacterGuide';

const characterGuideData = [
  {
    characterCount: 'Applications look the same',
    description: `Colleges receive tens of thousands of applications every year.  Many of them are from students who are academically qualified to attend that college.  However, most of them look the same, making it hard for admissions officers to pick the right students to make up their ideal class.
So how do you make your application stand out?  By highlighting your superpower, or “niche”. 
`,
  },
  {
    characterCount: 'What is a Niche',
    description: `A niche is a special skill or talent that will make you extra appealing to the admissions offices at highly selective institutions.  You could have several niches.  A niche can be anything from playing soccer to being a French literature fan`,
  },
  {
    characterCount: 'Demonstrate you pursued your Niche',
    description: `Your college applications need to show you have a passion you pursued through multiple, structured channels and that you achieved significant regional, national, or even international recognition in a particular field.  You will write about your niche in your essays, and support it with your activities, honors, and courses related to that niche.`,
  },
  {
    characterCount: 'Tracking it all in Universily ',
    description: `Universily helps you determine your superpower by organizing the elements of your story into niches.  The system tracks everything you have done, like courses, activities, and honors, and groups them into niches.  You can create multiple niches to track the different things you have done and focus your college applications on the niche that tells the most unique story. `,
  },
];

export const NicheDocsGuidelines = ({ isSelectedInfo = true }) => {
  return (
    <>
      {isSelectedInfo && (
        <div className="flex flex-col gap-6 p-6">
          <div className="text-sm text-[#22252D]">Tips for Niches</div>
          <div className="flex flex-col gap-4">
            {characterGuideData?.map((item, index) => (
              <>
                <MyStoryDocsCharacterGuide
                  characterCount={item.characterCount}
                  description={item.description}
                  key={item?.id}
                />
                {characterGuideData.length - 1 !== index && (
                  <Divider
                    className="opacity-20 -mx-3 border-t"
                    sx={{
                      borderColor: '#BABAAE',
                    }}
                  />
                )}
              </>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
