import TabPanel from '@mui/lab/TabPanel';
import Tab from '../../shared/Tab';
import Tabs from '../../shared/Tabs';
import TabContext from '@mui/lab/TabContext';
import { ReactComponent as TextIcon } from '../../../assets/svgs/a-text.svg';
import { useState } from 'react';
import { Divider } from '@mui/material';

const AddDetailsPopupDocs = ({ tabData = [] }) => {
  const [tabValue, setTabValue] = useState(0);
  return (
    <div className="px-2 flex-1">
      <TabContext value={tabValue}>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => {
            e.stopPropagation();
            setTabValue(newValue);
          }}
        >
          {tabData.map((item, index) => (
            <Tab key={index} label={item.label} icon={<TextIcon />} />
          ))}
        </Tabs>
        <Divider
          sx={{
            borderColor: '#EAEAEB',
            marginTop: '-1px',
          }}
        />

        <TabPanel
          value={tabValue}
          sx={{
            color: '#22252D',
            padding: '16px 20px !important',
          }}
        >
          {tabData[tabValue]?.content === ''
            ? 'No Details Added Yet'
            : tabData[tabValue]?.content}
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default AddDetailsPopupDocs;
