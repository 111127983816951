import React from 'react';
import Chip from '../../../shared/Chip';
import Tooltip from '../../../shared/Tooltip';
import { GenericMenu } from '../../../shared/GenericMenu/GenericMenu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { GenericTabs } from '../../../shared/GenericTabs/GenericTabs';
import { ChevronRightOutlined } from '@mui/icons-material';
import { useMyStoryStore } from '../../../../stores/myStoryStore';
import { useShallow } from 'zustand/react/shallow';
import useActivityStore from '../../../../stores/ActivityStore';
import { MyActivitySlideOutTips } from './myActivitySlideOutTips';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import { MyActivitySlidOutSettings } from './myActivitySlideOutSettings';
import { MyActivitySlideOutNotes } from './myActivitySlideOutNotes';
import MenuItem from '../../../shared/MenuItem';
import TeamMemberAvatarGroup from '../../../shared/TeamMemberAvatarGroup';
import { Avatar, IconButton } from '@mui/material';
import { avatarTitle } from '../../../shared/TeamMembersGroup';
import useMyTeamStore from '../../../../stores/MyTeamStore';
import IosShareIcon from '@mui/icons-material/IosShare';
import {
  UTILITY_TYPES,
  filterUniAndTeamUniversily,
} from '../../../../utils/utlityTypes';
import useMyHubStore from '../../../../stores/MyHubStore';
export const MyActivitySlideOut = () => {
  const tabs = [
    { label: 'Notes', content: <MyActivitySlideOutNotes /> },
    { label: 'Details', content: <MyActivitySlidOutSettings /> },
    { label: 'Tips', content: <MyActivitySlideOutTips /> },
  ];
  const { setStorySlideOut, setActiveSlideOutTabIndex } = useMyStoryStore(
    useShallow((state) => ({
      setStorySlideOut: state.setStorySlideOut,
      setActiveSlideOutTabIndex: state.setActiveSlideOutTabIndex,
    }))
  );

  const myTeamStore = useMyTeamStore(
    useShallow((state) => ({
      myTeam: state.myTeam,
    }))
  );
  const myHubStore = useMyHubStore(
    useShallow((state) => ({
      isShareUtility: state.isShareUtility,
      utilityId: state.utilityId,
      setIsShareUtility: state.setIsShareUtility,
      setUtilityId: state.setUtilityId,
      setUtilityType: state.setUtilityType,
      setUtilityName: state.setUtilityName,
    }))
  );

  const {
    addEditActivityId,
    activitiesById,
    setIsCopyingActivity,
    setCopyActivityId,
    setIsDeletingActivity,
    setDeleteActivityId,
  } = useActivityStore(
    useShallow((state) => ({
      activities: state.activities,
      addEditActivityId: state.addEditActivityId,
      activitiesById: state.activitiesById,
      setCopyActivityId: state.setCopyActivityId,
      setIsCopyingActivity: state.setIsCopyingActivity,
      setDeleteActivityId: state.setDeleteActivityId,
      setIsDeletingActivity: state.setIsDeletingActivity,
    }))
  );

  const menuItems = [
    {
      label: 'Copy',
      icon: <MenuItem.CopyIcon />,
      action: () => {
        setIsCopyingActivity(true);
        setCopyActivityId(addEditActivityId);
        setStorySlideOut(false);
      },
    },
    {
      label: 'Delete',
      icon: <MenuItem.DeleteIcon />,
      action: () => {
        setIsDeletingActivity(true);
        setDeleteActivityId(addEditActivityId);
        setStorySlideOut(false);
      },
    },
  ];

  const activity = activitiesById[addEditActivityId];
  const teamMembers = myTeamStore.myTeam.filter((item) =>
    activity?.sharedWith?.includes(item.id)
  );
  return (
    <div className=" h-full flex flex-col">
      <div className="bg-[#F6F6F6] px-4 py-4 flex justify-between font-roboto items-center">
        <span className="font-semibold text-base font-roboto flex gap-3">
          <ChevronRightOutlined
            onClick={() => {
              setStorySlideOut(false);
              setActiveSlideOutTabIndex(0);
            }}
            className="cursor-pointer"
          />
          {activity?.activityName}
        </span>

        <div className="flex items-center gap-2">
          <TeamMemberAvatarGroup
            handleClick={() => {
              setActiveSlideOutTabIndex(1);
            }}
          >
            {filterUniAndTeamUniversily(teamMembers)?.map((reviewer) => (
              <Tooltip title={avatarTitle(reviewer)}>
                <Avatar src={reviewer?.photoURL} />
              </Tooltip>
            ))}
          </TeamMemberAvatarGroup>
          <Chip
            label="Activities"
            color="purple"
            icon={<PedalBikeIcon height={10} />}
            className="font-medium"
          />
          <IconButton
              aria-label="Share"
              onClick={()=> {
                myHubStore.setIsShareUtility(true);
                myHubStore.setUtilityId(addEditActivityId);
                myHubStore.setUtilityType(UTILITY_TYPES.Activities);
                myHubStore.setUtilityName(
                  activitiesById[addEditActivityId]?.activityName
                );
              }}
            >
            <IosShareIcon style={{fill: '#838489'}} />
          </IconButton>
          <Tooltip title="More Actions">
            <GenericMenu buttonLabel={<MoreVertIcon />} menuItems={menuItems} />
          </Tooltip>
          
        </div>
      </div>
      {/* Body  */}
      <GenericTabs tabs={tabs} />
    </div>
  );
};
