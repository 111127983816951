// For some reason, the imported svg files from figma were skewed
import { ReactComponent as ActivityCircularIcon } from '../../../assets/svgs/ActivityCircularIcon.svg';
import { ReactComponent as HonorCircularIcon } from '../../../assets/svgs/HonorCircularIcon.svg';
import { ReactComponent as CourseCircularIcon } from '../../../assets/svgs/CourseCircularIcon.svg';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useRef} from 'react';
import useHonorStore from '../../../stores/HonorStore';
import useActivityStore from '../../../stores/ActivityStore';
import { useShallow } from 'zustand/react/shallow';
import { useMyStoryStore } from '../../../stores/myStoryStore';
import { UTILITY_TYPES } from '../../../utils/utlityTypes';
import useCourseStore from '../../../stores/CourseStore';

export const NICHE_CHIP_TYPES = {
  HONOR: 'HONOR',
  COURSE: 'COURSE',
  ACTIVITY: 'ACTIVITY',
};

const typeToIconMap = {
  HONOR: <HonorCircularIcon />,
  COURSE: <CourseCircularIcon />,
  ACTIVITY: <ActivityCircularIcon />,
};

const NicheChip = ({
  utilityData = {},
  nicheName = '',
  isViewDetails = false,
  isParent = false,
  isMobile = false,
  ...props
}) => {
  const button = useRef();

  const activityStore = useActivityStore();
  const honorStore = useHonorStore();
  const courseStore = useCourseStore(
    useShallow((state) => ({
      setAddEditCourseId: state.setAddEditCourseId,
    }))
  );
  const { setActiveStoryType, setStorySlideOut } = useMyStoryStore(
    useShallow((state) => ({
      setActiveStoryType: state.setActiveStoryType,
      setStorySlideOut: state.setStorySlideOut,
    }))
  );

  const handleNicheChipOnClick = () => {
    // Ensure utilityData and its type property exist before proceeding
    if (!utilityData?.type) return;

    // Destructure id and type from utilityData
    const { id, type } = utilityData;

    // Define an object mapping each type to its corresponding action
    const slideOutSettings = {
      ACTIVITY: () => {
        activityStore.setAddEditActivityId(id); // Set the activity ID for editing
        setActiveStoryType(UTILITY_TYPES.Activities); // Set the active story type to "Activities"
      },
      HONOR: () => {
        honorStore.setAddEditHonorId(id); // Set the honor ID for editing
        setActiveStoryType(UTILITY_TYPES.Honors); // Set the active story type to "Honors"
      },
      COURSE: () => {
        courseStore.setAddEditCourseId(id); // Set the course ID for editing
        setActiveStoryType(UTILITY_TYPES.Courses); // Set the active story type to "Courses"
      },
    };

    // Retrieve the action for the current type
    const handleSlideOut = slideOutSettings[type];

    // If a valid action exists, execute it and trigger the story slide-out
    if (handleSlideOut) {
      handleSlideOut();
      setStorySlideOut(true);
    }
  };

  return (
    <>
      {utilityData?.name && (
        <div
          className={`flex cursor-pointer items-center p-2 pr-4 gap-4 rounded-full border hover:shadow ${
            !isViewDetails ? 'group bg-[#FCFCFB]' : 'cursor-pointer bg-white'
          } 
           ${
             utilityData?.docInformation &&
             ((utilityData?.docInformation?.length === 1 &&
               utilityData?.docInformation[0]?.charCount === 0) ||
               (utilityData?.docInformation[0]?.charCount === 0 &&
                 utilityData?.docInformation[1]?.charCount === 0)) &&
             'border-[#BEC8FB] bg-cream-50 shadow-sm'
           }

          `}
          ref={button}
          onClick={(event) => {
            if (!isMobile) {
              event.stopPropagation();
              handleNicheChipOnClick();
            }
          }}
          {...props}
        >
          <div>{typeToIconMap[utilityData?.type]}</div>
          <div className="flex-1 font-archivo text-[#22252D] text-sm text-ellipsis overflow-hidden whitespace-nowrap">
            {utilityData?.name}
          </div>
          {/* {!isParent && ( */}
          <div
            className={`items-center cursor-pointer hover:underline text-[#1449AB] text-xs hidden  text-ellipsis overflow-hidden whitespace-nowrap
            ${!isViewDetails && 'group-hover:sm:flex hidden'}`}
          >
            {(utilityData?.docInformation &&
              !isParent &&
              ((utilityData?.docInformation?.length === 1 &&
                utilityData?.docInformation[0]?.charCount === 0) ||
                (utilityData?.docInformation[0]?.charCount === 0 &&
                  utilityData?.docInformation[1]?.charCount === 0))) ||
            utilityData?.description === '' ? (
              <div> Add Details</div>
            ) : (
              <div>View Details</div>
            )}

            <KeyboardArrowRightIcon fontSize="small" />
          </div>
          {/* )} */}
        </div>
      )}
    </>
  );
};

export default NicheChip;
