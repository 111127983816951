import React from 'react';
import Chip from '../../../shared/Chip';
import Tooltip from '../../../shared/Tooltip';
import { GenericMenu } from '../../../shared/GenericMenu/GenericMenu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { GenericTabs } from '../../../shared/GenericTabs/GenericTabs';
import { ChevronRightOutlined } from '@mui/icons-material';
import { useMyStoryStore } from '../../../../stores/myStoryStore';
import { useShallow } from 'zustand/react/shallow';
import { MyHonorSlideOutTips } from './myHonorSlideOutTips';
import IosShareIcon from '@mui/icons-material/IosShare';
import { MyHonorSlidOutSettings } from './myHonorSlideOutSettings';
import { MyHonorSlideOutNotes } from './myHonorSlideOutNotes';
import MenuItem from '../../../shared/MenuItem';
import { ReactComponent as TrophyIcon } from '../../../../assets/svgs/trophy.svg';
import TeamMemberAvatarGroup from '../../../shared/TeamMemberAvatarGroup';
import { Avatar, IconButton } from '@mui/material';
import { avatarTitle } from '../../../shared/TeamMembersGroup';
import useMyTeamStore from '../../../../stores/MyTeamStore';
import {
  UTILITY_TYPES,
  filterUniAndTeamUniversily,
} from '../../../../utils/utlityTypes';
import useMyHubStore from '../../../../stores/MyHubStore';
import useHonorStore from '../../../../stores/HonorStore';
export const MyHonorSlideOut = () => {
  const tabs = [
    { label: 'Notes', content: <MyHonorSlideOutNotes /> },
    { label: 'Details', content: <MyHonorSlidOutSettings /> },
    { label: 'Tips', content: <MyHonorSlideOutTips /> },
  ];
  const { setStorySlideOut, setActiveSlideOutTabIndex } = useMyStoryStore(
    useShallow((state) => ({
      setStorySlideOut: state.setStorySlideOut,
      setActiveSlideOutTabIndex: state.setActiveSlideOutTabIndex,
    }))
  );

  const myTeamStore = useMyTeamStore(
    useShallow((state) => ({
      myTeam: state.myTeam,
    }))
  );
  const myHubStore = useMyHubStore(
    useShallow((state) => ({
      isShareUtility: state.isShareUtility,
      utilityId: state.utilityId,
      setIsShareUtility: state.setIsShareUtility,
      setUtilityId: state.setUtilityId,
      setUtilityType: state.setUtilityType,
      utilityType: state.utilityType,
      setUtilityName: state.setUtilityName,
    }))
  );

  const {
    addEditHonorId,
    honorsById,
    setCopyHonorsId,
    setDeleteHonorId,
    setIsCopyingHonors,
    setIsDeletingHonors,
  } = useHonorStore(
    useShallow((state) => ({
      addEditHonorId: state.addEditHonorId,
      honorsById: state.honorsById,
      setCopyHonorsId: state.setCopyHonorsId,
      setIsCopyingHonors: state.setIsCopyingHonors,
      setDeleteHonorId: state.setDeleteHonorId,
      setIsDeletingHonors: state.setIsDeletingHonors,
    }))
  );
  const menuItems = [
    {
      label: 'Copy',
      action: () => {
        setIsCopyingHonors(true);
        setCopyHonorsId(addEditHonorId);
        setStorySlideOut(false);
      },
    },
    {
      label: 'Delete',
      action: () => {
        setIsDeletingHonors(true);
        setDeleteHonorId(addEditHonorId);
        setStorySlideOut(false);
      },
    },
  ];
  const { honorName, sharedWith } = honorsById[addEditHonorId];

  const teamMembers = myTeamStore.myTeam.filter((item) =>
    sharedWith.includes(item.id)
  );
  return (
    <div className=" h-full flex flex-col">
      <div className="bg-[#F6F6F6] px-4 py-4 flex justify-between font-roboto items-center">
        <span className="font-semibold text-base font-roboto flex gap-3">
          <ChevronRightOutlined
            onClick={() => {
              setStorySlideOut(false);
              setActiveSlideOutTabIndex(0);
            }}
            className="cursor-pointer"
          />
          {honorName}
        </span>

        <div className="flex items-center gap-2">
          <TeamMemberAvatarGroup
            handleClick={() => {
              setActiveSlideOutTabIndex(1);
            }}
          >
            {filterUniAndTeamUniversily(teamMembers)?.map((reviewer) => (
              <Tooltip title={avatarTitle(reviewer)}>
                <Avatar src={reviewer?.photoURL} />
              </Tooltip>
            ))}
          </TeamMemberAvatarGroup>
          <Chip
            label="Honors"
            color="yellow"
            icon={<TrophyIcon />}
            className="font-medium"
          />
          <IconButton
              aria-label="Share"
              onClick={()=> {
                myHubStore.setIsShareUtility(true);
                myHubStore.setUtilityId(addEditHonorId);
                myHubStore.setUtilityType(UTILITY_TYPES.Honors);
                myHubStore.setUtilityName(honorsById[addEditHonorId].honorName);
              }}
            >
            <IosShareIcon style={{fill: '#838489'}} />
          </IconButton>
          <Tooltip title="More Actions">
            <GenericMenu buttonLabel={<MoreVertIcon />} menuItems={menuItems} />
          </Tooltip>
        </div>
      </div>
      {/* Body  */}
      <GenericTabs tabs={tabs} />
    </div>
  );
};
