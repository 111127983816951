import React from 'react';
import { Tabs, Tab } from '@mui/material';
import { useMyStoryStore } from '../../../stores/myStoryStore';

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="flex-1 overflow-auto"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div className="h-full"> {children}</div>}
    </div>
  );
};

export const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const GenericTabs = ({ tabs }) => {
  const activeSlideOutTabIndex = useMyStoryStore(
    (state) => state.activeSlideOutTabIndex
  );
  const setActiveSlideOutTabIndex = useMyStoryStore(
    (state) => state.setActiveSlideOutTabIndex
  );

  const handleChange = (event, newValue) => {
    setActiveSlideOutTabIndex(newValue);
  };

  return (
    <div className="flex flex-col flex-1 overflow-auto ">
      <Tabs
        className="border shadow-bottom"
        value={activeSlideOutTabIndex}
        onChange={handleChange}
        aria-label="simple tabs"
      >
        {tabs.map((tab, index) => (
          <Tab label={tab.label} key={index} {...a11yProps(index)} />
        ))}
      </Tabs>
      {tabs.map((tab, index) => (
        <TabPanel value={activeSlideOutTabIndex} index={index} key={index}>
          {tab.content}
        </TabPanel>
      ))}
    </div>
  );
};
