import { useState } from 'react';
import EditUtilityDetails from '../../shared/EditUtilityDetails';

const EditHonorDetails = ({
  niches = [],
  onContinue = () => {},
  data,
  disabled = false,
  setDisabled = () => {},
  ...args
}) => {
  const [honorData, setHonorData] = useState(data);
  const onChange = (honorData) => {
    setHonorData(honorData);
  };

  return (
    <EditUtilityDetails
      {...args}
      honorData={data}
      title="Edit Honor and Niche Name"
      label="Honor"
      disabled={disabled}
      onChange={onChange}
      onContinue={() => {
        if (disabled) return;

        setDisabled(true);
        onContinue({
          ...(data.id && { id: data.id }),
          ...honorData,
        });
      }}
      niches={niches}
    />
  );
};

export default EditHonorDetails;
