import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import RemoveIcon from '@mui/icons-material/Remove';

const activityListData = [
  { listItem: 'List activities in order of importance.' },
  {
    listItem:
      'After the title, put the grade and the position. For summer activities put the year and duration in weeks.',
  },
  { listItem: 'Use a natural tone of voice.' },
  { listItem: 'Be specific and do not use generalizations.' },
  { listItem: 'Do not list opinions or self praise.' },
  { listItem: 'Highlight if you were selected for a position or award.' },
  {
    listItem:
      'Once you add a Team Member they will be able to access and comment on your Activities.',
  },
];

const ActivityDocsTipsPanel = () => {
  const [expanded, setExpanded] = useState(true);
  return (
    <div
      className="flex bg-[#1D2E66] flex-col  text-white rounded-lg p-5 gap-3 cursor-pointer"
      onClick={() => {
        setExpanded(!expanded);
      }}
    >
      <div className=" flex justify-between font-medium">
        <div> Important Tips</div>
        {!expanded ? (
          <div>
            <AddIcon sx={{ color: '#ffffff' }} />
          </div>
        ) : (
          <div>
            <RemoveIcon sx={{ color: '#ffffff' }} />
          </div>
        )}
      </div>
      {expanded && (
        <div
          className={`${expanded && 'transition duration-500 ease-in-out'} `}
        >
          <div className="text-xs">
            Here’s where you’ll briefly describe your activity. Focus on
            significant and quantifiable impact you may have had.
          </div>
          <ul className="text-[#C1C7D0] text-[13px] tracking-xs leading-5 list-disc text-start marker:text-white pt-2">
            {activityListData?.map((item, index) => (
              <li className="ml-3 text-[13px]" key={index}>
                {item.listItem}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ActivityDocsTipsPanel;
