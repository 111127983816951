import { Divider } from '@mui/material';
import MyStoryDocsCharacterGuide from '../MyStoryDocsCharacterGuide';
import { useState } from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const characterGuideData = [
  {
    characterCount: '100 Characters',
    description: `Most colleges, including those that use the
          Common App, allow 100 characters for your 
          Honors description.`,
  },
  {
    characterCount: '250 Characters',
    description: `Some colleges (like the UC's) allow
          250 characters. `,
  },
];
const HonorListData = [
  {
    id: 0,
    listItem:
      ' Briefly describe your award with the award name, the level (local, state, national) and what you did to earn it.',
  },
  {
    id: 1,
    listItem:
      '  Once you add a Team Member they will be able to access and comment on your Honors.',
  },
];

const HonorDocsGuidelines = () => {
  const [expanded, setExpanded] = useState(true);
  return (
    <div
      className="flex flex-col gap-6 p-6 cursor-pointer"
      onClick={() => {
        setExpanded(!expanded);
      }}
    >
      <div className="text-sm text-[#22252D]">Tips For Honor Details</div>
      <div className="flex flex-col gap-4">
        {characterGuideData.map((item, index) => (
          <>
            <MyStoryDocsCharacterGuide
              characterCount={item.characterCount}
              description={item.description}
            />
          </>
        ))}
      </div>
      <Divider
        className="opacity-20 -mx-3 border-t"
        sx={{
          borderColor: '#BABAAE',
        }}
      />
      <div className="flex bg-[#1D2E66] flex-col  text-white rounded-lg p-5 gap-3">
        <div className="flex justify-between font-medium">
          <div> Important Tips</div>
          {!expanded ? (
            <div>
              <AddIcon sx={{ color: '#ffffff' }} />
            </div>
          ) : (
            <div>
              <RemoveIcon sx={{ color: '#ffffff' }} />
            </div>
          )}
        </div>
        {expanded && (
          <div
            className={`${expanded && 'transition duration-500 ease-in-out'} `}
          >
            <div className="text-xs">
              {/* Here’s where you’ll briefly describe your honor. Focus on
              significant and quantifiable impact you may have had. */}
            </div>
            <ul className="text-[#C1C7D0] text-[13px] tracking-xs leading-5 list-disc text-start marker:text-white pt-2">
              {HonorListData?.map((item, index) => (
                <li className="ml-3 text-[13px]" key={item?.id}>
                  {item.listItem}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default HonorDocsGuidelines;
