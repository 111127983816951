// col-start-1 col-end-1
// col-start-2 col-end-2
// col-start-3 col-end-3
// col-start-4 col-end-4
// col-start-5 col-end-5
// col-start-6 col-end-6
// col-start-7 col-end-7

import { useEffect } from 'react';
import useNicheStore from '../../../stores/NicheStore';
import NicheChip from './NicheChip';

// const gradesOrder = ['9th', '10th', '11th', '12th', '12th+'];

const NicheLaneGantt = ({
  items = [],
  nicheName = '',
  isViewDetails = false,
  unAssignedLane,
  isParent = false,
  isMobile = false,
}) => {
  useEffect(() => {});
  // eslint-disable-next-line no-unused-vars
  const sort = items
    .filter((item) => item?.grades?.length > 0)
    .map((item) => {
      item.sortedGrades = item?.grades.map((g) => {
        if (g === '12th+') {
          return Number(g?.slice(0, -3)) + 1;
        } else {
          return Number(g?.slice(0, -2));
        }
      });
      item?.sortedGrades.sort((a, b) => a - b);
      return item;
    });

  const unAssignedItems = items.filter((item) => item.grades?.length === 0);

  let resultArray = [];
  let flag = true;
  // eslint-disable-next-line no-unused-vars
  const assignedItems = items
    .filter((item) => item.grades?.length > 0)
    .map((item) => {
      let tempObj = { ...item, startCol: null, endCol: null };
      for (let i = 0; i < item?.sortedGrades?.length; i++) {
        if (flag) {
          tempObj = { ...tempObj, startCol: item?.sortedGrades[i] - 7 };
        }
        if (item?.sortedGrades.includes(item?.sortedGrades[i] + 1)) {
          flag = false;
          continue;
        } else {
          flag = true;
        }
        tempObj = { ...tempObj, endCol: item?.sortedGrades[i] - 6 };

        resultArray = [...resultArray, tempObj];
      }

      return resultArray;
    });

  const nicheStore = useNicheStore();
  return (
    <>
      <div className="p-2">
        <div className="grid grid-cols-6 gap-y-2 gap-x-4">
          {unAssignedItems.map((item) => {
            return (
              <div key={item.id} className="col-start-1 col-end-1 z-50">
                {nicheName === 'Unassigned' ? (
                  <NicheChip
                    utilityData={item}
                    nicheName={nicheName}
                    isViewDetails={isViewDetails}
                    isParent={isParent}
                    isMobile={isMobile}
                  />
                ) : (
                  <NicheChip
                    utilityData={item}
                    nicheName={nicheName}
                    isViewDetails={isViewDetails}
                    isParent={isParent}
                    isMobile={isMobile}
                  />
                )}
              </div>
            );
          })}
          {resultArray?.map((item) => (
            <div
              key={item.id}
              className={`col-start-${item.startCol} col-end-${item.endCol} z-50`}
            >
              {nicheName === 'Unassigned' ? (
                <>
                  {nicheStore.activityFilterByType === 'ACTIVITY' && (
                    <NicheChip
                      utilityData={item.type === 'ACTIVITY' ? item : {}}
                      nicheName={nicheName}
                      isViewDetails={isViewDetails}
                      isParent={isParent}
                      isMobile={isMobile}
                    />
                  )}
                  {nicheStore.honorFilterByType === 'HONOR' && (
                    <NicheChip
                      utilityData={item.type === 'HONOR' ? item : {}}
                      nicheName={nicheName}
                      isViewDetails={isViewDetails}
                      isParent={isParent}
                      isMobile={isMobile}
                    />
                  )}
                  {nicheStore.courseFilterByType === 'COURSE' && (
                    <NicheChip
                      utilityData={item.type === 'COURSE' ? item : {}}
                      nicheName={nicheName}
                      isViewDetails={isViewDetails}
                      isParent={isParent}
                      isMobile={isMobile}
                    />
                  )}
                  {nicheStore.courseFilterByType === '' &&
                    nicheStore.activityFilterByType === '' &&
                    nicheStore.honorFilterByType === '' && (
                      <NicheChip
                        utilityData={item}
                        nicheName={nicheName}
                        isViewDetails={isViewDetails}
                        isParent={isParent}
                        isMobile={isMobile}
                      />
                    )}
                </>
              ) : (
                <NicheChip
                  utilityData={item}
                  nicheName={nicheName}
                  isViewDetails={isViewDetails}
                  isParent={isParent}
                  isMobile={isMobile}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default NicheLaneGantt;
