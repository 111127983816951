import { AddCircle } from '@mui/icons-material';
import Button from './Button';
import Tooltip from './Tooltip';
import ToggleButton from './ToggleButton';
import ToggleButtonGroup from './ToggleButtonGroup';
import useNicheStore from '../../stores/NicheStore';

const ChooseNiche = ({
  niches = [],
  data = {},
  selectedNiche = 'None',
  utilityName = '',
  onChange = () => {},
  onNicheAdd = () => {},
}) => {
  const nicheStore = useNicheStore();
  const tooltipTitle = (
    <div className="text-sm">
      A niche is an area in which you have a special interest, talent, or
      experience. A niche can be anything from soccer to being an enthusiastic
      French geek. The combination of your classes, activities and honors will
      eventually lead you to the niches, you'll emphasize in your college
      applications.
    </div>
  );
  return (
    <div className="flex flex-col gap-3">
      <div className="text-grey-900 opacity-50 text-xs">
        Choose your niche (Optional)
      </div>
      <div className="flex flex-col gap-3 items-start flex-wrap overflow-y-auto w-full whitespace-nowrap">
        <ToggleButtonGroup
          value={selectedNiche[0] === '' ? 'None' : selectedNiche}
          size="small"
          className="w-fit"
          onChange={onChange}
          exclusive
          aria-label="Niches"
        >
          <ToggleButton key="None" value="None">
            None
          </ToggleButton>
          {/* <ToggleButton
            key="Art"
            value="Art"
          >
            Art
          </ToggleButton>
          <ToggleButton
            key="Photography"
            value="Photography"
          >
            Photography
          </ToggleButton> */}

          {niches.length > 0 &&
            niches.map((niche) => (
              <ToggleButton
                disableFocusRipple
                disableRipple
                key={niche.value}
                value={niche.value}
                sx={{
                  width: 'fit-content',
                }}
              >
                {niche.label}
              </ToggleButton>
            ))}
        </ToggleButtonGroup>

        <Tooltip title={tooltipTitle} placement="right-start">
          <div>
            <Button
              variant="text"
              className="shrink-0"
              onClick={() => {
                nicheStore.setIsSelectTeamMember(false);
                nicheStore.setUtilityName(utilityName);
                nicheStore.setIsAddingNiche(true);
              }}
              startIcon={<AddCircle />}
            >
              Add Niche
            </Button>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default ChooseNiche;
