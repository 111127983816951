import { useEffect, useState } from 'react';
import MyStoryActivityDocs from '../components/myStory/activities/MyStoryActivityDocs';
import useActivityStore from '../stores/ActivityStore';
import useNicheStore from '../stores/NicheStore';
import { updateStoryItem } from '../firebase/services/updateServices';
import { addStoryItemTypes, templateType } from '../constants/other';
import { useGoogleDocs } from '../hooks';
import { googleDriveFolders } from '../constants/other';
import useMyTeamStore from '../stores/MyTeamStore';
import useProfileStore from '../stores/ProfileStore';
import { useNavigate } from 'react-router-dom';
import useTemplateStore from '../stores/TemplateStore';
import { activityDocCharVersion } from '../constants/staticData';
import SuccessSnackbar from '../components/shared/SuccessSnackbar';
import useThemeStore from '../stores/ThemeStore';
import { useShallow } from 'zustand/react/shallow';
import useStudentStore from '../stores/StudentStore';
import useTeamStore from '../stores/TeamStore';

const steps = [
  {
    target: '.first-step',
    content: (
      <div>
        Use this area to track your extracurricular activities. The Common App
        will only give you 150 characters for the description so make them
        count! <br />
        When asked whether or not you intend to participate in a similar
        activity in college, check “yes” whenever possible. Don’t worry if
        you’re not ready to add the description yet, you can always add it
        later.
      </div>
    ),
    disableBeacon: true,
    placement: 'right',
    arrow: false,
  },
  {
    target: '.second-step',
    content: (
      <div>
        Add other Details about Activities here like Activity Type, Which Year &
        Timeframes
      </div>
    ),
    disableBeacon: true,
    placement: 'top',
    arrow: true,
  },
  {
    target: '.third-step',
    content: (
      <div>
        You can edit Activities here like Activity Type, Which Year & Timeframes
      </div>
    ),
    disableBeacon: true,
    placement: 'top',
    arrow: true,
  },
  {
    target: '.fifth-step',
    content: <div>You can edit the title and niche here</div>,
    disableBeacon: true,
    placement: 'top',
    arrow: true,
  },
  {
    target: '.sixth-step',
    content: (
      <div>
        You can make a copy of your Activity directly just with a single click.
      </div>
    ),
    disableBeacon: true,
    placement: 'bottom',
    arrow: true,
  },
  {
    target: '.seventh-step',
    content: (
      <div>
        You can collaborate with your teachers, counselors, family members from
        here.
      </div>
    ),
    disableBeacon: true,
    placement: 'bottom',
    arrow: true,
  },
];

const MyStoryActivityDocsContainer = ({
  showNoGoogleAccountSnackbar = () => {},
  isParent = false,
  canCreateNewUtilities = () => {},
}) => {
  const activityStore = useActivityStore();
  const nicheStore = useNicheStore();
  const googleDocs = useGoogleDocs();
  const templateStore = useTemplateStore();
  const profileStore = useProfileStore(
    useShallow((state) => {
      return {
        profileLogin: state.profileLogin,
        isParent: state.isParent,
      };
    })
  );
  const navigate = useNavigate();
  const addingOrEditingDocs = useActivityStore(
    (state) => state.addingOrEditingDocs
  );
  const myTeamStore = useMyTeamStore();

  const activities = useActivityStore((state) => state.activities);
  const [activity, setActivity] = useState({});
  const [loadingState, setLoadingState] = useState('');
  const { isMobile } = useThemeStore();
  const activeStudentReviewers = useStudentStore(
    (state) => state.activeStudentReviewers
  );
  const selectedTeam = useTeamStore((state) => state.selectedTeam);
  const [isSuccessSnackbar, setIsSuccessSnackbar] = useState({
    open: false,
    message: '',
  });
  useEffect(() => {
    const activity =
      activityStore.activitiesById[activityStore.addEditActivityId] || {};
    setActivity(activity);
    //eslint-disable-next-line
  }, [activities, addingOrEditingDocs]);
  // Listening to activities as well because of onAddTabBtnClick

  async function onTabAddClick(version) {
    console.log('onTabAddClick', version);
    setLoadingState(version);
    if (profileStore.profileLogin?.loggedInType === 'google') {
      let templateString = await googleDocs.googleDocData(
        templateStore.templateData,
        templateType.ACTIVITY
      );
      const activityDoc = await googleDocs.createGoogleDoc(
        `${activity.activityName} (${version})`,
        googleDriveFolders.ACTIVITY,
        templateString || ''
      );
      if (activityDoc?.webViewLink) {
        const docInfo =
          version === activityDocCharVersion.CHAR_150
            ? { ...activity.docs, 150: activityDoc.webViewLink }
            : { ...activity.docs, 350: activityDoc.webViewLink };
        await updateStoryItem(
          {
            ...activity,
            docs: docInfo,
          },
          addStoryItemTypes.ACTIVITY
        );
        await googleDocs.giveTeamMembersAccessToDocs(
          activityDoc?.id,
          myTeamStore.myTeam
            .filter(({ id }) => activity.reviewers?.includes(id))
            .map((teamMember) => teamMember?.email)
        );
        setLoadingState('');
      } else {
        const res = await googleDocs.requestScopeAccessFromUser();
        if (res === 'error') {
          setIsSuccessSnackbar({
            open: true,
            message:
              'Google Drive and Docs was not granted try adding documents later.',
          });
        } else {
          setIsSuccessSnackbar({
            open: true,
            message: 'You can start creating your Activity Google Documents.',
          });
        }
        setLoadingState('');
        return res;
      }
    } else {
      showNoGoogleAccountSnackbar();
      setLoadingState('');
    }
  }
  const studentViewActivityReviewerData = myTeamStore.myTeam.filter(
    // eslint-disable-next-line array-callback-return
    ({ id, email, photoURL, firstName, lastName }) => {
      if (activity.reviewers?.includes(id)) {
        return {
          id,
          email,
          photoURL,
          firstName,
          lastName,
        };
      }
    }
  );
  const parentViewActivityReviewerData = activeStudentReviewers.filter(
    // eslint-disable-next-line array-callback-return
    ({ reviewerDocId, email, photoURL, firstName, lastName }) => {
      if (activity.reviewers?.includes(reviewerDocId)) {
        return {
          id: reviewerDocId,
          email,
          photoURL,
          firstName,
          lastName,
        };
      }
    }
  );

  return (
    <>
      <MyStoryActivityDocs
        id={activity.id}
        title={activity.activityName}
        nicheName={nicheStore.getNicheById(activity.nicheId)?.nicheName}
        grades={activity.grades}
        durationHoursPerWeek={activity.hoursPerWeek}
        durationWeeksPerYear={activity.weeksPerYear}
        isParent={isParent}
        docInformation={activity.docInformation}
        activityTypes={activity.activityTypes?.map((type) => type.label)}
        onBack={() => {
          //activityStore.setAddEditActivityId(null);
          if (activityStore.isAllActivitiesPageOpen) {
            navigate(`/${selectedTeam?.id}/all-activities`);
            activityStore.setAddingOrEditingDocs(false);
          } else {
            activityStore.setAddingOrEditingDocs(false);
          }
        }}
        onAddTabBtnClick={(version) => onTabAddClick(version)}
        isMobile={isMobile}
        docs={Object.values(activity.docs || {})}
        docsKeys={Object.keys(activity.docs || {})}
        reviewers={
          profileStore.isParent
            ? parentViewActivityReviewerData
            : studentViewActivityReviewerData
        }
        open={activityStore.addingOrEditingDocs}
        run={true}
        loadingState={loadingState}
        steps={steps}
        onEdit={() => {
          activityStore.setAddEditActivityId(activity.id);
          activityStore.setEditingActivity(true);
        }}
        onEditHeader={() => {
          activityStore.setAddEditActivityId(activity.id);
          activityStore.setEditingActivityHeader(true);
        }}
        canCreateNewUtilities={canCreateNewUtilities}
      />
      <SuccessSnackbar
        message={isSuccessSnackbar.message}
        open={isSuccessSnackbar.open}
        onClose={() => {
          setIsSuccessSnackbar({
            open: false,
            message: '',
          });
        }}
      />
    </>
  );
};

export default MyStoryActivityDocsContainer;
