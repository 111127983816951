import { Dialog } from '@mui/material';
import { useEffect, useState } from 'react';
import useChatStore from '../../../../stores/ChatStore';
import useNicheStore from '../../../../stores/NicheStore';
import MyStoryDocsHeader from '../../MyStoryDocsHeader';
import NichePage from '../NichePage';
import { NicheUtilityFilterTabs } from './NicheUtilityFilterTabs';
import { ViewDetailsPopup } from './ViewDetailsPopup';
import useActivityStore from '../../../../stores/ActivityStore';
import useCourseStore from '../../../../stores/CourseStore';
import useHonorStore from '../../../../stores/HonorStore';

export const ViewDetailsPage = ({
  data = {},
  isParent = false,
  currentGrade,
  ...props
}) => {
  const nicheStore = useNicheStore();
  const chatStore = useChatStore();
  const activityStore = useActivityStore();
  const courseStore = useCourseStore();
  const honorStore = useHonorStore();
  const onClickChat = () => {
    chatStore.setIsChatWindowOpen(!chatStore.isChatWindowOpen);
  };
  const menuItems = [
    {
      id: 1,
      title: 'Delete Niche',
      onClick: () => {
        nicheStore.setDeleteNicheId(data.nicheId);
        nicheStore.setIsDeletingNiche(true);
        nicheStore.setIsViewDetails(false);
        nicheStore.setViewNicheId(null);
        setTabValue(0);
      },
    },
  ];
  const [tabValue, setTabValue] = useState(0);
  const [lanes, setLanes] = useState(data);
  useEffect(() => {
    switch (tabValue) {
      case 0:
        setLanes(data);
        break;
      case 1:
        setLanes({
          ...lanes,
          items: data?.items?.filter((value) => value.type === 'ACTIVITY'),
        });
        break;
      case 2:
        setLanes({
          ...lanes,
          items: data?.items?.filter((value) => value.type === 'HONOR'),
        });
        break;
      case 3:
        setLanes({
          ...lanes,
          items: data?.items?.filter((value) => value.type === 'COURSE'),
        });
        break;
      default:
        setLanes(data);
    }
    //eslint-disable-next-line
  }, [data, tabValue]);

  // ViewDetails Popup Data
  const getUtilityData = (utilityId) => {
    if (utilityId) {
      return data?.items?.find(({ id }) => id === utilityId);
    }
  };

  return (
    <Dialog
      fullScreen={true}
      disableRestoreFocus={true}
      disableEnforceFocus={true}
      isHeight={false}
      style={{
        zIndex: 80,
      }}
      sx={{
        '.MuiDialog-paper': {
          backgroundColor: '#FCFCFB',
        },
      }}
      {...props}
    >
      <div className="flex flex-col h-screen relative">
        <div
          className="px-8 py-4"
          style={{ backgroundColor: data.color ? data.color : '#EFEFEA' }}
        >
          <MyStoryDocsHeader
            id={data?.nicheId}
            menuItems={menuItems}
            title={data.label}
            saveIcon={false}
            isParent={isParent}
            onBack={() => {
              nicheStore.setIsViewDetails(false);
              nicheStore.setIsViewDetailPopup(false);
              nicheStore.setViewNicheId(null);
              nicheStore.setViewDetailPopupUtilityId(null);
              setTabValue(0);
            }}
            isChatWindowOpen={chatStore.isChatWindowOpen}
            onClickChat={onClickChat}
            isNiche={true}
            nicheName={
              nicheStore.niches?.find((item) => item?.id === data?.nicheId)
                ?.nicheName
            }
            onEditHeader={() => {
              nicheStore.setEditNicheId(data.nicheId);
              nicheStore.setIsEditingNiche(true);
            }}
          />
        </div>
        <div className="px-8 pt-6">
          <NicheUtilityFilterTabs
            totalCount={data && data?.items?.length}
            activityCount={
              data &&
              data?.items?.filter((item) => item.type === 'ACTIVITY').length
            }
            courseCount={
              data &&
              data?.items?.filter((item) => item.type === 'COURSE').length
            }
            honorCount={
              data &&
              data?.items?.filter((item) => item.type === 'HONOR').length
            }
            onChange={(e, newValue) => setTabValue(newValue)}
          />
        </div>
        <NichePage
          isViewDetails={true}
          lanes={lanes}
          isAddNew={false}
          isParent={isParent}
          currentGrade={currentGrade}
          showOverlay={
            nicheStore.niches.length === 0 &&
            activityStore?.activities.length === 0 &&
            courseStore?.courses.length === 0 &&
            honorStore.honors.length === 0
          }
        />
        {nicheStore.isViewDetailPopup && (
          <ViewDetailsPopup
            isParent={isParent}
            utilityData={getUtilityData(nicheStore.viewDetailPopupUtilityId)}
            nicheName={data.label}
          />
        )}
      </div>
    </Dialog>
  );
};
