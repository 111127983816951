import { TextField } from '@mui/material';
import Dialog from './Dialog';
import ChooseNiche from './ChooseNiche';
import { useEffect, useState } from 'react';

const EditUtilityDetails = ({
  label,
  activityData,
  honorData,
  value = '',
  niches,
  disabled = false,
  onChange = () => {},
  ...args
}) => {
  // const [addedNicheName, setAddedNicheName] = useState('');
  // const onNicheAdd = (nicheName) => {
  //   setAddedNicheName(nicheName);
  // };
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (activityData) {
      onChange(formData);
    }
    if (honorData) {
      onChange(formData);
    }
    // eslint-disable-next-line
  }, [formData, activityData, honorData]);

  useEffect(() => {
    if (activityData) {
      setFormData({
        activityName: activityData.activityName || '',
        nicheId: activityData.nicheId || '',
      });
    }
    if (honorData) {
      setFormData({
        honorName: honorData.honorName || '',
        nicheId: honorData.nicheId || '',
      });
    }
  }, [honorData, activityData]);

  return (
    <Dialog continueText="Update" disabled={disabled} {...args}>
      <div className="py-2 flex flex-col gap-7">
        <div>
          <TextField
            id="filled-error"
            label={label}
            value={
              activityData
                ? formData.activityName
                : honorData
                ? formData.honorName
                : ''
            }
            onChange={(e) => {
              if (activityData) {
                setFormData({ ...formData, activityName: e.target.value });
              }
              if (honorData) {
                setFormData({ ...formData, honorName: e.target.value });
              }
            }}
            required
            fullWidth
          />
        </div>
        <div>
          <ChooseNiche
            niches={niches}
            utilityName={activityData ? 'Activity' : 'Honor'}
            selectedNiche={[formData.nicheId]}
            onChange={(e, nicheId) => {
              setFormData({ ...formData, nicheId });
            }}
          />
          {/* <div>{addedNicheName}</div> */}
        </div>
      </div>
    </Dialog>
  );
};

export default EditUtilityDetails;
