import { myStoryUtilities } from '../../../constants/staticData';
import useChatStore from '../../../stores/ChatStore';
import useHonorStore from '../../../stores/HonorStore';
import MyStoryDocsHeader from '../MyStoryDocsHeader';

const MyStoryHonorDocsHeader = ({
  id = '',
  title = '',
  nicheName = '',
  onBack = () => {},
  onEdit = () => {},
  onEditHeader = () => {},
  onInfo = () => {},
  canCreateNewUtilities = () => {},
  isSelectedInfo,
  isParent = false,
}) => {
  const honorStore = useHonorStore();
  const chatStore = useChatStore();
  const onClickChat = () => {
    chatStore.setIsChatWindowOpen(!chatStore.isChatWindowOpen);
  };
  const menuItems = [
    {
      id: 1,
      title: 'Delete Honor',
      onClick: () => {
        honorStore.setDeleteHonorId(id);
        honorStore.setIsDeletingHonors(true);
      },
    },
  ];

  return (
    <>
      <MyStoryDocsHeader
        id={id}
        onBack={onBack}
        title={title}
        nicheName={nicheName}
        saveIcon={true}
        menuItems={menuItems}
        onEdit={onEdit}
        isParent={isParent}
        onEditHeader={onEditHeader}
        isNicheName={true}
        onCopy={() => {
          if (canCreateNewUtilities(myStoryUtilities.HONOR)) {
            honorStore.setCopyHonorsId(id);
            honorStore.setIsCopyingHonors(true);
          }
        }}
        isChatWindowOpen={chatStore.isChatWindowOpen}
        onClickChat={onClickChat}
        onInfo={onInfo}
        isSelectedInfo={isSelectedInfo}
        canCreateNewUtilities={canCreateNewUtilities}
      />
    </>
  );
};

export default MyStoryHonorDocsHeader;
