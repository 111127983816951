import { useEffect, useState } from 'react';
import { SelectDateButton } from '../../shared/SelectDateButton';
import Tooltip from '../../shared/Tooltip';
import ScoreInputField from './ScoreInputFiled';
import InfoIcon from '@mui/icons-material/Info';
import Alert from '../../shared/Alert';
import Button from '../../shared/Button';
import { AddCircle } from '@mui/icons-material';

const AddNewSATScore = ({
  satScores = [],
  satSubjects = [],
  satTarget = [],
  onChangeScore = () => {},
  onChangeTarget = () => {},
  isAddingSATTarget = false,
  isEditingSATTarget = false,
  scoreData = [],
  targetData = [],
  isContinue = false,
}) => {
  const [isInputDisable, setIsInputDisable] = useState(false);
  const [isTargetScore, setIsTargetScore] = useState(
    isAddingSATTarget || isEditingSATTarget ? true : false
  );
  const [selectedDate, setSelectedDate] = useState(
    (scoreData?.testDate !== '' && scoreData?.testDate?.toDate()) || ''
  );
  const [isFutureDate, setIsFutureDate] = useState(false);
  const [satScoreData, setSATScoreData] = useState(scoreData.scores || []);
  const [satTargetData, setSATTargetData] = useState(targetData.targets || []);

  const onScoreChange = (subjectId, subjectName, newValue) => {
    const TotalSubject = satSubjects.find(
      (subject) => subject.subject === 'Total'
    );
    // Check if the subject being updated is "Total"
    if (subjectName === 'Total') {
      // Directly update the "Total" subject's scoreValue
      setSATScoreData(
        satScoreData.map((score) =>
          score.subjectName === 'Total'
            ? { ...score, subjectId: TotalSubject.id, scoreValue: newValue }
            : score
        )
      );
    } else {
      // For any other subject, either update or add it to the array
      const existingSubjectIndex = satScoreData.findIndex(
        (score) => score.subjectId === subjectId
      );

      let updatedSATScoreData;
      if (existingSubjectIndex !== -1) {
        // Update existing subject's scoreValue
        updatedSATScoreData = satScoreData.map((score, index) =>
          index === existingSubjectIndex
            ? { ...score, scoreValue: newValue }
            : score
        );
      } else {
        // Add new subject with its scoreValue
        updatedSATScoreData = [
          ...satScoreData,
          { subjectId, subjectName, scoreValue: newValue },
        ];
      }

      // Calculate the new total scoreValue excluding the "Total" subject
      const totalScoreValue = updatedSATScoreData
        .filter((score) => score.subjectName !== 'Total')
        .reduce((acc, current) => acc + current.scoreValue, 0);

      // Check if there is a "Total" subject in the array and update it
      const totalSubjectIndex = updatedSATScoreData.findIndex(
        (score) => score.subjectName === 'Total'
      );
      if (totalSubjectIndex !== -1) {
        // Update the "Total" subject's scoreValue
        updatedSATScoreData[totalSubjectIndex].scoreValue = totalScoreValue;
      } else {
        // If there's no "Total" subject, add it with the calculated total scoreValue
        updatedSATScoreData.push({
          subjectId: TotalSubject.id, // Assuming a unique ID for the "Total"
          subjectName: 'Total',
          scoreValue: totalScoreValue,
        });
      }

      // Update the state with the new SAT score data
      setSATScoreData(updatedSATScoreData);
    }
  };

  //Runs when the target score is changed
  const onTargetChange = (subjectId, subjectName, newValue) => {
    const TotalSubject = satSubjects.find(
      (subject) => subject.subject === 'Total'
    );

    // Check if the subject being modified is "Total"
    if (subjectName === 'Total') {
      // Directly update the Total's targetValue
      setSATTargetData(
        satTargetData.map((score) =>
          score.subjectName === 'Total'
            ? { ...score, subjectId: TotalSubject.id, targetValue: newValue }
            : score
        )
      );
    } else {
      // For non-Total subjects, add or update the subject
      const existingSubject = satTargetData.find(
        (score) => score.subjectId === subjectId
      );

      let updatedSATTargetData;
      if (existingSubject) {
        // Update existing subject
        updatedSATTargetData = satTargetData.map((score) =>
          score.subjectId === subjectId
            ? { ...score, targetValue: newValue }
            : score
        );
      } else {
        // Add new subject
        updatedSATTargetData = [
          ...satTargetData,
          { subjectId, subjectName, targetValue: newValue },
        ];
      }

      // Recalculate the total excluding the "Total" subject
      const calculatedTotal = updatedSATTargetData
        .filter((subject) => subject.subjectName !== 'Total')
        .reduce((acc, current) => acc + current.targetValue, 0);

      // Find if there's already a "Total" subject
      const totalSubjectIndex = updatedSATTargetData.findIndex(
        (subject) => subject.subjectName === 'Total'
      );
      if (totalSubjectIndex !== -1) {
        // Update the Total subject
        updatedSATTargetData[totalSubjectIndex].targetValue = calculatedTotal;
      } else {
        // Or add the Total subject if it doesn't exist
        updatedSATTargetData.push({
          subjectId: TotalSubject.id,
          subjectName: 'Total',
          targetValue: calculatedTotal,
        });
      }

      // Finally, update the state with the new data including the recalculated total
      setSATTargetData(updatedSATTargetData);
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    if (selectedDate) {
      if (selectedDate <= currentDate) {
        setIsFutureDate(false);
        setIsInputDisable(false);
        if (satScoreData.length > 0) {
          onChangeScore({
            scores: satScoreData,
            testDate: selectedDate,
          });
        } else {
          onChangeScore({
            testDate: selectedDate,
            scores: [],
          });
        }
      } else {
        setIsFutureDate(true);
        setIsInputDisable(true);
        setSATScoreData([]);
        onChangeScore({
          testDate: selectedDate,
          scores: [],
        });
      }
    }
    // eslint-disable-next-line
  }, [selectedDate]);

  useEffect(() => {
    if (satScoreData.length > 0) {
      onChangeScore({
        scores: satScoreData,
        testDate: selectedDate,
      });
    } else {
      onChangeScore({
        testDate: selectedDate,
        scores: [],
      });
    }
    // eslint-disable-next-line
  }, [satScoreData]);

  const targetDataExists = () => {
    const targetDataEmpty = satTargetData.filter(
      (target) => target?.targetValue === ''
    );
    return targetDataEmpty.length !== satTargetData.length;
  };

  useEffect(() => {
    if (satTargetData.length > 0 && targetDataExists()) {
      onChangeTarget(satTargetData);
    } else {
      onChangeTarget([]);
    }
    // eslint-disable-next-line
  }, [satTargetData]);

  const selectDateTooltipTile = `You can also select a future date and add your 
  score later, after you’ve taken the test.`;

  const targetTooltip = (
    <div className="flex flex-col gap-3 text-1sm">
      <div>Target Score</div>
      <div>
        Determine your target score by looking at the median scores for the
        colleges to which you’d like to apply. Your target is the score you
        think will give you the best chance of admission to these colleges,
        taking their median scores into account.
      </div>
    </div>
  );
  return (
    <div>
      {satTarget.length === 0 &&
        satScores.length === 0 &&
        !isAddingSATTarget &&
        !isEditingSATTarget && (
          <div className="text-lead-500 text-1sm">
            Add the Target Score you wish to achieve. Also add your SAT Actual
            Score along with the date you appeared on or wish to appear on.
          </div>
        )}

      {((satScores.length !== 0 &&
        scoreData.length === 0 &&
        !(isAddingSATTarget || isEditingSATTarget)) ||
        scoreData?.scores?.length === 0) && (
        <div className="text-lead-500 text-1sm">Add your SAT Actual score</div>
      )}

      {satScores.length !== 0 &&
        scoreData?.scores?.length !== 0 &&
        scoreData.length !== 0 &&
        !(isAddingSATTarget || isEditingSATTarget) && (
          <div className="text-lead-500 text-1sm">
            Edit your SAT Actual Score.
          </div>
        )}

      {isAddingSATTarget && (
        <div className="text-lead-500 text-1sm pb-2">
          Add the Target Score you wish to achieve.
        </div>
      )}

      {isEditingSATTarget && (
        <div className="text-lead-500 text-1sm pb-2">
          Edit the Target Score you wish to achieve.
        </div>
      )}

      {isFutureDate && (
        <Alert className="w-fit mt-3" severity="info">
          As you have selected a future date, you will be able to add actual
          scores after you have taken the test.
        </Alert>
      )}

      {isContinue && selectedDate === '' && (
        <Alert className="w-fit mt-3" severity="error">
          Select the date on which you appeared or plan to appear for your SAT
          test.
        </Alert>
      )}

      {!isAddingSATTarget && !isEditingSATTarget && (
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-6 px-6 pt-4">
          <div
            className={`flex flex-col gap-1 ${
              satTarget?.length === 0 ? 'col-start-3' : 'col-start-2'
            } ${isTargetScore && 'hidden'}`}
          >
            <div className="text-xs text-grey-900 opacity-50">
              {!scoreData?.testDate && selectedDate === ''
                ? ' Took on / Take on:'
                : isFutureDate
                ? 'Take on :'
                : 'Took on :'}
            </div>

            <Tooltip
              title={selectedDate === '' && selectDateTooltipTile}
              placement="right"
            >
              <div className="w-fit">
                <SelectDateButton
                  value={selectedDate}
                  onChange={(newValue) => {
                    setSelectedDate(newValue.$d);
                  }}
                />
              </div>
            </Tooltip>
          </div>
        </div>
      )}

      <div className="grid grid-cols-2 sm:grid-cols-3 px-0 sm:px-6 py-4 gap-6 items-center font-medium text-grey-900">
        <div>Subject</div>
        {(satTarget.length === 0 ||
          isAddingSATTarget ||
          isEditingSATTarget) && (
          <div
            className={`gap-1 items-center flex sm:flex ${
              !isTargetScore && 'hidden'
            }`}
          >
            <div>Target Score (Optional)</div>
            <Tooltip title={targetTooltip} placement="right">
              <InfoIcon
                sx={{
                  height: '16px',
                  width: '16px',
                }}
              />
            </Tooltip>
          </div>
        )}
        <div>
          {(satTarget?.length === 0 && satScores?.length === 0) ||
          (!isAddingSATTarget &&
            !isEditingSATTarget &&
            satScores?.length === 0) ? (
            <div>Actual Score</div>
          ) : (
            ''
          )}
        </div>
      </div>
      {satSubjects?.map((satSubject, index) => {
        return (
          <div
            className="grid grid-cols-2 sm:grid-cols-3 px-0 sm:px-6 py-4 gap-4 sm:gap-6 items-center text-xs text-grey-900/50"
            key={satSubject?.id}
          >
            <div>{satSubject?.subject}</div>
            {(satTarget.length === 0 ||
              isAddingSATTarget ||
              isEditingSATTarget) && (
              <ScoreInputField
                minScore={satSubject?.minScore}
                maxScore={satSubject?.maxScore}
                className={`sm:!flex w-full sm:w-110 ${
                  !isTargetScore && '!hidden'
                }`}
                value={
                  satTargetData.find(
                    (score) => score.subjectId === satSubject.id
                  )?.targetValue || ''
                }
                onChange={(newValue) => {
                  onTargetChange(satSubject?.id, satSubject?.subject, newValue);
                }}
                isHelperText={true}
              />
            )}

            {(satTarget?.length === 0 && satScores?.length === 0) ||
            (!isAddingSATTarget && !isEditingSATTarget) ? (
              <ScoreInputField
                disabled={isInputDisable}
                minScore={satSubject?.minScore}
                maxScore={satSubject?.maxScore}
                className={`w-full sm:w-110 ${isTargetScore && '!hidden'}`}
                value={
                  satScoreData.find(
                    (score) => score.subjectId === satSubject.id
                  )?.scoreValue || ''
                }
                onChange={(newValue) => {
                  onScoreChange(satSubject?.id, satSubject?.subject, newValue);
                }}
                isHelperText={true}
              />
            ) : (
              ''
            )}
          </div>
        );
      })}
      {satTarget.length === 0 && !isAddingSATTarget && !isEditingSATTarget && (
        <div className="flex sm:hidden flex-col gap-4 text-xs text-grey-400 font-medium">
          {!isTargetScore && (
            <>
              <b className="">Target Score</b>
              <div>
                Determine your target score by looking at the median scores for
                the colleges to which you’d like to apply. Your target is the
                test the score you think will give you the best chance of
                admission to these colleges, taking their median scores into
                account.
              </div>
            </>
          )}
          <Button
            startIcon={<AddCircle />}
            variant="text"
            className="w-fit"
            onClick={() => setIsTargetScore(!isTargetScore)}
          >
            {!isTargetScore ? 'Add Target Score' : 'Add Score'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default AddNewSATScore;
