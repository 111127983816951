import { useState } from 'react';
import Dialog from '../../shared/Dialog';
import AddNewHonor from './AddNewHonor';
import useHonorStore from '../../../stores/HonorStore';
import useTeamStore from '../../../stores/TeamStore';
import { useShallow } from 'zustand/react/shallow';
import {
  filterUniAndTeamUniversily,
  INVITE_STATUS,
} from '../../../utils/utlityTypes';

const honorListData = [
  { id: '1', label: 'School' },
  { id: '2', label: 'City/Community' },
  { id: '3', label: 'State/Regional' },
  { id: '4', label: 'National' },
  { id: '5', label: 'International' },
];

const AddNewHonorDialog = ({
  niches = [],
  honorTypes = [],
  data = {},
  onContinue = () => {},
  editMode = false,
  setDisabled = () => {},
  disabled = false,
  onCancel = () => {},
  isAdding = false,
  ...props
}) => {
  const [honorData, setHonorData] = useState(data);
  const [isHonorName, setIsHonorName] = useState(false);
  const honorStore = useHonorStore();
  const onChange = (honorData) => {
    setHonorData(honorData);
  };
  const teamStore = useTeamStore(
    useShallow((state) => ({
      teams: state.teams,
      selectedTeam: state.selectedTeam,
    }))
  );
  const collaborators = teamStore?.selectedTeam?.id
    ? teamStore?.selectedTeam?.teamMembers
        ?.filter((profile) => profile?.invite_status === INVITE_STATUS.JOINED)
        ?.filter(
          (member) => member.email !== teamStore?.selectedTeam?.studentEmail
        )
        ?.map((profile) => ({
          email: profile.email,
          id: profile.uid,
          photoURL: profile?.photoURL,
          label: profile?.firstName + ' ' + profile?.lastName,
        }))
    : [];
  return (
    <Dialog
      isHeight={true}
      disabled={disabled}
      setDisabled={setDisabled}
      title={
        honorStore.addingHonors ||
        (honorStore?.editingHonors &&
          honorData?.grades?.length === 0 &&
          honorData?.honorTypes?.length === 0)
          ? !honorStore?.addingHonors
            ? 'Add Honor details'
            : 'Add New Honor'
          : 'Edit Honor Details'
      }
      continueText={honorStore.addingHonors ? 'Add' : 'Update'}
      onContinue={() => {
        if (disabled) return;

        if (honorData.honorName === '') {
          setIsHonorName(true);
        } else {
          setIsHonorName(false);
        }
        if (honorData.honorName !== '') {
          setDisabled(true);
          onContinue({
            ...(data.id && { id: data.id }),
            ...honorData,
          });
        }
      }}
      onCancel={() => {
        onCancel();
        setIsHonorName(false);
      }}
      {...props}
    >
      <AddNewHonor
        honorData={data}
        niches={niches}
        honorTypes={honorListData}
        onChange={onChange}
        editMode={true}
        collaborators={filterUniAndTeamUniversily(collaborators)}
        selectedCollaborators={collaborators.filter((collaborator) =>
          data?.sharedWith?.includes(collaborator?.id)
        )}
        isHonorName={isHonorName}
        isAdding={isAdding}
      />
    </Dialog>
  );
};

export default AddNewHonorDialog;
