import React, { useCallback, useEffect, useState } from 'react';
import useNicheStore from '../../../../stores/NicheStore';
import { useShallow } from 'zustand/react/shallow';
import useStudentStore from '../../../../stores/StudentStore';
import useProfileStore from '../../../../stores/ProfileStore';
import ErrorIcon from '@mui/icons-material/Error';
import SelectTeamMembers from '../../../shared/SelectTeamMembers';
import InputWithTooltip from '../../../shared/InputWithTooltip';
import { InputAdornment } from '@mui/material';

export const EditNicheSettings = ({
  tooltipTitle = 'Edit Niche',
  niche = {},
  niches = [],
  teamMemberData = [],
  selectedTeamMember = [],
  onChange,
  isNicheEmpty,
  setIsNicheEmpty,
}) => {
  const { activeStudentReviewers, selectedStudent } = useStudentStore(
    useShallow((state) => {
      return {
        activeStudentReviewers: state.activeStudentReviewers,
        selectedStudent: state.selectedStudent,
      };
    })
  );
  const [formData, setFormData] = useState({
    nicheName: niche.nicheName || '',
    sharedWith: niche.sharedWith || [],
    ...niche,
  });
  const [nicheExists, setNicheExists] = useState(false);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState(
    defaultTeamMembers()
  );
  const nicheStore = useNicheStore(
    useShallow((state) => ({
      isSelectTeamMember: state.isSelectTeamMember,
      utilityName: state.utilityName,
    }))
  );
  const isParent = useProfileStore((state) => state.isParent);

  // To set the default team members
  function defaultTeamMembers() {
    if (selectedTeamMember?.length > 0) {
      return selectedTeamMember;
    } else {
      if (activeStudentReviewers?.length > 0 && isParent) {
        return activeStudentReviewers.map((reviewer) => ({
          email: reviewer.email,
          id: reviewer.reviewerDocId,
          photoURL: reviewer.photoURL,
          label: reviewer.firstName + ' ' + reviewer.lastName,
        }));
      }
      return teamMemberData;
    }
  }

  useEffect(() => {
    onChange(formData);
  }, [formData]);

  useEffect(() => {
    if (selectedTeamMembers.length > 0) {
      setFormData({
        ...formData,
        sharedWith: selectedTeamMembers?.map((teamMember) => {
          return {
            id: teamMember?.id,
            email: teamMember?.email,
            photoURL: teamMember?.photoURL,
            uid: teamMember?.uid,
          };
        }),
      });
    }
    // eslint-disable-next-line
  }, [selectedTeamMembers]);

  const handleChange = useCallback(
    (e) => {
      setFormData({ ...formData, nicheName: e.target.value });
      setNicheExists(
        niches
          .map((niche) => niche.label.toLowerCase())
          .includes(e.target.value.toLowerCase())
      );
    },
    [formData, niches]
  );

  return (
    <div className="flex flex-col gap-8 py-4 px-2">
      <div className="flex flex-col gap-2">
        <InputWithTooltip
          error={nicheExists || isNicheEmpty}
          helperText={
            nicheExists && !isNicheEmpty ? (
              <div className="flex items-center gap-1 text-xs">
                <ErrorIcon color="error" className="text-base" />
                {`Niche ${formData.nicheName} already exists`}
              </div>
            ) : (
              isNicheEmpty &&
              !nicheExists && (
                <div className="flex items-center gap-1 text-xs">
                  <ErrorIcon color="error" className="text-base" />
                  Enter Niche Name
                </div>
              )
            )
          }
          required
          fullWidth
          label="Niche Name"
          placeholder="Eg. Sports, Culinary etc."
          onChange={handleChange}
          value={formData?.nicheName}
          InputProps={{
            startAdornment: <InputAdornment position="start"></InputAdornment>,
          }}
          sx={{
            '.MuiFormHelperText-root': {
              marginLeft: 0,
              marginTop: '5px',
            },
          }}
          title={tooltipTitle}
        />

        {!nicheStore.isSelectTeamMember && nicheStore.utilityName && (
          <div className="font-medium text-xs text-grey-900/50">
            Think of other niches where this {nicheStore.utilityName} might fit
          </div>
        )}
      </div>
      {nicheStore.isSelectTeamMember && (
        <div className="w-full">
          <SelectTeamMembers
            label="Select Team Members (optional)"
            options={teamMemberData}
            value={selectedTeamMembers}
            menuListTitle="Select your Existing Team Members"
            onChange={(e, teamMembers) => {
              setSelectedTeamMembers(teamMembers);
            }}
            tooltipTitle="You can select or add Team Members with whom you wish to collaborate"
          />
        </div>
      )}
    </div>
  );
};
