import React, { useEffect, useState } from 'react'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../navigation/constants';
import useTeamStore from '../../stores/TeamStore';
import { useShallow } from 'zustand/react/shallow';
import useNicheStore from '../../stores/NicheStore';
import useActivityStore from '../../stores/ActivityStore';
import useHonorStore from '../../stores/HonorStore';
import useCourseStore from '../../stores/CourseStore';

const ProfileCompletionWidget = () => {

    const navigate = useNavigate()

    const [nicheStatus, setNicheStatus] = useState({
        status: '',
        bgColor: '',
        textColor: '',
        indicatorBg: ''
    })

    const nicheStore = useNicheStore(useShallow((state) => ({
        niches: state.niches,
    })))

    const activityStore = useActivityStore(useShallow((state) => ({
        activities: state.activities,
    })))

    const honorStore = useHonorStore(useShallow((state) => ({
        honors: state.honors,
    })))

    const courseStore = useCourseStore(useShallow((state) => ({
        courses: state.courses,
    })))

    useEffect(()=> {
        const newStatus = getStatus();

        // Avoid unnecessary state updates by comparing with the current state
        setNicheStatus((prev) => {
            if (prev.status === newStatus.status && prev.textColor === newStatus.textColor && prev.bgColor === newStatus.bgColor) {
                return prev; // No update needed
            }
            return newStatus; // Update state if values are different
        });
    },[nicheStore.niches?.length, activityStore?.activities?.length, honorStore.honors?.length, courseStore.courses?.length])

    function getStatus() {
        const nicheLength = nicheStore.niches?.length;
        const activityLength = activityStore.activities?.length;
        const honorLength = honorStore.honors?.length;
        const courseLength = courseStore.courses?.length;
        switch (true) {
            case activityLength >= 4 &&
                courseLength >= 3 &&
                honorLength >= 2 &&
                nicheLength > 0:
                return { status: "You're a Superstar!", textColor: "text-green-500", bgColor: "bg-green-50", indicatorBg: "bg-green-500" };
        
            case activityLength >= 1 &&
                courseLength >= 1 &&
                honorLength >= 1 &&
                nicheLength > 0:
                return { status: "You're amazing! Keep going!", textColor: "text-blue-500", bgColor: "bg-blue-50", indicatorBg: "bg-blue-500" };
        
            case activityLength > 2 && courseLength >= 1 && nicheLength > 0:
                return { status: "You're making progress", textColor: "text-orange-500", bgColor: "bg-orange-50", indicatorBg: "bg-orange-500" };
        
            case activityLength >= 1 && nicheLength > 0:
                return { status: "You're just getting started!", textColor: "text-red-500", bgColor: "bg-red-50", indicatorBg: "bg-red-500" };
        
            default:
                return { status: "Not Started", textColor: "text-gray-500", bgColor: "bg-gray-50", indicatorBg: "bg-gray-500" };
        }
    }
    

    const {selectedTeam} = useTeamStore(
        useShallow((state) => ({
            selectedTeam: state.selectedTeam,
        }))
    );

    return (
        <div className={`px-5 flex py-2 flex-col gap-1 border-[#336AD1] border-opacity-10 rounded-lg mr-5 ${nicheStatus?.bgColor}`}>
            <div className='flex justify-between gap-3 items-center'>
                <div className={`w-2 h-2 rounded-full animate-pulse ${nicheStatus?.indicatorBg}`} />
                <p className={`text-sm font-medium ${nicheStatus?.textColor} underline-red-200`}>{nicheStatus.status}</p>
                <div className='text-xs text-grey-300 ml-4 flex items-center ' role='button' onClick={()=> navigate(`/${selectedTeam?.id}${ROUTES.MY_STORY}/niche`)}>Go to Niche <span><ChevronRightIcon fontSize='small'/></span></div>
            </div>
        </div>
    )
}

export default ProfileCompletionWidget