import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MyStoryHonorDocs from '../components/myStory/honors/MyStoryHonorDocs';
import SuccessSnackbar from '../components/shared/SuccessSnackbar';
import {
  addStoryItemTypes,
  googleDriveFolders,
  templateType,
} from '../constants/other';
import { honorDocCharVersion } from '../constants/staticData';
import { updateStoryItem } from '../firebase/services/updateServices';
import { useGoogleDocs } from '../hooks';
import useHonorStore from '../stores/HonorStore';
import useMyTeamStore from '../stores/MyTeamStore';
import useNicheStore from '../stores/NicheStore';
import useProfileStore from '../stores/ProfileStore';
import useTemplateStore from '../stores/TemplateStore';
import useStudentStore from '../stores/StudentStore';
import useThemeStore from '../stores/ThemeStore';
import useTeamStore from '../stores/TeamStore';

const steps = [
  {
    target: '.first-step',
    content: (
      <div>
        The common app only gives you 100 characters here, so the best honors
        entries are succinct and not only highlight what the prize was, but also
        what it was for. Squeeze in as many details as you can about the purpose
        and meaning of each award!
      </div>
    ),
    disableBeacon: true,
    placement: 'right',
    arrow: false,
  },
  {
    target: '.second-step',
    content: (
      <div>
        Add other Details about Honors here like Level of Honor, Which Year
      </div>
    ),
    disableBeacon: true,
    placement: 'top',
    arrow: true,
  },
  {
    target: '.third-step',
    content: (
      <div>You can edit Honors here like Level of Honor, Which Year</div>
    ),
    disableBeacon: true,
    placement: 'top',
    arrow: true,
  },
  {
    target: '.fifth-step',
    content: <div>You can edit the title and niche</div>,
    disableBeacon: true,
    placement: 'top',
    arrow: true,
  },
  {
    target: '.sixth-step',
    content: (
      <div>
        You can make a copy of your honor directly just with a single click.
      </div>
    ),
    disableBeacon: true,
    placement: 'bottom',
    arrow: true,
  },
  {
    target: '.seventh-step',
    content: (
      <div>
        You can collaborate with your teachers, counselors, family members from
        here.
      </div>
    ),
    disableBeacon: true,
    placement: 'bottom',
    arrow: true,
  },
];

const MyStoryHonorDocsContainer = ({
  showNoGoogleAccountSnackbar = () => {},
  isParent = false,
  canCreateNewUtilities = () => {},
}) => {
  const honorStore = useHonorStore();
  const nicheStore = useNicheStore();
  const googleDocs = useGoogleDocs();
  const templateStore = useTemplateStore();
  const profileStore = useProfileStore();
  const { isMobile } = useThemeStore();
  const navigate = useNavigate();
  const addingOrEditingHonorDocs = useHonorStore(
    (state) => state.addingOrEditingHonorDocs
  );
  const [loadingState, setLoadingState] = useState('');
  const [isSuccessSnackbar, setIsSuccessSnackbar] = useState({
    open: false,
    message: '',
  });
  const selectedTeam = useTeamStore((state) => state.selectedTeam);
  const myTeamStore = useMyTeamStore();
  const studentStore = useStudentStore();
  const honors = useHonorStore((state) => state.honors);
  const [honor, setHonor] = useState({});
  useEffect(() => {
    const honor = honorStore.honorsById[honorStore.addEditHonorId] || {};
    setHonor(honor);
    //eslint-disable-next-line
  }, [honors, addingOrEditingHonorDocs]);
  const honorReviewers = myTeamStore.myTeam.filter(
    // eslint-disable-next-line array-callback-return
    ({ id, email, photoURL, firstName, lastName }) => {
      if (honor.reviewers?.includes(id)) {
        return {
          id,
          email,
          photoURL,
          firstName,
          lastName,
        };
      }
    }
  );
  const honorReviewersParentView = studentStore.activeStudentReviewers.filter(
    ({ reviewerDocId, email, photoURL, firstName, lastName }) => {
      if (honor.reviewers?.find((reviewerId) => reviewerId === reviewerDocId)) {
        return {
          id: reviewerDocId,
          email,
          photoURL,
          firstName,
          lastName,
        };
      }
    }
  );
  return (
    <>
      <MyStoryHonorDocs
        id={honor.id}
        title={honor.honorName}
        isParent={isParent}
        nicheName={nicheStore.getNicheById(honor.nicheId)?.nicheName}
        grades={honor.grades}
        docInformation={honor.docInformation}
        honorTypes={honor.honorTypes?.map((type) => type.label)}
        onBack={() => {
          if (honorStore.isAllHonorsPageOpen) {
            navigate(`/${selectedTeam?.id}/all-honors`);
            honorStore.setAddingOrEditingHonorDocs(false);
          } else {
            honorStore.setAddingOrEditingHonorDocs(false);
          }
        }}
        onAddTabBtnClick={async (version) => {
          setLoadingState(version);
          if (profileStore.profileLogin?.loggedInType === 'google') {
            let templateString = await googleDocs.googleDocData(
              templateStore.templateData,
              templateType.HONOR
            );
            const honorDoc = await googleDocs.createGoogleDoc(
              `${honor.honorName} (${version})`,
              googleDriveFolders.HONOR,
              templateString || ''
            );
            if (honorDoc?.webViewLink) {
              const docInfo =
                version === honorDocCharVersion.CHAR_100
                  ? { ...honor.docs, 100: honorDoc.webViewLink }
                  : { ...honor.docs, 250: honorDoc.webViewLink };
              await updateStoryItem(
                {
                  ...honor,
                  docs: docInfo,
                },
                addStoryItemTypes.HONOR
              );
              await googleDocs.giveTeamMembersAccessToDocs(
                honorDoc?.id,
                myTeamStore.myTeam
                  .filter(({ id }) => honor.reviewers?.includes(id))
                  .map((teamMember) => teamMember?.email)
              );
              setLoadingState('');
            } else {
              const res = await googleDocs.requestScopeAccessFromUser();
              if (res === 'error') {
                setIsSuccessSnackbar({
                  open: true,
                  message:
                    'Google Drive and Docs was not granted try adding documents later.',
                });
              } else {
                setIsSuccessSnackbar({
                  open: true,
                  message:
                    'You can start creating your Honor Google Documents.',
                });
              }
              setLoadingState('');
              return res;
            }
          } else {
            showNoGoogleAccountSnackbar();
            setLoadingState('');
          }
        }}
        reviewers={isParent ? honorReviewersParentView : honorReviewers}
        onEdit={() => {
          honorStore.setAddEditHonorId(honor.id);
          honorStore.setEditingHonors(true);
        }}
        onEditHeader={() => {
          honorStore.setAddEditHonorId(honor.id);
          honorStore.setEditingHonorsHeader(true);
        }}
        docs={Object.values(honor.docs || {})}
        docsKeys={Object.keys(honor.docs || {})}
        open={honorStore.addingOrEditingHonorDocs}
        steps={steps}
        run={true}
        isMobile={isMobile}
        canCreateNewUtilities={canCreateNewUtilities}
        loadingState={loadingState}
      />
      <SuccessSnackbar
        message={isSuccessSnackbar.message}
        open={isSuccessSnackbar.open}
        onClose={() => {
          setIsSuccessSnackbar({
            open: false,
            message: '',
          });
        }}
      />
    </>
  );
};

export default MyStoryHonorDocsContainer;
