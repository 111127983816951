import { Avatar } from '@mui/material'
import React from 'react'
import CollegeDueChip from './CollegeDueChip'
import CollegeProgressBar from './CollegeProgressBar'
import { getFormattedDate, getUtilityLabel } from '../../../utils/helper'
import { roundOptions } from '../../../constants/other'
import { useNavigate } from 'react-router-dom'
import useTeamStore from '../../../stores/TeamStore'
import { useShallow } from 'zustand/react/shallow'
import useApplicationStore from '../../../stores/ApplicationStore'
import { UTILITY_OPENED_FROM } from '../../../navigation/constants'

const CollegeItem = ({
    collegeDetails = {},
}) => {
    const navigate = useNavigate()

    const {selectedTeam} = useTeamStore(useShallow((state) => ({
        selectedTeam: state.selectedTeam,
    })))

    const applicationStore = useApplicationStore(useShallow((state) => ({
        setIsAddApplicationRequirement: state.setIsAddApplicationRequirement,
        setIsCollegeId: state.setIsCollegeId,
        setIsAddCollegeApplication: state.setIsAddCollegeApplication,
        setSlideOutTabIndex: state.setSlideOutTabIndex,
        setIsSlideOutOpen: state.setIsSlideOutOpen,
        setOpenedFrom: state.setOpenedFrom,
    })))


    const currentRoundData = collegeDetails?.rounds?.find((round) => round?.id === collegeDetails?.round);

    const checkDifference =
    currentRoundData && currentRoundData?.applicationDueDate
        ? Math.round(
            (new Date(currentRoundData?.applicationDueDate?.toDate()).getTime() -
            new Date().getTime()) /
            (1000 * 60 * 60 * 24)
        )
    : 0;

    function openCollegeDetails() {
        if (
            collegeDetails?.applicationPlatform?.length > 1 &&
            !collegeDetails?.applicationPlatformName
        ) {
            applicationStore.setIsAddCollegeApplication(true);
            applicationStore.setIsCollegeId(collegeDetails?.collegeId);
        } else {
            applicationStore.setIsAddApplicationRequirement(true);
            applicationStore.setIsCollegeId(collegeDetails?.collegeId);
        }
        applicationStore.setIsSlideOutOpen(true);
        applicationStore.setSlideOutTabIndex(0);
        applicationStore.setOpenedFrom(UTILITY_OPENED_FROM.MY_HUB);
        navigate(`/${selectedTeam?.id}/myColleges`);
    }

    return (
        <div className='col-span-1 rounded-2xl dashboard_box_shadow cursor-pointer py-5 px-3.5 z-10 flex flex-col gap-2 border hover:scale-[1.02] hover:transition-all  hover:bg-gray-50 border-gray-100' onClick={openCollegeDetails}>
            <div className='flex items-center justify-between gap-2'>
                <div className='w-3/5 flex gap-2 items-center text-sm font-medium'>
                    <Avatar src={collegeDetails?.collegeLogoURL} sx={{ width: 24, height: 24 }}/>
                    <div className='truncate'> {collegeDetails?.collegeData?.collegeName}</div>
                </div>
                {(checkDifference < 10 && checkDifference > 0) && <div className='w-max overflow-auto self-end'>
                    <CollegeDueChip daysLeft={checkDifference}/>
                </div>}
            </div>
            <CollegeProgressBar progress={collegeDetails?.applicationProgress} height='xs'/>
            <div className='flex items-center gap-2'>
                <div className='w-2/5 flex flex-col gap-1'>
                    <div className='text-2xs text-[#929292]'>Due Date</div>
                    <div className='text-sm text-[#5A5A5A]'>{getFormattedDate(currentRoundData?.applicationDueDate)}</div>
                </div>
                <div className='w-3/5 flex flex-col gap-1'>
                    <div className='text-2xs text-[#929292]'>Round</div>
                    <div className='text-sm text-[#5A5A5A]'>{getUtilityLabel(currentRoundData?.round, roundOptions) || '---'}</div>
                </div>
            </div>
        </div>
    )
}


export default CollegeItem