import { Drawer, useMediaQuery } from '@mui/material';
import React from 'react';
import { useMyStoryStore } from '../../../stores/myStoryStore';
import { useShallow } from 'zustand/react/shallow';
import useChatStore from '../../../stores/ChatStore';
import useTestStore from '../../../stores/TestStore';
import useNicheStore from '../../../stores/NicheStore';

export const MyStorySlideOut = ({ open, children }) => {
  const { setStorySlideOut } = useMyStoryStore(
    useShallow((state) => ({
      setStorySlideOut: state.setStorySlideOut,
    }))
  );
  const chatStore = useChatStore(
    useShallow((state) => ({
      isGenAIChatOpen: state.isGenAIChatOpen,
    }))
  );
  const testStore = useTestStore(
    useShallow((state) => ({
      setIsEditingSATScore: state.setIsEditingSATScore,
      isEditingSATScore: state.isEditingSATScore,
      setIsEditingSATTarget: state.setIsEditingSATTarget,
      setIsEditingACTScore: state.setIsEditingACTScore,
      setIsEditingACTTarget: state.setIsEditingACTTarget,
    }))
  );
  const nicheStore = useNicheStore(
    useShallow((state) => ({
      setEditNicheId: state.setEditNicheId,
    }))
  );
  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <Drawer
      PaperProps={{
        style: {
          width: isMobile ? '100%' : '40%',
          maxWidth: isMobile ? '100%' : '40%',
          position: 'absolute',
          right: chatStore.isGenAIChatOpen ? 400 : '0',
          boxShadow: 0,
        },
      }}
      slotProps={{
        backdrop: {
          style: {
            position: 'absolute',
          },
        },
      }}
      ModalProps={{
        container: document.getElementById('story-page'),
        style: {
          zIndex: 82,
          top: 64,
        },
      }}
      anchor="right"
      open={open}
      onClose={() => {
        setStorySlideOut(false);
        testStore.setIsEditingSATScore(false);
        testStore.setIsEditingSATTarget(false);
        testStore.setIsEditingACTScore(false);
        testStore.setIsEditingACTTarget(false);
        nicheStore.setEditNicheId(null);
      }}
    >
      {children}
    </Drawer>
  );
};
