import { ReactComponent as GenAIIcon } from '../../assets/svgs/genAI.svg';

import useChatStore from '../../stores/ChatStore';
import Tooltip from './Tooltip';
import useProfileStore from '../../stores/ProfileStore';
import { userTypeKeywords } from '../../constants/keywords';
import Button from './Button';
import { ASK_UNI_MAIL } from '../../constants/staticData';
import useMyTeamStore from '../../stores/MyTeamStore';
import { getProfileDataByEmail } from '../../firebase/services/user';
import { addStoryItem } from '../../firebase/services/addServices';
import { addStoryItemTypes } from '../../constants/other';
import ASK_UNI from '../../assets/UNI_AI_4.png';
const AIChatIcon = ({ id = '', isHeader = false, setChatUtil = () => {} }) => {
  const chatStore = useChatStore();
  const profileStore = useProfileStore();
  const myTeamStore = useMyTeamStore();
  const isParent =
    profileStore?.profile?.profileType === userTypeKeywords.UNIVERSILY;

  const handleAskUniChatClick = async () => {
    if (chatStore.isGenAIChatOpen) {
      chatStore.setIsChatOpen(false);
      chatStore.setOpenChatId(null);
      chatStore.setOpenChatType(null);
      chatStore.setUtilityId(null);
      chatStore.setIsGenAIChatOpen(false);
      chatStore.setIsChatWindowOpen(false);
      chatStore.setIsFilterTopic(false);
    } else {
      const chatTopic = chatStore.chatRoomsDM.find(
        (chatDM) => chatDM.email === ASK_UNI_MAIL
      );

      if (chatTopic?.chatParticipants) {
        chatStore.setOpenChatId(chatTopic.id);
        chatStore.setOpenChatType('directMessage');
        chatStore.setIsChatWindowOpen(true);
      } else {
        const teamUniversily = myTeamStore.myTeam.find(
          (teamMember) => teamMember.email === ASK_UNI_MAIL
        );
        if (teamUniversily) {
          const reviewer = await getProfileDataByEmail(ASK_UNI_MAIL);
          const response = await addStoryItem(
            {
              isTopic: false,
              archivedBy: [],
              chatParticipants: [reviewer?.uid, profileStore.profile?.uid],
            },
            addStoryItemTypes.CHAT_ROOM
          );
          if (response) {
            chatStore.setOpenChatType('directMessage');
            chatStore.setOpenChatId(response);
            chatStore.setIsChatWindowOpen(true);
          }
        }
      }
      chatStore.setIsGenAIChatOpen(true);
      chatStore.setIsChatWindowOpen(true);
    }
  };
  return (
    <div className="relative" onClick={handleAskUniChatClick}>
      <Tooltip title={isParent ? 'Chat with Student' : 'Chat with Uni'}>
        <Button
          component="label"
          variant="text"
          className="rounded-lg !bg-[#FECA65]"
        >
          <div className="text-primary px-3 font-bold text-md flex items-center justify-center gap-2">
            <img src={ASK_UNI} alt="" className="bg-cover -mb-1.5 w-9" />
            <div className="text-sm font-medium text-[#674935]">Ask Uni</div>
          </div>
        </Button>
      </Tooltip>
    </div>
  );
};

export default AIChatIcon;
