import React, { useState } from 'react';
import { Menu, MenuItem, IconButton } from '@mui/material';

export const GenericMenu = ({ menuItems, buttonLabel }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    action();
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {buttonLabel}
      </IconButton>
      {menuItems?.length > 0 ? (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {menuItems?.map((item, index) => (
            <MenuItem
              key={index}
              onClick={() => handleMenuItemClick(item.action)}
            >
              {item.label}
            </MenuItem>
          ))}
        </Menu>
      ) : null}
    </div>
  );
};
