import React, { useState } from 'react';
import useNicheStore from '../../../../stores/NicheStore';
import { useShallow } from 'zustand/react/shallow';
import useStudentStore from '../../../../stores/StudentStore';
import useMyTeamStore from '../../../../stores/MyTeamStore';
import useProfileStore from '../../../../stores/ProfileStore';
import Button from '../../../shared/Button';
import { addStoryItemTypes, NOTIFICATION_ACTION_TYPES } from '../../../../constants/other';
import {
  updateDocument,
  updateStoryItem,
} from '../../../../firebase/services/updateServices';
import { CHAT_ROOMS } from '../../../../firebase/constants';
import {
  addTeamUniversilyToChatParticipants,
  addTeamUniversilyToReviewers,
  filterUniAndTeamUniversily,
  INVITE_STATUS,
  UTILITY_TYPES,
} from '../../../../utils/utlityTypes';
import AddNewCourse from '../../courses/AddNewCourse';
import useCourseStore from '../../../../stores/CourseStore';
import useTeamStore from '../../../../stores/TeamStore';
import { addNotificationItem } from '../../../../firebase/services/addServices';

export const MyCourseSlidOutSettings = () => {
  const nicheStore = useNicheStore(
    useShallow((state) => ({
      niches: state.niches,
    }))
  );

  const courseStore = useCourseStore(
    useShallow((state) => ({
      courses: state.courses,
      addEditCourseId: state.addEditCourseId,
    }))
  );
  const profileStore = useProfileStore(
    useShallow((state) => ({
      isParent: state.isParent,
      profile: state.profile,
    }))
  );

  const studentStore = useStudentStore(
    useShallow((state) => ({
      activeStudentReviewers: state.activeStudentReviewers,
    }))
  );

  const myTeamStore = useMyTeamStore(
    useShallow((state) => ({
      myTeam: state.myTeam,
    }))
  );
  const teamStore = useTeamStore(
    useShallow((state) => ({
      selectedTeam: state.selectedTeam,
      teams: state.teams,
    }))
  );
  const getCourseData = (courseId) => {
    if (courseId) {
      const { id, courseName, nicheId, grades, description, sharedWith } =
        courseStore.courses.find(({ id }) => id === courseId);
      return {
        id,
        courseName: courseName,
        nicheId,
        grades,
        description,
        sharedWith,
      };
    }
  };

  const data = getCourseData(courseStore.addEditCourseId);
  const [isLoading, setIsLoading] = useState(false);
  const [courseData, setCourseData] = useState(data);
  const [isCourseName, setIsCourseName] = useState(false);

  const onChange = (courseData) => {
    setCourseData(courseData);
  };

  const handleSave = async () => {
    setIsLoading(true);
    if (courseData.courseName === '') {
      setIsCourseName(true);
      return;
    } else {
      setIsCourseName(false);
    }
    const res = await updateStoryItem(
      {
        ...courseData,
        id: courseStore.addEditCourseId,
        sharedWith: addTeamUniversilyToReviewers(
          courseData.sharedWith.map(({ id }) => id)
        ),
      },
      addStoryItemTypes.COURSE
    );
    if (res) {
      // create notification course update
      const notificationData = {
        utilityType: UTILITY_TYPES.Courses,
        utilityName: courseData?.courseName,
        utilityId: courseStore?.addEditCourseId,
        teamId: teamStore?.selectedTeam?.id,
        actionType: NOTIFICATION_ACTION_TYPES.UPDATED,
      }
      await addNotificationItem(notificationData);
    }

    // Update chat room participants when activity details are updated
    await updateDocument(
      CHAT_ROOMS,
      'utilityId',
      courseStore.addEditCourseId,
      'chatParticipants',
      addTeamUniversilyToChatParticipants([
        ...courseData.sharedWith.map(({ uid }) => uid),
        profileStore?.profile?.uid,
      ])
    );
    setIsLoading(false);
  };

  const teamDataReviewersParentView = studentStore?.activeStudentReviewers.map(
    (reviewer) => ({
      email: reviewer.email,
      label: `${reviewer.firstName} ${reviewer.lastName}`,
      photoURL: reviewer.photoURL,
      id: reviewer.reviewerDocId,
    })
  );
  const teamDataReviewers = myTeamStore.myTeam
    .filter((teamMember) => teamMember?.isRegistered && teamMember?.isAccepted)
    .map(({ id, email, firstName, lastName, photoURL, profileUserId }) => ({
      id: id,
      email,
      label: `${firstName} ${lastName}`,
      photoURL: photoURL,
      uid: profileUserId,
    }));

  const niches = (nicheStore.niches || []).map(({ id, nicheName }) => ({
    value: id,
    label: nicheName,
  }));

  const teamMemberData = profileStore?.isParent
    ? teamDataReviewersParentView
    : teamDataReviewers;

  const collaborators = teamStore?.selectedTeam?.id
    ? teamStore?.selectedTeam?.teamMembers
        ?.filter((profile) => profile?.invite_status === INVITE_STATUS.JOINED)
        ?.filter(
          (member) => member.email !== teamStore?.selectedTeam?.studentEmail
        )
        ?.map((profile) => ({
          email: profile.email,
          id: profile.uid,
          photoURL: profile?.photoURL,
          label: profile?.firstName + ' ' + profile?.lastName,
        }))
    : [];
  console.log({ courseData });
  return (
    <div className="h-full flex flex-col justify-between px-2 py-2">
      <div className="overflow-auto ">
        <AddNewCourse
          collaborators={filterUniAndTeamUniversily(collaborators)}
          selectedCollaborators={filterUniAndTeamUniversily(
            collaborators.filter((collaborator) =>
              courseData?.sharedWith?.includes(collaborator?.id)
            )
          )}
          niches={niches}
          onChange={onChange}
          courseData={data}
          editMode={true}
          isCourseName={isCourseName}
          isAdding={false}
        />
      </div>

      <div className="pb-6 flex justify-end pr-6 pt-4 shadow-top w-full">
        <Button onClick={handleSave} disabled={isLoading}>
          {isLoading ? 'Saving...' : 'Save'}
        </Button>
      </div>
    </div>
  );
};
