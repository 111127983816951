import DeleteDialog from '../../shared/DeleteDialog';

const DeleteCourseDialog = ({ ...args }) => {
  return (
    <DeleteDialog title="Delete Course" {...args} P>
      <div className="text-[#2C303C] font-medium">
        Are you sure you want to delete this course?
      </div>
      <div className="text-lead-500 text-[13px]">
        Your team will lose access to this Course.
      </div>
    </DeleteDialog>
  );
};

export default DeleteCourseDialog;
