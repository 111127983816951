import React, { useState } from 'react';
import AddTeam from '../../../assets/svgs/addteamsection.svg';
import yellowribbon from '../../../assets/svgs/addteamsectionyellowbottom.svg';
import purpleconfetti from '../../../assets/svgs/addteamsectionpurpletop.svg';
import Button from '../../../components/shared/Button';
import AddTeamMemberDialog from '../../../components/shared/AddTeamMemberDialog';
import { getProfileDataByEmail } from '../../../firebase/services/user';
import { INVITE_STATUS } from '../../../utils/utlityTypes';
import { updateDocument } from '../../../firebase/services/updateServices';
import { PROFILE, TEAMS } from '../../../firebase/constants';
import useTeamStore from '../../../stores/TeamStore';
import useProfileStore from '../../../stores/ProfileStore';
import { openSnackbar } from '../../../components/shared/GlobalSnackbar/GlobalSnackbar';
export const InviteTeamSection = ({ teamMembers }) => {
  const [addCollaborator, setAddCollaborator] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const team = useTeamStore((state) => state.selectedTeam);
  const profile = useProfileStore((state) => state.profile);
  const teamId = team?.id;

  const addNewCollaborator = async (teamMember) => {
    if (teamMember.email && teamMember.role) {
      const { email, role } = teamMember;
      const invitedCollaborator = await getProfileDataByEmail(email);
      const inviteStatus = invitedCollaborator
        ? INVITE_STATUS.REGISTERED
        : INVITE_STATUS.NOT_REGISTERED;
      await updateDocument(TEAMS, 'id', teamId, 'teamMembers', [
        {
          email: email,
          role: role,
          dateAdded: new Date(),
          invite_status: inviteStatus,
          uid: invitedCollaborator ? invitedCollaborator.uid : '',
          invitedBy: profile?.uid,
        },
      ]);
      if (invitedCollaborator) {
        await updateDocument(PROFILE, 'email', email, TEAMS, [
          { teamId: teamId, status: inviteStatus },
        ]);
      }
      openSnackbar('Team member added successfully', 'success', 4000);
      setAddCollaborator(false);
    }
  };
  return (
    <>
      <div className="flex justify-between border border-neutral-200 px-6 py-10 dashboard_box_shadow rounded-2xl">
        {/* For action and context text */}
        <div className="flex flex-col justify-center gap-2 max-w-[722px]">
          <span className="font-normal text-xl leading-5 text-black relative">
            Start by adding a team member
            <img
              src={purpleconfetti}
              className="absolute w-6 h-7 top-0 right-0"
              alt=""
              srcset=""
            />
          </span>
          <p className="font-normal text-base font-roboto leading-6 text-neutral-600 mb-5 relative">
            Your team can include parents, counselors, or anyone you trust to
            help with the college application process. Ask them to review and
            provide feedback on all parts of your story and applications.
            <img
              className="absolute bottom-[-5] right-10"
              src={yellowribbon}
              alt=""
            />
          </p>
          <Button
            variant="contained"
            color="primary"
            className="w-28 h-10 rounded-lg"
            onClick={() => setAddCollaborator(true)}
          >
            + Add Team
          </Button>
        </div>
        {/* For the image */}
        <div className="max-w-[412px]">
          <img src={AddTeam} alt="" srcset="" />
        </div>
      </div>
      <AddTeamMemberDialog
        existingTeamMembers={teamMembers}
        disabled={disabled}
        setDisabled={setDisabled}
        open={addCollaborator}
        onContinue={addNewCollaborator}
        onCancel={() => {
          setDisabled(false);
          setAddCollaborator(false);
        }}
        memberData={{
          firstName: '',
          lastName: '',
          email: '',
          role: '',
        }}
      />
    </>
  );
};
