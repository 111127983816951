import ScoreInputField from './ScoreInputFiled';

const SectionHeader = ({ columnOneTitle, columnTwoTitle }) => {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 px-0 sm:px-6 py-4 gap-6 items-center font-medium text-grey-900">
      <div></div>
      <div className={`gap-1 items-center flex sm:flex`}>
        <span>{columnOneTitle}</span>
      </div>
      <div>
        <div>{columnTwoTitle}</div>
      </div>
    </div>
  );
};

const SectionInputs = ({
  subjectName,
  onChange,
  dataKey,
  data,
  type,
  inputKeys,
}) => {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 px-0 sm:px-6 py-4 gap-4 sm:gap-6 items-center text-xs text-grey-900/50">
      <div>{subjectName}</div>

      <ScoreInputField
        className={`sm:!flex w-full sm:w-110`}
        value={data[dataKey][inputKeys[0]] ? data[dataKey][inputKeys[0]] : ' '}
        onChange={(newValue) => {
          onChange(newValue, dataKey, inputKeys[0]);
        }}
        isHelperText={false}
      />

      <ScoreInputField
        disabled={false}
        className={`w-full sm:w-110`}
        value={data[dataKey][inputKeys[1]] ? data[dataKey][inputKeys[1]] : ' '}
        onChange={(newValue) => {
          onChange(newValue, dataKey, inputKeys[1]);
        }}
        isHelperText={false}
      />
    </div>
  );
};

export const AddNewGPAScore = ({
  GPARankData = {},
  setGPARankData = () => {},
}) => {
  const onScoreChange = (newValue, data, inputKey) => {
    setGPARankData((prevData) => {
      return {
        ...prevData,
        [data]: {
          ...prevData[data],
          [inputKey]: parseFloat(newValue),
        },
      };
    });
  };

  return (
    <div>
      <SectionHeader columnOneTitle="Score" columnTwoTitle="Out of" />
      <SectionInputs
        subjectName="Unweighted GPA"
        onChange={onScoreChange}
        dataKey="GPAData"
        data={GPARankData}
        type="unweighted"
        inputKeys={['unweighted', 'unweightedOutOf']}
      />
      <SectionInputs
        subjectName="Weighted GPA"
        onChange={onScoreChange}
        dataKey="GPAData"
        type="weighted"
        data={GPARankData}
        inputKeys={['weighted', 'weightedOutOf']}
      />
      <SectionHeader columnOneTitle="Place" columnTwoTitle="Out of" />
      <SectionInputs
        subjectName="Unweighted Rank"
        onChange={onScoreChange}
        dataKey="RankData"
        type="unweighted"
        data={GPARankData}
        inputKeys={['unweighted', 'unweightedOutOf']}
      />
      <SectionInputs
        subjectName="Weighted Rank"
        onChange={onScoreChange}
        dataKey="RankData"
        type="weighted"
        data={GPARankData}
        inputKeys={['weighted', 'weightedOutOf']}
      />
    </div>
  );
};
