import { useState } from 'react';
import Dialog from '../../shared/Dialog';
import AddNewActivity from './AddNewActivity';
import useActivityStore from '../../../stores/ActivityStore';
import {
  filterUniAndTeamUniversily,
  INVITE_STATUS,
} from '../../../utils/utlityTypes';
import useTeamStore from '../../../stores/TeamStore';
import { useShallow } from 'zustand/react/shallow';
import useProfileStore from '../../../stores/ProfileStore';

const AddNewActivityDialog = ({
  niches = [],
  editMode = false,
  allActivityTypes = [],
  data = {},
  onContinue = () => {},
  disabled = false,
  setDisabled = () => {},
  onCancel = () => {},
  isAdding = false,
  ...props
}) => {
  const [activityData, setActivityData] = useState(data);
  const selectedTeam = useTeamStore((state) => state.selectedTeam);
  const teamStore = useTeamStore(
    useShallow((state) => ({
      teams: state.teams,
      selectedTeam: state.selectedTeam,
    }))
  );
  const profile = useProfileStore((state) => state.profile);
  const [isActivityName, setIsActivityName] = useState(false);
  const onChange = (activityData) => {
    setActivityData(activityData);
  };
  const activityStore = useActivityStore();

  const activityTags = allActivityTypes?.sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  const collaborators = teamStore?.selectedTeam?.id
    ? teamStore?.selectedTeam?.teamMembers
        ?.filter((profile) => profile?.invite_status === INVITE_STATUS.JOINED)
        ?.filter((member) => member.email !== selectedTeam?.studentEmail)
        ?.map((profile) => ({
          email: profile.email,
          id: profile.uid,
          photoURL: profile?.photoURL,
          label: profile?.firstName + ' ' + profile?.lastName,
        }))
    : [];

  return (
    <Dialog
      disabled={disabled}
      isHeight={true}
      title={
        isAdding ||
        (activityStore?.editingActivity &&
          activityData?.grades?.length === 0 &&
          !activityData?.durationHoursPerWeek &&
          !activityData?.durationWeeksPerYear &&
          activityData?.activityTypes?.length === 0)
          ? !isAdding
            ? 'Add Activity details'
            : 'Add New Activity'
          : 'Edit Activity Details'
      }
      onCancel={() => {
        onCancel();
        setIsActivityName(false);
        activityData.activityName = '';
      }}
      continueText={activityStore?.addingActivity ? 'Add' : 'Update'}
      onContinue={async () => {
        if (disabled) return;

        if (activityData.activityName === '') {
          setIsActivityName(true);
        } else {
          setIsActivityName(false);
        }
        if (activityData.activityName !== '') {
          setDisabled(true);
          onContinue({
            ...(data.id && { id: data.id }),
            ...activityData,
          });
        }
      }}
      {...props}
    >
      <AddNewActivity
        isAdding={isAdding}
        activityData={data}
        niches={niches}
        editMode={true}
        allActivityTypes={activityTags}
        onChange={onChange}
        collaborators={filterUniAndTeamUniversily(collaborators)}
        selectedCollaborators={filterUniAndTeamUniversily(
          collaborators.filter((collaborator) =>
            data?.sharedWith?.includes(collaborator?.id)
          )
        )}
        isActivityName={isActivityName}
      />
    </Dialog>
  );
};

export default AddNewActivityDialog;
