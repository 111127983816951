import useHonorStore from '../../../../stores/HonorStore';
import useNicheStore from '../../../../stores/NicheStore';
import MyStoryHonorDocsFooter from '../../MyStoryHonorDocsFooter';
import AddDetailsPopupDocs from '../AddDetailsPopupDocs';
import AddDetailsPopupEmptyBody from '../AddDetailsPopupEmptyBody';
import { ViewDetailsHeader } from './ViewDetailsHeader';

export const HonorViewDetailsPopup = ({
  utilityData = [],
  openStoryDoc = () => {},
  onClose = () => {},
  isParent = false,
}) => {
  const honorStore = useHonorStore();
  const nicheStore = useNicheStore();
  const onEdit = () => {
    honorStore.setAddEditHonorId(utilityData.id);
    honorStore.setAddingOrEditingHonorDocs(true);
  };
  return (
    <>
      <ViewDetailsHeader
        title={utilityData.name}
        type="Honor"
        isParent={isParent}
        onClose={onClose}
        onEdit={onEdit}
        onReassignNiche={() => {
          nicheStore.setIsReassignNiche(true);
          nicheStore.setReassignUtilityType('HONOR');
          nicheStore.setReassignUtilityId(utilityData.id);
        }}
      />
      {utilityData.docInformation &&
        ((utilityData?.docInformation?.length === 1 &&
          utilityData?.docInformation[0]?.charCount > 0) ||
          utilityData?.docInformation[0]?.charCount > 0 ||
          utilityData?.docInformation[1]?.charCount > 0) && (
          <AddDetailsPopupDocs tabData={utilityData?.docInformation} />
        )}
      {utilityData.docInformation &&
        ((utilityData?.docInformation?.length === 1 &&
          utilityData?.docInformation[0]?.charCount === 0) ||
          (utilityData?.docInformation[0]?.charCount === 0 &&
            utilityData?.docInformation[1]?.charCount === 0)) && (
          <AddDetailsPopupEmptyBody openStoryDoc={onEdit} isParent={isParent} />
        )}
      <div className="px-6 py-4 bottom-0">
        <MyStoryHonorDocsFooter
          grades={utilityData.grades}
          honorTypes={utilityData.honorTypes?.map((type) => type.label)}
          reviewers={utilityData.reviewers}
          editIcon={false}
          onEdit={() => {
            honorStore.setAddEditHonorId(utilityData.id);
            honorStore.setEditingHonors(true);
          }}
        />
      </div>
    </>
  );
};
