import { useLocation, useNavigate } from 'react-router-dom';
import useMyTeamStore from '../stores/MyTeamStore';
import useNicheStore from '../stores/NicheStore';
import useActivityStore from '../stores/ActivityStore';
import useHonorStore from '../stores/HonorStore';
import useCourseStore from '../stores/CourseStore';
import TestContainer from './Test.container';
import { useEffect, useState } from 'react';
import NicheContainer from './Niche.container';
import { Drawer } from '@mui/material';
import UpgradeBanner from '../components/shared/upgrade/UpgradeBanner';
import SuccessSnackbar from '../components/shared/SuccessSnackbar';
import NoGoogleAccountSnackbar from '../components/shared/NoGoogleAccountSnackbar';
import FunFactSnackbar from '../components/shared/FunFactSnackbar';
import AddNewCourseDialog from '../components/myStory/courses/AddNewCourseDialog';
import AddNewActivityDialog from '../components/myStory/activities/AddNewActivityDialog';
import EditActivityDetails from '../components/myStory/activities/EditActivityDetails';
import MyStoryActivityDocsContainer from './MyStoryActivityDocs.container';
import AddNewHonorDialog from '../components/myStory/honors/AddNewHonorDialog';
import EditHonorDetails from '../components/myStory/honors/EditHonorDetails';
import MyStoryHonorDocsContainer from './MyStoryHonorDocs.container';
import AddNewCourseDialogContainer from './AddNewCourseDialog.container';
import { addStoryItemTypes, NOTIFICATION_ACTION_TYPES } from '../constants/other';
import { updateStoryItem } from '../firebase/services/updateServices';
import { addNotificationItem, addStoryItem } from '../firebase/services/addServices';
import useTestStore from '../stores/TestStore';
import useProfileStore from '../stores/ProfileStore';
import useSubscriptionStore from '../stores/SubscriptionStore';
import { userTypeKeywords } from '../constants/keywords';
import { useShallow } from 'zustand/react/shallow';
import useStudentStore from '../stores/StudentStore';
import GPAContainer from './GPA.container';
import { canCreateNewUtilities } from '../utils/canCreateNewUtilities';
import { useMyStoryStore } from '../stores/myStoryStore';
import {
  UTILITY_TYPES,
  addTeamUniversilyToChatParticipants,
  filterUniAndTeamUniversily,
} from '../utils/utlityTypes';
import useTeamStore from '../stores/TeamStore';
import { openSnackbar } from '../components/shared/GlobalSnackbar/GlobalSnackbar';
import { removeNullUndefined } from '../utils/helper';

const ActionsContainer = () => {
  const [isSuccessSnackbar, setIsSuccessSnackbar] = useState({
    open: false,
    message: '',
  });
  const [showNoGoogleAccSnackbar, setShowNoGoogleAccSnackbar] = useState(false);
  const [selectedAddNewItem, setSelectedAddNewItem] = useState('');
  const [disabled, setDisabled] = useState(false);
  const courseStore = useCourseStore();
  const activityStore = useActivityStore();
  const honorStore = useHonorStore();
  const nicheStore = useNicheStore();
  const studentStore = useStudentStore(
    useShallow((state) => {
      return {
        selectedStudent: state.selectedStudent,
        activeStudentReviewers: state.activeStudentReviewers,
      };
    })
  );
  const myTeamStore = useMyTeamStore(
    useShallow((state) => {
      return { myTeam: state.myTeam };
    })
  );
  const myStoryStore = useMyStoryStore(
    useShallow((state) => ({
      setStorySlideOut: state.setStorySlideOut,
      setActiveStoryType: state.setActiveStoryType,
    }))
  );
  const selectedTeam = useTeamStore((state) => state.selectedTeam);

  const testStore = useTestStore();
  const profileStore = useProfileStore();
  const location = useLocation();
  const navigate = useNavigate();

  const subscriptionStore = useSubscriptionStore();

  const niches = (nicheStore.niches || []).map(({ id, nicheName }) => ({
    value: id,
    label: nicheName,
  }));

  const isParent =
    profileStore?.profile?.profileType !== userTypeKeywords.STUDENT &&
    profileStore?.profile?.profileType !== userTypeKeywords.ADMINISTRATOR;

  const getCourseData = (courseId) => {
    if (courseId) {
      const { id, courseName, nicheId, grades, description, reviewers } =
        courseStore.courses.find(({ id }) => id === courseId);
      return {
        id,
        courseName: courseName,
        nicheId,
        grades,
        description,
        reviewers,
      };
    }
  };

  useEffect(() => {
    if (
      profileStore.profileLogin?.loggedInType === 'emailPassword' &&
      profileStore.profileLogin?.loggedInType !== 'google' &&
      profileStore.profileLogin?.profileType !== userTypeKeywords.STUDENT
    ) {
      setShowNoGoogleAccSnackbar(true);
    }
  }, [profileStore.profileLogin]);

  const getHonorData = (honorId) => {
    if (honorId) {
      const { id, honorName, nicheId, grades, honorTypes, reviewers } =
        honorStore.honors.find(({ id }) => id === honorId);
      return {
        id,
        honorName,
        nicheId,
        grades,
        honorTypes,
        reviewers,
      };
    }
  };

  const getActivityData = (activityId) => {
    if (activityId) {
      const {
        id,
        activityName,
        nicheId,
        grades,
        hoursPerWeek,
        weeksPerYear,
        activityTypes,
        sharedWith,
      } = activityStore.activities.find(({ id }) => id === activityId);
      return {
        id,
        activityName,
        nicheId,
        grades,
        hoursPerWeek,
        weeksPerYear,
        activityTypes,
        sharedWith,
      };
    }
  };
  const teamDataReviewers = myTeamStore.myTeam
    .filter((teamMember) => teamMember?.isRegistered && teamMember?.isAccepted)
    .map(({ id, email, firstName, lastName, photoURL, profileUserId }) => ({
      uid: profileUserId,
      id: id,
      email,
      label: `${firstName} ${lastName}`,
      photoURL: photoURL,
    }));

  const funFactMessageForActivity = (
    <span>
      The Common App colleges allow up to 10 Activities, and the University
      <br />
      of California colleges allow up to 20 Activities and Honors combined.
    </span>
  );
  const funFactMessageForHonor = (
    <span>The Common App allows you to enter up to five Honors</span>
  );

  async function ActivityOnContinue(activityData) {
    let activityId;

    // Create new activity
    if (isParent) {
      // Parent creates activity
      const parentActivityData = {
        ...activityData,
        createdBy: profileStore.profile.uid,
        owner: selectedTeam?.studentEmail,
        sharedWith: activityData.sharedWith.map(
          (collaborator) => collaborator?.id
        ),
      };

      activityId = await addStoryItem(
        parentActivityData,
        addStoryItemTypes.ACTIVITY
      );
    } else {
      // Student creates activity
      activityId = await addStoryItem(
        {
          ...activityData,
          createdBy: profileStore.profile.uid,
          owner: profileStore.profile.email,
          sharedWith: activityData.sharedWith.map(
            (collaborator) => collaborator?.id
          ),
        },
        addStoryItemTypes.ACTIVITY
      );
    }
    if (activityId) {
      // create notification for the activity creation
      const notificationData = {
        utilityType: UTILITY_TYPES.Activities,
        utilityName: activityData.activityName,
        utilityId: activityId,
        teamId: selectedTeam?.id,
        actionType: NOTIFICATION_ACTION_TYPES.CREATED,
      }
      await addNotificationItem(notificationData);


      // Create chat channel for activity
      const channelMembers = addTeamUniversilyToChatParticipants([
        ...activityData?.sharedWith?.map((member) => member?.id),
        isParent ? selectedTeam?.studentUID : profileStore.profile.uid,
      ]).filter((member) => member !== null && member !== undefined);
      await addStoryItem(
        {
          isTopic: true,
          archivedBy: [],
          chatParticipants: removeNullUndefined(channelMembers),
          topicName: 'Topic for ' + activityData?.activityName,
          utilityId: activityId,
          topicType: 'Activities',
        },
        addStoryItemTypes.CHAT_ROOM
      );
    }
    setIsSuccessSnackbar({
      open: true,
      message: 'A New Activity has been created.',
    });
    if (
      activityStore.activities.length === 0 &&
      location.pathname !== '/myStory/niche'
    ) {
      activityStore.setIsFunFactSnackbarOpen(true);
    }
    setDisabled(false);
    activityStore.setAddingActivity(false);
    // Strings to search for
    const searchStrings = ["/myHub", "/myNiche"];

    // Check if the content contains any of the search strings
    const foundStrings = searchStrings.filter(searchString => location.pathname.includes(searchString));
    if (foundStrings.length === 0) {
      myStoryStore.setActiveStoryType(UTILITY_TYPES.Activities);
      myStoryStore.setStorySlideOut(true);
      navigate(`/${selectedTeam?.id}/myStory/activities`);
      activityStore.setAddEditActivityId(activityId);
    }

  }

  async function HonorOnContinue(honorData) {
    let honorId;
    // Create new honor
    if (!isParent) {
      // Student creates honor
      const studentHonorData = {
        docInformation: [
          {
            content: '',
            label: `100 Characters`,
            charCount: 0,
          },
        ],
        ...honorData,
        createdBy: profileStore.profile.uid,
        owner: profileStore.profile.email,
        sharedWith: honorData?.sharedWith?.map(
          (collaborator) => collaborator?.id
        ),
      };
      console.log('STUDENT HONOR DATA', studentHonorData);
      honorId = await addStoryItem(studentHonorData, addStoryItemTypes.HONOR);
    } else {
      // Parent creates honor
      const parentHonorData = {
        ...honorData,
        owner: selectedTeam?.studentEmail,
        sharedWith: honorData?.sharedWith?.map(
          (collaborator) => collaborator?.id
        ),
        createdBy: profileStore.profile.uid,
      };
      honorId = await addStoryItem(parentHonorData, addStoryItemTypes.HONOR);
      openSnackbar('A New Honor has been created.');
    }

    if (honorId) {
      // create notification for the honor creation
      const notificationData = {
        utilityType: UTILITY_TYPES.Honors,
        utilityName: honorData.honorName,
        utilityId: honorId,
        teamId: selectedTeam?.id,
        actionType: NOTIFICATION_ACTION_TYPES.CREATED,
      }
      await addNotificationItem(notificationData);

      // Create chat channel for honor
      const channelMembers = addTeamUniversilyToChatParticipants([
        ...honorData?.sharedWith?.map((member) => member?.id),
        isParent ? selectedTeam?.studentUID : profileStore.profile.uid,
      ]).filter((member) => member !== null && member !== undefined);;
      await addStoryItem(
        {
          isTopic: true,
          archivedBy: [],
          chatParticipants: removeNullUndefined(channelMembers),
          topicName: 'Topic for ' + honorData?.honorName,
          utilityId: honorId,
          topicType: 'Honors',
        },
        addStoryItemTypes.CHAT_ROOM
      );
    }

    // Strings to search for
    const searchStrings = ["/myHub", "/myNiche"];

    // Check if the content contains any of the search strings
    const foundStrings = searchStrings.filter(searchString => location.pathname.includes(searchString));

    if (
      honorStore.honors.length === 0 &&
      foundStrings.length === 0
    ) {
      honorStore.setIsFunFactSnackbarOpen(true);
    }
    honorStore.setAddingHonors(false);
    if (foundStrings.length === 0) {
      myStoryStore.setActiveStoryType(UTILITY_TYPES.Honors);
      myStoryStore.setStorySlideOut(true);
      navigate(`/${selectedTeam?.id}/myStory/honors`);
      honorStore.setAddEditHonorId(honorId);
    }
    setDisabled(false);
  }

  const teamDataReviewersParentView = studentStore?.activeStudentReviewers.map(
    (reviewer) => ({
      email: reviewer.email,
      label: `${reviewer.firstName} ${reviewer.lastName}`,
      photoURL: reviewer.photoURL,
      id: reviewer.reviewerDocId,
    })
  );

  return (
    <>
      <GPAContainer />
      <TestContainer testTypeData={testStore.testTypes} />
      <NicheContainer />
      <Drawer
        anchor="top"
        open={subscriptionStore.showUpgradeBanner}
        onClose={() => {
          subscriptionStore.setShowUpgradeBanner(false);
          subscriptionStore.setUpgradeBannerUtil('');
        }}
      >
        <UpgradeBanner
          upgradeMessage={`You’ve reached the ${subscriptionStore.upgradeBannerUtil} limit for your free Universily subscription.`}
        />
      </Drawer>

      <SuccessSnackbar
        message={isSuccessSnackbar.message}
        open={isSuccessSnackbar.open}
        onClose={() => {
          setIsSuccessSnackbar({
            open: false,
            message: '',
          });
        }}
        autoHideDuration={6000}
      />

      <FunFactSnackbar
        open={
          activityStore.isFunFactSnackbarOpen ||
          honorStore.isFunFactSnackbarOpen
        }
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => {
          activityStore.setIsFunFactSnackbarOpen(false);
          honorStore.setIsFunFactSnackbarOpen(false);
        }}
        message={
          activityStore.isFunFactSnackbarOpen
            ? funFactMessageForActivity
            : funFactMessageForHonor
        }
      />
      <AddNewCourseDialog
        editMode={true}
        open={courseStore.courseGettingEdited || false}
        niches={niches}
        title="Edit Course"
        onCancel={() => {
          courseStore.editCourse(null);
        }}
        isAdding={courseStore.addingCourse}
        onContinue={async (data) => {
          await updateStoryItem(data, addStoryItemTypes.COURSE);
          setIsSuccessSnackbar({
            open: true,
            message: 'Course details have been updated.',
          });
          courseStore.editCourse(null);
        }}
        data={getCourseData(courseStore.courseGettingEdited)}
        teamMemberData={
          isParent ? teamDataReviewersParentView : teamDataReviewers
        }
      />
      <>
        <AddNewActivityDialog
          isAdding={activityStore?.addingActivity}
          niches={niches}
          editMode={true}
          disabled={disabled}
          setDisabled={setDisabled}
          allActivityTypes={activityStore.activityTypes.map(
            ({ code, description }) => ({ id: code, label: description })
          )}
          open={
            (activityStore.addingActivity &&
              !activityStore.addingOrEditingDocs) ||
            activityStore.editingActivity
          }
          onContinue={(activityData) => ActivityOnContinue(activityData)}
          onCancel={() => {
            setDisabled(false);
            activityStore.setAddingActivity(false);
            activityStore.setEditingActivity(false);
          }}
          data={
            activityStore.editingActivity
              ? getActivityData(activityStore.addEditActivityId)
              : {}
          }
          teamMemberData={
            isParent
              ? filterUniAndTeamUniversily(teamDataReviewersParentView)
              : filterUniAndTeamUniversily(teamDataReviewers)
          }
        />
        <EditActivityDetails
          disabled={disabled}
          setDisabled={setDisabled}
          open={activityStore.editingActivityHeader}
          onCancel={() => {
            setDisabled(false);
            activityStore.setEditingActivityHeader(false);
            //activityStore.setAddEditActivityId(null);
          }}
          niches={niches}
          onContinue={async (activityData) => {
            if (activityStore.editingActivityHeader) {
              await updateStoryItem(activityData, addStoryItemTypes.ACTIVITY);
              activityStore.setEditingActivityHeader(false);
              setIsSuccessSnackbar({
                open: true,
                message: 'Activity details have been updated.',
              });
            }
            setDisabled(false);
          }}
          data={
            activityStore.editingActivityHeader
              ? getActivityData(activityStore.addEditActivityId)
              : {}
          }
        />

        <MyStoryActivityDocsContainer
          showNoGoogleAccountSnackbar={() => setShowNoGoogleAccSnackbar(true)}
          isParent={isParent}
          canCreateNewUtilities={canCreateNewUtilities(profileStore.profile)}
        />
      </>
      <AddNewHonorDialog
        niches={niches}
        editMode={true}
        disabled={disabled}
        setDisabled={setDisabled}
        allHonorTypes={honorStore.honorTypes.map(({ code, description }) => ({
          id: code,
          label: description,
        }))}
        isAdding={honorStore.addingHonors}
        open={
          honorStore.editingHonors ||
          (honorStore.addingHonors && !honorStore.addingOrEditingHonorDocs)
        }
        onContinue={(honorData) => HonorOnContinue(honorData)}
        onCancel={() => {
          honorStore.setAddingHonors(false);
          honorStore.setEditingHonors(false);
          setDisabled(false);
        }}
        data={
          honorStore.editingHonors
            ? getHonorData(honorStore.addEditHonorId)
            : {}
        }
        teamMemberData={
          isParent
            ? filterUniAndTeamUniversily(teamDataReviewersParentView)
            : filterUniAndTeamUniversily(teamDataReviewers)
        }
      />
      <EditHonorDetails
        disabled={disabled}
        setDisabled={setDisabled}
        open={honorStore.editingHonorsHeader}
        onCancel={() => {
          setDisabled(false);
          honorStore.setEditingHonorsHeader(false);
          // honorStore.setAddEditHonorId(null);
        }}
        niches={niches}
        onContinue={async (honorData) => {
          if (honorStore.editingHonorsHeader) {
            await updateStoryItem(honorData, addStoryItemTypes.HONOR);
            honorStore.setEditingHonorsHeader(false);
            setIsSuccessSnackbar({
              open: true,
              message: 'Honor details have been updated.',
            });
          }
        }}
        data={
          honorStore.editingHonorsHeader
            ? getHonorData(honorStore.addEditHonorId)
            : {}
        }
      />
      <MyStoryHonorDocsContainer
        showNoGoogleAccountSnackbar={() => setShowNoGoogleAccSnackbar(true)}
        isParent={isParent}
        canCreateNewUtilities={canCreateNewUtilities(profileStore.profile)}
      />

      <AddNewCourseDialogContainer
        disabled={disabled}
        setDisabled={setDisabled}
        open={selectedAddNewItem === 'courses' || courseStore.addingCourse}
        niches={niches}
        isAdding={courseStore.addingCourse}
        onFinish={() => {
          setSelectedAddNewItem(null);
          courseStore.setAddingCourse(false);
          setDisabled(false);
        }}
        onCancel={() => {
          setDisabled(false);
          honorStore.setAddingHonors(false);
          honorStore.setEditingHonors(false);
          courseStore.setAddingCourse(false);
        }}
        data={
          honorStore.editingHonors
            ? getHonorData(honorStore.addEditHonorId)
            : {}
        }
        teamMemberData={
          isParent
            ? filterUniAndTeamUniversily(teamDataReviewersParentView)
            : filterUniAndTeamUniversily(teamDataReviewers)
        }
      />
    </>
  );
};

export default ActionsContainer;
