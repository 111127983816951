import NicheLaneHeader from './NicheLaneHeader';
import NicheLaneGantChart from './NicheLaneGantChart';
import { useEffect, useState } from 'react';
import useProfileStore from '../../../stores/ProfileStore';
import MyStoryAddNewButton from '../../../components/myStory/MyStoryAddNewButton';

const NicheLane = ({
  nicheId = '',
  label = '',
  count = 0,
  showTeamMembers = false,
  isViewDetails = false,
  reviewers = [],
  items = [],
  setAddUtiltytoNiche = () => {},
  unAssignedLane,
  isParent = false,
  canCreateNewUtilities = () => {},
  isMobile = false,
}) => {
  const [utilityData, setUtilityData] = useState([]);

  useEffect(() => {
    setUtilityData(items || []);
  }, [items]);

  const sortUtilityData = (checkboxValue) => {
    let filteredData = [];
    if (checkboxValue?.length > 0) {
      // eslint-disable-next-line array-callback-return
      checkboxValue?.map((checked) => {
        // eslint-disable-next-line array-callback-return
        items?.map((item) => {
          if (item?.type.toLowerCase() === checked?.label) {
            filteredData.push(item);
          }
        });
      });
      setUtilityData(filteredData);
    } else {
      setUtilityData(items);
    }
  };

  return (
    <>
      <div className="flex">
        {!isViewDetails && (
          <div className="shrink-0 w-48 p-1">
            <NicheLaneHeader
              id={nicheId}
              label={label}
              isParent={isParent}
              unAssignedLane={unAssignedLane}
              count={items?.length}
              showTeamMembers={showTeamMembers}
              reviewers={reviewers}
              setAddUtiltytoNiche={setAddUtiltytoNiche}
              canCreateNewUtilities={canCreateNewUtilities}
              sortUtilityData={sortUtilityData}
            />
          </div>
        )}
        <div className="flex-1">
          <NicheLaneGantChart
            items={utilityData}
            nicheName={label}
            isViewDetails={isViewDetails}
            unAssignedLane={unAssignedLane}
            isParent={isParent}
            isMobile={isMobile}
          />
          {items?.length === 0 && !unAssignedLane && (
            <div className="flex justify-center items-center flex-col gap-6 h-full bg-grey-50 sm:pr-[250px]">
              {!isParent ? (
                <div className="flex justify-center text-center items-center text-lead-500 z-50 text-sm bg-grey-50 ">
                  Let’s start adding your activities, honors, tests and courses.
                  <br /> The combination of these elements will eventually{' '}
                  <br />
                  lead you to the niches you’ll use to show colleges what makes
                  you unique.
                </div>
              ) : (
                <div className="flex justify-center text-center items-center text-lead-500 z-50 text-sm bg-grey-50 ">
                  Student is yet to add <br />
                  Activities, Courses and Honors
                </div>
              )}
              <div className="flex items-center bg-grey-50 z-50">
                {!isParent && (
                  <MyStoryAddNewButton
                    variant="text"
                    showDropDown={true}
                    isUtility={true}
                    showNiche={false}
                    showTests={false}
                    canCreateNewUtilities={canCreateNewUtilities}
                    sx={{ backgroundColor: 'none' }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NicheLane;
