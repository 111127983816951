import { activityDocCharVersion } from '../../constants/staticData';
import useProfileStore from '../../stores/ProfileStore';
import Button from '../shared/Button';
import StudentDocWarning from '../../assets/svgs/studentDocZeroState.png';
import ParentDocWarning from '../../assets/svgs/parentDocZeroState.png';
import { useEffect, useState, useRef, useCallback } from 'react';
import { updateStoryItem } from '../../firebase/services/updateServices';
import { addStoryItemTypes } from '../../constants/other';
import useActivityStore from '../../stores/ActivityStore';
import useHonorStore from '../../stores/HonorStore';

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

export const CharacterCount = ({ count, limit }) => {
  return (
    <>
      {
        <span
          className={`text-sm font-medium py-2 px-4 font-roboto ${
            count > limit ? 'text-[#DB4F59]' : 'text-grey-300'
          }`}
        >
          {count} / {limit} characters
        </span>
      }
    </>
  );
};

export const StoryDocsTextArea = ({ onChange, placeholder, value }) => {
  return (
    <textarea
      key={placeholder}
      value={value}
      className="w-full  activity-input placeholder:text-grey-100 placeholder:opacity-50 placeholder:font-roboto placeholder:text-2xl focus:outline-none p-4 resize-none text-grey-900 font-roboto font-normal text-xl h-40"
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

const MyStoryDocs = ({
  header,
  tabs,
  docGuidelines,
  docLink,
  footer,
  steps,
  run,
  continuous,
  isSelectedInfo,
  docsVersions,
  docInformation = '',
  chatPromptUtilName = '',
  placeholderText = '',
  id = '',
  onAddTabBtnClick = () => {},
}) => {
  const [shortText, setShortText] = useState('');
  const [longText, setLongText] = useState('');
  const addingOrEditingDocs = useActivityStore(
    (state) => state.addingOrEditingDocs
  );
  const addingOrEditingHonorDocs = useHonorStore(
    (state) => state.addingOrEditingHonorDocs
  );
  useEffect(() => {
    setShortText(
      docInformation?.find(
        (doc) =>
          doc?.label === '150 Characters' || doc?.label === '100 Characters'
      )?.content ?? ''
    );
    setLongText(
      docInformation?.find(
        (doc) =>
          doc?.label === '350 Characters' || doc?.label === '250 Characters'
      )?.content ?? ''
    );
  }, [docInformation]);
  useEffect(() => {
    if (
      (chatPromptUtilName === 'Honors' && !addingOrEditingHonorDocs) ||
      (chatPromptUtilName === 'Activities' && !addingOrEditingDocs)
    ) {
      setShortText('');
      setLongText('');
    }
  }, []);
  const activitiesById = useActivityStore((state) => state.activitiesById);
  const activity = activitiesById[id] || {};
  const honorsById = useHonorStore((state) => state.honorsById);
  const honor = honorsById[id] || {};

  const debouncedUpdateRef = useRef(null);

  if (!debouncedUpdateRef.current) {
    debouncedUpdateRef.current = debounce(
      async (originalDocumentData, updatedDocInformation, type) => {
        await updateStoryItem(
          { ...originalDocumentData, docInformation: updatedDocInformation },
          type
        );
      },
      1000
    );
  }

  const updateLongText = (value) => {
    setLongText(value);
    const updatedDocInformation = docInformation.find(
      (doc) =>
        doc?.label === '350 Characters' || doc?.label === '250 Characters'
    );
    if (updatedDocInformation) {
      updatedDocInformation.content = value;
      updatedDocInformation.charCount = value?.length;
    } else {
      docInformation.push({
        label:
          chatPromptUtilName === 'Honors' ? '250 Characters' : '350 Characters',
        content: value,
        charCount: value?.length,
      });
    }
    if (chatPromptUtilName === 'Honors') {
      debouncedUpdateRef.current(
        honor,
        docInformation,
        addStoryItemTypes.HONOR
      );
    } else {
      debouncedUpdateRef.current(
        activity,
        docInformation,
        addStoryItemTypes.ACTIVITY
      );
    }
  };

  const updateShortText = (value) => {
    setShortText(value);
    const updatedDocInformation = docInformation.find(
      (doc) =>
        doc?.label === '150 Characters' || doc?.label === '100 Characters'
    );
    if (updatedDocInformation) {
      updatedDocInformation.content = value;
      updatedDocInformation.charCount = value?.length;
    } else {
      docInformation.push({
        label: '150 Characters',
        content: value,
        charCount: value?.length,
      });
    }
    if (chatPromptUtilName === 'Honors') {
      debouncedUpdateRef.current(
        honor,
        docInformation,
        addStoryItemTypes.HONOR
      );
    } else {
      debouncedUpdateRef.current(
        activity,
        docInformation,
        addStoryItemTypes.ACTIVITY
      );
    }
  };

  return (
    <div className="flex flex-col bg-white h-full overflow-hidden">
      <div className="flex sm:h-18 py-6 sm:py-0 items-center px-4 sm:px-8 border-b border-[#EAEAEB] shrink-0">
        {header}
      </div>
      <div className="flex flex-1 overflow-hidden">
        <div
          className={`${
            isSelectedInfo && 'w-0 sm:w-full'
          } flex flex-col flex-1 grow border-r border-[#EAEAEB] overflow-auto`}
        >
          <div
            id="story-flow-step1"
            className="sm:flex sm:flex-1 sm:first-step w-full"
          >
            {docInformation !== 0 ? (
              <>
                <div className="h-full sm:w-1/2 flex flex-col w-full">
                  <span className="p-2 text-gray-400">Short description</span>
                  <StoryDocsTextArea
                    value={shortText}
                    onChange={updateShortText}
                    placeholder={placeholderText}
                  />
                  <CharacterCount count={shortText?.length} limit={150} />
                </div>
                <div className="sm:border-r sm:my-4 mx-4 border-b"></div>
                <div className="h-full sm:w-1/2 flex flex-col w-full">
                  <span className="p-2 text-gray-400">Long description</span>
                  <StoryDocsTextArea
                    value={longText}
                    onChange={updateLongText}
                    placeholder={placeholderText}
                  />
                  <CharacterCount count={longText?.length} limit={350} />
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div
          className={`${
            isSelectedInfo
              ? 'w-full sm:w-96 transition-all duration-300 overflow-hidden'
              : 'w-0 transition-all duration-300 overflow-hidden'
          } bg-[#FCFCFD] shrink-0 overflow-y-auto z-50`}
        >
          {docGuidelines}
        </div>
      </div>
      <div className="flex  flex-col sm:flex-row sm:items-center sm:h-18 py-2 sm:py-0 px-8 border-t border-[#EAEAEB] shrink-0 overflow-auto sm:overflow-hidden">
        {footer}
      </div>
    </div>
  );
};

export default MyStoryDocs;
