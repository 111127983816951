import AddNewCourse from './AddNewCourse';
import Dialog from '../../shared/Dialog';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useTeamStore from '../../../stores/TeamStore';
import { useShallow } from 'zustand/react/shallow';
import {
  filterUniAndTeamUniversily,
  INVITE_STATUS,
} from '../../../utils/utlityTypes';

const AddNewCourseDialog = ({
  niches = [],
  editMode = false,
  onContinue = () => {},
  onCancel = () => {},
  data = {},
  teamMemberData = [],
  disabled = false,
  setDisabled = () => {},
  isAdding,
  ...props
}) => {
  const [courseData, setCourseData] = useState(data);
  const navigate = useNavigate();
  const location = useLocation();
  const onChange = (courseData) => {
    setCourseData(courseData);
  };
  const [isCourseName, setIsCourseName] = useState(false);
  const teamStore = useTeamStore(
    useShallow((state) => ({
      teams: state.teams,
      selectedTeam: state.selectedTeam,
    }))
  );
  const selectedTeam = useTeamStore((state) => state.selectedTeam);
  const collaborators = teamStore?.selectedTeam?.id
    ? teamStore?.selectedTeam?.teamMembers
        ?.filter((profile) => profile?.invite_status === INVITE_STATUS.JOINED)
        ?.filter(
          (member) => member.email !== teamStore.selectedTeam?.studentEmail
        )
        ?.map((profile) => ({
          email: profile.email,
          id: profile.uid,
          photoURL: profile?.photoURL,
          label: profile?.firstName + ' ' + profile?.lastName,
        }))
    : [];

  return (
    <Dialog
      isHeight={true}
      disabled={disabled}
      title="Add New Course"
      maxWidth="sm"
      editMode={editMode}
      continueText={editMode ? 'Update' : 'Add'}
      onContinue={(e) => {
        e.preventDefault();
        if (disabled) return;
        if (courseData.courseName === '') {
          setIsCourseName(true);
        } else {
          setIsCourseName(false);
        }
        if (courseData.courseName !== '') {
          setDisabled(true);
          onContinue({
            ...(data.id && { id: data.id }),
            ...courseData,
          });
        }
        //TODO (RUTUJA) - FIGURE OUT WHY THIS IS HERE
        // if (location.pathname === '/myStory/courses')
        //   navigate('/myStory/courses');
      }}
      onCancel={() => {
        onCancel();
        setIsCourseName(false);
      }}
      {...props}
    >
      <AddNewCourse
        niches={niches}
        onChange={onChange}
        courseData={data}
        editMode={true}
        collaborators={filterUniAndTeamUniversily(collaborators)}
        selectedCollaborators={collaborators.filter((collaborator) =>
          data?.sharedWith?.includes(collaborator?.id)
        )}
        isCourseName={isCourseName}
        isAdding={isAdding}
      />
    </Dialog>
  );
};

export default AddNewCourseDialog;
