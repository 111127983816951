import { useEffect } from 'react';
import { useState } from 'react';
import ChooseNiche from '../../shared/ChooseNiche';
import Dialog from '../../shared/Dialog';

const ReassignNicheDialog = ({
  open = false,
  niches = [],
  selectedNiche = [],
  onNicheAdd = () => {},
  onContinue = () => {},
  onCancel = () => {},
}) => {
  const [niche, setNiche] = useState(selectedNiche);

  useEffect(() => {
    if (selectedNiche !== niche) setNiche(selectedNiche);
    //eslint-disable-next-line
  }, [selectedNiche]);

  return (
    <Dialog
      open={open}
      title="Reassign Niche"
      onCancel={onCancel}
      onContinue={(event) => onContinue(niche)}
      continueText="Reassign"
    >
      <ChooseNiche
        niches={niches}
        selectedNiche={niche}
        onNicheAdd={onNicheAdd}
        onChange={(e, newValue) => setNiche(newValue)}
      />
    </Dialog>
  );
};

export default ReassignNicheDialog;
