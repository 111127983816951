import { Tabs as TABS } from '@mui/material';
import { styled } from '@mui/system';

const Tabs = styled(TABS)(() => ({
  height: '40px',
  minHeight: '40px',
  '.MuiTabs-scroller': {
    height: '40px',
    overflow: 'auto !important',
    '&::-webkit-scrollbar': {
      display: 'none !important',
    },
  },
  '.MuiTabs-indicator': {
    backgroundColor: '#22252D',
    zIndex: 1,
  },
}));

export default Tabs;
