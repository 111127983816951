import { myStoryUtilities } from '../../../constants/staticData';
import useActivityStore from '../../../stores/ActivityStore';
import useChatStore from '../../../stores/ChatStore';
import MyStoryDocsHeader from '../MyStoryDocsHeader';

const MyStoryActivityDocsHeader = ({
  id,
  title,
  nicheName,
  isParent,
  onBack = () => {},
  onEdit = () => {},
  onInfo = () => {},
  isSelectedInfo,
  onEditHeader = () => {},
  canCreateNewUtilities = () => {},
}) => {
  const activityStore = useActivityStore();
  const chatStore = useChatStore();
  const onClickChat = () => {
    chatStore.setIsChatWindowOpen(!chatStore.isChatWindowOpen);
  };
  const menuItems = [
    {
      id: 1,
      title: 'Delete Activity',
      onClick: () => {
        activityStore.setDeleteActivityId(id);
        activityStore.setIsDeletingActivity(true);
      },
    },
  ];
  return (
    <>
      <MyStoryDocsHeader
        id={id}
        onBack={onBack}
        title={title}
        nicheName={nicheName}
        saveIcon={true}
        isParent={isParent}
        menuItems={menuItems}
        onEditHeader={onEditHeader}
        isChatWindowOpen={chatStore.isChatWindowOpen}
        onClickChat={onClickChat}
        isNicheName={true}
        onCopy={() => {
          if (canCreateNewUtilities(myStoryUtilities.ACTIVITY)) {
            activityStore.setCopyActivityId(id);
            activityStore.setIsCopyingActivity(true);
          }
        }}
        onInfo={onInfo}
        isSelectedInfo={isSelectedInfo}
        isActivity={true}
      />
    </>
  );
};

export default MyStoryActivityDocsHeader;
