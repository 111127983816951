import useCourseStore from '../../../../stores/CourseStore';
import useNicheStore from '../../../../stores/NicheStore';
import AddDetailsPopupCourseDesc from '../AddDetailsPopupCourseDesc';
import AddDetailsPopupFooter from '../AddDetailsPopupFooter';
import { ViewDetailsHeader } from './ViewDetailsHeader';

export const CourseViewDetailsPopup = ({
  utilityData = {},
  nicheName = '',
  onClose = () => {},
  isParent = false,
}) => {
  const courseStore = useCourseStore();
  const nicheStore = useNicheStore();
  return (
    <>
      <ViewDetailsHeader
        title={utilityData.name}
        type="Course"
        onClose={onClose}
        onEdit={() => {
          courseStore.editCourse(utilityData.id);
        }}
        isParent={isParent}
        onReassignNiche={() => {
          nicheStore.setIsReassignNiche(true);
          nicheStore.setReassignUtilityType('COURSE');
          nicheStore.setReassignUtilityId(utilityData.id);
        }}
      />
      <AddDetailsPopupCourseDesc
        description={utilityData?.description}
        openAddDialog={() => {
          courseStore.editCourse(utilityData.id);
        }}
        isParent={isParent}
      />
      <AddDetailsPopupFooter
        niche={nicheName}
        grades={utilityData.grades}
        reviewers={utilityData.reviewers}
      />
    </>
  );
};
