import { InputAdornment } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import useNicheStore from '../../../stores/NicheStore';
import Dialog from '../../shared/Dialog';
import SelectTeamMembers from '../../shared/SelectTeamMembers';
import InputWithTooltip from '../../shared/InputWithTooltip';
import ErrorIcon from '@mui/icons-material/Error';
import useProfileStore from '../../../stores/ProfileStore';
import { getActiveStudentReviewers } from '../../../firebase/services/getServices';
import useStudentStore from '../../../stores/StudentStore';
import { useShallow } from 'zustand/react/shallow';

const tooltipTitle = (
  <div className="text-sm">
    A niche is an area in which you have a special interest, talent, or
    experience. A niche can be anything from soccer to being an enthusiastic
    French literature fan. The combination of your classes, activities and
    honors will eventually lead you to the niches you’ll emphasize in your
    college applications.
  </div>
);

const AddNewNiche = ({
  title = 'Add Niche',
  niche = {},
  open = false,
  niches = [],
  onNicheAdd = () => {},
  onContinue = () => {},
  onCancel = () => {},
  collaborators = [],
  selectedCollaborators = [],
}) => {
  const [isNicheEmpty, setIsNicheEmpty] = useState(false);
  const [formData, setFormData] = useState({
    nicheName: niche.nicheName || '',
    sharedWith: niche.sharedWith || [],
  });
  const [nicheExists, setNicheExists] = useState(false);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState(
    defaultTeamMembers()
  );
  const nicheStore = useNicheStore();
  const isParent = useProfileStore((state) => state.isParent);
  const { activeStudentReviewers, selectedStudent } = useStudentStore(
    useShallow((state) => {
      return {
        activeStudentReviewers: state.activeStudentReviewers,
        selectedStudent: state.selectedStudent,
      };
    })
  );
  // To set the default team members
  function defaultTeamMembers() {
    if (open) {
      return collaborators;
    }
    return selectedCollaborators;
  }
  useEffect(() => {
    if (selectedTeamMembers.length > 0) {
      setFormData({
        ...formData,
        sharedWith: selectedTeamMembers.map((teamMember) => teamMember),
      });
    }
    //eslint-disable-next-line
  }, [selectedTeamMembers]);

  const handleChange = useCallback(
    (e) => {
      setFormData({ ...formData, nicheName: e.target.value });
      setNicheExists(
        niches
          .map((niche) => niche.label.toLowerCase())
          .includes(e.target.value.toLowerCase())
      );
    },
    [formData, niches]
  );

  return (
    <Dialog
      title={title}
      continueText={`${nicheStore.isEditingNiche ? 'Update' : 'Add'}`}
      open={open}
      onContinue={() => {
        if (formData.nicheName === '') {
          setIsNicheEmpty(true);
        } else {
          setIsNicheEmpty(false);
        }
        if (formData.nicheName !== '') {
          if (isParent) {
            onContinue({ ...formData, createdBy: selectedStudent?.uid });
          } else {
            onContinue(formData);
          }
          setNicheExists(false);
        }
      }}
      onCancel={() => {
        onCancel();
        setNicheExists(false);
        setIsNicheEmpty(false);
      }}
    >
      <div className="flex flex-col gap-8 py-2">
        <div className="flex flex-col gap-2">
          <InputWithTooltip
            error={nicheExists || isNicheEmpty}
            helperText={
              nicheExists && !isNicheEmpty ? (
                <div className="flex items-center gap-1 text-xs">
                  <ErrorIcon color="error" className="text-base" />
                  {`Niche ${formData.nicheName} already exists`}
                </div>
              ) : (
                isNicheEmpty &&
                !nicheExists && (
                  <div className="flex items-center gap-1 text-xs">
                    <ErrorIcon color="error" className="text-base" />
                    Enter Niche Name
                  </div>
                )
              )
            }
            required
            fullWidth
            label="Niche Name"
            placeholder="E.g. Marine Biology Conservationist or Social Media Entrepreneur"
            onChange={handleChange}
            value={formData?.nicheName}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
            sx={{
              '.MuiFormHelperText-root': {
                marginLeft: 0,
                marginTop: '5px',
              },
            }}
            title={tooltipTitle}
          />

          {!nicheStore.isSelectTeamMember && nicheStore.utilityName && (
            <div className="font-medium text-xs text-grey-900/50">
              Think of other niches where this {nicheStore.utilityName} might
              fit
            </div>
          )}
        </div>
        {nicheStore.isSelectTeamMember && (
          <div className="w-full">
            <SelectTeamMembers
              label="Select Team Members (optional)"
              options={collaborators}
              value={selectedTeamMembers}
              menuListTitle="Select your Existing Team Members"
              onChange={(e, teamMembers) => {
                setSelectedTeamMembers(teamMembers);
              }}
              tooltipTitle="You can select or add Team Members with whom you wish to collaborate"
            />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default AddNewNiche;
