import TagsInput from '../../shared/TagsInput';
import Tooltip from '../../shared/Tooltip';

export default function HonorLevelTagInput({
  label = 'Level of Honor',
  placeholder = '',
  tooltipTitle = '',
  menuListCount = 5,
  options = [],
  ...props
}) {
  return (
    <Tooltip title={tooltipTitle} placement="right">
      <TagsInput
        multiple
        label={label}
        placeholder={placeholder}
        menuListCount={menuListCount}
        options={options}
        isViewAll={false}
        sx={{
          '.MuiFormControl-root': {
            width: '100%',
            '.MuiOutlinedInput-notchedOutline': {
              span: {
                margin: '0px 4px',
              },
            },
            '.MuiAutocomplete-endAdornment': {
              '.MuiAutocomplete-popupIndicator': {
                display: 'none',
              },
            },
          },
        }}
        {...props}
      />
    </Tooltip>
  );
}
