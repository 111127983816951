import create from 'zustand';

const useSubscriptionStore = create((set) => ({
  showUpgradeBanner: false,
  setShowUpgradeBanner: (showUpgradeBanner) =>
    set(() => ({ showUpgradeBanner })),
  upgradeBannerUtil: null,
  setUpgradeBannerUtil: (upgradeBannerUtil) =>
    set(() => ({ upgradeBannerUtil })),
  showSubscriptionDialog: false,
  setShowSubscriptionDialog: (showSubscriptionDialog) =>
    set(() => ({ showSubscriptionDialog })),
}));

export default useSubscriptionStore;
