import React from 'react';
import essayZeroState from '../../assets/svgs/essay-zero-state.svg';
import AddEssayButton from '../../components/myEssay/AddEssayButton';
import useAccessCheck from '../../utils/accessCheck';
import { UTILITY_TYPES } from '../../utils/utlityTypes';
export const EssaySectionZeroState = () => {
  const [checkAccess] = useAccessCheck([UTILITY_TYPES.Essay]);
  return (
    <div className="flex justify-between">
      <div className=" flex flex-col gap-3">
        <h2 className="font-normal text-xl leading-5 text-black font-archivo">
          Start by creating a Master Essay
        </h2>
        <p className="font-normal text-base leading-6 mb-6 text-neutral-600 font-roboto">
          Tired of endless essays? Create one core essay <br /> for multiple
          colleges!
        </p>

        <AddEssayButton
          showDropDown={true}
          variant="contained"
          text="Start Essay"
          canCreateNewEssays={checkAccess}
        />
      </div>
      <div>
        <img src={essayZeroState} alt="" srcset="" />
      </div>
    </div>
  );
};
