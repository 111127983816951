import { useEffect, useState } from 'react';
import ChooseNiche from '../../shared/ChooseNiche';
import InputWithTooltip from '../../shared/InputWithTooltip';
import SelectTeamMembers from '../../shared/SelectTeamMembers';
import AddMoreHonorDetails from './AddMoreHonorDetails';
import ErrorIcon from '@mui/icons-material/Error';
import { useLocation } from 'react-router-dom';
import Alert from '../../shared/Alert';
import useStudentStore from '../../../stores/StudentStore';
import TextAreaWithTooltip from '../../shared/TextAreaWithTooltip';
const tooltipTitle = (
  <div className="text-sm flex flex-col gap-5">
    <div>
      This a space where you’ll track all of your awards and honors during high
      school.
    </div>
    <div>
      Awards and honors are an excellent way to demonstrate your talents and
      accomplishments and stand out from the crowd.
    </div>
  </div>
);
const AddNewHonor = ({
  niches,
  honorData = {},
  honorTypes = [],
  onChange = () => {},
  editMode = false,
  collaborators = [],
  selectedCollaborators = [],
  isAdding = false,
  isHonorName,
}) => {
  const [formData, setFormData] = useState({
    honorName: honorData.honorName || '',
    nicheId: honorData.nicheId || '',
    grades: honorData.grades || [],
    honorTypes: honorData.honorTypes || [],
    sharedWith: honorData.sharedWith || [],
    docInformation: honorData.docInformation || [],
  });
  const activeStudentReviewers = useStudentStore(
    (state) => state.activeStudentReviewers
  );
  const location = useLocation();
  function defaultTeamMembers() {
    if (isAdding) {
      return collaborators;
    }
    return selectedCollaborators;
  }
  const [selectedTeamMembers, setSelectedTeamMembers] = useState(
    defaultTeamMembers()
  );

  useEffect(() => {
    setFormData({
      ...formData,
      sharedWith: selectedTeamMembers.map((teamMember) => {
        return {
          id: teamMember?.id,
          email: teamMember?.email,
          photoURL: teamMember?.photoURL,
          uid: teamMember?.uid,
        };
      }),
    });
    //eslint-disable-next-line
  }, [selectedTeamMembers]);

  useEffect(() => {
    onChange(formData);
    //eslint-disable-next-line
  }, [formData]);

  return (
    <div className="flex flex-col gap-8 py-4 px-2">
      {isAdding && location.pathname === '/myStory/niche' && (
        <Alert className="w-fit mt-3" severity="info">
          Note: Adding Honors from Niche will also create that item in your
          Honors section. You’ll also be able to add further details and
          collaborate in the Honors section.
        </Alert>
      )}
      {isAdding && location.pathname === '/myHub' && (
        <Alert className="w-fit mt-3" severity="info">
          Note: Adding Honors from the Hub will also create that item in your
          Honors section. You’ll also be able to add further details and
          collaborate in the Honors section.
        </Alert>
      )}
      <div className="flex shrink-0">
        <InputWithTooltip
          error={isHonorName}
          required
          label="Honor"
          placeholder="E.g. NC Youth & Government Award"
          title={tooltipTitle}
          value={formData.honorName}
          onChange={(e) => {
            setFormData({ ...formData, honorName: e.target.value });
          }}
          helperText={
            isHonorName && (
              <div className="flex items-center gap-1 text-xs">
                <ErrorIcon color="error" className="text-base" />{' '}
                {`Enter Honor Title`}
              </div>
            )
          }
        />
      </div>
      <div>
        <ChooseNiche
          niches={niches}
          utilityName="Honor"
          selectedNiche={[formData.nicheId]}
          onChange={(e, niche) => {
            if (niche !== null) {
              setFormData({ ...formData, nicheId: niche });
            }
          }}
        />
      </div>
      <>
        <TextAreaWithTooltip
          title={
            'Most colleges, including those that use the Common App, allow 100 characters for your Honors description.'
          }
          placeholder="E.g. 1st place, NC Youth & Government, wrote and defended a bill on raising the minimum wage"
          redIndicator={true}
          maxLength="100"
          label="Short Description"
          value={
            formData?.docInformation?.find(
              (doc) => doc.label === '100 Characters'
            )?.content || ''
          }
          onChange={(e) => {
            setFormData({
              ...formData,
              docInformation: [
                {
                  label: '100 Characters',
                  content: e.target.value,
                  charCount: e.target.value.length,
                },
                ...formData.docInformation.filter(
                  (doc) => doc.label !== '100 Characters'
                ),
              ],
            });
          }}
        />
        <TextAreaWithTooltip
          title={"Some colleges (like the UC's) allow 250 characters."}
          placeholder="E.g. 1st place, NC Youth & Government, wrote and defended a bill on raising the minimum wage. Competition was against winning teams from top 20 high schools in the state."
          redIndicator={true}
          maxLength="250"
          label="Long Description"
          value={
            formData?.docInformation?.find(
              (doc) => doc.label === '250 Characters'
            )?.content || ''
          }
          onChange={(e) => {
            setFormData({
              ...formData,
              docInformation: [
                {
                  label: '250 Characters',
                  content: e.target.value,
                  charCount: e.target.value.length,
                },
                ...formData.docInformation.filter(
                  (doc) => doc.label !== '250 Characters'
                ),
              ],
            });
          }}
        />
      </>

      <div>
        <AddMoreHonorDetails
          grades={formData.grades}
          allHonorTypes={honorTypes}
          honorTypes={formData.honorTypes}
          expanded={editMode}
          onChange={({ ...honorDetails }) => {
            setFormData({ ...formData, ...honorDetails });
          }}
        />
      </div>
      <div>
        <SelectTeamMembers
          label="Select Team Members (optional)"
          options={collaborators}
          value={selectedTeamMembers}
          menuListTitle="Select your Existing Team Members"
          onChange={(e, teamMembers) => {
            setSelectedTeamMembers(teamMembers);
          }}
          tooltipTitle={`You can select or add Team Members with whom you wish to collaborate`}
        />
      </div>
    </div>
  );
};

export default AddNewHonor;
