import { useState } from 'react';
import EditUtilityDetails from '../../shared/EditUtilityDetails';
import useStudentStore from '../../../stores/StudentStore';

const EditActivityDetails = ({
  niches = [],
  onContinue = () => {},
  disabled = false,
  setDisabled = () => {},
  data,
  ...args
}) => {
  const [activityData, setActivityData] = useState(data);
  const isParent = useStudentStore((state) => state.isParent);
  const onChange = (activityData) => {
    setActivityData(activityData);
  };
  const selectedStudent = useStudentStore((state) => state.selectedStudent);

  return (
    <EditUtilityDetails
      {...args}
      activityData={data}
      title="Edit Activity and Niche Name"
      label="Activity"
      onChange={onChange}
      disabled={disabled}
      onContinue={() => {
        if (disabled) return;
        setDisabled(true);
        if (isParent) {
          onContinue({
            ...(data.id && { id: data.id }),
            ...activityData,
            createdBy: selectedStudent?.uid,
          });
        } else {
          onContinue({
            ...(data.id && { id: data.id }),
            ...activityData,
          });
        }
      }}
      niches={niches}
    />
  );
};

export default EditActivityDetails;
