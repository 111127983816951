import { AddCircle } from '@mui/icons-material';
import Button from '../../shared/Button';

const AddDetailsPopupEmptyBody = ({
  openStoryDoc = () => {},
  isParent = false,
}) => {
  return (
    <div className="p-6 flex-1">
      {!isParent ? (
        <div className="flex flex-col h-full bg-[#FCFCF9] border-2 border-dashed border-[#E7E7E1] gap-2 justify-center items-center rounded-xl">
          <div className="text-xl text-[#22252D] opacity-70 font-medium">
            No Details Added Yet
          </div>
          <div>
            <Button
              variant="text"
              startIcon={<AddCircle />}
              onClick={openStoryDoc}
            >
              Start Adding Details Now
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col h-full bg-[#FCFCF9] border-2 border-dashed border-[#E7E7E1] gap-2 justify-center items-center rounded-xl">
          <div className="text-xl text-[#22252D] opacity-70 font-medium">
            Details yet to be added
          </div>
        </div>
      )}
    </div>
  );
};

export default AddDetailsPopupEmptyBody;
