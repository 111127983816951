import { TextField, InputAdornment } from '@mui/material';
import { styled } from '@mui/system';
import Tooltip from './Tooltip';

const CustomTextArea = styled(TextField)(() => ({
  width: '100%',
  borderRadius: '8px',
  fontSize: '14px',
  color: '#22252D',
  '.MuiOutlinedInput-notchedOutline': {
    span: {
      margin: '0px 8px',
    },
  },
}));

const TextAreaWithTooltip = ({
  minRows = 2,
  maxRows = 2,
  maxLength = 100,
  value = '',
  label = 'Tell us about your course',
  title,
  redIndicator = false,
  ...props
}) => {
  return (
    <div>
      <div className="mb-1">
        {title ? (
          <Tooltip title={title} arrow placement="right">
            <CustomTextArea
              label={label}
              multiline
              minRows={minRows}
              maxRows={maxRows}
              inputProps={redIndicator ? {} : { maxLength: maxLength }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
              value={value}
              {...props}
            />
          </Tooltip>
        ) : (
          <CustomTextArea
            label={label}
            multiline
            minRows={minRows}
            maxRows={maxRows}
            inputProps={redIndicator ? {} : { maxLength: maxLength }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
            value={value}
            {...props}
          />
        )}
      </div>
      <div
        className={`flex justify-end text-xs text-[#7D7D7D] ${
          redIndicator && value?.length > maxLength
            ? 'text-red-500'
            : 'text-[#7D7D7D]'
        }`}
      >
        {value.length}/{maxLength} Characters
      </div>
    </div>
  );
};
export default TextAreaWithTooltip;
