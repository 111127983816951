import ScoreInputField from './ScoreInputFiled';
import Tooltip from '../../shared/Tooltip';
import { useEffect, useState } from 'react';
import { SelectDateButton } from '../../shared/SelectDateButton';
import InfoIcon from '@mui/icons-material/Info';
import Alert from '../../shared/Alert';
import Button from '../../shared/Button';
import { AddCircle } from '@mui/icons-material';

export const AddNewACTScore = ({
  actScores = [],
  actSubjects = [],
  actTarget = [],
  onChangeScore = () => {},
  onChangeTarget = () => {},
  isAddingACTTarget = false,
  isEditingACTTarget = false,
  scoreData = [],
  targetData = [],
  isContinue = false,
}) => {
  const [isInputDisable, setIsInputDisable] = useState(false);
  const [isTargetScore, setIsTargetScore] = useState(
    isAddingACTTarget || isEditingACTTarget ? true : false
  );
  const [selectedDate, setSelectedDate] = useState(
    (scoreData?.testDate !== '' && scoreData?.testDate?.toDate()) || ''
  );
  const [isFutureDate, setIsFutureDate] = useState(false);
  const [actScoreData, setACTScoreData] = useState(scoreData?.scores || []);
  const [actTargetData, setACTTargetData] = useState(targetData?.targets || []);

  const onScoreChange = (subjectId, subjectName, newValue) => {
    if (actScoreData.length > 0) {
      const existingSubject = actScoreData.find(
        (score) => score.subjectId === subjectId
      );
      if (existingSubject) {
        setACTScoreData(
          actScoreData.map((score) =>
            score.subjectId === subjectId
              ? { ...score, scoreValue: newValue }
              : score
          )
        );
      } else {
        setACTScoreData([
          ...actScoreData,
          { subjectId, subjectName, scoreValue: newValue },
        ]);
      }
    } else {
      setACTScoreData([{ subjectId, subjectName, scoreValue: newValue }]);
    }
  };

  const onTargetChange = (subjectId, subjectName, newValue) => {
    if (actTargetData.length > 0) {
      const existingSubject = actTargetData.find(
        (score) => score.subjectId === subjectId
      );
      if (existingSubject) {
        setACTTargetData(
          actTargetData.map((score) =>
            score.subjectId === subjectId
              ? { ...score, targetValue: newValue }
              : score
          )
        );
      } else {
        setACTTargetData([
          ...actTargetData,
          { subjectId, subjectName, targetValue: newValue },
        ]);
      }
    } else {
      setACTTargetData([{ subjectId, targetValue: newValue }]);
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    if (selectedDate) {
      if (selectedDate <= currentDate) {
        setIsFutureDate(false);
        setIsInputDisable(false);
        if (actScoreData.length > 0) {
          onChangeScore({
            scores: actScoreData,
            testDate: selectedDate,
          });
        } else {
          onChangeScore({
            testDate: selectedDate,
            scores: [],
          });
        }
      } else {
        setIsFutureDate(true);
        setIsInputDisable(true);
        setACTTargetData([]);
        onChangeScore({
          testDate: selectedDate,
          scores: [],
        });
      }
    }
    // eslint-disable-next-line
  }, [actScoreData, selectedDate]);

  const targetDataExists = () => {
    const targetDataEmpty = actTargetData.filter(
      (target) => target?.targetValue === ''
    );
    return targetDataEmpty.length !== actTargetData.length;
  };

  useEffect(() => {
    if (actTargetData.length > 0 && targetDataExists()) {
      onChangeTarget(actTargetData);
    } else {
      onChangeTarget([]);
    }
    // eslint-disable-next-line
  }, [actTargetData]);

  const targetTooltip = (
    <div className="flex flex-col gap-3 text-1sm">
      <div>Target Score</div>
      <div>
        Determine your target score by looking at the median scores for the
        colleges to which you’d like to apply. Your target is the score you
        think will give you the best chance of admission to these colleges,
        taking their median scores into account.
      </div>
    </div>
  );

  const selectDateTooltipTile = `You can also select a future date and
    add your score later, after you’ve taken the test.`;
  return (
    <div>
      {actTarget.length === 0 &&
        actScores.length === 0 &&
        !isAddingACTTarget &&
        !isEditingACTTarget && (
          <div className="text-lead-500 text-1sm">
            Add the Target Score you wish to achieve. Also add your ACT Actual
            Score along with the date you appeared on or wish to appear on.
          </div>
        )}

      {((actScores.length !== 0 &&
        scoreData.length === 0 &&
        !(isAddingACTTarget || isEditingACTTarget)) ||
        scoreData?.scores?.length === 0) && (
        <div className="text-lead-500 text-1sm">Add your ACT Actual score</div>
      )}

      {actScores.length !== 0 &&
        scoreData?.scores?.length !== 0 &&
        scoreData.length !== 0 &&
        !(isAddingACTTarget || isEditingACTTarget) && (
          <div className="text-lead-500 text-1sm">
            Edit your ACT Actual Score.
          </div>
        )}

      {isAddingACTTarget && (
        <div className="text-lead-500 text-1sm pb-2">
          Add the Target Score you wish to achieve.
        </div>
      )}

      {isEditingACTTarget && (
        <div className="text-lead-500 text-1sm pb-2">
          Edit the Target Score you wish to achieve.
        </div>
      )}

      {isFutureDate && (
        <Alert className="w-fit mt-3" severity="info">
          As you have selected a future date, you will be able to add actual
          scores after you have taken the test.
        </Alert>
      )}
      {isContinue && selectedDate === '' && (
        <Alert className="w-fit mt-3" severity="error">
          Select the date on which you appeared or plan to appear for your ACT
          test.
        </Alert>
      )}
      {!isAddingACTTarget && !isEditingACTTarget && (
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-6 px-6 pt-4">
          <div
            className={`flex flex-col gap-1 ${
              actTarget?.length === 0 ? 'col-start-3' : 'col-start-2'
            } ${isTargetScore && 'hidden'}`}
          >
            <div className="text-xs text-grey-900 opacity-50">
              {!scoreData?.testDate && selectedDate === ''
                ? ' Took on / Take on:'
                : isFutureDate
                ? 'Take on :'
                : 'Took on :'}
            </div>

            <Tooltip
              title={selectedDate === '' && selectDateTooltipTile}
              placement="right"
            >
              <div className="w-fit">
                <SelectDateButton
                  value={selectedDate}
                  onChange={(newValue) => {
                    setSelectedDate(newValue.$d);
                  }}
                />
              </div>
            </Tooltip>
          </div>
        </div>
      )}
      <div className="grid grid-cols-2 sm:grid-cols-3 px-0 sm:px-6 py-4 gap-6 items-center font-medium text-grey-900">
        <div>Subject</div>
        {(actTarget.length === 0 ||
          isAddingACTTarget ||
          isEditingACTTarget) && (
          <div
            className={`gap-1 items-center flex sm:flex ${
              !isTargetScore && 'hidden'
            }`}
          >
            <div>Target Score (Optional)</div>
            <Tooltip title={targetTooltip} placement="right">
              <InfoIcon
                sx={{
                  height: '16px',
                  width: '16px',
                }}
              />
            </Tooltip>
          </div>
        )}
        <div>
          {(actTarget?.length === 0 && actScores?.length === 0) ||
          (!isAddingACTTarget && !isEditingACTTarget) ? (
            <div>Actual Score</div>
          ) : (
            ''
          )}
        </div>
      </div>
      {actSubjects?.map((actSubject) => {
        return (
          <div
            className="grid grid-cols-2 sm:grid-cols-3 px-0 sm:px-6 py-4 gap-6 items-center text-xs text-grey-900/50"
            key={actSubject.id}
          >
            <div>{actSubject?.subject}</div>
            {(actTarget.length === 0 ||
              isAddingACTTarget ||
              isEditingACTTarget) && (
              <ScoreInputField
                minScore={actSubject?.minScore}
                maxScore={actSubject?.maxScore}
                className={`sm:!flex w-full sm:w-110 ${
                  !isTargetScore && '!hidden'
                }`}
                value={
                  actTargetData?.find(
                    (target) => target.subjectId === actSubject.id
                  )?.targetValue || ''
                }
                onChange={(newValue) => {
                  onTargetChange(actSubject.id, actSubject.subject, newValue);
                }}
                isHelperText={true}
              />
            )}
            {(actTarget?.length === 0 && actScores?.length === 0) ||
            (!isAddingACTTarget && !isEditingACTTarget) ? (
              <ScoreInputField
                className={`w-full sm:w-110 ${isTargetScore && '!hidden'}`}
                disabled={isInputDisable}
                minScore={actSubject?.minScore}
                maxScore={actSubject?.maxScore}
                value={
                  actScoreData?.find(
                    (score) => score.subjectId === actSubject.id
                  )?.scoreValue || ''
                }
                onChange={(newValue) => {
                  onScoreChange(actSubject?.id, actSubject?.subject, newValue);
                }}
                isHelperText={true}
              />
            ) : (
              ''
            )}
          </div>
        );
      })}
      {actTarget.length === 0 && !isAddingACTTarget && !isEditingACTTarget && (
        <div className="flex sm:hidden flex-col gap-4 text-xs text-grey-400 font-medium">
          {!isTargetScore && (
            <>
              <b className="">Target Score</b>
              <div>
                Determine your target score by looking at the median scores for
                the colleges to which you’d like to apply. Your target is the
                test the score you think will give you the best chance of
                admission to these colleges, taking their median scores into
                account.
              </div>
            </>
          )}
          <Button
            startIcon={<AddCircle />}
            variant="text"
            className="w-fit"
            onClick={() => setIsTargetScore(!isTargetScore)}
          >
            {!isTargetScore ? 'Add Target Score' : 'Add Score'}
          </Button>
        </div>
      )}
    </div>
  );
};
