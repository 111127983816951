import { generateModifiedData } from '../components/myApplications/MyApplicationLetters';
import { supplementalTags } from '../constants/other';

// Profile Items Keywords
export const accountPersonalKeywords = {
  birthDay: 'Birthday',
  gender: 'Gender',
  firstName: 'First Name',
  lastName: 'Last Name',
};

export const getFormattedStringFromArray = (items) => {
  let formattedString = '';
  if (Array.isArray(items)) {
    items?.forEach((item) => {
      if (items.indexOf(item) === 0) {
        formattedString = formattedString.concat(item);
      } else if (items.indexOf(item) !== items.length - 1) {
        formattedString = formattedString.concat(`, ${item}`);
      } else {
        formattedString = formattedString.concat(` and ${item}`);
      }
    });
  }
  return formattedString;
};

export const handleValidationCheck = (obj) => {
  let keysFounded = [];

  Object.keys(obj).forEach((item) => {
    if (
      typeof obj[item] === 'string' &&
      (obj[item] === null || obj[item].trim() === '')
    ) {
      return keysFounded.push(
        `${
          !!accountPersonalKeywords[item] ? accountPersonalKeywords[item] : item
        } is empty.`
      );
    } else if (obj[item] === 0) {
      return keysFounded.push(`${item} is can't be 0.`);
    }
  });
  if (keysFounded.length === 0) {
    keysFounded = false;
  }
  return keysFounded;
};

export const currentDateChecker = (givenDate) => {
  if (givenDate) {
    const currentDate = new Date();
    if (currentDate?.getTime() >= givenDate?.getTime()) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

export const capitalizeFirstLetter = (string) => {
  if (string && string !== '')
    return string.charAt(0).toUpperCase() + string.slice(1);
  return '';
};

export const checkEssayAdded = (id, allEssays) => {
  return allEssays?.find((essay) => essay?.essayReferenceId === id);
};

export const sortData = (arrayData) => {
  if (arrayData?.length > 0) {
    return arrayData.sort((a, b) => {
      if (!a?.updatedAt && !b?.updatedAt) {
        return (
          b.createdAt?.toDate().valueOf() - a.createdAt?.toDate().valueOf()
        );
      } else if (a?.updatedAt && b?.updatedAt) {
        return (
          b.updatedAt?.toDate().valueOf() - a.updatedAt?.toDate().valueOf()
        );
      } else if (b?.updatedAt && !a?.updatedAt) {
        return (
          b.updatedAt?.toDate().valueOf() - a.createdAt?.toDate().valueOf()
        );
      } else if (a?.updatedAt && !b?.updatedAt) {
        return (
          b.createdAt?.toDate().valueOf() - a.updatedAt?.toDate().valueOf()
        );
      }
      return [];
    });
  }
  return [];
};

export function checkWrongScore(subjects, scores) {
  let errorArray = subjects?.map((item) => {
    let score = scores?.scores.find((value) => value.subjectId === item.id);
    if (
      (score?.scoreValue > item.maxScore ||
        score?.scoreValue < item.minScore) &&
      score?.subjectId === item.id
    ) {
      return true;
    } else {
      return false;
    }
  });
  const getErrorState = (element) => element === true;
  return errorArray.some(getErrorState);
}

export function checkWrongTarget(subjects, targets) {
  let errorArray = subjects?.map((item) => {
    let score = targets?.find((value) => value.subjectId === item.id);
    if (
      (score?.targetValue > item.maxScore ||
        score?.targetValue < item.minScore) &&
      score?.subjectId === item.id
    ) {
      return true;
    } else {
      return false;
    }
  });
  const getErrorState = (element) => element === true;
  return errorArray.some(getErrorState);
}

export const getFormattedDate = (date) => {
  if (date && date !== '') {
    let newDate;
    if (date.seconds) {
      newDate = date?.toDate();
    } else {
      newDate = date;
    }
    const formattedDate =
      (newDate?.getMonth() > 8
        ? newDate?.getMonth() + 1
        : '0' + (newDate?.getMonth() + 1)) +
      '/' +
      (newDate?.getDate() > 9 ? newDate?.getDate() : '0' + newDate?.getDate()) +
      '/' +
      newDate?.getFullYear();
    return formattedDate;
  }
  return '--';
};

export const getFormattedDateInMonth = (date) => {
  if (date && date !== '') {
    const formattedDate = date.toDate().toLocaleDateString('en-us', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
    return formattedDate;
  }
  return '--';
};

// Parse date mm/dd/yyyy to Date object
export const parseDate = (dateInString) => {
  if (!dateInString) return '';
  const [month, day, year] = dateInString.split('-');
  const date = day && month && year && new Date(+year, month - 1, +day);
  return { date };
};

export const getSingularUtility = (utilName) => {
  switch (utilName) {
    case 'Activities':
      return 'Activity';
    case 'Honors':
      return 'Honor';
    case 'Courses':
      return 'Course';
    case 'Niche':
      return 'Niche';
    case 'Tests':
      return 'Test';
    case 'Essays':
      return 'Essay';
    case 'Applications':
      return 'Application';
    default:
      return utilName;
  }
};

export const prefixCount = (count) => {
  return count && String(count).length === 1 ? `0${count}` : count;
};

export const isUtilityState = (array) => {
  let isCompleted = false;
  if (Array.isArray(array) && array?.length > 0) {
    isCompleted = array?.map((object) => {
      return Object.values(object).every((x) => x !== null && x !== '');
    });
    return isCompleted.every((item) => item);
  }
  return false;
};

export const generateRandomId = () => {
  const randLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26));
  const uniqid = randLetter + Date.now();
  return uniqid;
};

export const removeRow = (utilityID, data) => {
  return data?.filter((item) => item.id !== utilityID);
};

export const getUtilityLabel = (title, optionsArray) => {
  if (optionsArray?.length > 0) {
    return optionsArray?.find((item) => item?.id === title)?.label;
  }
  return false;
};

export const getApplicationsUtilityStatus = (utilityData, dataItems) => {
  if (utilityData?.length > 0) {
    const filteredDataWithoutIgnore = utilityData?.filter(
      (data) => data?.status !== 'Ignore'
    );
    const allSubmitted = filteredDataWithoutIgnore.every(
      (item) => item.status === 'Submitted'
    );
    const notStarted = filteredDataWithoutIgnore.every(
      (item) =>
        item.status === 'Not Yet Started' ||
        item.status === 'Not Yet Requested' ||
        item.status === 'Not Yet Taken'
    );
    const allIgnore = utilityData.every((item) => item.status === 'Ignore');
    if (allIgnore) {
      return 'not-required';
    } else if (allSubmitted) {
      return 'completed';
    } else if (notStarted) {
      return 'not-started';
    } else {
      return 'in-progress';
    }
  }
  return 'not-started';
};
export const getApplicationsTestStatus = (
  utilityData,
  isSATScores,
  isACTScores
) => {
  if (utilityData) {
    const status = utilityData.some((item) => item.status === 'Submitted');
    if (status) {
      return 'completed';
    } else if (
      utilityData?.length === 2 &&
      utilityData.every((item) => item.status === 'Ignore')
    ) {
      return 'not-required';
    } else if (utilityData.some((item) => item.status !== 'Not Yet Taken')) {
      return 'in-progress';
    }
  }
  if (isSATScores?.length > 0 || isACTScores?.length > 0) {
    return 'in-progress';
  }
  return 'not-started';
};

export const getOverwrittenData = (items) => {
  let newList = [];
  items &&
    items.length &&
    items.forEach((item) => {
      if (
        item?.id &&
        !newList.find((existingItem) => existingItem?.id === item?.id)
      ) {
        if (item?.autoLoaded) {
          const overwrittenRow = items.find((letter) =>
            item?.autoLoaded
              ? letter?.refId === item.id
              : letter?.id === item.refId
          );
          if (!overwrittenRow) {
            newList.push(item);
          }
        } else {
          newList.push(item);
        }
      }
    });
  return newList;
};

export const getPlatformResources = (itemList, applicationData) => {
  return itemList?.filter(
    (essay) =>
      essay.whereSubmitted === applicationData?.applicationPlatformName ||
      essay.whereSubmitted === 'college-website'
  );
};

export const getSupplementalData = (itemList, applicationData) => {
  return itemList?.filter(
    (essay) =>
      essay.whereSubmitted === applicationData?.applicationPlatformName ||
      essay.whereSubmitted === 'college-website' ||
      supplementalTags.find(
        (tag) => tag.applicationPlatform === essay.whereSubmitted
      )
  );
};

export const getSubmittedValue = (utilityData) => {
  let submittedCount = 0;
  if (utilityData) {
    utilityData?.forEach((item) => {
      if (item?.status === 'Submitted') {
        submittedCount = submittedCount + 1;
      }
    });
  }
  return submittedCount;
};

const getEssaysCountByUtilityData = (utilityData) => {
  const essayOverwrittenData = utilityData?.essay;

  const essayList = utilityData?.essays;

  // Create a map for quick lookup of refId and its status in essayOverwrittenData
  const overwrittenEssayStatus = new Map();
  essayOverwrittenData?.forEach((item) => {
    overwrittenEssayStatus.set(item?.refId, item?.status);
  });

  // Filter and count the essays that are not ignored and either not started or not present in essayOverwrittenData
  const validEssayCount = essayList?.filter((essay) => {
    const refId = essay?.essayId || essay?.id;
    const statusInOverwritten = overwrittenEssayStatus.get(refId);

    // If the essay is not present in the overwritten data or status is not 'ignore'
    return (
      statusInOverwritten === undefined || statusInOverwritten !== 'Ignore'
    );
  }).length;

  return validEssayCount;
};

const getSupplementalCountByUtilityData = (utilityData) => {
  const supplementalOverwrittenData = utilityData?.supplemental;

  const supplementalList = utilityData?.supplementalInfo;

  // Create a map for quick lookup of refId and its status in supplementalOverwrittenData
  const overwrittenSupplementalStatus = new Map();
  supplementalOverwrittenData?.forEach((item) => {
    overwrittenSupplementalStatus.set(item?.id, item?.status);
  });

  // Filter and count the supplementals that are not ignored and either not started or not present in supplementalOverwrittenData
  const validSupplementalCount = supplementalList?.filter((supplemental) => {
    const refId = supplemental?.id;
    const statusInOverwritten = overwrittenSupplementalStatus.get(refId);

    // If the supplemental is not present in the overwritten data or status is not 'ignore'
    return (
      statusInOverwritten === undefined || statusInOverwritten !== 'Ignore'
    );
  }).length;

  return validSupplementalCount;
};

const getTranscriptCountByUtilityData = (utilityData) => {
  const transcriptOverwrittenData = utilityData?.transcriptDetails;

  const transcriptList = utilityData?.tranScripts;

  // Create a map for quick lookup of refId and its status in transcriptOverwrittenData
  const overwrittenTranscriptStatus = new Map();
  transcriptOverwrittenData?.forEach((item) => {
    overwrittenTranscriptStatus.set(item?.id, item?.status);
  });

  // Filter and count the transcripts that are not ignored and either not started or not present in transcriptOverwrittenData
  const validTranscriptCount = transcriptList?.filter((transcript) => {
    const refId = transcript?.id;
    const statusInOverwritten = overwrittenTranscriptStatus.get(refId);

    // If the transcript is not present in the overwritten data or status is not 'ignore'
    return (
      statusInOverwritten === undefined || statusInOverwritten !== 'Ignore'
    );
  }).length;

  return validTranscriptCount;
};

const getTestCountByUtilityData = (utilityData) => {
  const testOverwrittenData = utilityData?.testDetails;

  const testList = utilityData?.tests;

  // Create a map for quick lookup of refId and its status in testOverwrittenData
  const overwrittenTestStatus = new Map();
  testOverwrittenData?.forEach((item) => {
    overwrittenTestStatus.set(item?.id, item?.status);
  });

  // Filter and count the tests that are not ignored and either not started or not present in testOverwrittenData
  const validTestCount = testList?.filter((test) => {
    const refId = test?.id;
    const statusInOverwritten = overwrittenTestStatus.get(refId);
    // If the test is not present in the overwritten data or status is not 'ignore'
    return (
      test?.testDesc !== 'not-accepted' &&
      (statusInOverwritten === undefined || statusInOverwritten !== 'Ignore')
    );
  }).length;

  return validTestCount;
};

const getTotalUtilityCount = (utilityData) => {
  // To calculate the total essay count excluding ignored essays
  // const essayCount1 =
  //   getOverwrittenData(
  //     getPlatformResources(utilityData?.essays, utilityData)
  //   )?.filter((item) => {
  //     const result = utilityData?.essay?.find(
  //       (essay) => essay?.refId === item.id && ((item?.status !== 'Ignore') || (item?.status !== 'Submitted'))
  //     );
  //     return result;
  //   })?.length || 0;

  const essayCount = getEssaysCountByUtilityData(utilityData);

  // To calculate the total supplemental count excluding ignored supplementals
  // const supplementalCount =
  //   getOverwrittenData(
  //     getSupplementalData(utilityData?.supplementalInfo, utilityData)
  //   )?.filter((item) => {
  //     const result = utilityData?.supplemental?.find(
  //       (supplement) =>
  //         supplement?.id === item.id && ((item?.status !== 'Ignore') || (item?.status !== 'Submitted'))
  //     );
  //     return result;
  //   })?.length || 0;

  // NOTE: need confirmation from rick
  const supplementalCount = getSupplementalCountByUtilityData(utilityData);

  // To calculate the total letters count excluding ignored letters
  const lettersData = getOverwrittenData(utilityData?.letters);
  const modifiedLettersData = generateModifiedData(
    lettersData,
    utilityData?.recLetters
  );
  const lettersCount =
    modifiedLettersData?.filter((item) => item?.status !== 'Ignore')?.length ||
    0;

  // const transcriptCount =
  //   getOverwrittenData(utilityData?.tranScripts).filter((item) =>
  //     utilityData?.transcriptDetails?.find(
  //       (transcript) =>
  //         transcript?.id === item.id && transcript?.status !== 'Ignore'
  //     )
  //   )?.length || 0;

  const transcriptCount = getTranscriptCountByUtilityData(utilityData);

  const testCount = getTestCountByUtilityData(utilityData);

  // const testCount =
  //   utilityData?.testDetails?.filter((item) => item?.status !== 'Ignore')
  //     ?.length || 0;

  const BASIC_APPLICATION_INFORMATION = 4;
  const totalUtilityCount =
    essayCount +
    supplementalCount +
    lettersCount +
    transcriptCount +
    BASIC_APPLICATION_INFORMATION +
    testCount;
  return totalUtilityCount;
};

const getSubmittedCount = (utilityData) => {
  const essaySubmittedCount = getSubmittedValue(utilityData?.essay);
  const supplementalSubmittedCount = getSubmittedValue(
    utilityData?.supplemental
  );
  const taskCompleted =
    (utilityData?.tasksOnApplicationPlatform &&
      Object.values(utilityData?.tasksOnApplicationPlatform)?.filter(
        (item) => item
      )?.length) ||
    0;

  const modifiedLettersData = generateModifiedData(
    utilityData?.letters,
    utilityData?.recLetters
  );

  const lettersSubmittedCount = getSubmittedValue(modifiedLettersData);
  const transcriptSubmittedCount = getSubmittedValue(
    utilityData?.transcriptDetails
  );
  const testsSubmittedCount = getSubmittedValue(utilityData?.testDetails);
  const totalSubmitted =
    essaySubmittedCount +
    taskCompleted +
    supplementalSubmittedCount +
    lettersSubmittedCount +
    transcriptSubmittedCount +
    testsSubmittedCount;
  return totalSubmitted;
};

export const getApplicationCompletionValue = (utilityData) => {
  const totalCount = getTotalUtilityCount(utilityData);
  const totalCompletion = getSubmittedCount(utilityData);
  if (totalCount && totalCompletion) {
    const ratioToCount = 100 / totalCount;
    const completionValue = totalCompletion * ratioToCount;
    const count = Math.round(completionValue);
    return count;
  } else {
    return 0;
  }
};

export const checkInterviewDate = (date) => {
  const currentDate = new Date().getTime();
  if (date) {
    const givenDate = new Date(date.toDate()).getTime();
    if (currentDate > givenDate) {
      return true;
    }
  }
  return false;
};

export const isDateInRange = (target, pastDate, currentDate = new Date()) => {
  return target >= pastDate && target <= currentDate;
};

export const advanceDateByYear = (date) => {
  if (!date) return;
  return new Date(date.setFullYear(date.getFullYear() + 1));
};

export const advanceDateToYear = (date, targetYear) => {
  if (!date || !targetYear) return;
  const newDate = new Date(date);
  newDate.setFullYear(targetYear);
  return newDate;
};

export const getUserContext = (
  user,
  enrichedData,
  isParent,
  selectedStudent
) => {
  const userData = {
    name: user?.profileName,
    profileType: user?.profileType,
    grade: user?.grade,
    schoolName: user?.schoolName,
    schoolAddress: user?.schoolLocation,
    email: user?.email,
    address: user?.address,
    ...enrichedData,
  };
  // If user is parent, then we need to show the data of the student as well
  if (isParent) {
    userData.name = `${selectedStudent?.studentFirstName} ${selectedStudent?.studentLastName}`;
    userData.grade = selectedStudent?.studentGrade;
    userData.profileType = selectedStudent?.studentProfileType;
    userData.schoolName = selectedStudent?.studentSchoolName;
    userData.email = selectedStudent?.studentEmail;
    userData.schoolAddress = selectedStudent?.studentSchoolAddress;
    userData.address = selectedStudent?.studentAddress;
    const parentData = {
      name: user?.profileName,
      profileType: user?.profileType,
      grade: user?.grade,
      schoolName: user?.schoolName,
      schoolAddress: user?.schoolLocation,
      email: user?.email,
      address: user?.address,
    };
    return `Below is the data related to me, the ${
      user?.profileName
    }: \n\n ${JSON.stringify(parentData)}
    and 
    Here is the data related to the selected student: \n\n ${JSON.stringify(
      userData
    )} 
    
    Keep in mind that the data is specific to the selected student. Answer the ${
      user?.profileType
    }'s questions from their perspective, considering their relationship to the student and any relevant details.
    
    If a student is not selected, instruct the ${
      user?.profileType
    } to select a student from "My Students". If the ${
      user?.profileType
    } asks about a different student, remind them to select the appropriate student from "My Students". If data related to the selected student is not available, inform the ${
      user?.profileType
    } to verify if they have selected the correct student.     
    `;
  }
  if (!user) return '';
  return `Here is USER DATA: ${JSON.stringify(userData)}`;
};

export const getActivityContext = (activities) => {
  if (activities && activities.length > 0) {
    return activities.reduce(
      (a, b) => `${a ? `${a};` : a} ${b.activityName}`,
      ''
    );
  } else return 'none';
};
export const getHonorContext = (honors) => {
  if (honors && honors.length > 0) {
    return honors.reduce((a, b) => `${a ? `${a};` : a} ${b.honorName}`, '');
  } else return 'none';
};
export const getCourseContext = (courses) => {
  if (courses && courses.length > 0) {
    return courses.reduce((a, b) => `${a ? `${a};` : a} ${b.courseName}`, '');
  } else return 'none';
};
export const getNicheContext = (niches) => {
  if (niches && niches.length > 0) {
    return niches.reduce((a, b) => `${a ? `${a};` : a} ${b.nicheName}`, '');
  } else return 'none';
};
export const getEssayContext = (essays) => {
  if (essays && essays.length > 0) {
    return essays.reduce((a, b) => `${a ? `${a};` : a} ${b.essayName}`, '');
  } else return 'none';
};
export const getSatTestContext = (testTypes, testScores, testSubjects) => {
  let SAT = 'none';
  if (testScores && testScores.length > 0) {
    const sub = testSubjects?.find(({ subject }) => subject === 'Total');
    const testType = testTypes?.find(({ type }) => type === 'SAT');
    if (sub && testType) {
      const selectedScore = testScores?.filter(
        (item) => item?.testType === testType?.id
      );
      if (selectedScore?.length > 0) {
        const selectedSub = selectedScore
          ?.filter((item) =>
            item?.scores?.some(({ subjectId }) => subjectId === sub?.id)
          )
          ?.map((item) =>
            item?.scores?.filter(({ subjectId }) => subjectId === sub?.id)
          )
          ?.flat();
        if (selectedSub?.length > 0) {
          const maxValue = selectedSub.reduce((max, current) => {
            return current?.scoreValue > max ? current?.scoreValue : max;
          }, selectedSub[0]?.scoreValue || 0);
          SAT = maxValue;
        } else SAT = 'none';
      } else SAT = 'none';
    } else {
      SAT = 'none';
    }
  } else SAT = 'none';

  return SAT;
};
export const getActTestContext = (testTypes, testScores, testSubjects) => {
  let ACT = 'none';
  if (testScores && testScores.length > 0) {
    const sub = testSubjects?.find(({ subject }) => subject === 'Composite');
    const testType = testTypes?.find(({ type }) => type === 'ACT');
    if (sub && testType) {
      const selectedScore = testScores?.filter(
        (item) => item?.testType === testType?.id
      );
      if (selectedScore?.length > 0) {
        const selectedSub = selectedScore
          ?.filter((item) =>
            item?.scores?.some(({ subjectId }) => subjectId === sub?.id)
          )
          ?.map((item) =>
            item?.scores?.filter(({ subjectId }) => subjectId === sub?.id)
          )
          ?.flat();
        if (selectedSub?.length > 0) {
          const maxValue = selectedSub.reduce((max, current) => {
            return current?.scoreValue > max ? current?.scoreValue : max;
          }, selectedSub[0]?.scoreValue || 0);
          ACT = maxValue;
        } else ACT = 'none';
      } else ACT = 'none';
    } else {
      ACT = 'none';
    }
  } else ACT = 'none';

  return ACT;
};

export const getCollegeName = (collegeId, colleges) => {
  let collegeName = 'none';
  collegeName =
    colleges?.find((item) => item.collegeId === collegeId)?.collegeData
      ?.collegeName || 'none';
  return collegeName;
};

export const getNicheStoryItemContext = (
  niches,
  activities,
  honors,
  courses
) => {
  let finalContext = '';
  if (niches && niches.length > 0) {
    niches.forEach(({ id, nicheName }) => {
      finalContext += `Niche: ${nicheName}; Activities: ${getActivityContext(
        activities?.filter(({ nicheId }) => nicheId === id)
      )}; Honors: ${getHonorContext(
        honors?.filter(({ nicheId }) => nicheId === id)
      )}; Courses: ${getCourseContext(
        courses?.filter(({ nicheId }) => nicheId === id)
      )}; \n`;
    });
  }
  return finalContext;
};

export const getStoryContext = ({ activities, honors, courses }) => {
  return `Based on my story, what niches might apply to me?
  
  Here is my story.

  Activities: ${getActivityContext(activities)};
  Honors: ${getHonorContext(honors)}
  Courses: ${getCourseContext(courses)}
  `;
};

export const sortArrayByRecentDate = (arr) =>
  arr.sort((a, b) => {
    const dateA = a.createdAt.toDate();
    const dateB = b.createdAt.toDate();
    if (dateA > dateB) {
      return 1; // b should come before a
    } else if (dateA < dateB) {
      return -1; // b should come after a
    } else {
      return 0; // dates are equal
    }
  });

export const displayTextWithLineBreaks = (text) => {
  const textWithBreaks = text
    ? text.split('\n').map((line, index) => (
        <>
          {line}
          <br />
        </>
      ))
    : '';

  return <div>{textWithBreaks}</div>;
};

// Get the team members in the format to maintain document structure firestore
export const getTeamInFormat = (teamMembers) => {
  return teamMembers?.map((teamMember) => {
    return {
      uid: teamMember?.uid,
      role: teamMember?.role,
      dateAdded: teamMember?.dateAdded,
      invite_status: teamMember?.invite_status,
      email: teamMember.email,
    };
  });
};

export const removeNullUndefined = (array) => array.filter((content) => content !== null && content !== undefined);
