import React from 'react';


const CollegeProgressBar = ({
    progress,
    className = '',
    showPercentage = true,
    height = 'sm',
    color = 'blue'
}) => {
  // Ensure progress is between 0 and 100
    const clampedProgress = Math.min(Math.max(progress, 0), 100);
    
    const heightClass = {
        xs: 'h-1',
        sm: 'h-2',
        md: 'h-3'
    };

    const colorClass = {
        blue: 'bg-brand-blue-500',
        gray: 'bg-gray-500'
    };

    return (
    <div className={`flex items-center gap-4 w-full ${className}`}>
        <div className={`flex-1 bg-gray-200 rounded-full overflow-hidden ${heightClass[height]}`}>
            <div
            className={`${heightClass[height]} ${colorClass[color]} rounded-full transition-all duration-300 ease-in-out`}
            style={{ width: `${clampedProgress}%` }}
            />
        </div>
        {showPercentage && (
            <span className="text-xs text-gray-600 font-normal">
            {clampedProgress}%
            </span>
        )}
    </div>
    );
};

export default CollegeProgressBar;