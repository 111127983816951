import useNicheStore from '../../../stores/NicheStore';
import NicheLane from './NicheLane';

const gradesOrder = ['9th', '10th', '11th', '12th', '12th+'];

const NicheLanes = ({
    lanes = [],
    currentGrade = '12th',
    isViewDetails = false,
    setAddUtiltytoNiche = () => {},
    unAssignedLane,
    isParent = false,
    isOpen,
    canCreateNewUtilities = () => {},
    isMobile = false,
    }) => {
        const currentGradeIndex = gradesOrder.findIndex(
            (g) => g.toLowerCase() === currentGrade.toLowerCase()
        );

    return (
        <div
            className={`'h-max'
            } relative overflow-y-auto rounded-b-2xl`}
        >
            <div className="sticky h-72 inset-0 flex pointer-events-none z-40">
                {!isViewDetails && <div className="w-48 shrink-0"></div>}
                <div className="grid grid-cols-6 flex-1 h-full border-l border-[#D6D6D8]">
                {gradesOrder.map((grade, index) => (
                    <div
                    key={index}
                    className="col-span-1 border-r border-dashed"
                    style={{
                        borderColor:
                        currentGradeIndex === index ? '#332C2B' : '#BABAAE',
                    }}
                    />
                ))}
                </div>
            </div>
            <div className="absolute inset-0">
                {
                lanes?.length > 0
                    ? lanes?.map((lane, index) => (
                        <div
                        key={index}
                        className="border-b last:border-b-0 overflow-auto"
                        style={{ backgroundColor: lane.color }}
                        >
                            <NicheLane
                                {...lane}
                                reviewers={lane?.sharedWith}
                                isParent={isParent}
                                isViewDetails={isViewDetails}
                                unAssignedLane={unAssignedLane}
                                setAddUtiltytoNiche={setAddUtiltytoNiche}
                                canCreateNewUtilities={canCreateNewUtilities}
                                isMobile={isMobile}
                            />
                        </div>
                    ))
                    : null
                }
            </div>
        </div>
    );
};

export default NicheLanes;
